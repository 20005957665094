<template>
  <el-dialog :visible="showModify" :title="titles[handleType]" width="600px" :show-close="false">
    <el-form
      v-if="['edit','add'].includes(handleType)"
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="150px"
    >
      <el-form-item label="成品物料" prop="materialsId">
        <!-- 成品物料 -->
        <SelectRemoteSearch
          v-if="['add'].includes(handleType)"
          ref="materialsId"
          v-model="ruleForm.materialsId"
          class="select"
          clearable
          placeholder="请选择成品物料"
          option-val="id"
          option-label="fullLabel"
          size-name="size"
          :api="processApi.getFinishedMaterials"
          :params="{status:0}"
          collapse-tags
          @change="val=>handleSelect('material',val)"
        />
        <div v-else>{{ ruleForm.materialsName }}</div>
      </el-form-item>
      <el-form-item label="工艺路线" prop="processFlowId">
        <SelectRemoteSearch
          v-if="['add'].includes(handleType)"
          ref="processFlowId"
          v-model="ruleForm.processFlowId"
          :disabled="!ruleForm.materialsId"
          :super-disabled="!ruleForm.materialsId"
          class="select"
          clearable
          placeholder="请选择工艺路线"
          option-val="id"
          option-label="name"
          :api="processApi.getProcessList"
          collapse-tags
          :params="processParams"
          @change="val=>handleSelect('processFlowId',val)"
        />
        <div v-else>{{ ruleForm.processFlowName }}</div>
      </el-form-item>
      <el-form-item label="版本" prop="version">
        <SelectRemoteSearch
          v-if="['add'].includes(handleType)"
          ref="version"
          v-model="ruleForm.version"
          :disabled="!ruleForm.processFlowId"
          :super-disabled="!ruleForm.processFlowId"
          class="select"
          clearable
          placeholder="请选择版本"
          option-val="procedureList"
          option-label="version"
          :api="processApi.getProcessDetail"
          collapse-tags
          :params="procedureParams"
          result-field-name="versionList"
          @change="val=>handleSelect('version',val)"
        />
        <div v-else>{{ ruleForm.processFlowVersion }}</div>
      </el-form-item>
      <el-form-item label="工序" prop="flowProcedureId">
        <el-select
          v-if="['add'].includes(handleType)"
          v-model="ruleForm.flowProcedureId"
          placeholder="请选择工序"
          :disabled="!ruleForm.version"
        >
          <el-option
            v-for="item in procedureList"
            :key="item.id"
            :label="item.procedureName"
            :value="item.id"
          />
        </el-select>
        <div v-else>{{ ruleForm.procedureName }}</div>
      </el-form-item>
      <el-form-item label="合格品单价" prop="qualifiedPrice">
        <el-input-number
          v-model="ruleForm.qualifiedPrice"
          controls-position="right"
          :step="0.01"
          @blur="ruleForm.qualifiedPrice=sliceVal(ruleForm.qualifiedPrice,2)"
        />
      </el-form-item>
      <el-form-item prop="unqualifiedPrice">
        <span slot="label">不良品单价<el-tooltip
          class="item"
          effect="dark"
          content="注：可设为负数，表示不良品需要罚钱。"
          placement="top"
        >
          <i class="el-icon-question" />
        </el-tooltip>
        </span>

        <el-input-number
          v-model="ruleForm.unqualifiedPrice"
          controls-position="right"
          :step="0.01"
          @blur="ruleForm.unqualifiedPrice=sliceVal(ruleForm.unqualifiedPrice,2)"
        />
      </el-form-item>
      <el-form-item v-if="handleType==='edit'" label="历史数据如何处理" prop="isInspect">
        <el-radio v-model="ruleForm.isInspect" :label="false">历史数据保留不影响</el-radio>
        <el-radio v-model="ruleForm.isInspect" :label="true">重新计算所选时间范围内的数据</el-radio>
      </el-form-item>
      <el-form-item v-if="ruleForm.isInspect&&handleType==='edit'" label="时间" prop="time">
        <el-date-picker
          v-model="ruleForm.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          clearable
        />
      </el-form-item>
    </el-form>
    <el-form
      v-if="['del'].includes(handleType)"
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="220px"
    >
      <el-form-item label="修改后历史工资数据如何处理" prop="isInspect">
        <el-radio v-model="ruleForm.isInspect" :label="false">历史数据保留不影响</el-radio>
        <el-radio v-model="ruleForm.isInspect" :label="true">重新计算所选时间范围内的数据</el-radio>
      </el-form-item>
      <el-form-item v-if="ruleForm.isInspect" label="时间" prop="time">
        <el-date-picker
          v-model="ruleForm.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          clearable
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="medium" @click="hidePop">取 消</el-button>
      <el-button type="primary" size="medium" @click="onSave">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import processApi from '@/api/information/production/process'
import api from '@/api/production/prodStatistic'
import { sliceVal } from '@/utils'
export default {
  props: {
    handleType: {
      type: String,
      default: ''
    },
    editRaw: {
      type: Object,
      default: () => ({})
    }

  },
  data() {
    const validate = (rule, value, callback) => {
      if (!value && ['add'].includes(this.handleType)) {
        callback(new Error('请选择版本'))
      }
      callback()
    }
    return {
      showModify: false,
      processApi,
      processParams: {
        materialsId: ''
      },
      procedureParams: {
        id: ''
      },
      titles: {
        edit: '修改',
        add: '新增',
        del: '删除'
      },
      ruleForm: {
        materialsId: '',
        processFlowId: '',
        version: '',
        flowProcedureId: '',
        qualifiedPrice: '',
        unqualifiedPrice: '',
        isInspect: false,
        time: []
      },
      rules: {
        materialsId: [
          { required: true, message: '请选择物料', trigger: 'change' }
        ],
        processFlowId: [
          { required: true, message: '请选择工艺路线', trigger: 'change' }
        ],
        version: [
          { required: true, validator: validate, trigger: 'change' }
        ],
        flowProcedureId: [
          { required: true, message: '请选择工序', trigger: 'change' }
        ],
        qualifiedPrice: [
          { required: true, message: '请输入合格品单价', trigger: 'change' }
        ],
        unqualifiedPrice: [
          { required: true, message: '请输入不良品单价', trigger: 'change' }
        ],
        radio: [
          { required: true, message: '请选择处理方式', trigger: 'change' }
        ],
        time: [
          { required: true, message: '请选择时间', trigger: 'change' }
        ]
      },
      procedureList: [] // 工序
    }
  },
  created() {
  },
  methods: {
    sliceVal,
    hidePop() {
      this.initData()
      this.showModify = false
    },
    showPop() {
      this.showModify = true
      this.getDetail()
    },
    getDetail(raw) {
      this.ruleForm = { ...this.ruleForm, ...this.editRaw }
    },
    initData() {
      this.ruleForm = {
        materialsId: '',
        processFlowId: '',
        version: '',
        flowProcedureId: '',
        qualifiedPrice: '',
        unqualifiedPrice: '',
        isInspect: false,
        time: []
      }
    },
    round(number, precision) {
      return this._.round(number, precision)
    },
    handleSelect(type, val) {
      switch (type) {
        case 'material':
          this.ruleForm.processFlowId = ''
          this.ruleForm.flowProcedureId = ''
          this.ruleForm.version = ''
          this.procedureList = []
          this.processParams.materialsId = val
          this.$refs.processFlowId.reset()
          break

        case 'processFlowId':
          this.ruleForm.flowProcedureId = ''
          this.ruleForm.version = ''
          this.procedureList = []
          this.procedureParams.id = val
          this.$refs.version.reset()
          break

        case 'version':
          this.ruleForm.flowProcedureId = ''
          this.procedureList = val
          break
        default:
          break
      }
    },

    async onSave() {
      console.log(this.ruleForm)
      const valid = await this.$refs.ruleForm.validate()
      if (!valid) return
      const { flowProcedureId, materialsId, processFlowId, qualifiedPrice, unqualifiedPrice, isInspect, time, version } = this.ruleForm
      let params
      let Api
      switch (this.handleType) {
        case 'add':
        {
          // 找出所选版本下的所选工序的版本ID
          const processFlowVersionId = version.find(item => item.processFlowId === processFlowId).processFlowVersionId
          params = {
            flowProcedureId,
            materialsId,
            processFlowId,
            qualifiedPrice,
            unqualifiedPrice,
            processFlowVersionId
          }
          Api = api.createSalary
          break
        }

        case 'edit':
          params = {
            isInspect,
            qualifiedPrice,
            unqualifiedPrice,
            inspectStartDate: time.length ? time[0] : null,
            inspectEndDate: time.length ? time[1] : null,
            id: this.ruleForm.id
          }
          Api = api.editSalary
          break

        default:
          params = {
            isInspect,
            inspectStartDate: time.length ? time[0] : null,
            inspectEndDate: time.length ? time[1] : null,
            id: this.ruleForm.id
          }
          Api = api.delSalary
          break
      }
      const res = await Api(params)
      if (res) {
        this.$message.success('操作成功')
        this.hidePop()
        this.$parent.$refs.mTable.setTableData(true)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 22px;
}
::v-deep .el-dialog__header {
  background: #f8f8fb;
  padding: 20px;
}
.dialog-footer {
  height: 57px;
  width: 100%;
  border-top: 1px solid #dde3eb;
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
