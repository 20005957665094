<template>
  <div class="container">
    <div class="header-box">
      <div class="left-box">
        <div class="top-box">
          <div class="sum-box">
            本月累计工资/元
            <div class="count-1">¥{{ detail.currentMonthTotal }}</div>
            今日新增
            <div class="count-2">+¥{{ detail.todayTotal }}</div>
          </div>
          <img class="icon" src="@/assets/plans/salary1.png">
        </div>
        <div class="bottom-box">
          <div class="type-box">
            <img class="icon" src="@/assets/plans/salary3.png">
            <div>
              合格品
              <p style="color: #00B42E;font-size: 28px;font-weight: 600;">
                ¥{{ detail.currentMonthQualifiedTotal }}</p>
            </div>
          </div>
          <div class="type-box" style="background: rgba(224,32,32,0.1);">
            <img class="icon" src="@/assets/plans/salary2.png">
            <div>
              不良品
              <p style="color: #E02020;font-size: 28px;font-weight: 600;">
                ¥{{ detail.currentMonthUnqualifiedTotal }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class=" right-box">
        <div class="chart-title">月度工资总额</div>
        <ve-histogram height="240px" :data="chartData" :extend="barChartExtend" />
      </div>
    </div>

    <div class="table-box">
      <p style="font-size: 18px;font-weight: 600;color: #41456B;margin-top:0">今日生产任务</p>
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data="searchFormData"
        style="padding: 0"
        @update:formData="data=>searchFormData=data"
        @search="reSearch"
      >
        <SelectRemoteSearch
          v-for="item in searchFormList"
          :key="item.key"
          :slot="item.key"
          :ref="item.key"
          class="select"
          clearable
          :placeholder="apiMaps[item.key].placeholder"
          :multiple="!apiMaps[item.key].notMultiple"
          :option-val="apiMaps[item.key].optionVal"
          :option-label="apiMaps[item.key].optionLabel"
          :api="apiMaps[item.key].api"
          collapse-tags
          @change="val => {
            handleSelect(item.key, val)
          }
          "
        />
      </SearchForm>

      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        :tags="functionHeader.tags"
        search-title="请输入工号/姓名"
        style="padding: 10px 0 10px 0;"
        class="func-header"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" class="btn-box">
          <div>
            <el-button
              v-for="btn in btns"
              :key="btn.key"
              size="small"
              :style="{borderRadius: '14px'}"
              @click="onClickTime(btn.key)"
            >{{ btn.innerText }}</el-button>
          </div>
          <el-date-picker
          style="width: 400px"
            v-model="functionHeader.time"
            type="daterange"
            range-separator="至"
            start-placeholder="计划开工时间"
            end-placeholder="完工时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
          />
        </div>
      </FunctionHeader>
      <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"
        @selection-change="data=>selectData = data"
      >
        <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
      </MTable>
    </div>
  </div>
</template>
<script>
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { columns, searchFormList } from './data'
import api from '@/api/production/prodStatistic'
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import dayjs from 'dayjs'
const today = dayjs()
const startOfDay = today.startOf('day').format('YYYY-MM-DD HH:mm:ss')
const endOfDay = today.endOf('day').format('YYYY-MM-DD HH:mm:ss')
const startOfWeek = dayjs().startOf('week').format('YYYY-MM-DD HH:mm:ss')
const endOfWeek = dayjs().endOf('week').format('YYYY-MM-DD HH:mm:ss')
const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss')
const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss')
const startOfYear = dayjs().startOf('year').format('YYYY-MM-DD HH:mm:ss')
const endOfYear = dayjs().endOf('year').format('YYYY-MM-DD HH:mm:ss')
export default {
  name: 'PerformanceSalary',
  components: { FunctionHeader, SearchForm },
  data() {
    return {
      api,
      barChartExtend: {
        grid: {
          left: 0,
          right: 0,
          top: 20,
          bottom: 0
        },
        legend: {
          show: false
        },
        xAxis: {
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              color: '#9597AE'
            }
          },
          axisLabel: {
            interval: 0, // 横轴信息全部显示
            fontSize: 14
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#DDE3EB'
            }
          },
          axisLabel: {
            fontSize: 14,
            color: '#9597AE'
          }
        },
        tooltip: {
          show: true
        },
        series: {
          barWidth: '40%',
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: '#F6F7F9'
          },
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#607FFF' // 0% 处的颜色
              }, {
                offset: 1, color: '#607FFF' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
          label: {
            show: false,
            position: 'top',
            valueAnimation: true,
            fontSize: 20
          }
        },
        textStyle: {
          color: '#9597AE'
        }
      },
      chartData: {
        columns: ['日期', '工资'],
        rows: []
      },
      apiMaps: {
        orderId: {
          api: api.getOrderPages,
          notMultiple: true,
          placeholder: '生产订单',
          optionVal: 'id',
          optionLabel: 'produceOrderNumber'
        },
        workshopIds: {
          placeholder: '车间',
          api: () => api.getAreaList({
            searchVal: Encrypt(JSON.stringify(
              [{
                id: getUUid(),
                fieldName: 'type',
                fieldType: 'number',
                fieldValue: 1,
                operator: 'eq'
              }]
            ))
          }),
          optionVal: 'id',
          optionLabel: 'name'
        },
        lineIds: {
          placeholder: '产线',
          api: () => api.getAreaList({
            searchVal: Encrypt(JSON.stringify(
              [{
                id: getUUid(),
                fieldName: 'type',
                fieldType: 'number',
                fieldValue: 2,
                operator: 'eq'
              }]
            ))
          }),
          optionVal: 'id',
          optionLabel: 'name'
        },
        organizationIds: {
          placeholder: '组织架构',
          api: api.getOrganizationListPage,
          optionVal: 'id',
          optionLabel: 'name'
        },
        userGroupIds: {
          placeholder: '用户组',
          api: api.getUserGroupListPage,
          optionVal: 'id',
          optionLabel: 'name'
        }
      },
      btns: [
        {
          props: { size: 'small' },
          innerText: '今日',
          key: 'day'
        },
        {
          props: { size: 'small' },
          innerText: '本周',
          key: 'week'
        },
        {
          props: { size: 'small' },
          innerText: '本月',
          key: 'month'
        },
        {
          props: { size: 'small' },
          innerText: '本年',
          key: 'year'
        }
      ],
      functionHeader: {
        searchVal: '',
        time: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            innerText: '全部导出',
            hidden: !this.permission('Export'),
            on: {
              // click: this.purchase
            }
          },
          {
            tagName: 'el-button',
            props: { size: 'small' },
            innerText: '导出',
            hidden: !this.permission('Export'),
            on: {
              // click: this.purchase
            }
          }
        ]
      },
      searchFormData: {},
      columns,
      searchFormList,
      selectData: [],
      detail: {}
    }
  },

  computed: {
    height() {
      return this.$store.state.app.contentHeight - 600
    }
  },
  created() {
    this.getChartData()
  },
  mounted() {
    this.onClickTime('month')
  },
  methods: {
    onClickTime(key) {
      const time = {
        day: [startOfDay, endOfDay],
        week: [startOfWeek, endOfWeek],
        month: [startOfMonth, endOfMonth],
        year: [startOfYear, endOfYear]
      }
      this.functionHeader.time = time[key]
      this.$refs.mTable.setTableData(true)
    },
    handleSelect(key, val) {
      this.searchFormData[key] = val
      this.$refs.mTable?.setTableData(true)
    },
    reSearch(data, val) {
      if (val === 1) {
        this.searchFormData = {}
        const refs = ['orderId', 'workshopIds', 'lineIds', 'organizationIds', 'userGroupIds']
        refs.forEach(ref => { this.$refs[ref][0].value = '' })
      }
      this.$refs.mTable.setTableData(true)
    },
    // 图标
    async getChartData() {
      const res = await api.getSalaryChart()
      this.detail = res
      this.chartData.rows = this.detail.monthTotals.map(item => ({ 日期: item.month === 1 ? item.year + '年' + item.month + '月' : item.month + '月', 工资: item.total }))
    },
    // 列表
    async getTableData(condition, callback) {
      const { page } = condition
      const { orderId, workshopIds = [], lineIds = [], organizationIds = [], userGroupIds = [] } = this.searchFormData
      const { searchVal, time } = this.functionHeader
      const params = {
        searchVal: searchVal,
        orderId,
        workshopIds: workshopIds.join(','),
        lineIds: lineIds.join(','),
        organizationIds: organizationIds.join(','),
        userGroupIds: userGroupIds.join(','),
        startTime: time.length ? time[0] : null,
        endTime: time.length ? time[1] : null
      }
      // 导出参数
      // this.functionHeader.exportParams = params
      const res = await api.getSalaryList({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      if (res) {
        this.showTable = res.total
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    }
  }

}
</script>

<style lang="scss" scoped>
.btn-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
::v-deep .custom-sel {
  div {
    min-width: 100%;
  }
}
.container {
  background-color: #f0f2f5;
  height: 100%;
  padding: 0 12px 0 0;

  .header-box {
    display: flex;
    justify-content: space-between;
    height: 285px;

    .left-box {
      font-size: 14px;
      color: #9597ae;
      flex: 1;
      background: #fff;
      margin-right: 12px;
      border-radius: 4px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sum-box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 126px;

          .count-1 {
            font-size: 46px;
            font-weight: 600;
            color: #41456b;
          }

          .count-2 {
            font-size: 20px;
            font-weight: 600;
            color: #41456b;
          }
        }

        .icon {
          width: 118px;
          height: auto;
        }
      }

      .bottom-box {
        display: flex;
        justify-content: space-between;

        .type-box {
          font-size: 18px;
          color: #393d60;
          display: flex;
          justify-content: space-between;
          padding: 8px;
          height: 120px;
          width: 49%;
          background: rgba(0, 171, 41, 0.1);
          border-radius: 4px;

          .icon {
            width: 50px;
            height: 50px;
          }

          div {
            margin: 20px 0;
            text-align: right;
          }
        }
      }
    }

    .right-box {
      flex: 2;
      background: #fff;
      border-radius: 4px;
      padding: 12px;
    }
  }

  .table-box {
    width: 100%;
    height: calc(100% - 300px);
    background: #fff;
    border-radius: 4px;
    margin-top: 12px;
    padding: 10px;
  }

  ::v-deep .function-header-wrap {
    .function-left {
      width: 100%;
      justify-content: space-between;
      padding-right: 90px;
    }
  }
  .chart-title {
    font-size: 18px;
    font-weight: 600;
    color: #545982;
  }
}
</style>
