var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item" },
    [
      _c("div", { staticClass: "left" }, [
        _vm.judgeField("batchNumber")
          ? _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.itemInfo.batchNumber || "--") + " ")
            ])
          : _vm._e(),
        _vm.judgeField("leftNumber")
          ? _c("div", { staticClass: "itemBar" }, [
              _c("div", [
                _vm._v(
                  "成品相关数量（" + _vm._s(_vm.itemInfo.mainUnitName) + "）："
                )
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.itemInfo.workOrderQuantity) +
                    "/" +
                    _vm._s(_vm.itemInfo.sourceQuantity) +
                    "/" +
                    _vm._s(_vm.itemInfo.produceWorkOrder.reportWorkQuantity)
                )
              ])
            ])
          : _vm._e(),
        _vm.judgeField("materialsInfo")
          ? _c("div", { staticClass: "itemBar" }, [
              _c("div", [_vm._v("成品：")]),
              _c("div", { staticClass: "itemRight" }, [
                _c("span", [_vm._v(_vm._s(_vm.itemInfo.specifications || ""))]),
                _c("span", { staticClass: "dividerColor" }, [
                  _vm._v(_vm._s(_vm.itemInfo.specifications ? "|" : ""))
                ]),
                _c("span", [_vm._v(_vm._s(_vm.itemInfo.materialsCode || ""))]),
                _c("span", { staticClass: "dividerColor" }, [
                  _vm._v(_vm._s(_vm.itemInfo.materialsCode ? "|" : ""))
                ]),
                _c("span", [_vm._v(_vm._s(_vm.itemInfo.materialsName || ""))])
              ])
            ])
          : _vm._e(),
        _vm.judgeField("produceWorkOrderNumber")
          ? _c("div", { staticClass: "itemBar" }, [
              _c("div", [_vm._v("生产工单：")]),
              _c("span", [
                _vm._v(_vm._s(_vm.itemInfo.produceWorkOrderNumber || "--"))
              ])
            ])
          : _vm._e(),
        _vm.judgeField("customerName")
          ? _c("div", { staticClass: "itemBar" }, [
              _c("div", [_vm._v("客户：")]),
              _c("span", [_vm._v(_vm._s(_vm.itemInfo.customerName || "--"))])
            ])
          : _vm._e(),
        _vm.judgeField("deliveryTime")
          ? _c("div", { staticClass: "itemBar" }, [
              _c("div", [_vm._v("交期：")]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.itemInfo.produceWorkOrder.deliveryTime || "--")
                )
              ])
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _vm.judgeField("procedureName")
            ? _c("div", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.itemInfo.procedureName || "--") + " "),
                _c(
                  "span",
                  {
                    staticClass: "status",
                    style: {
                      color: _vm.statusList[_vm.itemInfo.status].color,
                      background: _vm.statusList[_vm.itemInfo.status].background
                    }
                  },
                  [_vm._v(_vm._s(_vm.statusList[_vm.itemInfo.status].name))]
                )
              ])
            : _vm._e(),
          _vm.judgeField("midMaterial")
            ? _c("div", { staticClass: "itemBar" }, [
                _c("div", [_vm._v("中间品：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.itemInfo.produceWorkOrderTaskOutputMaterials
                        ? _vm.itemInfo.produceWorkOrderTaskOutputMaterials
                            .materials
                          ? _vm.itemInfo.produceWorkOrderTaskOutputMaterials
                              .materials.name
                          : "--"
                        : "--"
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.judgeField("rightNumber")
            ? _c("div", { staticClass: "itemBar" }, [
                _c("div", [
                  _vm._v(
                    "中间品相关数量（" +
                      _vm._s(_vm.itemInfo.mainUnitName) +
                      "）："
                  )
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.itemInfo.produceWorkOrderTaskOutputMaterials
                        ? _vm.itemInfo.produceWorkOrderTaskOutputMaterials
                            .quantity
                          ? _vm.itemInfo.produceWorkOrderTaskOutputMaterials
                              .quantity
                          : 0
                        : 0
                    ) +
                      "/" +
                      _vm._s(
                        _vm.itemInfo.outputReportWorkQuantity
                          ? _vm.itemInfo.outputReportWorkQuantity
                          : 0
                      ) +
                      " (" +
                      _vm._s(_vm.itemInfo.reportWorkQuantity) +
                      ")"
                  ),
                  !_vm.isShowCheckBox
                    ? _c("em", {
                        staticClass: "el-icon-edit editClor",
                        on: { click: _vm.toEditCount }
                      })
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm.judgeField("sourceQuantity")
            ? _c("div", { staticClass: "itemBar" }, [
                _c("div", [_vm._v("上道工序产出数量：")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.itemInfo.sourceQuantity || "--") + " ")
                ])
              ])
            : _vm._e(),
          _vm.judgeField("remark")
            ? _c("div", { staticClass: "itemBar" }, [
                _c("div", [_vm._v("备注：")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.itemInfo.remark || "--") + " "),
                  !_vm.isShowCheckBox
                    ? _c("em", {
                        staticClass: "el-icon-edit editClor",
                        on: { click: _vm.toEditRemark }
                      })
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm._l(_vm.itemInfo.produceWorkOrderTaskFieldMappingList, function(
            item,
            index
          ) {
            return _c("div", { key: index }, [
              item.type === 5 && item.isShow
                ? _c("div", { staticClass: "itemBar" }, [
                    _c("div", [_vm._v(_vm._s(item.fieldName) + "：")]),
                    _c("span", [
                      _vm._v(_vm._s(item.value ? item.value : "--") + " "),
                      !_vm.isShowCheckBox
                        ? _c("em", {
                            staticClass: "el-icon-edit editClor",
                            on: {
                              click: function($event) {
                                return _vm.toEditField(item)
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            ])
          }),
          _c(
            "div",
            {
              staticClass: "time",
              style: {
                color: _vm.statusList[_vm.itemInfo.status].color,
                background: _vm.statusList[_vm.itemInfo.status].background
              }
            },
            [_vm._v(" " + _vm._s(_vm.itemInfo.planEndTime.slice(0, 10)) + " ")]
          )
        ],
        2
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "编辑备注" },
          on: { onOk: _vm.updateRemark },
          model: {
            value: _vm.editDialogRemarkVisible,
            callback: function($$v) {
              _vm.editDialogRemarkVisible = $$v
            },
            expression: "editDialogRemarkVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "dialogFormBuildsRemark",
            attrs: {
              "form-data": _vm.dialogFormDatas,
              "form-list": _vm.dialogFormLists
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "编辑数量" },
          on: { onOk: _vm.updateCount },
          model: {
            value: _vm.editDialogCountVisible,
            callback: function($$v) {
              _vm.editDialogCountVisible = $$v
            },
            expression: "editDialogCountVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "dialogFormBuildsCount",
            attrs: {
              "form-data": _vm.dialogFormDatasCount,
              "form-list": _vm.dialogFormListsCount
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "编辑" + _vm.modifyField.fieldName },
          on: { onOk: _vm.submitFieldForm },
          model: {
            value: _vm.fieldVisible,
            callback: function($$v) {
              _vm.fieldVisible = $$v
            },
            expression: "fieldVisible"
          }
        },
        [
          _c("el-input", {
            staticStyle: { "padding-right": "15px" },
            attrs: { placeholder: "请输入" },
            model: {
              value: _vm.modifyField.text,
              callback: function($$v) {
                _vm.$set(_vm.modifyField, "text", $$v)
              },
              expression: "modifyField.text"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }