// 工单列表
export const columnsAll = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '生产状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  // {
  //   prop: 'workProcess',
  //   label: '工单进度',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'workProcess',
  //   minWidth: 180
  // },
  {
    prop: 'quantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: 'custom',
    width: 120,

  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    hiddenSearch: true,
    sortable: false,
    width: 120,
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '生产工单号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 200,
    slotName: 'produceWorkOrderNumber'
  },
  {
    prop: 'batchNumber',
    label: '生产批次码',
    hiddenSearch: true,
    sortable: false,
    width: 220
  },
  {
    prop: 'currentProcedureGroupName',
    label: '工单当前所处工序段',
    hiddenSearch: true,
    sortable: false,
    slotName: 'currentProcedureGroupName',
    width: 200
  },
  {
    prop: 'currentProcedureName',
    label: '工单当前所处工序',
    hiddenSearch: true,
    sortable: false,
    slotName: 'currentProcedure',
    width: 200
  },
  {
    prop: 'procedureClassName',
    label: '当前工序的工序分类',
    hiddenSearch: true,
    sortable: false,
    width: 200
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode',
    width: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 400
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false,
    slotName: 'processFlowName',
    width: 180
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'planStartTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'planEndTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'actualStartTime',
    label: '实际开始时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'actualEndTime',
    label: '实际结束时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'priority',
    label: '计划优先级',
    hiddenSearch: true,
    sortable: 'custom',
    slotName: 'priority',
    width: 150
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: 'custom',
    slotName: 'produceOrderNumber',
    width: 180
  },
  {
    prop: 'source',
    label: '工单来源',
    hiddenSearch: true,
    sortable: false,
    slotName: 'source'
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 250
  }
]
export const columnsBase = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsPicture',
    label: '物料图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsPicture'
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 300
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'quantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'reportWorkQuantity',
    label: '完工数量',
    hiddenSearch: true,
    sortable: false,
    width: 130
  }
]
export const operatorTask = [
  {
    prop: 'code',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', colSpan: 6 }
  },
  {
    prop: 'name',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'type',
    label: '工序类别',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '工序状态',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
export const fileAndDrawings = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'fileName',
    label: '名称',
    slotName: 'fileName',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    slotName: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
export const batchNumbers = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false
  }

]
export const reportWork = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'procedureIndex',
    label: '工序顺序',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'procedureType',
    label: '工序类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'procedureType'
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'arrivalTime',
    label: '进站时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'arrivalTime',
    width: 250
  },
  {
    prop: 'departureTime',
    label: '出站时间',
    sortable: false,
    hiddenSearch: true,
    slotName: 'departureTime',
    width: 250
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 190
  }
]

export const repairRecord = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'repairType',
    label: '返修类型',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'sourceWorkOrderNumber',
    label: '返修工单号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'sourceWorkOrderNumber',
    width: 150
  },
  {
    prop: 'status',
    label: '处理状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    width: 160,
    slotName: 'batchNumber'
  },
  {
    prop: 'repairCount',
    label: '返修数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planCount',
    label: '已计划数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'procedureName',
    label: '工序',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'inspectionSchemeName',
    label: '质检方案',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'inspectionSheetNumber',
    label: '质检单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '源工单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'inspectorName',
    label: '检验人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'endTime',
    label: '检验提交时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'planList',
    label: '返修计划号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'planList'
  }
]
export const receiveMaterials = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'warehouseType',
    label: '仓库',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseType',
    width: 200
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code'
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name'
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    slotName: 'specifications'
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '投产数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'width',
    label: '宽度',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'steelGrade',
    label: '钢卷号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'coilNo',
    label: '牌号',
    sortable: false,
    hiddenSearch: true
  }
]
export const arrivalColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'type',
    label: '类型',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'alreadyDepartureQuantity',
    label: '进/出站数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operateTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operator',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'actions',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 100
  }
]
export const workColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'stationName',
    label: '报工工位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'moldInstances',
    label: '所用模具',
    slotName: 'moldInstances',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operator',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'frequencyName',
    label: '班次',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operateTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 180
  }
]
export const wasteColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'reportWasteQuantity',
    label: '报废数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'wasteReason',
    label: '报废原因',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operator',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'frequencyName',
    label: '班次',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operateTime',
    label: '操作时间',
    sortable: false,
    hiddenSearch: true
  }
]
export const paramColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'code',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit',
    label: '状态',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsUnit1',
    label: '工序任务详情',
    sortable: false,
    hiddenSearch: true
  }
]
// 工序任务列表
export const operationTask = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '工序状态',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    slotName: 'status'
  },
  {
    prop: 'workOrderQuantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: 'custom',
    width: 120,
    headerTooltip:true,
    headerTotalcount:0

  },
  {
    prop: 'reportWorkQuantity',
    label: '已报工数量',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    headerTooltip:true,
    headerTotalcount:0
  },
  {
    prop: 'batchNumber',
    label: '生产批次码',
    hiddenSearch: true,
    sortable: false,
    width: 220
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: 'custom',
    slotName: 'produceOrderNumber',
    width: 180
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    hiddenSearch: true,
    sortable: false,
    width: 250
  },
  {
    prop: 'stationEquipment',
    label: '工位/设备',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    slotName: 'stationEquipment'
  },
  {
    prop: 'arrivalTime',
    label: '进站时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'departureTime',
    label: '出站时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode',
    width: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 400
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'currentProcedureGroupName',
    label: '工单当前所处工序段',
    hiddenSearch: true,
    sortable: false,
    slotName: 'currentProcedureGroupName',
    width: 200
  },
  {
    prop: 'currentProcedureName',
    label: '工单当前所处工序',
    hiddenSearch: true,
    sortable: false,
    slotName: 'currentProcedure',
    width: 180
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '所属生产工单号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 200,
    slotName: 'produceWorkOrderNumber'
  },
  {
    prop: 'procedureType',
    label: '工序类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'procedureType'
  },
  {
    prop: 'procedureClass',
    label: '工序属性',
    hiddenSearch: true,
    sortable: false,
    slotName: 'procedureClass'
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 230
  }
]
// 添加的工位
export const columnsStation = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'stationName',
    label: '工位名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '下发数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity',
    width: 210
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 100,
    align: 'center'
  }
]
export const columnsStations = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'stationName',
    label: '工位名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentNames',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'equipmentNames'
  },
  {
    prop: 'quantity',
    label: '下发数量',
    sortable: false,
    hiddenSearch: true
  }
]
export const stationColums = [
  {
    prop: 'stationName',
    label: '工位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '返修数量',
    sortable: false,
    hiddenSearch: true
  }
]

export const inspectionOrder = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '质检状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'inspectionType',
    label: '质检类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'inspectionType'
  },
  {
    prop: 'number',
    label: '质检单号',
    hiddenSearch: true,
    sortable: false,
    width: 160,
    slotName: 'number'
  },
  {
    prop: 'inspectionSchemeName',
    label: '质检方案',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'procedureName',
    label: '工序',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reports',
    label: '检验报告',
    hiddenSearch: true,
    sortable: false,
    slotName: 'reports'
  },
  {
    prop: 'creator',
    label: '制单人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '制单时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'inspectorName',
    label: '检验人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'startTime',
    label: '质检开始时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'endTime',
    label: '质检提交时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchCount',
    label: '批次数量',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'passRate',
    label: '合格率',
    hiddenSearch: true,
    sortable: false,
    slotName: 'passRate'
  },
  {
    prop: 'allCount',
    label: '检验（合格/不合格）',
    hiddenSearch: true,
    sortable: false,
    slotName: 'allCount'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 200
  }
]
// 外协任务列表
export const outSourceTask = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '工序状态',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    slotName: 'status'
  },
  {
    prop: 'workOrderQuantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: 'custom',
    width: 120
  },
  {
    prop: 'outWarehouseQuantity',
    label: '已出库数量',
    hiddenSearch: true,
    sortable: 'custom',
    width: 120
  },
  {
    prop: 'outWarehouseQuantityRecord',
    label: '出库记录',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    slotName: 'outWarehouseQuantityRecord'
  },
  {
    prop: 'inWarehouseQuantity',
    label: '已入库数量',
    hiddenSearch: true,
    sortable: 'custom',
    width: 120
  },
  {
    prop: 'inWarehouseQuantityRecord',
    label: '入库记录',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    slotName: 'inWarehouseQuantityRecord'
  },
  {
    prop: 'planInDates',
    label: '预计入库日期',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'suppliers',
    label: '供应商',
    hiddenSearch: true,
    sortable: false,
    width: 220
  },
  {
    prop: 'batchNumber',
    label: '生产批次码',
    hiddenSearch: true,
    sortable: false,
    width: 220
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: 'custom',
    slotName: 'produceOrderNumber',
    width: 180
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    hiddenSearch: true,
    sortable: false,
    width: 250
  },
  {
    prop: 'arrivalTime',
    label: '进站时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'departureTime',
    label: '出站时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode',
    width: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 400
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'currentProcedureGroupName',
    label: '工单当前所处工序段',
    hiddenSearch: true,
    sortable: false,
    slotName: 'currentProcedureGroupName',
    width: 180
  },
  {
    prop: 'currentProcedureName',
    label: '工单当前所处工序',
    hiddenSearch: true,
    sortable: false,
    slotName: 'currentProcedure',
    width: 180
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '所属生产工单号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 200,
    slotName: 'produceWorkOrderNumber'
  },
  {
    prop: 'procedureType',
    label: '工序类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'procedureType'
  },
  {
    prop: 'procedureClass',
    label: '工序属性',
    hiddenSearch: true,
    sortable: false,
    slotName: 'procedureClass'
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 80
  }
]
// 入库记录
export const inWarehouseColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'operateTime',
    label: '入库时间',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'quantity',
    label: '入库数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'supplierName',
    label: '供应商',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'operator',
    label: '操作人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'frequencyName',
    label: '班次',
    hiddenSearch: true,
    sortable: false
  }
]
// 出库记录
export const outWarehouseColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'operateTime',
    label: '出库时间',
    hiddenSearch: true,
    sortable: false,
    width: 180
  },
  {
    prop: 'quantity',
    label: '出库数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'supplierName',
    label: '供应商',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'operator',
    label: '操作人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'frequencyName',
    label: '班次',
    hiddenSearch: true,
    sortable: false
  }
]
// 减产记录
export const reductionRecord = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'procedureName',
    label: '减产工序',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'reason',
    label: '减产原因',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '减产批次',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'quantity',
    label: '减产数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'creator',
    label: '操作人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '操作时间',
    hiddenSearch: true,
    sortable: false
  }
]
// 拆单记录
export const divisionRecord = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'newProduceWorkOrderNumber',
    label: '拆单新工单号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'newProduceWorkOrderNumber',
    width: 150
  },
  {
    prop: 'procedureName',
    label: '拆单工序',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'reason',
    label: '拆单原因',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '拆单原批次码',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'quantity',
    label: '拆单数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'newBatchNumber',
    label: '拆单新批次码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'newProducePlanNumber',
    label: '拆单新计划号',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'creator',
    label: '操作人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '操作时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 200
  }
]
export const workStepColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'name',
    slotName: 'name',
    label: '工序',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'stepName',
    slotName: 'stepName',
    label: '操作步骤',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'description',
    slotName: 'description',
    label: '操作内容',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    slotName: 'remark',
    label: '检验标准',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    prop: 'params',
    slotName: 'params',
    label: '工步参数',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'paramsRecord',
    slotName: 'paramsRecord',
    label: '参数记录',
    hiddenSearch: true,
    sortable: false
  }
]
