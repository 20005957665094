var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderAdd" }, [
    _c("div", { staticClass: "detail-card" }, [
      _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
      _c(
        "div",
        { staticClass: "card-bot" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-form-inline",
              attrs: {
                "label-position": "right",
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "模具名称：", prop: "name" } },
                        [
                          ["edit", "add"].includes(_vm.handleType)
                            ? _c("el-input", {
                                attrs: {
                                  disabled: _vm.handleType === "edit",
                                  placeholder: "请输入模具名称"
                                },
                                model: {
                                  value: _vm.ruleForm.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "name", $$v)
                                  },
                                  expression: "ruleForm.name"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.ruleForm.name))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "模具分类：", prop: "categoryId" } },
                        [
                          ["edit", "add"].includes(_vm.handleType)
                            ? _c("CURDSelect", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "default-value": _vm.ruleForm.categoryId,
                                  "default-value-index": 0,
                                  "api-name": "MouldType"
                                },
                                on: { change: _vm.onSelect }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.categoryName))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "规格：", prop: "spec" } },
                        [
                          ["edit", "add"].includes(_vm.handleType)
                            ? _c("el-input", {
                                attrs: {
                                  disabled: _vm.handleType === "edit",
                                  placeholder: "请输入规格描述"
                                },
                                model: {
                                  value: _vm.ruleForm.spec,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "spec", $$v)
                                  },
                                  expression: "ruleForm.spec"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.ruleForm.spec))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "损耗计算方式：",
                            prop: "calculateType"
                          }
                        },
                        [
                          ["edit", "add"].includes(_vm.handleType)
                            ? _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.ruleForm.calculateType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "calculateType",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.calculateType"
                                  }
                                },
                                _vm._l(_vm.calculateTypes, function(item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: {
                                      label: item.label,
                                      value: item.key
                                    }
                                  })
                                }),
                                1
                              )
                            : _c("span", [_vm._v("计件")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "lossWarningScale" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _vm._v("损耗预警比例："),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "当损耗进度等于其数值是进行报警",
                                    placement: "top"
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-question" })]
                              )
                            ],
                            1
                          ),
                          ["edit", "add"].includes(_vm.handleType)
                            ? _c("el-input-number", {
                                attrs: { "controls-position": "right", min: 0 },
                                on: {
                                  blur: function($event) {
                                    _vm.ruleForm.lossWarningScale = _vm.sliceVal(
                                      _vm.ruleForm.lossWarningScale,
                                      2
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.lossWarningScale,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "lossWarningScale",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.lossWarningScale"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.ruleForm.lossWarningScale))
                              ]),
                          _vm._v(" % ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注：" } },
                        [
                          ["edit", "add"].includes(_vm.handleType)
                            ? _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "remarks", $$v)
                                  },
                                  expression: "ruleForm.remarks"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.ruleForm.remarks))])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "lines" }),
    _vm.$route.query.id
      ? _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("寿命信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c("MTable", {
                ref: "mUnitTable",
                attrs: {
                  height: 400,
                  "show-page": false,
                  data: _vm.procedureLifespans,
                  columns: _vm.columnsLifeInformation,
                  "columns-setting": false
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "index",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "unit",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(_vm._s(row.unit || "PCS"))
                        ])
                      }
                    },
                    {
                      key: "procedureSerialsCode",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(
                            _vm._s(row.procedureDupId.procedureSerialsCode)
                          )
                        ])
                      }
                    },
                    {
                      key: "lifespan",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            ["edit", "add"].includes(_vm.handleType)
                              ? _c("el-input-number", {
                                  attrs: {
                                    min: 0,
                                    "controls-position": "right",
                                    step: 1
                                  },
                                  on: {
                                    blur: function($event) {
                                      row.lifespan = _vm.sliceVal(
                                        row.lifespan,
                                        5
                                      )
                                    }
                                  },
                                  model: {
                                    value: row.lifespan,
                                    callback: function($$v) {
                                      _vm.$set(row, "lifespan", $$v)
                                    },
                                    expression: "row.lifespan"
                                  }
                                })
                              : _c("span", [_vm._v(_vm._s(row.lifespan))])
                          ],
                          1
                        )
                      }
                    }
                  ],
                  null,
                  false,
                  4177985992
                )
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "order-footer" }, [
      _c("div"),
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v(_vm._s(this.handleType !== "view" ? "取消" : "返回"))]
          ),
          this.handleType !== "view"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }