<template>
  <div class="procedure-detail-wrapper">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.push({ name: 'workList' })">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>工单详情</span>
      </div>
      <div class="right">
        <el-button type="primary" :loading="buttonLoading" @click="startRepair">
          发起返修
        </el-button>
        <el-button type="primary" :loading="buttonLoading" @click="printOrder">
          打印
        </el-button>
        <el-button type="primary" :loading="buttonLoading" @click="delTask">
          删除
        </el-button>
      </div>
    </div>
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <BaseData ref="BaseData" :detail-data="baseFormData" />
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">生产物料</div>
        <div class="card-bot">
          <MTable ref="mTable1" :show-page="false" :height="150" :columns="columnsBase" :data="materialsList" :columns-setting="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
          </MTable>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">工艺路线</div>
        <div class="card-bot">
          <process-flow ref="processFlow" :read-only="!saveButton" :process-flow-detail="processFlowVersion" />
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">产品图纸及附件</div>
        <div class="card-bot">
          <div class="b20 flex-sbc">
            <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">图纸</span>
          </div>
          <br>
          <MTable ref="mTable2" :show-page="false" :height="150" :columns="fileAndDrawings" :data="drawingsList" :columns-setting="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a :href="row.url" :download="row.name" target="_blank" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
          </MTable>
          <div class="bt20 flex-sbc">
            <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">其他附件</span>
          </div>
          <br>
          <MTable ref="mTable3" :show-page="false" :height="150" :data="filesList" :columns="fileAndDrawings" :columns-setting="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a :href="row.url" :download="row.name" target="_blank" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
          </MTable>
        </div>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="批次信息" name="first">
          <FunctionHeader
            :tags="functionHeader.tags"
            :hidden-search="true"
            style="padding-top:0"
          >
            <div slot="all" style="margin-left:20px;">
              <el-dropdown split-button @command="(command) => { allMore(command) }">
                <span class="el-dropdown-link">
                  批量操作
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="batchPrint">打印</el-dropdown-item>
                  <el-dropdown-item command="allDel">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </FunctionHeader>
          <MTable ref="mTable4" :height="300" :data="batchNumberList" :columns="batchNumbers" :show-page="false" :columns-setting="false" @selection-change="selectionChange">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="action" slot-scope="{ row }" class="all-btns">
              <el-button type="text" @click="printCode(row)">打印批次码</el-button>
              <el-divider direction="vertical" />
              <DelPopover v-if="row.isUse !== 1" class="li-btn" @onOk="(callback) => del(row, callback)" />
              <el-button v-else type="text" disabled>删除</el-button>
            </div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="投产记录" name="second">
          <FunctionHeader
            export-name="exportReceiveMaterials"
            :hidden-search="true"
            style="padding-top:0"
            :export-params="searchData"
          />
          <MTable ref="mTable5" :set-data-method="getReceiveMaterialsList" :height="300" :columns="receiveMaterials" :columns-setting="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="name" slot-scope="{ row }">{{ row.materials.name }}</div>
            <div slot="code" slot-scope="{ row }">{{ row.materials.code }}</div>
            <div slot="specifications" slot-scope="{ row}">{{ row.materials.specifications }}</div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="报工记录" name="third">
          <FunctionHeader
            export-name="exportReportWork"
            :hidden-search="true"
            style="padding-top:0"
            :export-params="searchData"
          />
          <MTable ref="mTable6" :set-data-method="getTaskList" :height="300" :columns="reportWork" :columns-setting="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="action" slot-scope="{ row }">
              <el-button type="text" @click="scanWorkRecord(row)">查看报工记录</el-button>
              <el-divider direction="vertical" />
              <el-button type="text" @click="scanWasteRecord(row)">查看报废历史</el-button>
            </div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="返修记录" name="four">
          <FunctionHeader
            export-name="exportRepairRecord"
            :hidden-search="true"
            style="padding-top:0"
            :export-params="searchData"
          />
          <MTable ref="mTable7" :set-data-method="getRepairRecordList" :height="300" :columns="repairRecord" :columns-setting="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="produceWorkOrderId" slot-scope="{ row }">
              <el-button type="text" @click="jumpDetail(row)">{{ row.repairWorkOrderNumber }}</el-button>
            </div>
            <div slot="repairWorkQuantity" slot-scope="{ row }">
              <el-button type="text" @click="getWorkQuantity(row)">{{ row.repairWorkQuantity }}</el-button>
            </div>
          </MTable>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 返修 -->
    <repair-model :visible.sync="repairVisible" :repair-list="repairList" :produce-work-order-id="parseInt(params.id)" :old-process-flow-id="baseFormData.processFlowId" @loadWord="getRepairRecordList" />
    <!-- 工位数量查看 -->
    <TableDialog title="查看工位数量" :show.sync="stationVisible" :columns="stationColums" :table-data="stationList" />
    <!-- 添加批次码 -->
    <batch-number
      :add-visible.sync="addVisible"
      :base-form-data="baseFormData"
      @sumbitBatch="sumbitBatch"
    />
    <TableDialog title="查看报工记录" :show.sync="workVisible" :columns="workColumns" :table-data="workList" @scanDetail="scanDetail" />
    <TableDialog title="查看报废历史" :show.sync="wasteVisible" :columns="wasteColumns" :table-data="wasteList" />
    <MDialog :value="show" title="查看上传工序参数" :show-footer="false" @close="show = false">
      <div v-for="row in groupList" :key="row.name" class="params-card">
        <div class="header flex-sbc">
          <div>参数组标题：{{ row.name }}</div>
        </div>
        <div class="body">
          <div v-for="item in row.fromData" :key="item.id">
            <span v-if="item.type !== 4" class="bot-ti">{{ item.key }}：{{ fixData(item.value) }}</span>
            <span v-else class="bot-ti">{{ item.key }}：
              <a v-for="items in fixArr(item.value)" :key="items" :href="items" target="_blank" class="imgs">
                <img :src="items">
              </a>
            </span>
          </div>
        </div>
      </div>
    </MDialog>
    <PrintOrder ref="printCodes" />
    <!--    打印批次码：瑞鼎定制-->
    <PrintBatchNumber v-if="memberCode === 'reagold'" ref="printCode" />
    <!--    打印批次码：奇奇定制-->
    <PrintBatchNumberKiKi v-else-if="memberCode === 'kiki'" ref="printCode" />
    <!--    打印批次码：产品-->
    <PrintCode v-else ref="printCode" />
  </div>
</template>

<script>
import { stationColums, columnsBase, fileAndDrawings, operatorTask, batchNumbers, receiveMaterials, reportWork, workColumns, wasteColumns, repairRecord } from './columns'
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index'
import BaseData from './components/base-data'
import BatchNumber from './components/batch-number'
import api from '@/api/production/work-order'
import apis from '@/api/production/operation-task'
import repairApi from '@/api/production/repair-record'
import exportRecord from '@/api/exportAndImport/export'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import PrintCode from '@/components/PrintCode/printCode'
import TableDialog from '@/components/TableDialog/TableDialog'
import PrintOrder from '@/components/PrintCode/printWorkOrder'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import processApi from '@/api/information/production/process'
import PrintBatchNumber from '@/project-custom/reagold/print-code/printBatchNumber'
import PrintBatchNumberKiKi from '@/project-custom/kiki/print-code/printBatchNumber'
import RepairModel from './components/repair-model/repair-model'
import orderApi from '@/api/production/orders'

export default {
  name: 'WorkDetail',
  components: { PrintBatchNumberKiKi, RepairModel, PrintBatchNumber, ProcessFlow, BaseData, FunctionHeader, DelPopover, BatchNumber, PrintCode, TableDialog, PrintOrder },
  data() {
    return {
      stationColums,
      activeName: 'first',
      stationVisible: false,
      repairList: [],
      stationList: [],
      taskData: [],
      workList: [],
      wasteList: [],
      params: {
        id: undefined
      },
      addVisible: false,
      saveButton: false,
      workColumns,
      wasteColumns,
      columnsBase,
      fileAndDrawings,
      operatorTask,
      batchNumbers,
      receiveMaterials,
      reportWork,
      repairRecord,
      baseFormData: {},
      functionHeader: {
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '添加批次',
            on: {
              click: this.addBatchNumber
            }
          }
        ]
      },
      materialsList: [],
      filesList: [],
      drawingsList: [],
      batchNumberList: [],
      buttonLoading: false,
      formOptions: {
        stationOptions: []
      },
      visible: false,
      formData: {},
      printCodes: [],
      workVisible: false,
      wasteVisible: false,
      show: false,
      groupList: [],
      processFlowVersion: {},
      searchData: {},
      currentWorkDetail: {},
      repairVisible: false
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    this.params = this.$route.query
    this.searchData = {
      searchVal: Encrypt(JSON.stringify([{
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      }])
      )
    }
    this.getWorkDetailById()
  },
  methods: {
    fixArr(val) {
      if (val) {
        return val.split('^')
      }
    },
    fixData(val) {
      if (val) {
        return val.split('^').join('；')
      }
    },
    // 打印工单
    printOrder() {
      this.$refs.printCodes.print(this.baseFormData, this.taskData)
    },
    // 删除工单
    delTask() {
      const that = this
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.deletePlan({ id: this.params.id })
        if (res) {
          this.$router.push({ name: 'workList' })
        }
      })
    },
    // 打印
    async printCode(row) {
      // 产品
      if (this.memberCode === 'mubai') {
        this.$refs.printCode.print(row.batchNumber)
      }
      // 瑞鼎定制
      if (this.memberCode === 'reagold') {
        let res = { remark: '' }
        if (this.baseFormData.produceOrderId) {
          res = await orderApi.getOrderDetail(this.baseFormData.produceOrderId)
        }
        this.$refs.printCode.print({
          ...this.baseFormData,
          batchNumber: row.batchNumber,
          remark: res.remark
        })
      }
      // 奇奇定制
      if (this.memberCode === 'kiki') {
        this.$refs.printCode.print(this.baseFormData)
        return false
      }
    },
    batchPrint() {
      if (this.printCodes.length > 0) {
        this.$refs.printCode.batchPrint(this.printCodes)
      } else {
        this.$message.info('请选择批次码')
      }
    },
    selectionChange(data) {
      this.printCodes = data.map(item => item.batchNumber)
    },
    allMore(command) {
      const functions = {
        batchPrint: () => { this.batchPrint() },
        allDel: () => { this.allDel() }
      }
      functions[command]()
    },
    // 批次码与工单绑定
    async sumbitBatch(data) {
      const newArr = data.map(item => {
        return {
          produceWorkOrderId: this.params.id,
          batchNumber: item.batchNumber
        }
      })
      const res = await api.addBatchNumber(newArr)
      if (res) {
        this.$message.success('添加成功')
        this.addVisible = false
        this.getWorkDetailById()
      }
    },
    // 添加批次码
    addBatchNumber() {
      this.addVisible = true
    },
    async getWorkDetailById() {
      const res = await api.getPlanDetail(this.params.id)
      if (res) {
        this.baseFormData = res
        const obj = {
          materialsName: res.materialsName,
          materialsCode: res.materialsCode,
          materialsSpecifications: res.materialsSpecifications,
          materialsUnit: res.materialsUnit,
          quantity: res.quantity,
          reportWorkQuantity: res.reportWorkQuantity
        }
        this.materialsList.push(obj)
        this.drawingsList = res.materials.drawing ? JSON.parse(res.materials.drawing) : []
        this.filesList = res.materials.attachment ? JSON.parse(res.materials.attachment) : []
        this.batchNumberList = res.batchNumberList
        this.getProcessDetailById(res.processFlowId, res.processFlowVersionId)
      }
    },
    // 投产
    async getReceiveMaterialsList(condition, callback) {
      const { page, search, order } = condition
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      })
      const res = await api.getReceiveMaterials({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    // 报工
    async scanWorkRecord(row) {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderTaskId',
        fieldType: 'number',
        fieldValue: row.id,
        operator: 'eq'
      })
      const res = await api.getReportWork({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.workList = res
      }
      this.workVisible = true
    },
    async scanWasteRecord(row) {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderTaskId',
        fieldType: 'number',
        fieldValue: row.id,
        operator: 'eq'
      })
      const res = await api.getReportWaste({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.wasteList = res
      }
      this.wasteVisible = true
    },
    // 删除批次
    del(row) {
      const arrs = []
      arrs.push(row.batchNumber)
      this.save(arrs)
    },
    // 批量删除
    allDel() {
      if (this.printCodes.length > 0) {
        this.save(this.printCodes)
      } else {
        this.$message.info('请选择批次码')
      }
    },
    async save(arr) {
      const obj = {
        produceWorkOrderId: this.params.id,
        batchNumberList: arr
      }
      const res = await api.deleteBatchNumber(obj)
      if (res) {
        this.$message.success('删除成功')
        this.getWorkDetailById()
      }
    },
    // 工序任务分页
    async getTaskList(condition, callback) {
      const { page, search, order } = condition
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      })
      const res = await apis.getOrderTaskpage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    scanDetail(row) {
      this.$nextTick(function() {
        this.groupList = row.procedureDetails ? JSON.parse(row.procedureDetails) : []
        this.show = true
      })
    },
    // 获取工艺详情
    async getProcessDetailById(id, versionId) {
      const res = await processApi.getProcessDetail({ id: id, versionId: versionId })
      if (res) {
        this.processFlowVersion = res.processFlowVersion
        this.taskData = res.processFlowVersion.procedureList
      }
    },
    // 返修记录
    async getRepairRecordList(condition, callback) {
      const { page, search, order } = condition
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      })
      const res = await repairApi.getRepairRecord({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    jumpDetail(row) {
      this.$router.push({ path: 'repair-detail', query: { id: row.id }})
    },
    // 查看返修记录工位数量列表
    getWorkQuantity(row) {
      this.stationList = row.repairStationList
      this.stationVisible = true
    },
    // 发起返修
    async startRepair() {
      await this.getRepair()
      this.repairVisible = true
    },
    // 获取返修批次
    async getRepair() {
      const res = await api.getRepairDetail({
        produceWorkOrderId: this.params.id
      })
      if (res) {
        this.repairList = res
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.all-btns{
  ::v-deep{
    .el-button{
      padding:0 20px;
    }
  }
}
.procedure-detail-wrapper {
  height: 100%;
  .procedure-detail-header{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456B;
    line-height: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    .left{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    img{
      width: 8px;
      height: 12px;
      display: block;
      margin-right: 10px;
    }
  }
  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
}
.params-card {
  border: 1px solid #ECECF7;
  margin-bottom: 20px;
  .header {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background: #ECECF7;
  }
  .body {
    width: 100%;
    .bot-ti{
      // height: 36px;
      line-height:36px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      display: inline-block;
      margin-bottom: 16px;
      margin-left: 20px;
      word-break: break-all;
      .imgs{
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 10px;
        img{
        width: 100px;
        height: 100px;
        display: inline-block;
        }
      }
    }
  }
}
</style>
