<template>
  <el-row class="searchs">
    <!-- 基本信息数据 -->
    <el-col :span="8">
      <span class="bot-ti">生产工单号：{{ detailData.produceWorkOrderNumber }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">工单当前所处工序：{{ detailData.produceWorkOrderTaskList ? fixProduceData(detailData.produceWorkOrderTaskList) : ''
      }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">生产批次码：{{ detailData.batchNumber }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">工单来源：{{ detailData.source ? taskSource[detailData.source].name : '' }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">所属计划号：{{ detailData.producePlanNumber }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">所属订单号：{{ detailData.produceOrderNumber }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">交期：{{ detailData.deliveryTime }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">计划开始时间：{{ detailData.planStartTime }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">计划结束时间：{{ detailData.planEndTime }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">产线：{{ detailData.areaName }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">工单属性：{{ detailData.isRepair === 0 ? '普通' : '返修' }}</span>
    </el-col>
    <el-col :span="8">
      <div class="bot-ti">计划优先级：
        <span
          :style="{ color: priorityStatus[detailData.priority] ? priorityStatus[detailData.priority].color : 'rgb(74, 79, 118)', background: `${priorityStatus[detailData.priority] ? priorityStatus[detailData.priority].color : 'rgb(74, 79, 118)'}20` }"
          class="tag-priority"
        >
          {{ priorityStatus[detailData.priority] ? priorityStatus[detailData.priority].name : '普通' }}
        </span>
      </div>
    </el-col>
    <el-col v-if="detailData.sourceWorkOrderNumber" :span="8" style="margin-top: -10px;">
      <div class="bot-ti" style="margin: 0">源工单号：
        <NewPageOpen :path="{ name: 'workDetail', query: {id: detailData.sourceWorkOrderId}}" :text="detailData.sourceWorkOrderNumber" />
      </div>
    </el-col>
  </el-row>
</template>
<script>
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import { taskSource } from '@/config/options.config'
export default {
  components: { NewPageOpen },

  props: {
    detailData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      taskSource
    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  methods: {
    fixProduceData(val) {
      const list = val.map(item => item.procedureName)
      return list.length === 1 ? list[0] : list.join('，')
    },
    fixData(val) {
      if (val && val.length > 0) {
        const data = val.map(item => item.batchNumber)
        return data.join()
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .searchs{
   .bot-ti{
      // height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      display:inline-block;
      margin-bottom:16px;
      word-break: break-all;
   }
   ::v-deep .el-button--text {
     padding: 0;
   }
 }
</style>
