<template>
  <div class="orders">
    <div class="top">
      <SearchForms
        ref="searchForms"
        :form-option="formOptions"
        @resetData="resetData"
        @searchData="searchTable"
      />
    </div>
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      search-title="请输入定额单号"
      :tags="functionHeader.tags"
      :export-params="functionHeader.exportParams"
      style="padding: 0px 0 10px 0; margin-left: -10px"
      class="func-header"
      @search="$refs.mTable.setTableData(true)"
    >
      <div
        slot="all"
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <el-dropdown
          size="small"
          split-button
          class="all-operator"
          @command="
            (command) => {
              allMore(command);
            }
          "
        >
          <span class="el-dropdown-link"> 批量操作 </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item  command="allDel"
              >删除</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </FunctionHeader>
    <MTable
      ref="mTable"
      :columns="columns"
      :height="height"
      :set-data-method="getTableData"
      @selection-change="selectionChange"
    >
      <div slot="number" slot-scope="{ row }">
        <NewPageOpen
          :path="{ name: 'certainNumberDetail', query: { id: row.id } }"
          :text="row.number"
        />
      </div>
      <div slot="remark" slot-scope="{ row }">
        <el-tooltip class="item" :content="row.remark" placement="top-end">
          <div
            style="
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            "
          >
            {{ row.remark }}
          </div>
        </el-tooltip>
      </div>
      <div slot="action" slot-scope="{ row }">
        <a  class="li-btn" @click="del(row)">删除</a>

      </div>

    </MTable>
    <MDialog
      :value="show"
      title="查看工序参数"
      :show-footer="false"
      @close="show = false"
    >
      <div v-for="row in groupList" :key="row.name" class="params-card">
        <div class="header flex-sbc">
          <div>参数组标题：{{ row.name }}</div>
        </div>
        <div class="body">
          <div v-for="item in row.fromData" :key="item.id">
            <span v-if="item.type !== 4" class="bot-ti"
              >{{ item.key }}：{{ fixData(item.value) }}</span
            >
            <span v-else class="bot-ti"
              >{{ item.key }}：
              <a
                v-for="items in fixArr(item.value)"
                :key="items"
                :href="items"
                target="_blank"
                class="imgs"
              >
                <img :src="items" />
              </a>
            </span>
          </div>
        </div>
      </div>
    </MDialog>
  </div>
</template>
  <script>
import FunctionHeader from "@/components/FunctionHeader";
import SearchForms from "./search-forms";
import { orderSource, orderStatus, pageStatus } from "@/config/options.config";
import api from "@/api/production/reportRecords";
import { columnsList } from "./columns";
import { Encrypt } from "@/utils/sercet";
import { getUUid } from "@/utils";
import NewPageOpen from "@/components/NewPageOpen/NewPageOpen";
import processApi from "@/api/information/production/process";
import workApi from "@/api/production/work-order";
import userApi from "@/api/sets/user/user";
import stationApi from "@/api/information/production/station";
import equipmentApi from "@/api/equipment";
import { export_json_to_excel2 } from "@/utils/plug/to-excel";
import fixCommonPage from "@/mixin/fix-common-page";
import quotaSheetApi from "@/api/production/quotaSheet";

export default {
  name: "ReportRecords",
  components: { NewPageOpen, FunctionHeader, SearchForms },
  mixins: [fixCommonPage],
  data() {
    return {
      api,
      orderSource,
      orderStatus,
      pageStatus,
      visible: false,
      searchFormData: [],
      searchFormDatas: {},
      functionHeader: {
        searchVal: "",
        tags: [
          {
            hidden: false,
            tagName: "el-button",
            props: { type: "primary", size: "small" },
            style: { width: "auto", background: "#607FFF", marginLeft: "10px" },
            innerText: "新增",
            on: {
              click: this.add,
            },
          },
        ],
        exportName: "exportOrders",
        importName: "orderList",
        exportParams: {},
      },
      columns: columnsList,
      searchDatas: [],
      selectionData: [],
      selectId: "",
      allMaterialsList: [],
      keyword: "",
      selectKeyword: "1",
      show: false,
      groupList: [],
      formOptions: {
        orderOptions: [],
        proceduresOptions: [],
        operatorOptions: [],
        flightOptions: [],
        workOrderOptions: [],
        areaOptions: [],
        stationOptions: [],
      },
      defaultSearch: {
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: "isEnabled",
              fieldType: "number",
              fieldValue: 1,
              operator: "eq",
            },
          ])
        ),
      },
      sortList: [
        {
          id: "1",
          name: "按报工时间最早",
        },
        {
          id: "2",
          name: "按报工时间最晚",
        },
      ],
      sortData: "",
      selectData: [],
    };
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235;
    },
    priorityStatus() {
      return this.$store.state.user.priorityList;
    },
    memberCode() {
      return sessionStorage.getItem("memberCode");
    },
  },
  mounted() {
    this.getAreaList();
    this.getOperatorPages();
    this.getStationLists();
    this.getUserList();
    this.getFrequencyList();
  },
  methods: {
    // 新增
    add() {
      this.$router.push({ path: "certain-number-add" });
    },
    allMore(command) {
      const functions = {
        allDel: () => { this.allDel() },
        batchExport: () => {
          this.isEbatchExport();
        },
      };
      functions[command]();
    },
    selectionChange(data) {
      console.log('data:',data)
      this.selectData = data;
      this.selectionData = data
    },
    isEbatchExport() {
      if (this.memberCode === "bbelc") {
        this.batchExport();
      } else {
        this.batchExports();
      }
    },
    // 北变
    batchExport() {
      const exportData = this._.cloneDeep(this.selectData);
      const val = "";
      exportData.forEach((item) => {
        if (
          item.procedureDetails &&
          JSON.parse(item.procedureDetails).length > 0
        ) {
          const data = JSON.parse(item.procedureDetails)[0].fromData.map(
            (items) => {
              return items.key === "实际片数" ? items.value : "";
            }
          );
          item.actualValue = data[0];
        }
      });
      const newColumns = [
        {
          prop: "actualValue",
          label: "实际片数",
        },
      ];
      const columns = [...this.columns, ...newColumns];
      const params = {
        key: columns
          .filter(
            (item) =>
              item.prop &&
              item.prop !== "index" &&
              item.prop !== "procedureDetails"
          )
          .map((item) => item.prop),
        header: columns
          .filter(
            (item) =>
              item.prop &&
              item.prop !== "index" &&
              item.prop !== "procedureDetails"
          )
          .map((item) => item.label),
        merges: [],
        data: exportData,
        filename: "报工记录",
      };
      export_json_to_excel2(params);
    },
    batchExports() {
      const ids = [
        {
          id: getUUid(),
          fieldName: "id",
          fieldType: "number",
          fieldValue: this.selectData.map((item) => item.id).join("^"),
          operator: "in",
        },
      ];
      const params = {
        searchVal: Encrypt(JSON.stringify(ids)),
      };
      this.$refs.functionHeader.export(params);
    },
    // 排序
    handleCommand() {
      const titles = new Map([
        ["1", "+operateTime"],
        ["2", "-operateTime"],
      ]);
      this.sorts = titles.get(this.sortData);
      this.$refs.mTable.setTableData(true);
    },
    fixData(val) {
      if (val) {
        return val.split("^").join("；");
      }
    },
    scanDetail(row) {
      this.groupList = row.procedureDetails
        ? JSON.parse(row.procedureDetails)
        : [];
      if (this.groupList.length === 0) {
        this.$message.info("工序参数无数据");
        return false;
      }
      this.show = true;
    },
    // 获取产线
    async getAreaList() {
      const search = [];
      search.push({
        id: getUUid(),
        fieldName: "type",
        fieldType: "number",
        fieldValue: 2,
        operator: "eq",
      });
      search.push({
        id: getUUid(),
        fieldName: "isEnabled",
        fieldType: "number",
        fieldValue: 1,
        operator: "eq",
      });
      const res = await processApi.getAreaList({
        searchVal: Encrypt(JSON.stringify(search)),
      });
      if (res) {
        this.formOptions.areaOptions = res;
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.areaList = res;
        }
      }
    },
    // 订单
    async getOrderpages() {
      const res = await workApi.getOrderpage();
      if (res) {
        this.formOptions.orderOptions = res;
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.orderNumbers = res;
        }
      }
    },
    // 工序
    async getOperatorPages() {
      const res = await workApi.getOperatorPage();
      if (res) {
        this.formOptions.proceduresOptions = res;
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.currentProcedures = res;
        }
      }
    },
    // 工单列表
    async getOrderList() {
      const res = await workApi.getPlanpageno();
      if (res) {
        this.formOptions.workOrderOptions = res;
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.workOrderList = res;
        }
      }
    },
    // 筛选工位
    async getStationLists() {
      const res = await stationApi.getStationList();
      if (res) {
        this.formOptions.stationOptions = res;
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.stationList = res;
        }
      }
    },
    // 操作人
    async getUserList() {
      const res = await userApi.getUserList();
      if (res) {
        this.formOptions.operatorOptions = res;
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.operatorList = res;
        }
      }
    },
    // 获取班次列表
    async getFrequencyList() {
      const res = await equipmentApi.getFrequencyList();
      if (res) {
        this.formOptions.flightOptions = res;
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.flightList = res;
        }
      }
    },
    typeChange(val) {
      this.selectKeyword = val;
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition;
      console.log(search, this.searchDatas);
      const params = {
        sort: order,
        searchVal: Encrypt(
          JSON.stringify([...this.searchDatas,
            {
              id: getUUid(),
              fieldName: "number",
              fieldType: "string",
              fieldValue: this.functionHeader.searchVal,
              operator: "like",
            },
          ])
        ),
      };
      this.functionHeader.exportParams = params;
      const res = await quotaSheetApi.getQuotaSheetPage({
        page: page.currentPage,
        limit: page.pageSize,
        ...params,
      });
      if (res) {
        callback({
          total: res.total,
          content: res.records,
        });
      }
      callback();
    },
    //获取定额单列表
    // async getQuotaSheetPage(){
    //   await  quotaSheetApi.getQuotaSheetPage()
    // },
    // 批量删除
    async allDel() {
      if (this.selectionData.length > 0) {
        console.log('selectionData:',this.selectionData)
        let idList = this.selectionData.map(item=>item.id)

        const res = await quotaSheetApi.allDel(idList);
        if (res) {
          this.$message.success("删除成功");
          this.$refs.mTable.setTableData();
        }
      } else {
        this.$message.error("请选择要删除的数据");
      }
    },
    async del(row) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await quotaSheetApi.deleteQuotaSheet(row.id)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    // 查询d
    searchTable(data, val, keyword, moldInstanceIds) {
      this.searchDatas = data;
      this.keyword = keyword;
      this.selectKeyword = val;
      this.moldInstanceIds = moldInstanceIds;
      this.$refs.mTable.setTableData(true);
    },
    // 重置
    resetData() {
      this.moldInstanceIds = "";
      this.searchDatas = [];
      this.selectKeyword = "";
      this.keyword = "";
      this.$refs.mTable.setTableData(true);
    },
  },
};
</script>
  
  <style scoped lang="scss">
.orders {
  padding: 10px;
  .li-btn {
    margin-right: 10px;
    color: #607fff;
    font-weight: 500;
  }
  .color-btns {
    color: #c4c4c4;
  }
  .searchs {
    padding-left: 0;
    padding-top: 0;
  }
  .input-select {
    width: 100px;
    display: block;
  }
  .input-with-select {
    width: 100%;
  }
  .all-operator {
    height: 32px;
    margin-left: 10px;
    ::v-deep {
      .el-button--primary,
      .el-button-group .el-button {
        height: 32px;
      }
    }
  }
  .func-header {
    ::v-deep .el-input--suffix .el-input__inner {
      height: 32px;
    }
  }
}
::v-deep .customer-content div {
  width: 100%;
}
.tip-tit {
  height: 18px;
  line-height: 18px;
}
div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #eceef2;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #393d60;
  margin-left: 10px;
}

div.tooltip:before,
div.tooltip:after {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #eceef2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #eceef2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.params-card {
  border: 1px solid #ececf7;
  margin-bottom: 20px;
  .header {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background: #ececf7;
  }
  .body {
    width: 100%;
    .bot-ti {
      // height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 16px;
      margin-left: 20px;
      word-break: break-all;
      .imgs {
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 10px;
        img {
          width: 100px;
          height: 100px;
          display: inline-block;
        }
      }
    }
  }
}
</style>
  