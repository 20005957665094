var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "orderList" })
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("订单详情")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "订单来源：" +
                    _vm._s(_vm.orderSource[_vm.detailData.source].name)
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("订单号：" + _vm._s(_vm.detailData.produceOrderNumber))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("交期：" + _vm._s(_vm.detailData.deliveryTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "bot-ti" }, [
                _vm._v("优先级： "),
                _c(
                  "span",
                  {
                    staticClass: "tag-priority",
                    style: {
                      color: _vm.priorityStatus[_vm.detailData.priority]
                        ? _vm.priorityStatus[_vm.detailData.priority].color
                        : "rgb(74, 79, 118)",
                      background:
                        (_vm.priorityStatus[_vm.detailData.priority]
                          ? _vm.priorityStatus[_vm.detailData.priority].color
                          : "rgb(74, 79, 118)") + "20"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.priorityStatus[_vm.detailData.priority]
                            ? _vm.priorityStatus[_vm.detailData.priority].name
                            : "普通"
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("客户：" + _vm._s(_vm.detailData.customerName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("备注：" + _vm._s(_vm.detailData.remark))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "bot-li" }, [
                _c("div", { staticClass: "bot-ti" }, [_vm._v("附件：")]),
                _c(
                  "div",
                  {
                    staticClass: "li-des",
                    staticStyle: { width: "calc(100% - 45px)" }
                  },
                  _vm._l(_vm.fixArrs(_vm.detailData.attachments), function(
                    items,
                    idx
                  ) {
                    return _c(
                      "span",
                      {
                        key: idx,
                        staticStyle: {
                          "word-break": "break-all",
                          color: "#0091FF",
                          cursor: "pointer"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handlePreview(items)
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(items.name) + " "),
                        idx < _vm.fixArrs(_vm.detailData.attachments).length - 1
                          ? _c("span", [_vm._v("，")])
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("div", { staticClass: "bot-li" }, [
                _c(
                  "span",
                  { staticClass: "bot-ti", staticStyle: { width: "90px" } },
                  [_vm._v("自定义标签：")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "li-des",
                    staticStyle: { width: "calc(100% - 90px)" }
                  },
                  _vm._l(_vm.fixArrs(_vm.detailData.customTag), function(
                    items,
                    idx
                  ) {
                    return _c(
                      "el-tag",
                      {
                        key: idx,
                        staticClass: "el-tags",
                        staticStyle: { margin: "0 5px 5px 0" },
                        attrs: { type: "info", size: "mini" }
                      },
                      [_vm._v(_vm._s(items))]
                    )
                  }),
                  1
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "销售订单编号：" + _vm._s(_vm.detailData.salesOrderCodes)
                )
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("订单产品")]),
        _c(
          "div",
          { staticClass: "tables" },
          [
            _c("MTable", {
              ref: "mTable",
              attrs: {
                "show-page": false,
                height: 150,
                columns: _vm.columnsProduct,
                data: _vm.productList
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "materialsPicture",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        row.materialsPicture && row.materialsPicture !== "[]"
                          ? _c("el-image", {
                              staticClass: "drawing-img",
                              attrs: {
                                src: row.materialsPicture,
                                "preview-src-list": _vm.fixImg(
                                  row.materialsPicture
                                )
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }
                },
                {
                  key: "materialsCode",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.viewMaterialsDetail(row.materialsId)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.materialsCode))]
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("计划用料")]),
        _c(
          "div",
          { staticClass: "tables" },
          [
            _vm.materialUsePlan.length
              ? _c("MTable", {
                  ref: "mTable",
                  attrs: {
                    "show-page": false,
                    height: 250,
                    columns: _vm.columnsMaterialPlan,
                    data: _vm.materialUsePlan
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "materialsCode",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.viewMaterialsDetail(
                                    row.materialsId
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.materialsCode))]
                          )
                        }
                      }
                    ],
                    null,
                    false,
                    2605272383
                  )
                })
              : _c("MBlank", {
                  attrs: { title: "暂无计划用料，请先配置产品BOM" }
                })
          ],
          1
        )
      ]),
      _c(
        "el-tabs",
        {
          staticClass: "detail-tabs",
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "关联计划", name: "first" } },
            [
              _c("FunctionHeader", {
                staticStyle: { "padding-top": "0" },
                attrs: {
                  tags: _vm.functionHeaders.tags,
                  "search-title": "生产计划单号"
                },
                on: {
                  search: function($event) {
                    return _vm.$refs.orderTables.setTableData(true)
                  }
                },
                model: {
                  value: _vm.functionHeaders.searchVal,
                  callback: function($$v) {
                    _vm.$set(_vm.functionHeaders, "searchVal", $$v)
                  },
                  expression: "functionHeaders.searchVal"
                }
              }),
              _c("MTable", {
                ref: "orderTables",
                attrs: {
                  height: 300,
                  "set-data-method": _vm.getPlanTableData,
                  columns: _vm.columnsPlanOrder
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "produceOrderNumber",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.jumpOrderDetail(row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.produceOrderNumber))]
                          )
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "status",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.planStatus[row.status].name))
                        ])
                      ])
                    }
                  },
                  {
                    key: "produceStatus",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c(
                          "div",
                          {
                            style: {
                              color:
                                row.status === 0
                                  ? "#FA6400"
                                  : row.status === 1
                                  ? "#00AB29"
                                  : "#607FFF"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.productionStatus[row.produceStatus].name
                                )
                            )
                          ]
                        )
                      ])
                    }
                  },
                  {
                    key: "processFlowName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.showProcess(row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.processFlowName))]
                          )
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "materialsCode",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.viewMaterialsDetail(row.materialsId)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.materialsCode))]
                      )
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        { staticClass: "all-btns" },
                        [
                          _vm.permission("CancelPlanOrder") &&
                          row.produceOrderId === _vm.id
                            ? _c(
                                "el-button",
                                { attrs: { type: "text", disabled: "" } },
                                [_vm._v("取消关联")]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancelPlanOrders(row)
                                    }
                                  }
                                },
                                [_vm._v("取消关联")]
                              )
                        ],
                        1
                      )
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "关联工单", name: "second" } },
            [
              _c("FunctionHeader", {
                staticStyle: { "padding-top": "0" },
                attrs: {
                  tags: _vm.functionHeader.tags,
                  "search-title": "生产工单号/订单号"
                },
                on: {
                  search: function($event) {
                    return _vm.$refs.orderTable.setTableData(true)
                  }
                },
                model: {
                  value: _vm.functionHeader.searchVal,
                  callback: function($$v) {
                    _vm.$set(_vm.functionHeader, "searchVal", $$v)
                  },
                  expression: "functionHeader.searchVal"
                }
              }),
              _c("MTable", {
                ref: "orderTable",
                attrs: {
                  height: 300,
                  "set-data-method": _vm.getTableData,
                  columns: _vm.columnsWorkOrder
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "produceWorkOrderNumber",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          row.isRepair === 0 && row.repairRecordList
                            ? _c("img", {
                                staticClass: "icon-img",
                                attrs: {
                                  src: require("@/assets/plans/repair-icon.png")
                                }
                              })
                            : _vm._e(),
                          row.isRepair === 1
                            ? _c("img", {
                                staticClass: "icon-img",
                                attrs: {
                                  src: require("@/assets/plans/repair-icon-have.png")
                                }
                              })
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.jumpDetail(row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.produceWorkOrderNumber))]
                          )
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "produceOrderNumber",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.jumpOrderDetail(row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.produceOrderNumber))]
                          )
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "isRepair",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("span", [
                          _vm._v(_vm._s(row.isRepair === 1 ? "返修" : "普通"))
                        ])
                      ])
                    }
                  },
                  {
                    key: "status",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c(
                          "div",
                          {
                            style: {
                              color:
                                row.status === 0
                                  ? "#FA6400"
                                  : row.status === 1
                                  ? "#00AB29"
                                  : "#607FFF"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.productionStatus[row.status].name)
                            )
                          ]
                        )
                      ])
                    }
                  },
                  {
                    key: "currentProcedure",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              row.produceWorkOrderTaskList
                                ? _vm.fixProduceData(
                                    row.produceWorkOrderTaskList
                                  )
                                : ""
                            )
                          )
                        ])
                      ])
                    }
                  },
                  {
                    key: "processFlowName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.showProcess(row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.processFlowName))]
                          )
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "materialsCode",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.viewMaterialsDetail(row.materialsId)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.materialsCode))]
                      )
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        { staticClass: "all-btns" },
                        [
                          _vm.permission("CancelWorkOrder") &&
                          row.produceOrderId === _vm.id
                            ? _c(
                                "el-button",
                                { attrs: { type: "text", disabled: "" } },
                                [_vm._v("取消关联")]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancelWorkOrders(row)
                                    }
                                  }
                                },
                                [_vm._v("取消关联")]
                              )
                        ],
                        1
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "工艺路线", "show-footer": false },
          on: {
            onOk: function($event) {
              _vm.processVisible = false
            }
          },
          model: {
            value: _vm.processVisible,
            callback: function($$v) {
              _vm.processVisible = $$v
            },
            expression: "processVisible"
          }
        },
        [
          _c("single-process-flow", {
            ref: "processFlow",
            attrs: {
              "current-procedure-names": _vm.currentProcedureName,
              "read-only": !_vm.saveButton,
              "all-work-order-task-list": _vm.allWorkTask ? _vm.allWorkTask : []
            }
          })
        ],
        1
      ),
      _c("edit-materials", { ref: "editMaterials" }),
      _c("select-work-order", {
        ref: "selectWorkOrder",
        attrs: { visible: _vm.showWorkOrder, produceOrderId: _vm.id },
        on: {
          "update:visible": function($event) {
            _vm.showWorkOrder = $event
          },
          submitForm: _vm.submitWorkOrder
        }
      }),
      _c("select-plan-order", {
        ref: "selectPlanOrder",
        attrs: { visible: _vm.showPlanOrder, "produce-order-id": _vm.id },
        on: {
          "update:visible": function($event) {
            _vm.showPlanOrder = $event
          },
          submitForm: _vm.submitPlanOrder
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }