var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-wrap" },
    [
      [
        _c("div", { staticClass: "container-top common-pad" }, [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("m-remote-select", {
                        staticClass: "input-prepend",
                        staticStyle: { width: "100%" },
                        attrs: {
                          searchType: "projectStatistic",
                          placeholder: "请选择项目",
                          "interface-fun": _vm.api.getProjectListPage,
                          options: _vm.projectList,
                          filterable: "",
                          clearable: "",
                          multiple: "",
                          "label-name": "projectName",
                          "search-name": "projectName",
                          "value-name": "projectId"
                        },
                        on: {
                          "update:options": function($event) {
                            _vm.projectList = $event
                          },
                          "on-change": _vm.projectChange
                        },
                        model: {
                          value: _vm.projectId,
                          callback: function($$v) {
                            _vm.projectId = $$v
                          },
                          expression: "projectId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("date-range", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "icon-show": false,
                          "format-date-type": "YYYY-MM-DD HH:mm:ss",
                          "can-no-select": false
                        },
                        on: {
                          dateRangeChange: function(val) {
                            return _vm.dateRangeChange(val)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: "",
                            placeholder: "请选择业务部门",
                            filterable: ""
                          },
                          on: { change: _vm.businessChange },
                          model: {
                            value: _vm.businessId,
                            callback: function($$v) {
                              _vm.businessId = $$v
                            },
                            expression: "businessId"
                          }
                        },
                        _vm._l(_vm.businessList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: "",
                            placeholder: "请选择用户",
                            filterable: ""
                          },
                          on: { change: _vm.userChange },
                          model: {
                            value: _vm.userId,
                            callback: function($$v) {
                              _vm.userId = $$v
                            },
                            expression: "userId"
                          }
                        },
                        _vm._l(_vm.userList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "projectHours",
              staticStyle: {
                display: "flex",
                "text-align": "center",
                "flex-wrap": "wrap",
                "justify-content": "space-between",
                color: "#303133"
              }
            },
            [
              _c("div", { staticClass: "projectContent" }, [
                _c("div", [_vm._v(" 项目总工时（h） ")]),
                _c("div", { staticClass: "hour" }, [
                  _vm._v(
                    " " + _vm._s(_vm.projectHour.projectTotalWorkHours) + " "
                  )
                ])
              ]),
              _c("div", { staticClass: "projectContent" }, [
                _c("div", [_vm._v(" 任务总工时（h） ")]),
                _c("div", { staticClass: "hour" }, [
                  _vm._v(
                    " " + _vm._s(_vm.projectHour.projectTaskWorkHours) + " "
                  )
                ])
              ]),
              _c("div", { staticClass: "projectContent" }, [
                _c("div", [_vm._v(" 项目其他总工时（h） ")]),
                _c("div", { staticClass: "hour" }, [
                  _vm._v(
                    " " + _vm._s(_vm.projectHour.projectOtherWorkHours) + " "
                  )
                ])
              ]),
              _c("div", { staticClass: "projectContent" }, [
                _c("div", [_vm._v(" 工单总工时（h） ")]),
                _c("div", { staticClass: "hour" }, [
                  _vm._v(
                    " " + _vm._s(_vm.projectHour.workOrderTotalWorkHours) + " "
                  )
                ])
              ]),
              _c("div", { staticClass: "projectContent" }, [
                _c("div", [_vm._v(" 工单生产工时（h） ")]),
                _c("div", { staticClass: "hour" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.projectHour.workOrderProductionWorkHours) +
                      " "
                  )
                ])
              ]),
              _c("div", { staticClass: "projectContent" }, [
                _c("div", [_vm._v(" 工单停工工时（h） ")]),
                _c("div", { staticClass: "hour" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.projectHour.workOrderStopWorkWorkHours) +
                      " "
                  )
                ])
              ]),
              _c("div", { staticClass: "projectContent" }, [
                _c("div", [_vm._v(" 工单返工工时（h） ")]),
                _c("div", { staticClass: "hour" }, [
                  _vm._v(
                    " " + _vm._s(_vm.projectHour.workOrderReworkWorkHours) + " "
                  )
                ])
              ]),
              _c("div", { staticClass: "projectContent" }, [
                _c("div", [_vm._v(" 工单其他工时（h） ")]),
                _c("div", { staticClass: "hour" }, [
                  _vm._v(
                    " " + _vm._s(_vm.projectHour.workOrderOtherWorkHours) + " "
                  )
                ])
              ])
            ]
          )
        ]),
        _vm._l(_vm.chartList, function(child, idx) {
          return _c("div", { key: child.id + 200 }, [
            _c(
              "div",
              { class: child.fullscreen ? "app-left-full" : "" },
              [
                _c("el-card", { key: child.id, staticClass: "box-card" }, [
                  _c(
                    "div",
                    {
                      staticClass: "box-card-header",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v(_vm._s(child.name))]),
                          child.tooltip
                            ? _c(
                                "el-tooltip",
                                {
                                  staticStyle: { "padding-left": "5px" },
                                  attrs: {
                                    effect: "dark",
                                    content: child.tooltip,
                                    placement: "top"
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "el-icon-question"
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          style: {
                            position: "relative",
                            padding: "12px 0"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "header-ri",
                              on: {
                                click: function($event) {
                                  return _vm.toggleFullScreen(child, idx)
                                }
                              }
                            },
                            [
                              !child.fullscreen
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "放大",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "ri-img",
                                        attrs: {
                                          src: require("@/assets/narrow-amplification/amplification.png"),
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "缩小",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "ri-img",
                                        attrs: {
                                          src: require("@/assets/narrow-amplification/narrow.png")
                                        }
                                      })
                                    ]
                                  )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "order-cen" },
                        [
                          _c(
                            "el-row",
                            _vm._l(child.content, function(item) {
                              return _c(
                                "el-col",
                                { key: item.id, attrs: { lg: 12, xl: 8 } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { color: "#303133" } },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c("charts", {
                                        ref: "charts" + item.id,
                                        refInFor: true,
                                        attrs: { "tab-info": item }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      child.type === "table"
                        ? _c("CustomTable", {
                            ref: "charts" + child.id,
                            refInFor: true,
                            attrs: { "tab-info": child, "to-day": _vm.toDay }
                          })
                        : _vm._e(),
                      child.type === "charts"
                        ? _c("charts", {
                            ref: "charts" + child.id,
                            refInFor: true,
                            attrs: { "tab-info": child }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ])
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }