<template>
  <div>
    <div class="login-right" style="width: 100vw;height: 100vh;background: rgba(3, 13, 32, 0.6);">
      <div class="center" style="width: 30%;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -80%);">
        <div class="blank-placeholder" />
        <div class="form-wrapper">
          <div class="cac-logo" style="display: flex;justify-content: center;align-items: center;margin-bottom: 20px">
            <img src="https://oss.mubyte.cn/static/logo-new%402x.png" style="display: block;width: 200px">
          </div>
          <div class="login-tab">
            <div class="form" style="border-top-left-radius: 8px;">
              <el-form ref="loginForm" :model="loginForm" class="login-form"
                       label-position="left">
                <div>
                  <el-form-item prop="userName">
                    <el-input v-model="loginForm.userName" class="inline-input"
                                       placeholder="请输入用户名" style="width: 100%"
                                      tabindex="1" />
                  </el-form-item>
                  <el-form-item prop="password" style="padding-bottom: 0">
                    <el-input v-model="loginForm.password" type="password" show-password
                              placeholder="请输入密码" name="password" tabindex="2" @keyup.enter.native="handleLogin" />
                  </el-form-item>
                </div>
                <el-button :loading="loading" type="primary" style="width:100%;color: #fff"
                           @click.native.prevent="handleLogin">立即登录
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/operationalBackOffice'
import { Encrypt } from '@/utils/sercet'

export default {
  name: 'MemberRouteSet',
  data() {
    return {
      loginForm:{
        userName: '',
        password: ''
      },
      loading: false,
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192 - 35
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    chromeHeight() {
      return document.documentElement.clientHeight
    }
  },
  beforeMount() {

  },
  methods: {
    async handleLogin(){
      if(!this.loginForm.userName || !this.loginForm.password){
        this.$message.error('请输入账号和密码')
        return false;
      }
      const account = this.loginForm.userName
      const password = Encrypt(this.loginForm.password)
      this.loading = true
      const res = await api.loginForOP({
        account,
        password
      })
      if (res) {
        // this.$message.success('登录成功')
        sessionStorage.setItem('userForOP', res.account)
        sessionStorage.setItem('tokenForOP', res.token)
        this.$router.push({
          name: 'OP_Home'
        })
        this.loading = false
      }else {
        this.loading = false
        sessionStorage.removeItem('userForOP')
        sessionStorage.removeItem('tokenForOP')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.memberRouteSetting_container {
  width: 100%;
  box-sizing: border-box;
  display: flex;

  .container_left {
    width: 30%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 10px;

    .userItem {
      height: 45px;
      padding-left: 15px;
      font-size: 15px;
      line-height: 45px;
      cursor: pointer;

      &.active {
        color: #FFFFFF;
        background: #717bdf;
        font-weight: bold;
      }
    }
  }

  .container_right {
    width: 70%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;

    .all-btns {
      display: flex;
      justify-content: space-between;

      .label {
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #606266;
        line-height: 40px;
        padding: 0 12px 0 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      .role-btn {
        height: 32px;
        margin-top: 4px;
      }
    }
  }
}
</style>

