import { columns, projectStatusOptions } from './columns'
import { transformColumnsToForm } from '@/utils'
let functionList = {}
export const getPlanFunctionList = val => {
  functionList = val
}
export const formList = transformColumnsToForm(columns)


// 项目任务表单搜索字段
export const searchFormList = [
  {
    colSpan: 6,
    key: 'status',
    tagName: 'el-select',
    props: {
      multiple: true,
      placeholder: '项目状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: projectStatusOptions
      }
    ]
  }
]
