var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchs" },
    [
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("生产计划号：" + _vm._s(_vm.detailData.producePlanNumber))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("生产订单号：" + _vm._s(_vm.detailData.produceOrderNumber))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("生产批次码：" + _vm._s(_vm.detailData.batchNumber))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("物料编码：" + _vm._s(_vm.detailData.materialsCode))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("物料名称：" + _vm._s(_vm.detailData.materialsName))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("规格：" + _vm._s(_vm.detailData.materialsSpecifications))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("单位：" + _vm._s(_vm.detailData.materialsUnit))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("计划数量：" + _vm._s(_vm.detailData.quantity))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("实际开始时间：" + _vm._s(_vm.detailData.actualStartTime))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("实际结束时间：" + _vm._s(_vm.detailData.actualEndTime))
        ])
      ]),
      _vm.isShow
        ? _c("el-col", { attrs: { span: 8 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("工艺：" + _vm._s(_vm.detailData.processFlowName))
            ])
          ])
        : _vm._e(),
      _vm.isShow
        ? _c("el-col", { attrs: { span: 8 } }, [
            _c("span", { staticClass: "bot-ti" }, [
              _vm._v("产线：" + _vm._s(_vm.detailData.areaName))
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }