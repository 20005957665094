var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "certainNumberList" })
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("定额单详情")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("定额单号：" + _vm._s(_vm.detailData.number))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("创建人：" + _vm._s(_vm.detailData.creator))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("创建时间：" + _vm._s(_vm.detailData.createTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("备注：" + _vm._s(_vm.detailData.remark))
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("物料需求")]),
        _c(
          "div",
          { staticClass: "tables" },
          [
            _c("MTable", {
              ref: "mTable",
              attrs: {
                "show-page": false,
                height: 250,
                columns: _vm.columnsProduct,
                data: _vm.productList
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "materialsCode",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.viewMaterialsDetail(row.materialsId)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.materialsCode))]
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "关联生产订单", name: "first" } },
            [
              _c(
                "div",
                { staticClass: "tables" },
                [
                  _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      "show-page": false,
                      height: 200,
                      columns: _vm.columnsProductOrderList,
                      data: _vm.produceOrderList
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "produceOrderNumber",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _c("NewPageOpen", {
                                attrs: {
                                  path: {
                                    name: "orderDetail",
                                    query: { id: row.id }
                                  },
                                  text: row.produceOrderNumber
                                }
                              })
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "materialsCode",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.viewMaterialsDetail(
                                    row.materialsId
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.materialsCode))]
                          )
                        }
                      },
                      {
                        key: "status",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(
                              _vm._s(
                                row.status === 0
                                  ? "未计划"
                                  : row.status === 1
                                  ? "全部计划未完工"
                                  : row.status === 2
                                  ? "已完工"
                                  : row.status === 3
                                  ? "全部计划未完工"
                                  : "--"
                              )
                            )
                          ])
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "工艺路线", "show-footer": false },
          on: {
            onOk: function($event) {
              _vm.processVisible = false
            }
          },
          model: {
            value: _vm.processVisible,
            callback: function($$v) {
              _vm.processVisible = $$v
            },
            expression: "processVisible"
          }
        },
        [
          _c("single-process-flow", {
            ref: "processFlow",
            attrs: {
              "current-procedure-names": _vm.currentProcedureName,
              "read-only": !_vm.saveButton,
              "all-work-order-task-list": _vm.allWorkTask ? _vm.allWorkTask : []
            }
          })
        ],
        1
      ),
      _c("edit-materials", { ref: "editMaterials" }),
      _c("select-work-order", {
        ref: "selectWorkOrder",
        attrs: { visible: _vm.showWorkOrder, "produce-order-id": _vm.id },
        on: {
          "update:visible": function($event) {
            _vm.showWorkOrder = $event
          },
          submitForm: _vm.submitWorkOrder
        }
      }),
      _c("select-plan-order", {
        ref: "selectPlanOrder",
        attrs: { visible: _vm.showPlanOrder, "produce-order-id": _vm.id },
        on: {
          "update:visible": function($event) {
            _vm.showPlanOrder = $event
          },
          submitForm: _vm.submitPlanOrder
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }