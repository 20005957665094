<template>
  <div>
    <div class="top">
      <SearchFormTask
        ref="searchForm"
        :form-option="formOptions"
        :is-out="true"
        @resetData="resetData"
        @searchDatas="searchTable"
      />
    </div>
    <el-divider class="e-divider" />
    <div class="materials-info">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="工单号/工序名称/生产批次码"
        class="functionHeader"
        :columns-key="columnsKey"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :columns="outSourceTask"
        :set-data-method="getTableDatas"
        class="mTable"
        :height="height"
        :columns-key="columnsKey"
        @selection-change="selectionChanges"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="status" slot-scope="{ row }">
          <div v-if="row.status || row.status == 0" :style="{color: row.status === 0 ? '#FA6400' : row.status === 1 ? '#00AB29' : '#9597AE'}"> {{ produceTaskStatus[row.status].name }}</div>
        </div>
        <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
        <div slot="outWarehouseQuantityRecord" slot-scope="{ row }">
          <a class="li-btn" @click="outWarehouseQuantityViews(row, 1)">查看</a>
        </div>
        <div slot="inWarehouseQuantityRecord" slot-scope="{ row }">
          <a class="li-btn" @click="outWarehouseQuantityViews(row, 0)">查看</a>
        </div>
        <div slot="produceOrderNumber" slot-scope="{ row }">
          <NewPageOpen v-if="isShowChild" :path="{ name: 'orderDetail', query: {id: row.produceOrderId}}" :text="row.produceOrderNumber" />
        </div>
        <div slot="produceWorkOrderNumber" slot-scope="{ row }">
          <img v-if="row.procedureClass === 1" src="@/assets/plans/repair-icon-have.png" class="icon-img">
          <NewPageOpen v-if="isShowChild" :path="{ path: 'work-detail', query: {id: row.produceWorkOrderId}}" :text="row.produceWorkOrderNumber" />
        </div>
        <div slot="batchNumberList" slot-scope="{ row }">
          <span>{{ fixData(row.produceWorkOrder.batchNumberList) }}</span>
        </div>
        <div slot="currentProcedure" slot-scope="{ row }">
          <div>{{ row.produceWorkOrder.produceWorkOrderTaskList ? fixProduceData(row.produceWorkOrder.produceWorkOrderTaskList, 'procedureName') : '' }}</div>
        </div>
        <div slot="currentProcedureGroupName" slot-scope="{ row }">
          <div>{{ row.produceWorkOrderTaskList ? fixProduceData(row.produceWorkOrder.produceWorkOrderTaskList, 'procedureGroupName') : '' }}</div>
        </div>
        <div slot="procedureClass" slot-scope="{ row }">
          <div>{{ procedureClass[row.procedureClass].name }}</div>
        </div>
        <div slot="procedureType" slot-scope="{ row }">
          <div>{{ procedureType[row.procedureType].name }}</div>
        </div>

        <div slot="action" slot-scope="{ row }" class="align-btn">
          <div v-if="row.isDeparture !== 1">
            <template v-if="permission('OperatorTaskAssignTask')">
              <a v-if="row.isAssign === 1" class="color-btn" @click="assignClick(row, 2)">已指派</a>
              <a v-else-if="row.isAssign !== 1 && row.procedureIssueMode === 1 && row.isIssue === 0" class="color-gray">指派任务</a>
              <a v-else class="color-btns wi-btn" @click="assignClick(row, 1)">指派任务</a>
            </template>
          </div>
          <div v-if="row.isDeparture === 1">
            <template v-if="permission('OperatorTaskAssignTask')">
              <a v-if="row.isAssign !== 1" class="color-gray">指派任务</a>
              <a v-if="row.isAssign === 1" class="color-gray">已指派</a>
            </template>
          </div>
        </div>
      </MTable>
    </div>
    <!-- 未下发指派-->
    <MDialog v-model="assignVisibleNo" title="指派任务" @onOk="submitUser">
      <div v-if="showAssign" class="all-flexss">
        <el-button type="primary" class="cancel-btn" @click="cancelAssign(1)">取消指派</el-button>
      </div>
      <SelectUserAndUserGroup ref="userAndUserGroup" v-model="transferSelectIds" :is-assign="false" :user-options="userOptions" :user-group-options="userGroupOptions" />
    </MDialog>
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
    <TableDialog title="查看入库记录" :show.sync="inWarehouseVisible" :columns="inWarehouseColumns" :table-data="inWarehouseList" />
    <TableDialog title="查看出库记录" :show.sync="outWarehouseVisible" :columns="outWarehouseColumns" :table-data="outWarehouseList" />
  </div>
</template>
<script>
import SelectUserAndUserGroup from '@/components/SelectUserAndUserGroup/SelectUserAndUserGroup'
import SearchFormTask from './components/search-form-task'
import FunctionHeader from '@/components/FunctionHeader/index'
import { inWarehouseColumns, outWarehouseColumns, outSourceTask, columnsStation } from './columns'
import api from '@/api/production/operation-task'
import apis from '@/api/production/work-order'
import stApi from '@/api/information/production/station'
import supplierApi from '@/api/information/unit'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { produceTaskStatus, procedureClass, procedureType } from '@/config/options.config'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import TableDialog from '@/components/TableDialog/TableDialog'
import fixCommonPage from '@/mixin/fix-common-page'

export default {
  name: 'Schedule',
  components: { TableDialog, NewPageOpen, EditMaterials, FunctionHeader, SearchFormTask, SelectUserAndUserGroup },
  mixins: [viewMaterialsDetail, fixCommonPage],
  props: {
    radio: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      api,
      produceTaskStatus,
      outSourceTask,
      columnsStation,
      procedureClass,
      procedureType,
      inWarehouseColumns,
      outWarehouseColumns,
      inWarehouseVisible: false,
      outWarehouseVisible: false,
      inWarehouseList: [],
      outWarehouseList: [],
      searchDatas: [],
      selectionData: [],
      functionHeader: {
        searchVal: ''
      },
      selectKeyword: '1',
      keyword: '',
      formOptions: {
        orderOptions: [],
        operatorOptions: [],
        produceWorkOrderList: [],
        customersOptions: []
      },
      dialogVisible: false,
      objectStation: [],
      stationList: [],
      produceWorkOrderTaskId: '',
      addStationList: [],
      arrayList: [],
      taskQunlity: 0,
      assignVisible: false,
      showDetail: true,
      assignVisibleNo: false,
      transferSelectIds: [],
      userOptions: [],
      userGroupOptions: [],
      isArrival: 0,
      stationId: '',
      currentIndex: -1,
      showAssign: false,
      supplierIds: '',
      outTime: [],
      statuses: '',
      batchParam: {},
      selectData: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 48 - 12
    },
    columnsKey() {
      return this.$route.name + 'OutSourceTask'
    }
  },
  mounted() {
    this.getOperatorPages()
    // this.getOrderList()
    // this.getOrderPages()
    this.getCustomersOptions()
  },
  methods: {
    selectionChanges(data) {
      this.selectData = data
    },
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    batchExport() {
      const data = this.selectData.map(item => item.id).join('^')
      const ids = [
        {
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: data,
          operator: 'in'
        }
      ]
      const params = {
        searchVal: Encrypt(JSON.stringify(ids))
      }
      this.$refs.functionHeader.export(params)
    },
    async outWarehouseQuantityViews(row, val) {
      const newData = [{
        id: getUUid(),
        fieldName: 'type',
        fieldType: 'number',
        fieldValue: val === 1 ? 1 : 0,
        operator: 'eq'
      },
      {
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: row.produceWorkOrderId,
        operator: 'eq'
      }]
      const res = await api.getOutsourcingTaskPage({ searchVal: Encrypt(JSON.stringify(newData)) })
      if (res) {
        if (val === 1) {
          this.outWarehouseVisible = true
          this.outWarehouseList = res
        } else {
          this.inWarehouseVisible = true
          this.inWarehouseList = res
        }
      }
    },
    // 供应商
    async getCustomersOptions() {
      const res = await supplierApi.getSupplierList()
      if (res) {
        this.formOptions.customersOptions = res
        this.$refs.searchForm.customersList = res
      }
    },
    jumpOrderDetail(row) {
      this.$router.push({ name: 'orderDetail', query: { id: row.produceOrderId }})
    },
    fixProduceData(val, key) {
      const list = val.filter(item => item[key]).map(item => item[key])
      return list.length === 1 ? list[0] : list.join('-')
    },
    // 取消指派
    async cancelAssign(type) {
      const data = {
        workOrderTaskId: this.produceWorkOrderTaskId,
        cancelType: type
      }
      const res = await api.cancelAssignLine(data)
      if (res) {
        if (type === 0) {
          this.dialogVisible = false
        }
        if (type === 1) {
          this.assignVisibleNo = false
        }
        this.assignVisible = false
        this.$refs.mTable.setTableData()
      }
    },
    // 订单
    // async getOrderPages() {
    //   const res = await apis.getOrderpage()
    //   if (res) {
    //     this.formOptions.orderOptions = res
    //     this.$refs.searchForm.orderNumbers = res
    //   }
    // },
    fixData(val) {
      if (val && val.length > 0) {
        const data = val.map(item => item.batchNumber)
        return data.join()
      } else {
        return ''
      }
    },
    // 工单列表
    // async getOrderList() {
    //   const res = await apis.getPlanpageno()
    //   if (res) {
    //     this.formOptions.produceWorkOrderList = res
    //     this.$refs.searchForm.produceWorkOrderList = res
    //   }
    // },
    // 工位
    async getStation(id, areaId) {
      const res = await stApi.getStationListByProcedureId(id, areaId)
      if (res) {
        this.stationList = res
      }
    },
    // 工序
    async getOperatorPages() {
      const res = await apis.getOperatorPage()
      if (res) {
        this.formOptions.operatorOptions = res
        this.$refs.searchForm.currentProcedures = res
      }
    },
    // 列表
    async getTableDatas(condition, callback) {
      const { page, search, order } = condition
      const newData = [{
        id: getUUid(),
        fieldName: 'sourceQuantity',
        fieldType: 'number',
        fieldValue: 0.0,
        operator: 'gt'
      },
      {
        id: getUUid(),
        fieldName: 'type',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      }]
      const params = {
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        materialsSearchType: this.selectKeyword,
        materialsSearchValue: this.keyword,
        statuses: this.statuses,
        supplierIds: this.supplierIds,
        outSourcingBeginTime: this.outTime[0],
        outSourcingEndTime: this.outTime[1],
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchDatas, ...newData]))
      }
      const data = {
        page: page.currentPage,
        limit: page.pageSize,
        ...params
      }
      const res = await api.getOrderTaskpage(data)
      if (res) {
        const data = res.records.map(item => ({
          ...item,
          materialsCode: item.produceWorkOrder.materialsCode,
          materialsName: item.produceWorkOrder.materialsName,
          materialsSpecifications: item.produceWorkOrder.materialsSpecifications,
          materialsUnit: item.produceWorkOrder.materialsUnit,
          deliveryTime: item.produceWorkOrder.deliveryTime,
          processFlowName: item.produceWorkOrder.processFlowName,
          areaName: item.produceWorkOrder.areaName,
          batchNumber: item.produceWorkOrder.batchNumber
        })
        )
        callback({
          total: res.total,
          content: data
        })
      }
      callback()
    },
    // 查询
    searchTable(data, val, keyword, supplierIds, date, statuses) {
      this.statuses = statuses
      this.searchDatas = data
      this.keyword = keyword
      this.selectKeyword = val
      this.supplierIds = supplierIds
      this.outTime = date
      this.$refs.mTable.setTableData(true)
    },
    // 重置
    resetData() {
      this.searchDatas = []
      this.keyword = ''
      this.selectKeyword = '1'
      this.statuses = ''
      this.supplierIds = ''
      this.outTime = []
      this.$refs.mTable.setTableData(true)
    },
    jumpDetail(row) {
      this.$router.push({ path: 'work-detail', query: { id: row.produceWorkOrderId }})
    },
    // 下发至工位
    stationClick(item, type) {
      this.showDetail = type === 1
      this.produceWorkOrderTaskId = item.id
      this.taskQunlity = (item.sourceQuantity - item.reportWorkQuantity).toFixed(2)
      this.addStationList = item.stationList ? item.stationList : []
      this.getStation(item.procedureId, item.areaId)
      this.objectStation = []
      this.dialogVisible = true
    },
    async dialogSubmit(done) {
      done()
      if (this.addStationList.length === 0) {
        this.$message.error('请选择下发的工位')
        return false
      }
      const counts = this.addStationList.every(item => !!item.quantity)
      if (!counts) {
        this.$message.error('数量必须大于零')
        return false
      }
      const res = await api.getTaskStation(this.addStationList)
      if (res) {
        this.$message.success('下发成功')
        this.dialogVisible = false
        this.$refs.mTable.setTableData()
      }
    },
    // 保存工位
    addStation() {
      const list = []
      this.objectStation.forEach(items => {
        this.stationList.forEach(item => {
          if (item.id === items) {
            list.push(item)
          }
        })
      })
      const ArrList = list.map(item => {
        return {
          produceWorkOrderTaskId: this.produceWorkOrderTaskId,
          stationId: item.id,
          stationName: item.name,
          quantity: undefined
        }
      })
      this.addStationList = [...this.addStationList, ...ArrList]
      this.objectStation = []
      this.arrayList = []
    },
    // 删除工位
    delTask(stationId) {
      this.addStationList = this.addStationList.filter(item => item.stationId !== stationId)
    },
    // 添加工位
    selectStation(item) {
      this.arrayList.push({
        produceWorkOrderTaskId: this.produceWorkOrderTaskId,
        stationId: item.id,
        stationName: item.name,
        quantity: undefined
      })
    },
    // 操作指派任务
    async assignClick(item, type) {
      this.showAssign = type === 2
      this.produceWorkOrderTaskId = item.id
      this.isArrival = item.isArrival
      if (item.isIssue === 1) {
        this.taskQunlity = item.workOrderQuantity - item.reportWorkQuantity
        this.addStationList = item.stationList ? item.stationList : []
        this.assignVisible = true
      } else {
        this.stationId = ''
        await this.getUser(item.id)
        this.transferSelectIds = item.userList ? item.userList.map(item => item.frontKey) : []
        this.assignVisibleNo = true
      }
    },
    // 用户组
    async getUser(id, stationId) {
      const res = await api.getUsersLine({
        workOrderTaskId: id,
        stationId: stationId
      })
      if (res) {
        this.userOptions = res.userList
        this.userGroupOptions = res.userGroupList
      }
    },
    // 工序id和工位id拿用户
    async getTaskUserIds(id, stationId) {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderTaskId',
        fieldType: 'number',
        fieldValue: id,
        operator: 'eq'
      })
      search.push({
        id: getUUid(),
        fieldName: 'stationId',
        fieldType: 'number',
        fieldValue: stationId,
        operator: 'eq'
      })
      const res = await api.getTaskUserId({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.transferSelectIds = res ? res.map(item => item.frontKey) : []
      }
    },
    // 已下发工位提交
    assignSubmit(done) {
      done()
      this.assignVisible = false
    },
    async assignStation(item, type, index) {
      await this.getUser(this.produceWorkOrderTaskId, item.stationId)
      await this.getTaskUserIds(this.produceWorkOrderTaskId, item.stationId)
      this.currentIndex = index
      this.stationId = item.stationId
      this.assignVisibleNo = true
    },
    // 下发指派提交
    async submitUser(done) {
      done()
      const ids = this.$refs.userAndUserGroup.getFormatData()
      const userId = ids.user.map(item => {
        return {
          id: item,
          type: 0,
          produceWorkOrderTaskId: this.produceWorkOrderTaskId,
          stationId: this.stationId
        }
      })
      const userGruop = ids.userGroup.map(item => {
        return {
          id: item,
          type: 1,
          produceWorkOrderTaskId: this.produceWorkOrderTaskId,
          stationId: this.stationId
        }
      })
      const allId = [...userId, ...userGruop]
      if (allId.length === 0) {
        this.$message.error('指派人列表不能为空')
        return false
      }
      const res = await api.getTaskUseLine(allId)
      if (res) {
        this.$message.success('指派成功')
        this.$refs.mTable.setTableData()
        this.showAssign = true
        this.assignVisibleNo = false
        if (this.addStationList.length > 0) {
          this.addStationList[this.currentIndex].userList = this.stationId ? [] : null
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.icon-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 19px;
  height: 19px;
  display: block;
}
.all-form{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  .all-btn{
    height: 32px;
    margin-left: 10px;
    display: inline-block;
  }
  ::v-deep .el-input__inner{
     width: 250px;
  }
}
.model-tit{
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456B;
  line-height: 20px;
  margin:0 0 8px 0;
}
.align-btn{
  text-align: center;
  .wi-btns{
    width: 70px;
    display: inline-block;
    text-align: right;
  }
  .wi-btn{
    width: 60px;
    display: inline-block;
    text-align: left;
  }
  .color-btns{
    color:#607FFF;
  }
}
.li-btn{
    margin-right:10px;
    color:#607FFF;
    text-align:center;
    padding:0;
    font-weight: 500;
    ::v-deep .el-button{
      padding: 0 20px;
    }
}
.color-btn{
  color:#00AB29;
  ::v-deep .el-button--text span{
    color:#00AB29;
  }
}
.color-gray{
  color:#c4c4c4;
}
.functionHeader{
  margin-left: -10px;
}
.all-btns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  height: 32px;
  .btns {
    color: #fff;
    height: 32px;
    padding: 0 10px;
    display: block;
  }
}
.cancel-btn {
  color: #fff;
  height: 32px;
  padding: 0 10px;
  display: inline-block;
  text-align: right;
  margin-bottom: 15px;
}
.all-flexs {
  display: flex;
  justify-content: space-between;
}
.all-flexss {
  display: flex;
  justify-content: flex-end;
  margin-right: 26px!important;
}
</style>
