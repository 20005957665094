var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "right",
        trigger: "hover",
        "popper-class": "pover-tips"
      }
    },
    [
      _c(
        "div",
        {
          class:
            _vm.item.status === 0 || _vm.item.status === 2 ? "plan" : "noplan",
          attrs: { slot: "reference" },
          on: { click: _vm.onClick },
          slot: "reference"
        },
        [
          _c("div", {
            staticClass: "runTime",
            style: { background: _vm.priorityStatus[_vm.item.priority].color }
          }),
          !_vm.item.actualStartTime
            ? _c("div", {
                staticClass: "actul-common-color",
                style: {
                  left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                  width:
                    _vm.getWidthAbout2Times(
                      _vm.item.planStartTime,
                      _vm.item.planEndTime
                    ) + "px"
                }
              })
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.actualStartTime, _vm.item.planStartTime) &&
          _vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.actualStartTime
                      ) -
                      6 +
                      "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) + "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.currentDate) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.currentDate,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          !_vm.compareDate(_vm.item.actualStartTime, _vm.item.planEndTime) &&
          _vm.compareDate(_vm.item.actualStartTime, _vm.item.planStartTime) &&
          !_vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.actualStartTime
                      ) + "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-end-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planEndTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planEndTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.actualStartTime, _vm.item.planEndTime) &&
          !_vm.compareDate(_vm.item.planEndTime, _vm.currentDate) &&
          !_vm.compareDate(_vm.item.planStartTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "lines",
                    style: {
                      width:
                        _vm.getWidthAbout2Times(
                          _vm.item.planEndTime,
                          _vm.item.actualStartTime
                        ) + "px"
                    }
                  },
                  [
                    _c("span", { staticClass: "circle" }),
                    _c("span", { staticClass: "line" }),
                    _c("span", { staticClass: "circle" })
                  ]
                ),
                _c("div", {
                  staticClass: "actul-end-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.planStartTime, _vm.item.actualStartTime) &&
          _vm.compareDate(_vm.item.planStartTime, _vm.currentDate) &&
          _vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time aa" }, [
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "lines",
                    style: {
                      width:
                        _vm.getWidthAbout2Times(
                          _vm.currentDate,
                          _vm.item.planStartTime
                        ) + "px"
                    }
                  },
                  [
                    _c("span", { staticClass: "circle" }),
                    _c("span", { staticClass: "line" }),
                    _c("span", { staticClass: "circle" })
                  ]
                ),
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.planStartTime, _vm.item.actualStartTime) &&
          !_vm.compareDate(_vm.item.planStartTime, _vm.currentDate) &&
          _vm.compareDate(_vm.item.planEndTime, _vm.currentDate)
            ? _c("div", { staticClass: "actul-time" }, [
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-common-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.currentDate) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.currentDate,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                })
              ])
            : _vm._e(),
          _vm.item.actualStartTime &&
          _vm.compareDate(_vm.item.planStartTime, _vm.item.actualStartTime) &&
          !_vm.compareDate(_vm.item.planStartTime, _vm.currentDate) &&
          _vm.compareDate(_vm.currentDate, _vm.item.planEndTime)
            ? _c("div", { staticClass: "actul-time cc" }, [
                _c("div", {
                  staticClass: "actul-time-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.actualStartTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.actualStartTime,
                        _vm.item.planEndTime
                      ) + "px"
                  }
                }),
                _c("div", {
                  staticClass: "actul-end-color",
                  style: {
                    left: _vm.getPositonOffset(_vm.item.planEndTime) + "px",
                    width:
                      _vm.getWidthAbout2Times(
                        _vm.item.planEndTime,
                        _vm.currentDate
                      ) -
                      6 +
                      "px"
                  }
                })
              ])
            : _vm._e()
        ]
      ),
      _c("div", { staticClass: "detail" }, [
        _c("div", { staticClass: "li-right-top" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.item.materialsName))
          ]),
          _c("div", { staticClass: "tips" }, [
            _c(
              "div",
              {
                staticClass: "tip",
                style: {
                  color: "#fff",
                  background:
                    _vm.item.produceStatus === 0
                      ? "rgba(224, 32, 32, 1)"
                      : _vm.item.produceStatus === 1
                      ? "rgba(0, 171, 41, 1)"
                      : "rgba(96, 127, 255, 1)"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.productionStatus[_vm.item.produceStatus].name) +
                    " "
                )
              ]
            ),
            _vm.item.isRepair === 1
              ? _c("img", {
                  staticClass: "tip-img",
                  attrs: { src: require("@/assets/plans/fanxiu-plan.png") }
                })
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "des" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.item.reportWorkQuantity ? _vm.item.reportWorkQuantity : 0
              ) +
                "/" +
                _vm._s(_vm.item.quantity)
            )
          ])
        ]),
        _c("div", { staticClass: "des-title" }, [
          _vm._v(
            "当前工序：" +
              _vm._s(
                _vm.item.produceWorkOrderTaskList
                  ? _vm.item.produceWorkOrderTaskList[0].procedureName
                  : ""
              )
          )
        ]),
        _vm.item.isRepair !== 1
          ? _c("div", { staticClass: "des-tit" }, [
              _vm._v("生产订单号：" + _vm._s(_vm.item.produceOrderNumber))
            ])
          : _vm._e(),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("物料规格：" + _vm._s(_vm.item.materialsSpecifications))
        ]),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("物料编码：" + _vm._s(_vm.item.materialsCode))
        ]),
        _c("div", { staticClass: "des-tit" }, [
          _vm._v("交期：" + _vm._s(_vm.item.deliveryTime))
        ]),
        _c("div", { staticClass: "dec" }, [
          _vm._v("计划优先级： "),
          _c(
            "span",
            {
              style: {
                color:
                  _vm.priorityStatus[_vm.item.priority].name === "普通"
                    ? "#fff"
                    : _vm.priorityStatus[_vm.item.priority].color
              }
            },
            [_vm._v(_vm._s(_vm.priorityStatus[_vm.item.priority].name) + " ")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }