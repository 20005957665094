var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "SearchForm",
            {
              ref: "searchForm",
              staticStyle: { padding: "0" },
              attrs: {
                "form-list-extend": _vm.searchFormList,
                "form-data": _vm.searchFormData
              },
              on: {
                "update:formData": function($event) {
                  _vm.searchFormData = $event
                },
                "update:form-data": function($event) {
                  _vm.searchFormData = $event
                },
                search: _vm.reSearch
              }
            },
            _vm._l(_vm.searchFormSelList, function(item) {
              return _c("SelectRemoteSearch", {
                key: item.key,
                ref: item.key,
                refInFor: true,
                staticClass: "select",
                attrs: {
                  slot: item.key,
                  "size-name": _vm.apiMaps[item.key].sizeName || "limit",
                  clearable: "",
                  "search-name": item.searchName || "search",
                  placeholder: _vm.apiMaps[item.key].placeholder,
                  multiple: !_vm.apiMaps[item.key].notMultiple,
                  "option-val": _vm.apiMaps[item.key].optionVal,
                  "option-label": _vm.apiMaps[item.key].optionLabel,
                  api: _vm.apiMaps[item.key].api,
                  "collapse-tags": ""
                },
                on: {
                  change: function(val) {
                    _vm.handleSelect(item.key, val)
                  }
                },
                slot: item.key
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "FunctionHeader",
        {
          ref: "functionHeader",
          staticClass: "func-header",
          staticStyle: { padding: "10px 0 10px 0" },
          attrs: {
            "search-title": "请输入模具名称",
            tags: !_vm.selectIds.length ? _vm.functionHeader.tags : [],
            exportName: !_vm.selectIds.length
              ? _vm.functionHeader.exportName
              : "",
            "import-name": !_vm.selectIds.length ? "moldDefineImport" : ""
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectIds.length,
                  expression: "selectIds.length"
                }
              ],
              attrs: { slot: "all" },
              slot: "all"
            },
            [
              _vm.permission("Export")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.allMore("onExport")
                        }
                      }
                    },
                    [_vm._v("批量导出")]
                  )
                : _vm._e(),
              _vm.permission("Del")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.allMore("Del")
                        }
                      }
                    },
                    [_vm._v("批量删除")]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "0 10px",
                    display: "inline-block",
                    "font-size": "12px",
                    color: "#393D60"
                  }
                },
                [
                  _vm._v(" 已选 "),
                  _c("span", { staticStyle: { color: "#607FFF" } }, [
                    _vm._v(_vm._s(_vm.selectIds.length))
                  ]),
                  _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "small" },
                  on: { click: _vm.resetData }
                },
                [_vm._v("清空")]
              )
            ],
            1
          )
        ]
      ),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        on: {
          "selection-change": function(data) {
            return (_vm.selectIds = data.map(function(item) {
              return item.id
            }))
          }
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "processFlowFullName",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "processDetail",
                        query: { id: row.processFlowId }
                      },
                      text: row.processFlowFullName
                    }
                  })
                ],
                1
              )
            }
          },
          {
            key: "materialsFullName",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "materialsDetail",
                        query: { id: row.materialsId }
                      },
                      text: row.materialsFullName
                    }
                  })
                ],
                1
              )
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.permission("View")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.jump("view", row.id)
                            }
                          }
                        },
                        [_vm._v("详情")]
                      )
                    : _vm._e(),
                  _vm.permission("Edit")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.jump("edit", row.id)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                  _vm.permission("Del")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.onDel([row.id])
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }