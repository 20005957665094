// import { priorityStatus } from '@/config/options.config'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
// 计划订单列表
export const columnsAll = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'status',
    label: '计划状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    width: 120
  },
  {
    prop: 'materialPreparationStatus',
    label: '备料状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialPreparationStatus',
    width: 120
  },
  {
    prop: 'quantity',
    label: '计划数量',
    width: 120,
    hiddenSearch: true,
    sortable: 'custom',
    form: { index: 8, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'batchNumber',
    label: '生产批次码',
    hiddenSearch: true,
    sortable: false,
    width: 220,
    form: { index: 2, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'produceStatus',
    label: '生产状态',
    width: 100,
    hiddenSearch: true,
    sortable: false,
    slotName: 'produceStatus'
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    width: 100,
    hiddenSearch: true,
    sortable: false
    // form: { index: 18, tagName: 'el-input', required: false,  props: { disabled: true}}
  },
  {
    prop: 'producePlanNumber',
    label: '生产计划号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 220,
    slotName: 'producePlanNumber',
    form: { index: 1, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'source',
    label: '计划来源',
    hiddenSearch: true,
    sortable: false,
    slotName: 'source'
  },
  {
    prop: 'type',
    label: '计划类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'type',
    width: 120
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: 'custom',
    slotName: 'produceOrderNumber',
    width: 180
  },
  {
    prop: 'priority',
    label: '计划优先级',
    hiddenSearch: true,
    sortable: 'custom',
    slotName: 'priority',
    width: 150,
    form: {
      index: 12,
      tagName: 'el-select',
      options: [],
      defaultValue: 1
    }
  },
  {
    prop: 'isRepair',
    label: '计划属性',
    hiddenSearch: true,
    sortable: false,
    width: 150,
    slotName: 'isRepair',
    form: { index: 17, tagName: 'el-select', required: false, props: { disabled: true }}
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    form: { index: 4, tagName: 'el-input', required: false, props: { disabled: true }},
    slotName: 'materialsCode',
    width: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    width: 180,
    form: { index: 5, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 400,
    form: { index: 6, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    form: { index: 7, tagName: 'el-input', required: false, props: { disabled: true }}
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: 'custom',
    width: 150,
    form: {
      index: 11,
      key: 'deliveryTime',
      tagName: 'el-date-picker',
      props: {
        align: 'center',
        type: 'date',
        valueFormat: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd'
      }
    }
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false,
    slotName: 'processFlowName',
    width: 180,
    form: {
      index: 9,
      key: 'processFlowId',
      tagName: 'div',
      customSlots: ['process']
    }
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false,
    width: 180,
    form: {
      index: 10,
      key: 'areaId',
      tagName: 'el-select'
    }
  },
  {
    prop: 'planStartTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180,
    form: {
      index: 13,
      key: 'planStartTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'planEndTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180,
    form: {
      index: 14,
      key: 'planEndTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'actualStartTime',
    label: '实际开始时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180,
    form: {
      index: 15,
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        disabled: true,
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm:ss'
      }
    }
  },
  {
    prop: 'actualEndTime',
    label: '实际结束时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180,
    form: {
      index: 16,
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        disabled: true,
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm:ss'
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    form: { index: 18, tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 190
  }
]
export const columnsPlan = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsPicture',
    label: '物料图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsPicture'
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'quantity',
    label: '计划数量',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'batchNumber',
    label: '生产批次码',
    slotName: 'batchNumber',
    hiddenSearch: true,
    sortable: false,
    width: 220
  }
]
export const columnsPlanTime = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    width: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'producePlanNumber',
    label: '生产计划号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 220
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: 'custom',
    width: 150
  },
  {
    prop: 'planStartTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false,
    width: 230,
    slotName: 'planStartTime'
  },
  {
    prop: 'planEndTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false,
    width: 230,
    slotName: 'planEndTime'
  }
]
