<template>
    <div class="orderAdd">
      <!-- <button @click="DecryptFn()">解密</button> -->
      <div class="procedure-detail-header">
        <div class="left" @click="$router.push({ name: 'certainNumberList' })">
          <img src="@/assets/information/procedure/左滑@2x.png">
          <span>定额单详情</span>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-col :span="6">
            <span class="bot-ti">定额单号：{{ detailData.number }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">创建人：{{ detailData.creator }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">创建时间：{{ detailData.createTime }}</span>
          </el-col>
          <el-col :span="6">
            <span class="bot-ti">备注：{{ detailData.remark }}</span>
          </el-col>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">物料需求</div>
        <div class="tables">
          <MTable
            ref="mTable"
            :show-page="false"
            :height="250"
            :columns="columnsProduct"
            :data="productList"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
           
            <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
          </MTable>
        </div>
      </div>
      
      <el-tabs v-model="activeName">
        <el-tab-pane label="关联生产订单" name="first">
        <div class="tables">

          <MTable
            ref="mTable"
            :show-page="false"
            :height="200"
            :columns="columnsProductOrderList"
            :data="produceOrderList"
          >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="produceOrderNumber" slot-scope="{ row }">
        <NewPageOpen  :path="{ name: 'orderDetail', query: {id: row.id}}" :text="row.produceOrderNumber" />

      </div>
      <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>

          <div slot="status" slot-scope="{ row }">{{ row.status===0?'未计划':row.status===1?'全部计划未完工':row.status===2?'已完工':row.status===3?'全部计划未完工':'--' }}</div>

          </MTable>
        </div>

          <!-- <MTable ref="orderTables" :height="300" :data="produceOrderList"  :columns="columnsProductOrderList">
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            
          </MTable> -->
        </el-tab-pane>
      </el-tabs>
      <!-- 工艺路线 -->
      <MDialog v-model="processVisible" title="工艺路线" :show-footer="false" @onOk="processVisible = false">
        <single-process-flow ref="processFlow" :current-procedure-names="currentProcedureName" :read-only="!saveButton" :all-work-order-task-list="allWorkTask ? allWorkTask : []" />
        <!-- <process-flow ref="processFlow" :read-only="!saveButton" :process-flow-detail="processFlowVersion" :showVersion="true"/> -->
      </MDialog>
      <!-- 物料详情-->
      <edit-materials ref="editMaterials" />
      <!-- 选择工单 -->
      <select-work-order
        ref="selectWorkOrder"
        :visible.sync="showWorkOrder"
        :produce-order-id="id"
        @submitForm="submitWorkOrder"
      />
      <!-- 选择计划 -->
      <select-plan-order
        ref="selectPlanOrder"
        :visible.sync="showPlanOrder"
        :produce-order-id="id"
        @submitForm="submitPlanOrder"
      />
    </div>
  </template>
  <script>
  import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
  import { columnsPlanOrder, columnsWorkOrder, columnsProduct,columnsMaterialPlan ,columnsProductOrderList} from './columnsNew.js'
  import { planStatus, orderSource, productionStatus } from '@/config/options.config'
  import api from '@/api/production/orders'
  import workApi from '@/api/production/work-order'
  import planApi from '@/api/production/plans'
  import processApi from '@/api/information/production/process'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import { getUUid } from '@/utils'
  import { Encrypt ,Decrypt} from '@/utils/sercet'
  import viewMaterialsDetail from '@/mixin/view-materials-detail'
  import EditMaterials from '@/views/information/materials/components/edit-materials'
  import SelectWorkOrder from '@/components/SelectWorkOrder/SelectWorkOrder'
  import SelectPlanOrder from '@/components/SelectPlanOrder/SelectPlanOrder'
  import saveAs from 'file-saver'
  import singleProcessFlow from '@/components/singleProcessFlow/singleProcessFlow'
  import quotaSheetApi from '@/api/production/quotaSheet'
import NewPageOpen from "@/components/NewPageOpen/NewPageOpen";
  
  export default {
    // eslint-disable-next-line vue/name-property-casing
    name: 'certainNumberDetail',
    components: {NewPageOpen, singleProcessFlow, FunctionHeader, ProcessFlow, EditMaterials, SelectWorkOrder, SelectPlanOrder },
    mixins: [viewMaterialsDetail],
    data() {
      return {
        columnsProductOrderList,
        columnsPlanOrder,
        columnsProduct,
        columnsMaterialPlan,
        columnsWorkOrder,
        planStatus,
        orderSource,
        productionStatus,
        allWorkTask: [],
        userList: [],
        customTag: [],
        showPlanOrder: false,
        showWorkOrder: false,
        show: false,
        orderList: [],
        detailData: {
          source: 0
        },
        productList: [],
        functionHeader: {
          searchVal: '',
          tags: [
            
          ]
        },
        functionHeaders: {
          searchVal: '',
          tags: [
           
          ]
        },
        activeName: 'first',
        relationType: 0,
        id: undefined,
        processVisible: false,
        saveButton: false,
        processFlowVersion: {},
        currentProcedureIds: [],
        currentProcedureName: '',
        produceOrderList:[]
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29 - 62 - 22
      },
      priorityStatus() {
        return this.$store.state.user.priorityList
      }
    },
    activated() {
      this.id = parseInt(this.$route.query.id)
      this.getOrderDetail(this.$route.query.id)
      // this.$refs.orderTables.setTableData()
    },
    methods: {
      //解密
      DecryptFn(text){
        let res =  Decrypt('ebd36d259c1c68289216f6f7e54bcce5d266b0779f3b4f5dd67cac781b7843c3fea371b1384c960416353558df6bf93f371ff31c833abb930a2beb708010419a5d05bff156b9f07a3ab4dde79cbc954f5afaa8a9c714fd0aae0175fa75bb7805d47aea512fd102d3f10a67efbca1a90ea74e46d73e23ff7d5d53c603948b654b')
        console.log(res)
      },
      async getProduceProcessList(row) {
        const res = await workApi.getProduceProcessList({
          id: row.id
        })
        if (res) {
          this.allWorkTask = res
        }
      },
      fixImg(img) {
        if (img) {
          return [img]
        }
      },
      handlePreview(file) {
        saveAs(file.url, file.name)
      },
      fixArrs(val) {
        return val ? JSON.parse(val) : ''
      },
      async submitPlanOrder(val) {
        this.showPlanOrder = false
        const ids = val.map(item => item.id)
        const res = await api.addPlanOrder({ id: this.id, workOrderIdList: ids })
        if (res) {
          this.$refs.orderTables.setTableData()
        }
      },
      async submitWorkOrder(val) {
        this.orderList = val
        this.showWorkOrder = false
        const ids = val.map(item => item.id)
        const res = await workApi.addWorkOrder({ id: this.id, workOrderIdList: ids })
        if (res) {
          this.$refs.orderTable.setTableData()
        }
      },
      async cancelWorkOrders(row) {
        this.$confirm('您确定要取消关联吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await workApi.cancelWorkOrder({ id: this.id, workOrderIdList: [row.id] })
          if (res) {
            this.$refs.orderTable.setTableData()
          }
        })
      },
      async cancelPlanOrders(row) {
        this.$confirm('您确定要取消关联吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.cancelPlanOrder({ id: this.id, workOrderIdList: [row.id] })
          if (res) {
            this.$refs.orderTables.setTableData()
          }
        })
      },
      async showProcess(row) {
        // await this.getProcessDetailById(row.processFlowId, row.processFlowVersionId)
        await this.getProduceProcessList(row)
        this.currentProcedureName = row.currentProcedureName
        this.processVisible = true
      },
      // 获取工艺详情
      async getProcessDetailById(id, versionId) {
        const res = await processApi.getProcessDetail({ id: id, versionId: versionId })
        if (res) {
          this.processFlowVersion = res.processFlowVersion
        }
      },
      fixProduceData(val) {
        const list = val.map(item => item.procedureName)
        return list.lenght === 1 ? list[0] : list.join('-')
      },
      jumpOrderDetail(row) {
        this.$router.push({ name: 'orderDetail', query: { id: row.produceOrderId }})
      },
      jumpDetail(row) {
        console.log(row.id)
        this.$router.push({ name: 'workDetail', query: { id: row.id }})
      },
      addWorkOrder() {
        // this.selectIds = this._.cloneDeep(this.orderList)
        this.showWorkOrder = true
      },
      addPlanOrder() {
        this.showPlanOrder = true
      },
      // 列表
      async getTableData(condition, callback) {
        const { page, search, order } = condition
        console.log(search)
        const res = await workApi.getPlanpage({
          page: page.currentPage,
          limit: page.pageSize,
          sort: order,
          searchKeyword: this.functionHeader.searchVal,
          produceOrderId: this.id,
          relationType: this.relationType,
          searchVal: Encrypt(JSON.stringify([...search]))
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      // 计划列表
      async getPlanTableData(condition, callback) {
        const { page, search, order } = condition
        const res = await planApi.getPlanpage({
          page: page.currentPage,
          limit: page.pageSize,
          sort: order,
          produceOrderId: this.id,
          relationType: this.relationType,
          searchKeyword: this.functionHeaders.searchVal,
          searchVal: Encrypt(JSON.stringify([...search]))
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      // 订单详情
      async getOrderDetail(id) {
        this.productList = []
        const res = await quotaSheetApi.getQuotaSheetDetail(id)
        if (res) {
          this.detailData = res
          this.productList=res.requirementList
          this.produceOrderList = res.produceOrderList
        }
      },
      returnList() {
        this.$router.push({ path: 'order-list' })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
  .bot-li {
    display: flex;
    justify-content: flex-start;
    .bot-ti{
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      display: inline-block;
      margin-bottom: 15px;
    }
  }
  .el-tags {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .icon-img {
      position: absolute;
      right: 0;
      top: 0;
      width: 19px;
      height: 19px;
      display: block;
  }
  .all-ipt{
    margin-top: 4px;
  }
  .procedure-detail-header{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456B;
    line-height: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    .left{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    img{
      width: 8px;
      height: 12px;
      display: block;
      margin-right: 10px;
    }
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .card-bot{
    display:flex;
    flex-flow:row wrap;
    .bot-ti{
      margin-top: 8px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      display:inline-block;
      margin-bottom:16px;
    }
  }
  .tables {
    padding: 20px;
    background: #fff;
  }
  </style>
  