var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "searchs" },
    [
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("生产工单号：" + _vm._s(_vm.detailData.produceWorkOrderNumber))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v(
            "工单当前所处工序：" +
              _vm._s(
                _vm.detailData.produceWorkOrderTaskList
                  ? _vm.fixProduceData(_vm.detailData.produceWorkOrderTaskList)
                  : ""
              )
          )
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("生产批次码：" + _vm._s(_vm.detailData.batchNumber))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v(
            "工单来源：" +
              _vm._s(
                _vm.detailData.source
                  ? _vm.taskSource[_vm.detailData.source].name
                  : ""
              )
          )
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("所属计划号：" + _vm._s(_vm.detailData.producePlanNumber))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("所属订单号：" + _vm._s(_vm.detailData.produceOrderNumber))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("交期：" + _vm._s(_vm.detailData.deliveryTime))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("计划开始时间：" + _vm._s(_vm.detailData.planStartTime))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("计划结束时间：" + _vm._s(_vm.detailData.planEndTime))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v("产线：" + _vm._s(_vm.detailData.areaName))
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("span", { staticClass: "bot-ti" }, [
          _vm._v(
            "工单属性：" +
              _vm._s(_vm.detailData.isRepair === 0 ? "普通" : "返修")
          )
        ])
      ]),
      _c("el-col", { attrs: { span: 8 } }, [
        _c("div", { staticClass: "bot-ti" }, [
          _vm._v("计划优先级： "),
          _c(
            "span",
            {
              staticClass: "tag-priority",
              style: {
                color: _vm.priorityStatus[_vm.detailData.priority]
                  ? _vm.priorityStatus[_vm.detailData.priority].color
                  : "rgb(74, 79, 118)",
                background:
                  (_vm.priorityStatus[_vm.detailData.priority]
                    ? _vm.priorityStatus[_vm.detailData.priority].color
                    : "rgb(74, 79, 118)") + "20"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.priorityStatus[_vm.detailData.priority]
                      ? _vm.priorityStatus[_vm.detailData.priority].name
                      : "普通"
                  ) +
                  " "
              )
            ]
          )
        ])
      ]),
      _vm.detailData.sourceWorkOrderNumber
        ? _c(
            "el-col",
            { staticStyle: { "margin-top": "-10px" }, attrs: { span: 8 } },
            [
              _c(
                "div",
                { staticClass: "bot-ti", staticStyle: { margin: "0" } },
                [
                  _vm._v("源工单号： "),
                  _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "workDetail",
                        query: { id: _vm.detailData.sourceWorkOrderId }
                      },
                      text: _vm.detailData.sourceWorkOrderNumber
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }