import { transformColumnsToForm } from '@/utils'

const upDownWorkStatus = [
  { name: '已上工', id: 0 },
  { name: '已下工', id: 1 },
  { name: '批量报工', id: 2 },
  { name: '上下工报工', id: 3 },
]
const workHoursType = [
  { name: '停工', id: 0 },
  { name: '返工', id: 1 },
  { name: '正常', id: 2 }
]
export const workHoursTypeMaps = workHoursType.reduce((pre, cur) => ({ ...pre, [cur.id]: cur.name }), {})
export const upDownWorkStatusMaps = upDownWorkStatus.reduce((pre, cur) => ({ ...pre, [cur.id]: cur.name }), {})

// 上下工记录列表字段
export const upDownWorkColumn = [
  // {
  //   type: 'selection',
  //   width: 50,
  //   fixed: 'left',
  //   reserveSelection: true
  // },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'direction',
    label: '状态',
    slotName: 'direction',
    sortable: false,
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'upWorkTime',
    label: '上工时间',
    slotName: 'upWorkTime',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'downWorkTime',
    label: '下工时间',
    slotName: 'downWorkTime',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'jobNumber',
    label: '工号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'userName',
    label: '姓名',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'workHoursType',
    label: '工时类型',
    slotName: 'workHoursType',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'workHoursReasonName',
    label: '工时原因',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'workOrderNumber',
    label: '工单编号',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'projectNumber',
    label: '项目编号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'projectName',
    label: '项目名称',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'singleUpWorkWorkHours',
    label: '单次上工工时/h',
    slotName: 'singleUpWorkWorkHours',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'personalTotalWorkHours',
    label: '工序个人工时/h',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 150,
    slotName: 'action',
    fixed: 'right'
  }
]


