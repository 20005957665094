<template>
  <div class="orderAdd">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.push({ name: 'orderList' })">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>创建计划</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-col :span="6">
          <span class="bot-ti">订单号：{{ detailData.produceOrderNumber }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">物料编码：{{ detailData.materialsCode }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">物料名称：{{ detailData.materialsName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">规格：{{ detailData.materialsSpecifications }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">单位：{{ detailData.materialsUnit }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">生产订单数量：{{ detailData.quantity }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">已计划数量：{{ detailData.planQuantity }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">报工数量：{{ detailData.reportWorkQuantity }}</span>
        </el-col>
      </div>
      <el-divider class="e-divider" />
      <div class="card-bot">
        <el-form ref="ruleForm" label-position="right" :model="ruleForm" :rules="rules" label-width="100px" class="demo-form">
          <el-row>
            <el-col :span="6">
              <el-form-item label="计划数量：" prop="quantity">
                <el-input-number v-if="planId === 0" v-model="ruleForm.quantity" :min="1" :max="detailData.quantity - detailData.planQuantity" controls-position="right" :step="1" />
                <el-input-number v-else v-model="ruleForm.quantity" :min="1" controls-position="right" :step="1" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="工艺：" prop="processFlowId">
                <el-input v-model="ruleForm.processFlowName" class="all-ipt">
                  <template slot="append">
                    <el-button icon="el-icon-more" @click="selectProcessFlows" />
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="产线：" prop="areaId">
                <el-select v-model="ruleForm.areaId" placeholder="请选择">
                  <el-option
                    v-for="item in areaIds"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="优先级：">
                <el-select v-model="ruleForm.priority" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in priorityStatus"
                    :key="index"
                    :label="item.name"
                    :value="index"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="交期：" prop="deliveryTime">
                <el-date-picker v-model="ruleForm.deliveryTime" type="date" placeholder="请选择" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy-MM-dd" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="计划时间：">
                <el-date-picker v-model="ruleForm.dates" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" placeholder="请选择" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" />
              </el-form-item>
            </el-col>
            <!-- 需要一个字段isBatchManager说明是否需要批次码 -->
            <el-col v-if="isBatchManager" :span="6">
              <el-form-item label="生产批次码" prop="batchNumber">
                <el-input v-model="ruleForm.batchNumber" />
                <el-button style="position:absolute;top: 22px;right:0" type="text" @click="addBatch">自动生成</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="order-footer">
      <div class="footer-le" />
      <div class="footer-ri">
        <el-button @click="returnList">取消</el-button>
        <el-button type="primary" :loading="saveLoading" @click="submitForms">确认并生成计划</el-button>
      </div>
    </div>
    <div class="lines" />
    <SelectProcessFlow
      ref="processFlow"
      :visible.sync="processFlowVisible"
      :select-process-and-version-ids="processIdAndVersionId"
      :materials-id="detailData.materialsId"
      @submit="selectFlow"
    />
  </div>
</template>
<script>
import { columnsPlan } from './columns'
import { orderSource } from '@/config/options.config'
import api from '@/api/production/orders'
import mApi from '@/api/information/materials'
import bApi from '@/api/information/print-label/batch-number'
import SelectProcessFlow from '@/components/SelectProcessFlow/SelectProcessFlow'
import baseSettingApi from '@/api/sets/base-setting'
import processApi from '@/api/information/production/process'

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'PlanAdd',
  components: { SelectProcessFlow },
  data() {
    return {
      isBatchManager: 0,
      columnsPlan,
      orderSource,
      saveLoading: false,
      showMaterials: false,
      show: false,
      orderList: [],
      processFlowIds: [],
      areaIds: [],
      ruleForm: {
        processFlowName: '',
        processFlowId: '',
        processFlowVersionId: '',
        areaId: '',
        deliveryTime: '',
        dates: [],
        priority: 0,
        quantity: undefined,
        batchNumber: null
      },
      rules: {
        quantity: [
          { required: true, message: '请输入数量', trigger: 'blur' }
        ],
        processFlowId: [
          { required: true, message: '请选择工艺', trigger: 'change' }
        ],
        areaId: [
          { required: true, message: '请选择产线', trigger: 'change' }
        ],
        deliveryTime: [
          { required: true, message: '请选择交期', trigger: 'change' }
        ],
        batchNumber: [
          { required: true, message: '请输入生产批次码', trigger: 'change' }
        ]
      },
      detailData: {},
      processFlowVisible: false,
      processIdAndVersionId: [],
      isSave: true,
      planId: 0,
      productionCode: ''
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'orderList') {
      this.$store.dispatch('tagsView/delCachedView', to)
    }
    next()
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29 - 62 - 22
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    if (['mubai', 'reagold'].includes(sessionStorage.getItem('memberCode'))) {
      this.getPlansConfig()
    }
    this.getOrderDetail(this.$route.query.id)
  },
  methods: {
    async getPlansConfig() {
      const res = await baseSettingApi.getCode({ code: 'plan_total_need_review' })
      if (res) {
        this.planId = +res.value
      }
    },
    fixImg(img) {
      if (img) {
        return [img]
      }
    },
    // 添加批次码
    async addBatch() {
      const data = {
        materialsId: this.detailData.materialsId,
        materialsCode: this.detailData.materialsCode
      }
      const res = await bApi.addBatchNumber(data)
      if (res) {
        this.ruleForm.batchNumber = res.batchNumber
      }
    },
    // 物料详情
    async getMaterialsDetail(id) {
      const res = await mApi.getMaterialsDetailList(id)
      this.isBatchManager = +res?.isBatchManager
      if (res && res.processFlow) {
        this.ruleForm.processFlowId = res.processFlowId
        this.ruleForm.processFlowVersionId = res.processFlowVersionId
        this.ruleForm.processFlowName = res.processFlow.name
        this.areaIds = res.processFlow.areaList
        this.ruleForm.areaId = res.processFlow.areaList[0].id
      }
    },
    // 订单详情
    async getOrderDetail(id) {
      const res = await api.getOrderDetail(id)
      if (res) {
        this.detailData = res
        this.ruleForm.priority = res.priority
        this.ruleForm.deliveryTime = res.deliveryTime
        this.ruleForm.quantity = res.quantity - res.planQuantity
        this.productionCode = res.remark
        this.getMaterialsDetail(res.materialsId)
        // this.getProcessList()
      }
    },
    // 工艺弹窗
    selectProcessFlows() {
      if (this.ruleForm.processFlowId) {
        this.processIdAndVersionId = [this.ruleForm.processFlowId, this.ruleForm.processFlowVersionId]
        this.$refs.processFlow.selectProcessChange(this.ruleForm.processFlowId)
      }
      this.processFlowVisible = true
    },
    selectFlow(data) {
      this.areaIds = data.processData.areaList
      this.ruleForm.processFlowName = data.processData.name
      this.ruleForm.processFlowId = data.processFlowVersion.processFlowId
      this.ruleForm.processFlowVersionId = data.processFlowVersion.id
      this.ruleForm.areaId = data.processData.areaList[0].id
      this.processFlowVisible = false
    },
    submitForms() {
      const that = this
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.getData()
        } else {
          return false
        }
      })
    },
    // 新增
    async getData() {
      const planStartTime = this.ruleForm.dates.length > 0 ? this.ruleForm.dates[0] : null
      const planEndTime = this.ruleForm.dates.length > 0 ? this.ruleForm.dates[1] : null
      // if (planStartTime > this.ruleForm.deliveryTime || planEndTime > this.ruleForm.deliveryTime) {
      //   return this.$message.warning('计划时间不能大于交期')
      // }
      this.saveLoading = true
      delete this.ruleForm.dates
      const res = await api.addPlan({ ...this.ruleForm, produceOrderId: this.detailData.id, planStartTime, planEndTime })
      if (res) {
        this.returnList()
      }
      this.saveLoading = false
    },
    // async getData () {
    //   let data = this.ruleForm
    //   let obj = {
    //     produceOrderId: this.orderList[0].id,
    //     quantity: this.orderList[0].quantity,
    //     batchNumber: this.orderList[0].batchNumber,
    //     planStartTime: this.ruleForm.dates.length > 0 ? this.ruleForm.dates[0] : null,
    //     planEndTime: this.ruleForm.dates.length > 0 ? this.ruleForm.dates[1] : null,
    //   }
    //   console.log('list', data, Object.assign(data, obj))
    //   const res = await api.addPlan(Object.assign(data, obj))
    //   if (res) {
    //     this.returnList()
    //   }
    //   this.saveLoading = false
    // },
    returnList() {
      this.$router.push({ path: 'order-list' })
    },
    async getProcessList() {
      console.log('this.materialsId, this.type==', this.detailData.materialsId, this.proceType)
      const defaultSearch = {
        materialsId: this.materialsId,
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          },
          {
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 0,
            operator: 'eq'
          }]
        ))
      }
      const res = await processApi.getProcessList(defaultSearch)
      if (res) {
        // this.processList = res
      }
    },
  }
}
</script>

<style scoped lang="scss">
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.all-ipt {
  // margin-top: 4px;
}
.all-flexss {
  display: flex;
  justify-content: center;
  .cancel-btn {
    margin-left: 15px;
  }
}
.e-divider {
  margin-top: 0;
  margin-bottom: 10px;
}
.orderAdd {
  width: 100%;
  height: 100%;
  //  position:relative;
  .detail-card {
    margin-bottom: 20px;
  }
  .tables {
    width: 100%;
    overflow: scroll;
  }
  .demo-form {
    width: 100%;
  }
  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }
  .card-bot ::v-deep {
    .el-form-item {
      margin-bottom: 16px;
    }
    .el-date-editor .el-range-separator,
    .el-date-editor .el-range__icon {
      line-height: 26px;
    }
    .el-input-number {
      height: 32px;
      line-height: 32px;
      width: 100%;
    }

    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input-number.is-controls-right .el-input-number__increase,
    .el-input-number.is-controls-right .el-input-number__decrease {
      line-height: 15px;
    }
  }
  .e-btn {
    margin-left: 20px;
    margin-top: 3px;
    width: 88px;
    display: inline-block;
  }
  .card-bot {
    display: flex;
    flex-flow: row wrap;
    .bot-ti {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 16px;
    }
  }

  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .ri-btns {
        background: #c9ccda;
        color: #ffffff;
      }
    }
  }
}
.procedure-detail-header {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456b;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img {
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}
</style>
