var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "apps" } },
    [
      _c(
        "div",
        {
          ref: "myImg",
          class: !_vm.fullscreen
            ? "app-left-full"
            : _vm.showTip
            ? "app-left"
            : "app-width",
          attrs: { id: "containers-left" }
        },
        [
          _c(
            "header",
            { staticClass: "top-bar" },
            [
              _c(
                "el-form",
                {
                  staticClass: "gatter-form",
                  attrs: { inline: true, size: "small" }
                },
                [
                  _c("div", { staticClass: "schedule-title" }, [
                    _vm._v("排程到产线")
                  ]),
                  _c("div", { staticClass: "form-li" }, [
                    _c("div", { staticClass: "common-date" }, [
                      _c(
                        "div",
                        { staticClass: "today", on: { click: _vm.nowMonth } },
                        [_vm._v("今天")]
                      ),
                      _c("img", {
                        attrs: { src: require("@/assets/plans/left-arro.png") },
                        on: {
                          click: function($event) {
                            return _vm.addMonth(2)
                          }
                        }
                      }),
                      _c("div", { staticClass: "date-tit" }, [
                        _vm._v(_vm._s(_vm.fixTime(_vm.commonDate)))
                      ]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/plans/right-arro.png")
                        },
                        on: {
                          click: function($event) {
                            return _vm.addMonth(1)
                          }
                        }
                      })
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-ri" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "250px" },
                              attrs: { size: "small" },
                              on: { change: _vm.selectScale },
                              model: {
                                value: _vm.scale,
                                callback: function($$v) {
                                  _vm.scale = $$v
                                },
                                expression: "scale"
                              }
                            },
                            _vm._l(_vm.scaleList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { ref: "container", staticClass: "containers" },
            [
              _c("v-gantt-chart", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                attrs: {
                  "start-time": _vm.times[0],
                  "end-time": _vm.times[1],
                  "cell-width": _vm.cellWidth,
                  "cell-height": _vm.cellHeight,
                  "time-lines": _vm.timeLines,
                  "title-height": _vm.titleHeight,
                  scale: _vm.scale,
                  "title-width": _vm.titleWidth,
                  "show-current-time": "",
                  "hide-header": _vm.hideHeader,
                  "data-key": _vm.dataKey,
                  "array-keys": _vm.arrayKeys,
                  "scroll-to-postion": _vm.positionA,
                  datas: _vm.datasA,
                  "scroll-to-time": _vm.scrollToTime
                },
                on: { scrollLeft: _vm.scrollLeftA },
                scopedSlots: _vm._u([
                  {
                    key: "block",
                    fn: function(ref) {
                      var data = ref.data
                      var item = ref.item
                      var getPositonOffset = ref.getPositonOffset
                      var getWidthAbout2Times = ref.getWidthAbout2Times
                      return [
                        _c("Test", {
                          attrs: {
                            data: data,
                            "update-time-lines": _vm.updateTimeLines,
                            "cell-height": _vm.cellHeight,
                            "current-time": _vm.currentTime,
                            item: item,
                            "get-positon-offset": getPositonOffset,
                            "get-width-about2-times": getWidthAbout2Times
                          },
                          on: { modifys: _vm.modify }
                        })
                      ]
                    }
                  },
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "common-header" }, [
                          _c(
                            "div",
                            { staticClass: "header-le" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "le-img",
                                  style: {
                                    background: _vm.showBtn ? "" : "#F8F8F8"
                                  },
                                  on: { click: _vm.changeSpread }
                                },
                                [
                                  _vm.showBtn
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content: "仅展示所有任务的产线",
                                            placement: "top"
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "ri-img",
                                            attrs: {
                                              src: require("@/assets/plans/down-arro.png")
                                            }
                                          })
                                        ]
                                      )
                                    : _c("img", {
                                        staticClass: "ri-img",
                                        attrs: {
                                          src: require("@/assets/plans/up-arro.png"),
                                          alt: ""
                                        }
                                      })
                                ],
                                1
                              ),
                              _c(
                                "el-popover",
                                {
                                  ref: "popover",
                                  staticStyle: { padding: "12p 12px 0" },
                                  attrs: { width: 215, offset: 20 },
                                  on: {
                                    hide: function($event) {
                                      _vm.show = true
                                    }
                                  },
                                  model: {
                                    value: _vm.showPopover,
                                    callback: function($$v) {
                                      _vm.showPopover = $$v
                                    },
                                    expression: "showPopover"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "popover-content-top" },
                                    [
                                      _c(
                                        "el-button",
                                        { on: { click: _vm.cancel } },
                                        [_vm._v("取消")]
                                      ),
                                      _c(
                                        "el-button",
                                        { on: { click: _vm.saveColumns } },
                                        [_vm._v("确定")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "popover-content-center" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: { change: _vm.selectAllColumns },
                                          model: {
                                            value: _vm.allChecked,
                                            callback: function($$v) {
                                              _vm.allChecked = $$v
                                            },
                                            expression: "allChecked"
                                          }
                                        },
                                        [_vm._v("全选")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "invert",
                                          attrs: { type: "text" },
                                          on: { click: _vm.invertSelectColumns }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/table_filter_images/fanxuan@2x.png"),
                                              alt: "反选",
                                              width: "13px"
                                            }
                                          }),
                                          _c("span", [_vm._v("反选")])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "popover-content-footer",
                                      style: {
                                        maxHeight:
                                          _vm.popoverContentMaxHeight + "px"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          staticClass: "check-wrapper",
                                          model: {
                                            value: _vm.checkedList,
                                            callback: function($$v) {
                                              _vm.checkedList = $$v
                                            },
                                            expression: "checkedList"
                                          }
                                        },
                                        _vm._l(_vm.settingColumns, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: index,
                                              attrs: { label: item.id }
                                            },
                                            [_vm._v(_vm._s(item.parentArea))]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName: "v-popover:popover",
                                      arg: "popover"
                                    }
                                  ],
                                  ref: "columnsSettingBtn",
                                  staticStyle: { padding: "0" },
                                  attrs: { type: "text" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "showMore",
                                      style: {
                                        background: _vm.show ? "" : "#F8F8F8"
                                      },
                                      on: { click: _vm.changeMore }
                                    },
                                    [
                                      _c("span", [_vm._v("筛选产线")]),
                                      _vm.show
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-caret-bottom icons"
                                          })
                                        : _c("i", {
                                            staticClass: "el-icon-caret-top"
                                          })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "header-ri",
                              on: { click: _vm.toggleFullScreen }
                            },
                            [
                              _vm.fullscreen
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "放大",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "ri-img",
                                        attrs: {
                                          src: require("@/assets/narrow-amplification/amplification.png"),
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.fullscreen
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        content: "缩小",
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "ri-img",
                                        attrs: {
                                          src: require("@/assets/narrow-amplification/narrow.png")
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "left",
                    fn: function(ref) {
                      var data = ref.data
                      return [
                        _c("TestLeft", {
                          attrs: { data: data },
                          on: { selectLine: _vm.selectLine }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                { staticClass: "tip-img", on: { click: _vm.selectImg } },
                [
                  _vm.showTip
                    ? _c("img", {
                        attrs: { src: require("@/assets/plans/spread.png") }
                      })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/plans/retract.png"),
                          alt: ""
                        }
                      })
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "app-right" },
        [
          _vm.showTip
            ? _c("schedule-right", {
                ref: "schedules",
                attrs: { "area-id": _vm.areaId, "area-name": _vm.areaName },
                on: { modify: _vm.modify }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "修改计划", width: "80%" },
          on: { onOk: _vm.dialogSubmit },
          model: {
            value: _vm.dialogVisible,
            callback: function($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-form" },
            [
              _vm.dialogFormData.status === 0 ||
              (_vm.dialogFormData.status === 2 && _vm.isStatus)
                ? _c("div", { staticClass: "form-btn" }, [
                    _c(
                      "div",
                      {
                        staticClass: "li-btn",
                        on: {
                          click: function($event) {
                            return _vm.fixWorkOrder(_vm.dialogFormData)
                          }
                        }
                      },
                      [_vm._v("下发至工单")]
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "form-top" },
                [
                  _c("base-data", {
                    attrs: {
                      "detail-data": _vm.dialogFormData,
                      "is-show":
                        _vm.dialogFormData.status === 0 ||
                        (_vm.dialogFormData.status === 2 && _vm.isStatus)
                          ? false
                          : true
                    }
                  })
                ],
                1
              ),
              _c(
                "MFormBuilder",
                {
                  ref: "dialogFormBuild",
                  attrs: {
                    "form-data": _vm.dialogFormData,
                    "form-list":
                      _vm.dialogFormData.status === 0 ||
                      (_vm.dialogFormData.status === 2 && _vm.isStatus)
                        ? _vm.dialogFormList
                        : _vm.haveDialogFormList,
                    "custom-rules": _vm.rules,
                    disabled: _vm.formDisabled
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "process" }, slot: "process" },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "all-ipt",
                          model: {
                            value: _vm.params.processFlowName,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "processFlowName", $$v)
                            },
                            expression: "params.processFlowName"
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c("el-button", {
                                attrs: { icon: "el-icon-more" },
                                on: { click: _vm.selectProcessFlows }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c("SelectProcessFlow", {
        ref: "processFlow",
        attrs: {
          visible: _vm.processFlowVisible,
          "select-process-and-version-ids": _vm.processIdAndVersionId,
          "proce-type": _vm.proceType
        },
        on: {
          "update:visible": function($event) {
            _vm.processFlowVisible = $event
          },
          submit: _vm.selectFlow
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }