
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columnsAll = [
  {
    prop: 'priority',
    label: '计划优先级',
    hiddenSearch: true,
    sortable: false,
    slotName: 'priority',
    form: {
      index: 0,
      tagName: 'el-select',
      options: [],
      defaultValue: 1
    }
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    hiddenSearch: true,
    sortable: false,
    form: {
      index: 1,
      key: 'deliveryTime',
      tagName: 'el-date-picker',
      props: {
        align: 'center',
        type: 'date',
        valueFormat: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd'
      }
    }
  },
  {
    prop: 'processFlowName',
    label: '工艺',
    hiddenSearch: true,
    sortable: false,
    slotName: 'processFlowName',
    form: {
      index: 2,
      key: 'processFlowId',
      tagName: 'div',
      customSlots: ['process']
    }
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false,
    form: {
      index: 3,
      key: 'areaId',
      tagName: 'el-select'
    }
  },
  {
    prop: 'planStartTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false,
    form: {
      index: 4,
      key: 'planStartTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'planEndTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false,
    form: {
      index: 5,
      key: 'planEndTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    form: { index: 8, tagName: 'el-input', required: false, props: { type: 'textarea' }}
  }
]
// 已下发
export const HavecolumnsAll = [
  {
    prop: 'planStartTime',
    label: '计划开始时间',
    hiddenSearch: true,
    sortable: false,
    form: {
      index: 4,
      key: 'planStartTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  },
  {
    prop: 'planEndTime',
    label: '计划结束时间',
    hiddenSearch: true,
    sortable: false,
    form: {
      index: 5,
      key: 'planEndTime',
      tagName: 'el-date-picker',
      required: false,
      props: {
        align: 'center',
        type: 'datetime',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm'
      }
    }
  }
]

