var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "materials-class" },
    [
      _c("div", { staticClass: "top" }, [
        _c(
          "div",
          {
            staticStyle: {
              "font-weight": "600",
              "font-size": "14px",
              color: "#393D60"
            }
          },
          [_vm._v("项目列表")]
        ),
        _c(
          "div",
          {
            staticClass: "add-materials-class",
            on: {
              click: function($event) {
                return _vm.add()
              }
            }
          },
          [_c("i", { staticClass: "el-icon-circle-plus" })]
        )
      ]),
      _c(
        "div",
        { staticClass: "tree" },
        [
          _c(
            "div",
            { staticClass: "input-wrapper", staticStyle: { display: "block" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    width: "248px",
                    "margin-bottom": "5px"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", placeholder: "请选择状态" },
                      on: {
                        change: function($event) {
                          return _vm.search()
                        }
                      },
                      model: {
                        value: _vm.statusInfo,
                        callback: function($$v) {
                          _vm.statusInfo = $$v
                        },
                        expression: "statusInfo"
                      }
                    },
                    [
                      _c("el-option", {
                        key: 0,
                        attrs: { label: "待开始", value: 0 }
                      }),
                      _c("el-option", {
                        key: 2,
                        attrs: { label: "进行中", value: 2 }
                      }),
                      _c("el-option", {
                        key: 4,
                        attrs: { label: "已结案", value: 4 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    width: "248px",
                    "margin-bottom": "5px"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入名称或编号", clearable: "" },
                    on: { change: _vm.inputChange },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search()
                      }
                    },
                    model: {
                      value: _vm.searchVal,
                      callback: function($$v) {
                        _vm.searchVal = $$v
                      },
                      expression: "searchVal"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { background: "#607fff" },
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [_vm._v(" 搜索 ")]
                  )
                ],
                1
              )
            ]
          ),
          _c("Tree", {
            ref: "zTrees",
            attrs: { view: _vm.view, nodes: _vm.nodes },
            on: { command: _vm.command, nodeClick: _vm.nodeClick }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.currentType },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }