<template>
  <div class="procedure-detail-wrapper">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.push({ name: 'workList' })">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>工单详情</span>
      </div>
      <div class="right">
        <el-button size="small" v-if="permission('GoRepair') && baseFormData.status === 1 && isProcess && baseFormData.isPartialOutbound === 0" type="primary" :loading="buttonLoadings" @click="startRepair">
          发起返修
        </el-button>
        <el-button v-if="permission('outSource') && baseFormData.status !== 2" size="small" type="primary" :loading="buttonLoading" @click="startOutSource">
          外协
        </el-button>
        <el-button size="small" v-if="permission('reductionRecord') && baseFormData.status === 1" type="primary" :loading="buttonLoading" @click="startReduction">
          减产
        </el-button>
        <el-button size="small" v-if="permission('divisionRecord') && baseFormData.status === 1" type="primary" :loading="buttonLoading" @click="startDivision">
          拆单
        </el-button>

        <el-button size="small" v-if="permission('PrintWorkOrder')" type="primary" :loading="buttonLoading" @click="printOrder">
          打印
        </el-button>
        <el-button size="small" v-if="permission('DelWorkOrder') && baseFormData.status === 0" type="primary" :loading="buttonLoading" @click="delTask">
          删除
        </el-button>
      </div>
    </div>
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <BaseData ref="BaseData" :detail-data="baseFormData" />
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">生产物料</div>
        <div class="card-bot">
          <MTable
            ref="mTable1"
            :show-page="false"
            :height="120"
            :columns="columnsBase"
            :data="materialsLists"
            :columns-setting="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="materialsPicture" slot-scope="{ row }">
              <el-image
                v-if="row.materialsPicture && row.materialsPicture !== '[]'"
                :src="row.materialsPicture"
                class="drawing-img"
                :preview-src-list="fixImg(row.materialsPicture)"
              />
            </div>
          </MTable>
        </div>
      </div>
      <el-tabs v-model="activeName" class="detail-tabs" @tab-click="handleClick" type="border-card">
        <el-tab-pane label="工艺路线" name="flows">
          <div style="background: #fff;padding: 15px;">
            <single-process-flow
              ref="processFlow"
              :is-partial-outbound="baseFormData.isPartialOutbound"
              :current-procedure-names="baseFormData.currentProcedureName"
              :read-only="true"
              :all-work-order-task-list="baseFormData.allWorkOrderTaskList ? baseFormData.allWorkOrderTaskList : []"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="工艺文件" name="files">
          <MTable
            ref="mTable2"
            :show-page="false"
            :height="150"
            :columns="fileAndDrawings"
            :data="fileList"
            :columns-setting="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="remark" slot-scope="{ row }">
              <el-tooltip :content="row.remark" placement="top">
                <div>{{ row.remark }}</div>
              </el-tooltip>
            </div>
            <div slot="fileName" slot-scope="{ row }">
              <a
                :href="row.url"
                :download="row.name"
                target="_blank"
                style="color: #607FFF"
              >{{ row.name }}</a>
              <img
                src="@/assets/information/procedure/附件@2x.png"
                style="width: 11px;margin-left: 5px"
              >
            </div>
          </MTable>
          <!-- <div class="b20 flex-sbc">
            <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">图纸</span>
          </div>
          <br>
          <MTable ref="mTable2" :show-page="false" :height="150" :columns="fileAndDrawings" :data="drawingsList" :columns-setting="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a :href="row.url" :download="row.name" target="_blank" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
          </MTable>
          <div class="bt20 flex-sbc">
            <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">其他附件</span>
          </div>
          <br>
          <MTable ref="mTable3" :show-page="false" :height="150" :data="filesList" :columns="fileAndDrawings" :columns-setting="false">
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a :href="row.url" :download="row.name" target="_blank" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
          </MTable> -->
        </el-tab-pane>
        <el-tab-pane v-if="permission('firstList')" label="批次信息" name="first">
          <FunctionHeader :tags="functionHeader.tags" :hidden-search="true" style="padding-top:0">
            <div slot="all" style="margin-left:20px;">
              <el-dropdown
                v-if="permission(activeName + 'BatchOperate')"
                size="small"
                split-button
                @command="(command) => { allMore(command) }"
              >
                <span class="el-dropdown-link">
                  批量操作
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="batchPrint">打印</el-dropdown-item>
                  <el-dropdown-item command="allDel">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </FunctionHeader>
          <MTable
            ref="mTable4"
            :height="300"
            :data="batchNumberList"
            :columns="batchNumbers"
            :show-page="false"
            :columns-setting="false"
            @selection-change="selectionChange"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="action" slot-scope="{ row }" class="all-btns">
              <el-button
                v-if="permission(activeName + 'PrintCode')"
                type="text"
                @click="printCode(row)"
              >打印批次码</el-button>
              <el-divider v-if="permission(activeName + 'PrintCode')" direction="vertical" />
              <template v-if="permission(activeName + 'Del')">
                <DelPopover
                  v-if="row.isUse !== 1"
                  class="li-btn"
                  @onOk="(callback) => del(row, callback)"
                />
                <el-button v-else type="text" disabled>删除</el-button>
              </template>
            </div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane v-if="permission('plansModifyMaterial')" label="计划用料" name="plans">
          <PlansMatertials
            v-if="permission('plansModifyMaterial')"
            :base-form-data="baseFormData"
            :active-name="activeName"
          />
        </el-tab-pane>
        <el-tab-pane v-if="permission('secondList')" label="投产记录" name="second">
          <FunctionHeader
            export-name="exportReceiveMaterials"
            :hidden-search="true"
            style="padding-top:0"
            :export-params="searchData"
          />
          <MTable
            ref="mTable5"
            :data="receiveMaterialsList"
            :height="300"
            :columns="receiveMaterials"
            :columns-setting="false"
            :show-page="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="name" slot-scope="{ row }">{{ row.materials.name }}</div>
            <div slot="code" slot-scope="{ row }">{{ row.materials.code }}</div>
            <div slot="warehouseType" slot-scope="{ row }">
              {{ row.warehouseType === 1 ? row.sitWarehouseName : row.warehouseName + '-' + row.warehouseLocationName }}
            </div>
            <div slot="specifications" slot-scope="{ row}">{{ row.materials.specifications }}</div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane v-if="permission('thirdList')" label="报工记录" name="third">
          <FunctionHeader
            export-name="exportReportWork"
            :hidden-search="true"
            style="padding-top:0"
            :export-params="searchData"
          />
          <MTable
            ref="reportTable"
            :set-data-method="getTaskList"
            :height="300"
            :columns="reportWork"
            :columns-setting="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="departureTime" slot-scope="{ row }">
              <span>{{ row.departureTime }}</span>
              <el-button
                v-if="baseFormData.isPartialOutbound === 0 && row.cancelCheckOutFlag === 1"
                type="text"
                class="mar-right"
                style="width: 40px;"
                @click="cancelDeparture(row)"
              >撤回</el-button>
            </div>
            <div slot="arrivalTime" slot-scope="{ row }">
              <span>{{ row.arrivalTime }}</span>
              <el-button
                v-if="baseFormData.isPartialOutbound === 0 && row.cancelCheckInFlag === 1"
                type="text"
                class="mar-right"
                style="width: 40px;"
                @click="cancelArrival(row)"
              >撤回</el-button>
            </div>
            <div
              slot="procedureType"
              slot-scope="{ row }"
              style="display:flex;justify-content: flex-start;align-items:center"
            >
              <span>{{ procedureType[row.procedureType] ? procedureType[row.procedureType].name : '' }}</span>
              <img
                v-if="row.procedureType === 0 && row.type === 1"
                src="@/assets/plans/wai.png"
                style="width: 40px;height:22px;margin-left: 10px;"
              >
            </div>

            <div v-if="row.procedureType === 0" slot="action" slot-scope="{ row }">
              <el-button
                v-if="baseFormData.isPartialOutbound === 1"
                type="text"
                @click="scanArrivalRecord(row)"
              >进出站记录</el-button>
              <el-divider v-if="baseFormData.isPartialOutbound === 1" direction="vertical" />
              <el-button
                v-if="permission(activeName + 'SubmitRecord')"
                type="text"
                @click="scanWorkRecord(row)"
              >报工记录</el-button>
              <el-divider v-if="permission(activeName + 'SubmitRecord')" direction="vertical" />
              <el-button
                v-if="permission(activeName + 'ScrapHistory')"
                type="text"
                @click="scanWasteRecord(row)"
              >报废历史</el-button>
            </div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane label="工步记录" name="step">
          <MTable
            ref="stepTable"
            :data="workStepList"
            :height="300"
            :columns="workStepColumns"
            :columns-setting="false"
            :show-page="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="name" slot-scope="{ row }" class="step-item">
              <el-tooltip effect="dark" :content="row.name" placement="top">
                <div style="overflow: hidden;text-overflow: ellipsis;">
                  {{ row.name }}</div>
              </el-tooltip>
            </div>
            <div slot="stepName" slot-scope="{ row }">
              <div
                v-for="(item,idx) in row.procedureSteps"
                :key="item.id"
                class="step-item"
                :style="{height:getHeight(item.params),borderBottom:idx===row.procedureSteps.length-1?'none':'1px solid #ebeef5'}"
              >
              <img src="" alt="" srcset="">
                <el-tooltip effect="dark" :content="item.name" placement="top">
                  <div style="overflow: hidden;text-overflow: ellipsis;">
                    {{ item.name }}</div>
                </el-tooltip>
              </div>
            </div>
            <div slot="description" slot-scope="{ row }">
              <div
                v-for="(item,idx) in row.procedureSteps"
                :key="item.id"
                class="step-item"
                :style="{height:getHeight(item.params),borderBottom:idx===row.procedureSteps.length-1?'none':'1px solid #ebeef5'}"
              >
                <el-tooltip effect="dark" :content="item.description" placement="top">
                  <div style="overflow: hidden;text-overflow: ellipsis;">{{ item.description||'-' }}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div slot="remark" slot-scope="{ row }">
              <div
                v-for="(item,idx) in row.procedureSteps"
                :key="item.id"
                class="step-item"
                :style="{height:getHeight(item.params),borderBottom:idx===row.procedureSteps.length-1?'none':'1px solid #ebeef5'}"
              >
                <el-tooltip effect="dark" :content="item.remark" placement="top">
                  <div style="overflow: hidden;text-overflow: ellipsis;">{{ item.remark||'-' }}</div>
                </el-tooltip>
              </div>
            </div>
            <div slot="params" slot-scope="{ row }">
              <div v-for="(item,idx) in row.procedureSteps" :key="item.id">
                <div
                  v-for="(i,idx2) in item.params"
                  :key="i.id"
                  class="step-item"
                  :style="{height:'35px',borderBottom:idx===row.procedureSteps.length-1&&idx2===item.params.length-1?'none':'1px solid #ebeef5'}"
                >
                  <el-tooltip effect="dark" :content="i.name" placement="top">
                    <div style="overflow: hidden;text-overflow: ellipsis;">
                      {{ i.name }}</div>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div slot="paramsRecord" slot-scope="{ row }">
              <div v-for="(item,idx) in row.procedureSteps" :key="item.id">
                <div
                  v-for="(i,idx2) in item.params"
                  :key="i.id"
                  class="step-item"
                  :style="{height:'35px',borderBottom:idx===row.procedureSteps.length-1&&idx2===item.params.length-1?'none':'1px solid #ebeef5'}"
                >
                  <el-tooltip effect="dark" :content="i.value" placement="top">
                    <div v-if="i.type===5||i.type===7" slot="content">
                      <el-image
                        v-for="url in getImgs(i.value)"
                        :key="url"
                        style="width: 100px; height: 100px"
                        :src="url"
                        :preview-src-list="getImgs(i.value)"
                      />
                    </div>
                    <div style="overflow: hidden;text-overflow: ellipsis;text-wrap: nowrap;">
                      {{ i.value||'-' }}</div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane v-if="permission('fourList')" label="返修记录" name="four">
          <FunctionHeader
            export-name="exportRepairRecord"
            :hiddenSearch="true"
            style="padding-top:0"
            :export-params="searchData"
          />
          <MTable
            ref="repairTable"
            :data="allLists"
            :height="300"
            :columns="repairRecord"
            :columns-setting="false"
            :show-page="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="status"  slot-scope="{ row }">
              <div>{{row.status === 0 ? '待处理' : row.status === 1 ? '部分处理' : '已处理'}}</div>
            </div>
            <div slot="batchNumber" slot-scope="{ row }">
              <div v-if="row.repairType === '报工返修'">
                {{row.repairBatchNumber}}
              </div>
              <div v-else>
                {{row.batchNumber}}
              </div>
            </div>

            <div slot="planList" slot-scope="{ row }">
              <div v-if="row.repairType === '报工返修'">
                {{row.repairPlanNumber}}
              </div>
              <div v-else>
                {{fixRepair(row.planList)}}
              </div>
            </div>
            <div slot="sourceWorkOrderNumber" slot-scope="{ row }">
              <div v-if="row.repairType === '报工返修'">
                <NewPageOpen
                  :path="{ name: 'workDetail', query: {id: row.repairWorkOrderId}}"
                  :text="row.repairWorkOrderNumber"
                />
              </div>
              <div v-else>
                <div v-for="item in row.planList && row.planList" :key="item.id">
                  <NewPageOpen
                    v-if="item.produceWorkOrder"
                    :path="{ name: 'workDetail', query: {id: item.produceWorkOrder.id}}"
                    :text="item.produceWorkOrder.produceWorkOrderNumber"
                  />
                </div>
              </div>
            </div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane v-if="permission('fiveList')" label="质检记录" name="five">
          <FunctionHeader :hidden-search="true" style="padding-top:0" />
          <MTable
            ref="qualityTable"
            :data="getQualityList"
            :height="300"
            :columns="inspectionOrder"
            :columns-setting="false"
            :show-page="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="number" slot-scope="{ row }">
              <a class="li-btn" @click="viewQuality(row)">{{ row.number }}</a>
              <!-- <NewPageOpen :path="{ name: 'qualityInspectionOrderDetail', query: {id: row.id, isView: 'view'}}" :text="row.number" /> -->
            </div>
            <div slot="inspectionType" slot-scope="{ row }">
              {{ row.inspectionType === 0 ? '首检' : '过程检' }}</div>
            <div slot="status" slot-scope="{ row }">
              <div
                class="faultStatus"
                :style="{ background: qualityStatus[row.status].background, color: qualityStatus[row.status].color }"
              >
                {{ qualityStatus[row.status].name }}</div>
            </div>
            <div slot="passRate" slot-scope="{ row }">
              <span v-if="row.inspectionType === 1">{{ row.passRate }}%</span>
              <span v-if="row.inspectionType === 0">{{row.result === 1 ? 100 : 0 }}%</span>
            </div>
            <div slot="allCount" slot-scope="{ row }">
              <span v-if="row.inspectionType === 0">{{row.result === 1 ? 1 : 0}}</span>
              <span v-if="row.inspectionType === 1">{{row.okCount}}/{{row.ngCount}}</span>
            </div>
            <div slot="reports" slot-scope="{ row }">
              <a class="li-btn" @click="views(row)">查看</a>
            </div>
            <div slot="action" slot-scope="{ row }">
              <a class="li-btn" @click="printQuality(row)">打印检验报告</a>
            </div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane v-if="permission('sixList')" label="减产记录" name="six">
          <FunctionHeader :hidden-search="true" style="padding-top:0" />
          <MTable
            ref="reductionTable"
            :data="reductionRecordList"
            :height="300"
            :columns="reductionRecord"
            :columns-setting="false"
            :show-page="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          </MTable>
        </el-tab-pane>
        <el-tab-pane v-if="permission('sevenList')" label="拆单记录" name="seven">
          <FunctionHeader :hidden-search="true" style="padding-top:0" />
          <MTable
            ref="divisionTable"
            :data="divisionRecordList"
            :height="300"
            :columns="divisionRecord"
            :columns-setting="false"
            :show-page="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="newProduceWorkOrderNumber" slot-scope="{ row }">
              <NewPageOpen
                :path="{ name: 'workDetail', query: {id: row.newProduceWorkOrderId}}"
                :text="row.newProduceWorkOrderNumber"
              />
            </div>
            <div slot="action" slot-scope="{ row }">
              <a v-if="+row.status === 1" class="li-btn color-btns">创建计划</a>
              <a v-else class="li-btn" @click="getRepairs(row)">创建计划</a>
            </div>
          </MTable>
        </el-tab-pane>
      </el-tabs>
      <div style="height: 50px;"/>
    </div>
    <!-- 外协 -->
    <MDialog v-model="outSourceVisible" title="外协申请" @onOk="saveOutSource">
      <MFormBuilder
        ref="dialogFormBuilds"
        :form-data="dialogFormDatas"
        :form-list="dialogFormList"
        :custom-rules="rules"
      />
    </MDialog>
    <!-- 返修 -->
    <repair-model
      :visible.sync="repairVisible"
      :repair-list="repairList"
      :produce-work-order-id="parseInt(params.id)"
      :old-process-flow-id="baseFormData.processFlowId"
      :base-form-data="baseFormData"
      @loadWord="loadWords"
    />
    <!-- 减产 -->
    <reduction-model
      :visible.sync="reductionVisible"
      :task-list="taskList"
      :base-form-data="baseFormData"
      @loadWord="loadWord"
    />
    <!-- 拆单 -->
    <division-model
      :visible.sync="divisionVisible"
      :task-list="taskList"
      :base-form-data="baseFormData"
      @loadWord="loadWords"
    />
    <!-- 拆单计划 -->
    <division-repair-model
      :visible.sync="repairVisibles"
      :repair-record-id="repairRecordId"
      :old-process-flow-id="baseFormData.processFlowId"
      :produce-work-order-id="baseFormData.id"
      :base-form-data="infos"
      @loadWord="loadWords"
    />
    <!-- 工位数量查看 -->
    <TableDialog
      title="查看工位数量"
      :show.sync="stationVisible"
      :columns="stationColums"
      :table-data="stationList"
    />
    <!-- 添加批次码 -->
    <batch-number
      :add-visible.sync="addVisible"
      :base-form-data="baseFormData"
      @sumbitBatch="sumbitBatch"
    />
    <TableDialog
      title="查看进出站记录"
      :show.sync="arrivalVisible"
      :columns="arrivalColumns"
      :table-data="allArrOutList"
      @cancelArrivals="cancelCheckInPartial"
    />
    <TableDialog
      title="查看报工记录"
      :show.sync="workVisible"
      :columns="workColumns"
      :table-data="workList"
      @scanDetail="scanDetail"
      @BaoDetail="BaoDetail"
    />
    <TableDialog
      title="查看报废历史"
      :show.sync="wasteVisible"
      :columns="wasteColumns"
      :table-data="wasteList"
    />
    <MDialog :value="show" title="查看上传工序参数" :show-footer="false" @close="show = false">
      <div v-for="row in groupList" :key="row.name" class="params-card">
        <div class="header flex-sbc">
          <div>参数组标题：{{ row.name }}</div>
        </div>
        <div class="body">
          <div v-for="item in row.fromData" :key="item.id">
            <span
              v-if="item.type !== 4"
              class="bot-ti"
            >{{ item.key }}：{{ fixData(item.value) }}</span>
            <span v-else class="bot-ti">{{ item.key }}：
              <a
                v-for="items in fixArr(item.value)"
                :key="items"
                :href="items"
                target="_blank"
                class="imgs"
              >
                <img :src="items">
              </a>
            </span>
          </div>
        </div>
      </div>
    </MDialog>
    <!-- 查看批次码 -->
    <view-result ref="viewResult" :visible.sync="visibles" />
    <!-- 打印质检报告-->
    <PrintResult ref="printQualitys" />
    <!-- 打印工单 -->
    <PrintOrder ref="printCodes" :common-ids="commonIds" />
    <!-- 打印批次码：瑞鼎定制 -->
    <PrintBatchNumber v-if="memberCode === 'reagold'" ref="printCode" :common-id="commonId" />
    <!-- 打印批次码：奇奇定制 -->
    <PrintCodes v-else-if="memberCode === 'kiki'" ref="printCode" :common-id="commonId" />
    <!-- <PrintBatchNumberKiKi v-else-if="memberCode === 'kiki'" ref="printCode" :commonId="commonId"/> -->
    <!-- 打印批次码：北变纵剪-->
    <PrintCodeBbelc
      v-else-if="(memberCode === 'bbelc' || memberCode === 'bbwz') && this.baseFormData.processFlowCode === 'TXGY008'"
      ref="printCode"
      :common-id="commonId"
    />
    <!-- 打印批次码：北变成品二维码-->
    <PrintPackage
      v-else-if="(memberCode === 'bbelc' || memberCode === 'bbwz') && this.baseFormData.processFlowCode === 'ZZGY001'"
      ref="printCode"
      :common-id="commonId"
    />
    <!-- 打印批次码：产品-->
    <PrintCode v-else ref="printCode" :commonId="commonId"/>

  </div>
</template>

<script>
import { qualityStatus, procedureType } from '@/config/options.config'
import { arrivalColumns, divisionRecord, reductionRecord, inspectionOrder, stationColums, columnsBase, fileAndDrawings, operatorTask, batchNumbers, receiveMaterials, reportWork, workColumns, wasteColumns, repairRecord, workStepColumns } from './columns'
import { dialogFormList } from './form-list'
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index'
import BaseData from './components/base-data'
import BatchNumber from './components/batch-number'
import api from '@/api/production/work-order'
import apis from '@/api/production/operation-task'
import oApis from '@/api/production/outSource'
import planApi from '@/api/production/plans'
import reportApi from '@/api/production/reportRecords'
import repairApi from '@/api/production/repair-record'
import inRepairApi from '@/api/quality/repair-record'
import qualityApi from '@/api/quality/inspection-orders'
import exportRecord from '@/api/exportAndImport/export'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import PrintCode from '@/components/PrintCode/printCode'
import PrintPackage from '@/components/PrintCode/printPackage'
import TableDialog from '@/components/TableDialog/TableDialog'
import PrintOrder from '@/components/PrintCode/printWorkOrder'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow' // 并联显示详情
import singleProcessFlow from '@/components/singleProcessFlow/singleProcessFlow'
import processApi from '@/api/information/production/process'
import PrintBatchNumber from '@/project-custom/reagold/print-code/printBatchNumber'
import RepairModel from './components/repair-model/repair-model'
import orderApi from '@/api/production/orders'
import PrintBatchNumberKiKi from '@/project-custom/kiki/print-code/printBatchNumber'
import PrintCodeBbelc from '@/project-custom/bbelc/print-code/printBatchNumber'
import ViewResult from '@/views/quality/quality-inspection-order/components/viewResult'
import PrintResult from '@/project-custom/reagold/print-code/printResult'
import ReductionModel from './components/reduction-model/reduction-model'
import DivisionModel from './components/division-model/division-model'
import divisionRepairModel from './components/division-repair-model/division-repair-model'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import PrintCodes from '@/project-custom/kiki/print-codes/printCodes'
import PlansMatertials from './plans-materials/index'
import DownloadDocx from './components/download-docx/download-docx'
export default {
  name: 'WorkDetail',
  components: { PlansMatertials, singleProcessFlow, PrintCodes, NewPageOpen, PrintPackage, divisionRepairModel, ReductionModel, DivisionModel, PrintResult, ViewResult, PrintCodeBbelc, PrintBatchNumberKiKi, RepairModel, PrintBatchNumber, ProcessFlow, BaseData, FunctionHeader, DelPopover, BatchNumber, PrintCode, TableDialog, PrintOrder,DownloadDocx },
  data() {
    return {
      workStepColumns,
      workStepList: [],
      fileList: [],
      taskList: [],
      arrivalColumns,
      outBandVisible: false,
      arrivalVisible: false,
      buttonLoadings: false,
      getQualityList: [],
      reductionRecordList: [],
      divisionRecordList: [],
      dialogFormDatas: {},
      outSourceVisible: false,
      repairList: [],
      isProcess: false,
      procedureType,
      infos: {},
      repairRecordId: undefined,
      divisionRecord,
      reductionRecord,
      qualityStatus,
      repairVisibles: false,
      reductionVisible: false,
      divisionVisible: false,
      visibles: false,
      inspectionOrder,
      cancelShow: false,
      stationColums,
      activeName: 'flows',
      stationVisible: false,
      stationList: [],
      taskData: [],
      workList: [],
      wasteList: [],
      params: {
        id: undefined
      },
      addVisible: false,
      workColumns,
      wasteColumns,
      columnsBase,
      fileAndDrawings,
      operatorTask,
      dialogFormList,
      batchNumbers,
      receiveMaterials,
      reportWork,
      repairRecord,
      baseFormData: {},
      functionHeader: {
        tags: [
          {
            hidden: !this.permission('firstAddBatchNumber'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '添加批次',
            on: {
              click: this.addBatchNumber
            }
          }
        ]
      },
      materialsLists: [],
      filesList: [],
      drawingsList: [],
      batchNumberList: [],
      buttonLoading: false,
      formOptions: {
        stationOptions: []
      },
      visible: false,
      formData: {},
      printCodes: [],
      workVisible: false,
      wasteVisible: false,
      show: false,
      groupList: [],
      processFlowVersion: {},
      searchData: {},
      currentWorkDetail: {},
      repairVisible: false,
      commonId: 'print',
      reportData: [],
      commonIds: 'print',
      rules: {
        processFlowId: [
          { required: true, message: '请选择工序', trigger: 'change' }
        ]
      },
      repairRecordList: [],
      inspectionRepairRecordList: [],
      allLists: [],
      receiveMaterialsList: [],
      arrivalList: [],
      allArrOutList: [],
      outBandList: []
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  async mounted() {
    this.params = this.$route.query
    this.activeName = 'flows'
    this.searchData = {
      searchVal: Encrypt(JSON.stringify([{
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      }])
      )
    }
    await this.getWorkDetailById()
    await this.$refs.reportTable.setTableData(true)
  },
  methods: {
    getImgs(val) {
      return val?.split(',') || []
    },
    getHeight(params) {
      return params?.length ? params.length * 35 + 'px' : 35 + 'px'
    },
    // 工序列表
    async getTaskLists() {
      const res = await reportApi.getReduceAndDivisionList({ workOrderId: this.baseFormData.id })
      if (res) {
        this.taskList = res
      }
    },
    viewQuality(row) {
      this.$router.push({ name: 'qualityInspectionOrderDetail', query: { id: row.id, isView: 'view' }})
    },
    async handleClick(tab, event) {
      if (this.activeName === 'second') {
        this.getReceiveMaterialsList()
      }
      if (this.activeName === 'four') {
        await this.getRepairRecordList()
        await this.getInspectionRepairRecordList()
        this.allLists = this.fixAll()
      }
      if (this.activeName === 'five') {
        this.getQualityLists()
      }
      if (this.activeName === 'six') {
        this.getProduceReductionRecordList()
      }
      if (this.activeName === 'seven') {
        this.getProduceDivisionRecordList()
      }
      if (this.activeName === 'step') {
        this.getStepRecord()
      }
      if (this.activeName === 'files') {
        this.getFileList()
      }
    },
    fixImg(img) {
      if (img) {
        return [img]
      }
    },
    async startOutSource() {
      await this.getProduce()
      if (this.dialogFormList[0].children[0].options.length === 0) {
        this.$message.error('该工单没有自制工序或未进站的工序')
        return false
      }
      this.outSourceVisible = true
    },
    // 工序
    async getProduce() {
      const newData = [
        {
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        },
        {
          id: getUUid(),
          fieldName: 'produceWorkOrderId',
          fieldType: 'number',
          fieldValue: this.params.id,
          operator: 'eq'
        }
      ]
      const res = await apis.getOrderTaskpageno({
        statuses: 0,
        searchVal: Encrypt(JSON.stringify([...newData]))
      })
      if (res) {
        this.dialogFormList[0].children[0].options = res.length > 0
          ? res.map(item => {
            return {
              id: item.id,
              name: item.procedureName
            }
          }) : []
      }
    },
    // 外协申请
    async saveOutSource(callback) {
      callback()
      const { form } = this.$refs.dialogFormBuilds
      if (form.taskIds === '' || (form.taskIds && form.taskIds.length === 0)) {
        this.$message.error('请选择工序')
        return false
      }
      this.$refs.dialogFormBuilds.formRefs().validate(async(valid) => {
        if (valid) {
          const id = encodeURIComponent(form.taskIds.join('^'))
          const remark = form.remark ? form.remark : ''
          const res = await oApis.saveOutsourcingRequest(id, remark)
          if (res) {
            this.$message.success('外协申请成功')
            this.outSourceVisible = false
          }
        }
      })
    },
    // 打印检验报告
    async printQuality(row) {
      const res = await qualityApi.getInspectionOrderDetailById(row.id)
      if (res) {
        this.$refs.printQualitys.printResult(res)
      }
    },
    // 查看报告
    async views(row) {
      const res = await qualityApi.getInspectionOrderDetailById(row.id)
      if (res) {
        this.$refs.viewResult.view(res)
        this.visibles = true
      }
    },
    async cancelCheckInPartial(val) {
      if (val.data.type === '进站') {
        const res = await apis.cancelCheckInPartial({
          produceWorkOrderTaskId: val.data.produceWorkOrderTaskId,
          batchNumber: val.data.batchNumber,
          isFirstProcedure: val.data.isFirstProcedure,
          nextTaskArrivalTime: val.data.nextTaskArrivalTime,
          alreadyDepartureQuantity: val.data.alreadyDepartureQuantity,
          isArrivalKey: val.data.isArrivalKey
        })
        if (res) {
          this.allArrOutList.splice(val.idx, 1)
          this.$refs.reportTable.setTableData()
        }
        return false
      }
      this.cancelCheckOutPartial(val)
    },
    async cancelCheckOutPartial(val) {
      const res = await apis.cancelCheckOutPartial({
        produceWorkOrderTaskId: val.data.produceWorkOrderTaskId,
        batchNumber: val.data.batchNumber,
        isLastProcedure: val.data.isLastProcedure,
        inspectionSheetId: val.data.inspectionSheetId,
        outboundTime: val.data.outboundTime,
        alreadyDepartureQuantity: val.data.alreadyDepartureQuantity,
        isOutBoundKey: val.data.isOutBoundKey
      })
      if (res) {
        this.allArrOutList.splice(val.idx, 1)
        this.$refs.reportTable.setTableData()
      }
    },
    // 分批次出入站记录
    async scanArrivalRecord(row) {
      this.arrivalList = []
      this.outBandList = []
      this.allArrOutList = []
      await this.arriveLists(row)
      await this.outBandLists(row)
      const list = this.arrivalList.length > 0 ? this.arrivalList.map(item => {
        return {
          ...item,
          type: '进站'
        }
      }) : []
      const lists = this.outBandList.length > 0 ? this.outBandList.map(item => {
        return {
          ...item,
          type: '出站'
        }
      }) : []
      this.allArrOutList = [...list, ...lists]
      this.arrivalVisible = true
    },
    async arriveLists(val) {
      const lastId = this.reportData.findIndex(item => item.id === val.id)
      const res = await reportApi.getArriveList({
        sign: val.procedureIndex === 1 ? 1 : 0,
        taskId: val.procedureIndex === 1 ? val.id : this.reportData[lastId + 1].id
      })
      if (res) {
        this.arrivalList = res || []
      }
    },
    async outBandLists(data) {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderTaskId',
        fieldType: 'number',
        fieldValue: data.id,
        operator: 'eq'
      })
      const res = await reportApi.getOutboundList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.outBandList = res || []
      }
    },
    // 进站撤回
    async cancelArrival(val) {
      this.$confirm('撤回将清除投产列表和工序参数填写等信息，确认撤回吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.cancelArrivals({ workOrderTaskId: val.id })
        if (res) {
          this.$refs.reportTable.setTableData()
        }
      })
    },
    // 出站撤回
    async cancelDeparture(val) {
      this.$confirm('确认撤回出站吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.cancelDepartures({ workOrderTaskId: val.id })
        if (res) {
          this.$refs.reportTable.setTableData()
        }
      })
    },
    // 报工撤回
    async BaoDetail(val) {
      this.$confirm('撤回报工将回退投产物料库存和工序参数填写等信息，确认撤回吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.cancelReportRecord({ id: val.data.id })
        if (res) {
          this.workList.splice(val.idx, 1)
          this.$refs.reportTable.setTableData()
        }
      })
    },
    loadWord() {
      this.getProduceReductionRecordList()
      // this.$refs.reductionTable.setTableData(true)
    },
    loadWords() {
      this.getProduceDivisionRecordList()
      // this.$refs.divisionTable.setTableData(true)
    },
    goPdf() {
      this.$router.push({ name: 'materialsSetting' })
    },
    fixArr(val) {
      if (val) {
        return val.split('^')
      }
    },
    fixData(val) {
      if (val) {
        return val.split('^').join('；')
      }
    },
    fixRepair(val) {
      if (val) return val.map(item => item.producePlanNumber).join()
    },
    // 打印工单
    async printOrder() {
      const data = this._.cloneDeep(this.baseFormData)
      data.priority = this.priorityStatus[data.priority].name
      data.deliveryTime = data.deliveryTime.substring(0, 10)
      data.taskData = this.taskData
      this.commonIds = `${data.produceWorkOrderNumber}print`
      this.$refs.printCodes.print(data)
    },
    // 删除工单
    delTask() {
      const that = this
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.deletePlan({ id: this.params.id })
        if (res) {
          this.$router.push({ name: 'workList' })
        }
      })
    },
    // 北变-报工记录
    async scanWorkRecords(val, row) {
      const code = val === 2 ? row.join('^') : row
      const data = [{
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      }]
      data.push(
        {
          id: getUUid(),
          fieldName: 'batchNumber',
          fieldType: 'string',
          fieldValue: code,
          operator: 'in'
        }
      )
      const res = await api.getReportWork({ searchVal: Encrypt(JSON.stringify(data)) })
      if (res) {
        if (val === 2) {
          // 多条重复的报工记录
          const list = res.filter(item => item.batchNumber)
          console.log('批量=》', list)
          this.reportData = list
          // 没有报工记录需过滤
          const obj = row.map(item => {
            return {
              batchNumber: item
            }
          })
          const datas = [...list, ...obj]
          const hash = {}
          const arr = datas.reduce((item, next) => {
            // 根据 batchNumber 去重
            if (!hash[next.batchNumber]) {
              hash[next.batchNumber] = true
              item.push(next)
            }
            return item
          }, [])

          console.log('批量=》', arr)
          this.reportData = arr
        } else {
          this.reportData = res.length > 0 ? res[0].procedureDetails ? JSON.parse(res[0].procedureDetails) : [] : []
        }
      }
    },
    // 打印
    async printCode(row) {
      this.commonId = `${row.batchNumber}print`
      // 瑞鼎定制
      if (this.memberCode === 'reagold') {
        let res = { remark: '' }
        if (this.baseFormData.produceOrderId) {
          res = await orderApi.getOrderDetail(this.baseFormData.produceOrderId)
        }
        this.$refs.printCode.print({
          ...this.baseFormData,
          batchNumber: row.batchNumber,
          remark: res.remark
        })
        return false
      }
      // 奇奇定制
      if (this.memberCode === 'kiki') {
        this.$refs.printCode.print(this.baseFormData)
        return false
      }
      // 北变定制-纵剪
      if ((this.memberCode === 'bbelc' || this.memberCode === 'bbwz') && this.baseFormData.processFlowCode === 'TXGY008') {
        await this.scanWorkRecords(1, row.batchNumber)
        this.$refs.printCode.print(Object.assign({}, this.baseFormData, {
          reportDatas: this.reportData,
          batchNumber: row.batchNumber
        }))
        return false
      }
      // 北变定制-成品
      if ((this.memberCode === 'bbelc' || this.memberCode === 'bbwz') && this.baseFormData.processFlowCode === 'ZZGY001') {
        this.$refs.printCode.print(row.batchNumber)
        return false
      }
      // 产品
      const newData = Object.assign({}, row, {
        materialsCode: this.baseFormData.materialsCode,
        materialsName: this.baseFormData.materialsName,
        materialsSpecifications: this.baseFormData.materialsSpecifications
      })
      this.$refs.printCode.print(newData)
    },
    async batchPrint() {
      this.commonId = `${this.printCodes[0]}print`
      if (this.printCodes.length > 0) {
        // 瑞鼎定制
        if (this.memberCode === 'reagold') {
          let res = { remark: '' }
          if (this.baseFormData.produceOrderId) {
            res = await orderApi.getOrderDetail(this.baseFormData.produceOrderId)
          }
          const obj = this.printCodes.map(item => {
            return {
              ...this.baseFormData,
              batchNumber: item,
              remark: res.remark
            }
          })
          this.$refs.printCode.batchPrint(obj)
          return false
        }
        // 北变定制-纵剪
        if ((this.memberCode === 'bbelc' || this.memberCode === 'bbwz') && this.baseFormData.processFlowCode === 'TXGY008') {
          await this.scanWorkRecords(2, this.printCodes)
          const objs = this.reportData.map(item => {
            return {
              ...this.baseFormData,
              batchNumber: item.batchNumber,
              reportDatas: item.procedureDetails ? JSON.parse(item.procedureDetails) : []
            }
          })
          this.$refs.printCode.batchPrint(objs)
          return false
        }
        // 北变定制-成品
        if ((this.memberCode === 'bbelc' || this.memberCode === 'bbwz') && this.baseFormData.processFlowCode === 'ZZGY001') {
          this.$refs.printCode.batchPrint(this.printCodes)
          return false
        }
        // 产品
        const newData = this.printCodes.map(item => {
          return {
            batchNumber: item,
            materialsCode: this.baseFormData.materialsCode,
            materialsName: this.baseFormData.materialsName,
            materialsSpecifications: this.baseFormData.materialsSpecifications
          }
        })
        this.$refs.printCode.batchPrint(newData)
      } else {
        this.$message.info('请选择批次码')
      }
    },
    selectionChange(data) {
      this.printCodes = data.map(item => item.batchNumber)
    },
    allMore(command) {
      const functions = {
        batchPrint: () => { this.batchPrint() },
        allDel: () => { this.allDel() }
      }
      functions[command]()
    },
    // 批次码与工单绑定
    async sumbitBatch(data) {
      const newArr = data.map(item => {
        return {
          produceWorkOrderId: this.params.id,
          batchNumber: item.batchNumber
        }
      })
      const res = await api.addBatchNumber(newArr)
      if (res) {
        this.$message.success('添加成功')
        this.addVisible = false
        this.getWorkDetailById()
      }
    },
    // 添加批次码
    addBatchNumber() {
      this.addVisible = true
    },
    async getWorkDetailById() {
      this.materialsLists = []
      const res = await api.getPlanDetail(this.params.id)
      if (res) {
        this.baseFormData = res
        if (+res.status === 1) {
          this.isProcess = res.produceWorkOrderTaskList ? +res.produceWorkOrderTaskList[0].isNeedProcessInspect === 0 : false
        }

        const obj = {
          materialsName: res.materialsName,
          materialsCode: res.materialsCode,
          materialsSpecifications: res.materialsSpecifications,
          materialsUnit: res.materialsUnit,
          materialsPicture: res.materialsPicture,
          quantity: res.quantity,
          reportWorkQuantity: res.reportWorkQuantity
        }
        this.materialsLists.push(obj)
        this.drawingsList = res.materials.drawing ? JSON.parse(res.materials.drawing) : []
        this.filesList = res.materials.attachment ? JSON.parse(res.materials.attachment) : []
        this.batchNumberList = res.batchNumberList
        this.taskData = res.allWorkOrderTaskList ? res.allWorkOrderTaskList.sort((x, y) => {
              return x.procedureIndex - y.procedureIndex
            })
          : []
        // this.getProcessDetailById(res.processFlowId, res.processFlowVersionId)
      }
    },
    // 投产
    async getReceiveMaterialsList() {
      const search = [{
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      }]
      const res = await api.getReceiveMaterials({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.receiveMaterialsList = res
      }
    },
    // 质检
    async getQualityLists() {
      const searchVal = []
      searchVal.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      })
      const res = await qualityApi.getInspectionOrderList({
        searchVal: Encrypt(JSON.stringify([...searchVal]))
      })
      if (res) {
        this.getQualityList = res
      }
    },
    // 减产
    async getProduceReductionRecordList() {
      const search = [{
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      }]
      const res = await api.getProduceReductionRecordList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.reductionRecordList = res
      }
    },
    // 拆单
    async getProduceDivisionRecordList() {
      const searchVal = [{
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      }]
      const res = await api.getProduceDivisionRecordList({
        searchVal: Encrypt(JSON.stringify([...searchVal]))
      })
      if (res) {
        this.divisionRecordList = res
      }
    },
    // 报工
    async scanWorkRecord(row) {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderTaskId',
        fieldType: 'number',
        fieldValue: row.id,
        operator: 'eq'
      })
      const res = await api.getReportWork({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.workList = res
      }
      this.workVisible = true
    },
    // 工步记录
    async getStepRecord() {
      const res = await api.getStepList({
        orderId: this.params.id
      })
      if (res) {
        console.log('工步记录', res)
        this.workStepList = res.procedures || []
      }
    },
    // 工艺文件
    async getFileList() {
      const res = await api.getFileList({
        id: this.params.id
      })
      if (res) {
        console.log('工艺文件', res)
        this.fileList = res || []
      }
    },
    async scanWasteRecord(row) {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderTaskId',
        fieldType: 'number',
        fieldValue: row.id,
        operator: 'eq'
      })
      const res = await api.getReportWaste({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.wasteList = res
      }
      this.wasteVisible = true
    },
    // 删除批次
    del(row) {
      const arrs = []
      arrs.push(row.batchNumber)
      this.save(arrs)
    },
    // 批量删除
    allDel() {
      if (this.printCodes.length > 0) {
        this.save(this.printCodes)
      } else {
        this.$message.info('请选择批次码')
      }
    },
    async save(arr) {
      const obj = {
        produceWorkOrderId: this.params.id,
        batchNumberList: arr
      }
      const res = await api.deleteBatchNumber(obj)
      if (res) {
        this.$message.success('删除成功')
        this.getWorkDetailById()
      }
    },
    // 工序任务分页
    async getTaskList(condition, callback) {
      const { page, search, order } = condition
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      })
      const res = await apis.getOrderTaskpage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.reportData = res.records
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    scanDetail(row) {
      this.$nextTick(function() {
        this.groupList = row.procedureDetails ? JSON.parse(row.procedureDetails) : []
        this.show = true
      })
    },
    // 获取工艺详情
    // async getProcessDetailById(id, versionId) {
    //   const res = await processApi.getProcessDetail({ id: id, versionId: versionId })
    //   if (res) {
    //     this.processFlowVersion = res.processFlowVersion
    //     this.taskData = res.processFlowVersion.procedureList
    //   }
    // },
    // 质检返修记录
    async getInspectionRepairRecordList() {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      })
      const res = await inRepairApi.getRepairRecordList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.inspectionRepairRecordList = res
      }
    },
    // 报工返修记录
    async getRepairRecordList() {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.params.id,
        operator: 'eq'
      },
      {
        id: getUUid(),
        fieldName: 'repairPlanStatus',
        fieldType: 'number',
        fieldValue: 0,
        operator: 'eq'
      })
      const res = await repairApi.getNoRepairRecord({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.repairRecordList = res
      }
    },
    fixAll() {
      const list = this.repairRecordList && this.repairRecordList.length > 0
        ? this.repairRecordList.map(item => {
          return {
            ...item,
            repairType: '报工返修',
            repairCount: item.repairWorkQuantity,
            planCount: item.repairWorkQuantity
          }
        }) : []
      const zList = this.inspectionRepairRecordList && this.inspectionRepairRecordList.length > 0
        ? this.inspectionRepairRecordList.map(item => {
          return {
            ...item,
            repairType: '质检返修'
          }
        }) : []
      return [...list, ...zList]
    },
    jumpDetail(row) {
      this.$router.push({ path: 'work-detail', query: { id: row.repairWorkOrderId }})
    },
    // 查看返修记录工位数量列表
    getWorkQuantity(row) {
      this.stationList = row.repairStationList
      this.stationVisible = true
    },
    // 发起返修
    async startRepair() {
      this.buttonLoadings = true
      await this.getRepair()
    },
    // 获取返修批次
    async getRepair() {
      const res = await api.getRepairDetail({
        produceWorkOrderId: this.params.id
      })
      if (res) {
        this.repairList = res
        this.repairVisible = true
      }
      this.buttonLoadings = false
    },
    // 发起减产
    async startReduction() {
      this.taskList = []
      if (this.baseFormData.isPartialOutbound === 0 && this.baseFormData.produceWorkOrderTaskList && this.baseFormData.produceWorkOrderTaskList[0].procedureType === 1) {
        this.$message.error('外协工序不可发起减产')
        return false
      }
      if (this.baseFormData.isPartialOutbound === 0 && this.baseFormData.produceWorkOrderTaskList && this.baseFormData.produceWorkOrderTaskList.length > 1) {
        this.$message.error('并联工序不可发起减产')
        return false
      }
      if (this.baseFormData.isPartialOutbound === 0 && (this.reportData.length === 0 || this.reportData[0].reportWorkQuantity === 0)) {
        this.$message.error('当前工序还未报工')
        return false
      }
      // if (this.baseFormData.isPartialOutbound === 1) {
      await this.getTaskLists()
      // }
      this.reductionVisible = true
    },
    // 发起拆单
    async startDivision() {
      this.taskList = []
      if (this.baseFormData.isPartialOutbound === 0 && this.baseFormData.produceWorkOrderTaskList && this.baseFormData.produceWorkOrderTaskList[0].procedureType === 1) {
        this.$message.error('外协工序不可发起拆单')
        return false
      }
      if (this.baseFormData.isPartialOutbound === 0 && this.baseFormData.produceWorkOrderTaskList && this.baseFormData.produceWorkOrderTaskList.length > 1) {
        this.$message.error('并联工序不可发起拆单')
        return false
      }
      if (this.baseFormData.isPartialOutbound === 0 && (this.reportData.length === 0 || this.reportData[0].reportWorkQuantity === 0)) {
        this.$message.error('当前工序还未报工')
        return false
      }
      // if (this.baseFormData.isPartialOutbound === 1) {
      await this.getTaskLists()
      // }
      this.divisionVisible = true
    },
    // 创建计划
    async getRepairs(row) {
      const data = this._.cloneDeep(row)
      this.infos = Object.assign({}, data, {
        materialsCode: this.baseFormData.materialsCode,
        materialsName: this.baseFormData.materialsName,
        materialsSpecifications: this.baseFormData.materialsSpecifications
      })
      this.repairRecordId = row.id
      this.repairVisibles = true
    }
  }
}
</script>

<style lang="scss" scoped>
.all-btns {
  ::v-deep {
    .el-button {
      padding: 0 20px;
    }
  }
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.mar-right {
  margin-left: 10px;
  width: 40px;
  display: inline-block;
}
.li-btn {
  // margin-right:10px;
  color: #607fff;
}
.color-btns {
  color: #c4c4c4;
}
.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center !important;
}
.procedure-detail-wrapper {
  height: 100%;
  .procedure-detail-header {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456b;
    line-height: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    img {
      width: 8px;
      height: 12px;
      display: block;
      margin-right: 10px;
    }
  }
  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
      .card-tits {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        background: #e5e7ea;
      }
      .card-click {
        margin-right: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #607fff;
        cursor: pointer;
      }
    }
  }
}
.params-card {
  border: 1px solid #ececf7;
  margin-bottom: 20px;
  .header {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background: #ececf7;
  }
  .body {
    width: 100%;
    .bot-ti {
      // height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 16px;
      margin-left: 20px;
      word-break: break-all;
      .imgs {
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 10px;
        img {
          width: 100px;
          height: 100px;
          display: inline-block;
        }
      }
    }
  }
}
.step-item {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
::v-deep .el-table {
  .cell {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
