<template>
  <div class="orderAdd">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.push({ name: 'orderList' })">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>订单详情</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-col :span="6">
          <span class="bot-ti">订单来源：{{ orderSource[detailData.source].name }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">订单号：{{ detailData.produceOrderNumber }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">交期：{{ detailData.deliveryTime }}</span>
        </el-col>
        <el-col :span="6">
          <div class="bot-ti">优先级：
            <span
              :style="{ color: priorityStatus[detailData.priority] ? priorityStatus[detailData.priority].color : 'rgb(74, 79, 118)', background: `${priorityStatus[detailData.priority] ? priorityStatus[detailData.priority].color : 'rgb(74, 79, 118)'}20` }"
              class="tag-priority"
            >
              {{ priorityStatus[detailData.priority] ? priorityStatus[detailData.priority].name : '普通' }}
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">客户：{{ detailData.customerName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">备注：{{ detailData.remark }}</span>
        </el-col>
        <el-col :span="6">
          <div class="bot-li">
            <div class="bot-ti">附件：</div>
            <div class="li-des" style="width: calc(100% - 45px);">
              <span v-for="(items, idx) in fixArrs(detailData.attachments)" :key="idx" style="word-break: break-all;color: #0091FF;cursor:pointer" @click="handlePreview(items)">
                {{ items.name }}
                <span v-if="idx < fixArrs(detailData.attachments).length -1">，</span>
              </span>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="bot-li">
            <span class="bot-ti" style="width: 90px;">自定义标签：</span>
            <div class="li-des" style="width: calc(100% - 90px);">
              <el-tag v-for="(items, idx) in fixArrs(detailData.customTag)" :key="idx" type="info" style="margin: 0 5px 5px 0;" class="el-tags" size="mini">{{ items }}</el-tag>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">销售订单编号：{{ detailData.salesOrderCodes }}</span>
        </el-col>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">订单产品</div>
      <div class="tables">
        <MTable
          ref="mTable"
          :show-page="false"
          :height="150"
          :columns="columnsProduct"
          :data="productList"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="materialsPicture" slot-scope="{ row }">
            <el-image
              v-if="row.materialsPicture && row.materialsPicture !== '[]'"
              :src="row.materialsPicture"
              class="drawing-img"
              :preview-src-list="fixImg(row.materialsPicture)"
            />

          </div>
          <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
        </MTable>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">计划用料</div>
      <div class="tables">
        <MTable
        v-if="materialUsePlan.length"
          ref="mTable"
          :show-page="false"
          :height="250"
          :columns="columnsMaterialPlan"
          :data="materialUsePlan"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
        </MTable>
      <MBlank v-else title="暂无计划用料，请先配置产品BOM" />

      </div>
    </div>
    <el-tabs v-model="activeName" class="detail-tabs" type="border-card">
      <el-tab-pane label="关联计划" name="first">
        <FunctionHeader
          v-model="functionHeaders.searchVal"
          :tags="functionHeaders.tags"
          style="padding-top:0"
          search-title="生产计划单号"
          @search="$refs.orderTables.setTableData(true)"
        />
        <MTable ref="orderTables" :height="300" :set-data-method="getPlanTableData" :columns="columnsPlanOrder">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="produceOrderNumber" slot-scope="{ row }">
            <el-button type="text" @click="jumpOrderDetail(row)">{{ row.produceOrderNumber }}</el-button>
          </div>
          <div slot="status" slot-scope="{ row }">
            <div>{{ planStatus[row.status].name }}</div>
          </div>
          <div slot="produceStatus" slot-scope="{ row }">
            <div :style="{color: row.status === 0 ? '#FA6400' : row.status === 1 ? '#00AB29' : '#607FFF'}"> {{ productionStatus[row.produceStatus].name }}</div>
          </div>

          <div slot="processFlowName" slot-scope="{ row }">
            <el-button type="text" @click="showProcess(row)">{{ row.processFlowName }}</el-button>
          </div>
          <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
          <div slot="action" slot-scope="{ row }" class="all-btns">
            <el-button v-if="permission('CancelPlanOrder') && row.produceOrderId === id" type="text" disabled>取消关联</el-button>
            <el-button v-else type="text" @click="cancelPlanOrders(row)">取消关联</el-button>
          </div>
        </MTable>
      </el-tab-pane>
      <el-tab-pane label="关联工单" name="second">
        <FunctionHeader
          v-model="functionHeader.searchVal"
          :tags="functionHeader.tags"
          style="padding-top:0"
          search-title="生产工单号/订单号"
          @search="$refs.orderTable.setTableData(true)"
        />
        <MTable ref="orderTable" :height="300" :set-data-method="getTableData" :columns="columnsWorkOrder">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="produceWorkOrderNumber" slot-scope="{ row }">
            <img v-if="row.isRepair === 0 && row.repairRecordList" src="@/assets/plans/repair-icon.png" class="icon-img">
            <img v-if="row.isRepair === 1" src="@/assets/plans/repair-icon-have.png" class="icon-img">
            <el-button type="text" @click="jumpDetail(row)">{{ row.produceWorkOrderNumber }}</el-button>
          </div>
          <div slot="produceOrderNumber" slot-scope="{ row }">
            <el-button type="text" @click="jumpOrderDetail(row)">{{ row.produceOrderNumber }}</el-button>
          </div>
          <div slot="isRepair" slot-scope="{ row }">
            <span>{{ row.isRepair === 1 ? '返修' : '普通' }}</span>
          </div>
          <div slot="status" slot-scope="{ row }">
            <div :style="{color: row.status === 0 ? '#FA6400' : row.status === 1 ? '#00AB29' : '#607FFF'}"> {{ productionStatus[row.status].name }}</div>
          </div>
          <div slot="currentProcedure" slot-scope="{ row }">
            <div>{{ row.produceWorkOrderTaskList ? fixProduceData(row.produceWorkOrderTaskList) : '' }}</div>
          </div>
          <div slot="processFlowName" slot-scope="{ row }">
            <el-button type="text" @click="showProcess(row)">{{ row.processFlowName }}</el-button>
          </div>
          <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
          <div slot="action" slot-scope="{ row }" class="all-btns">
            <el-button v-if="permission('CancelWorkOrder') && row.produceOrderId === id" type="text" disabled>取消关联</el-button>
            <el-button v-else type="text" @click="cancelWorkOrders(row)">取消关联</el-button>
          </div>
        </MTable>
      </el-tab-pane>
    </el-tabs>
    <!-- 工艺路线 -->
    <MDialog v-model="processVisible" title="工艺路线" :show-footer="false" @onOk="processVisible = false">
      <single-process-flow ref="processFlow" :current-procedure-names="currentProcedureName" :read-only="!saveButton" :all-work-order-task-list="allWorkTask ? allWorkTask : []" />
      <!-- <process-flow ref="processFlow" :read-only="!saveButton" :process-flow-detail="processFlowVersion" :showVersion="true"/> -->
    </MDialog>
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
    <!-- 选择工单 -->
    <select-work-order
      ref="selectWorkOrder"
      :visible.sync="showWorkOrder"
      :produceOrderId="id"
      @submitForm="submitWorkOrder"/>
    <!-- 选择计划 -->
    <select-plan-order
      ref="selectPlanOrder"
      :visible.sync="showPlanOrder"
      :produce-order-id="id"
      @submitForm="submitPlanOrder"
    />
  </div>
</template>
<script>
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import { columnsPlanOrder, columnsWorkOrder, columnsProduct,columnsMaterialPlan } from './columns'
import { planStatus, orderSource, productionStatus } from '@/config/options.config'
import api from '@/api/production/orders'
import workApi from '@/api/production/work-order'
import planApi from '@/api/production/plans'
import processApi from '@/api/information/production/process'
import FunctionHeader from '@/components/FunctionHeader/index'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import SelectWorkOrder from '@/components/SelectWorkOrder/SelectWorkOrder'
import SelectPlanOrder from '@/components/SelectPlanOrder/SelectPlanOrder'
import saveAs from 'file-saver'
import singleProcessFlow from '@/components/singleProcessFlow/singleProcessFlow'

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'OrderDetail',
  components: { singleProcessFlow, FunctionHeader, ProcessFlow, EditMaterials, SelectWorkOrder, SelectPlanOrder },
  mixins: [viewMaterialsDetail],
  data() {
    return {
      materialUsePlan:[],
      columnsPlanOrder,
      columnsProduct,
      columnsMaterialPlan,
      columnsWorkOrder,
      planStatus,
      orderSource,
      productionStatus,
      allWorkTask: [],
      userList: [],
      customTag: [],
      showPlanOrder: false,
      showWorkOrder: false,
      show: false,
      orderList: [],
      detailData: {
        source: 0
      },
      productList: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('AddWorkOrder'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '关联工单',
            on: {
              click: this.addWorkOrder
            }
          }
        ]
      },
      functionHeaders: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('AddPlanOrder'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '关联计划',
            on: {
              click: this.addPlanOrder
            }
          }
        ]
      },
      activeName: 'first',
      relationType: 0,
      id: undefined,
      processVisible: false,
      saveButton: false,
      processFlowVersion: {},
      currentProcedureIds: [],
      currentProcedureName: ''
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29 - 62 - 22
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  activated() {
    this.id = parseInt(this.$route.query.id)
    this.getOrderDetail(this.$route.query.id)
    this.getMaterialsRequirementList(this.$route.query.id)
    this.$refs.orderTables.setTableData()
    this.$refs.orderTable.setTableData()
  },
  methods: {
    async getProduceProcessList(row) {
      const res = await workApi.getProduceProcessList({
        id: row.id
      })
      if (res) {
        this.allWorkTask = res
      }
    },
    fixImg(img) {
      if (img) {
        return [img]
      }
    },
    handlePreview(file) {
      saveAs(file.url, file.name)
    },
    fixArrs(val) {
      return val ? JSON.parse(val) : ''
    },
    async submitPlanOrder(val) {
      this.showPlanOrder = false
      const ids = val.map(item => item.id)
      const res = await api.addPlanOrder({ id: this.id, workOrderIdList: ids })
      if (res) {
        this.$refs.orderTables.setTableData()
      }
    },
    async submitWorkOrder(val) {
      this.orderList = val
      this.showWorkOrder = false
      const ids = val.map(item => item.id)
      const res = await workApi.addWorkOrder({ id: this.id, workOrderIdList: ids })
      if (res) {
        this.$refs.orderTable.setTableData()
      }
    },
    async cancelWorkOrders(row) {
      this.$confirm('您确定要取消关联吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await workApi.cancelWorkOrder({ id: this.id, workOrderIdList: [row.id] })
        if (res) {
          this.$refs.orderTable.setTableData()
        }
      })
    },
    async cancelPlanOrders(row) {
      this.$confirm('您确定要取消关联吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.cancelPlanOrder({ id: this.id, workOrderIdList: [row.id] })
        if (res) {
          this.$refs.orderTables.setTableData()
        }
      })
    },
    async showProcess(row) {
      // await this.getProcessDetailById(row.processFlowId, row.processFlowVersionId)
      await this.getProduceProcessList(row)
      this.currentProcedureName = row.currentProcedureName
      this.processVisible = true
    },
    // 获取工艺详情
    async getProcessDetailById(id, versionId) {
      const res = await processApi.getProcessDetail({ id: id, versionId: versionId })
      if (res) {
        this.processFlowVersion = res.processFlowVersion
      }
    },
    fixProduceData(val) {
      const list = val.map(item => item.procedureName)
      return list.lenght === 1 ? list[0] : list.join('-')
    },
    jumpOrderDetail(row) {
      this.$router.push({ name: 'orderDetail', query: { id: row.produceOrderId }})
    },
    jumpDetail(row) {
      console.log(row.id)
      this.$router.push({ name: 'workDetail', query: { id: row.id }})
    },
    addWorkOrder() {
      // this.selectIds = this._.cloneDeep(this.orderList)
      this.showWorkOrder = true
    },
    addPlanOrder() {
      this.showPlanOrder = true
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      console.log(search)
      const res = await workApi.getPlanpage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        produceOrderId: this.id,
        relationType: this.relationType,
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    // 计划列表
    async getPlanTableData(condition, callback) {
      const { page, search, order } = condition
      const res = await planApi.getPlanpage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        produceOrderId: this.id,
        relationType: this.relationType,
        searchKeyword: this.functionHeaders.searchVal,
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    // 订单详情
    async getOrderDetail(id) {
      this.productList=[]
      const res = await api.getOrderDetail(id)
      if (res) {
        this.detailData = res
        this.productList.push(res)
      }
    },
    returnList() {
      this.$router.back()
    },
   async  getMaterialsRequirementList(id){
      const res = await api.getMaterialsRequirementList(id,1)
      if (res) {
        console.log('res:',res)
        this.materialUsePlan=res
      }
    }
  }
}
</script>

<style scoped lang="scss">
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.bot-li {
  display: flex;
  justify-content: flex-start;
  .bot-ti {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    display: inline-block;
    margin-bottom: 15px;
  }
}
.el-tags {
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.icon-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 19px;
  height: 19px;
  display: block;
}
.all-ipt {
  margin-top: 4px;
}
.procedure-detail-header {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456b;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img {
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}
.detail-card {
  margin-bottom: 20px;
}
.card-bot {
  display: flex;
  flex-flow: row wrap;
  .bot-ti {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    display: inline-block;
    margin-bottom: 16px;
  }
}
.tables {
  padding: 20px;
}
</style>
