<template>
  <div class="role-wrap">
    <div class="orders">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="searchFormListPreOrder"
          :form-data="searchFormData"
          style="padding: 0"
          :default-form="defaultForm"
          @update:formData="data=>searchFormData=data"
          @search="reSearch"
        />
      </div>
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        style="padding: 10px 0 10px 0;"
        class="func-header"
        search-title="请输入工单编号"
        @search="$refs.mTable.setTableData(true)"
      />

      <MTable
        ref="mTable"
        :columns="columnsPreOrder"
        :height="height"
        :set-data-method="getTableData"
        @selection-change="data=>selectData = data"
      >
        <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
        <div slot="status" slot-scope="{row }">{{ preOrderStatusMaps[row.status] }}</div>
        <div slot="hasProcessFlow" slot-scope="{row }">{{ hasProcessFlowMaps[row.hasProcessFlow] }}</div>
        <!--<div slot="hasProject" slot-scope="{row }">{{ row.hasProject === 1 ? "有" : "无" }}</div>-->
        <div slot="action" slot-scope="{row }">
          <el-button
            v-if="permission('AddOrder')"
            type="text"
            @click="addOrder(row)"
            :disabled="row.status==1"
          >补工单</el-button>
        </div>
      </MTable>

    </div>

  </div>
</template>
<script>
import api from '@/api/production/prod-plan'
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columnsPreOrder, searchFormListPreOrder, preOrderStatusMaps, hasProcessFlowMaps } from './data'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import dayjs from 'dayjs'

export default {
  name: 'PreOrder',
  components: { NewPageOpen, FunctionHeader, SearchForm },
  data() {
    return {
      api,
      preOrderStatusMaps,
      hasProcessFlowMaps,
      searchFormListPreOrder,
      columnsPreOrder,
      searchFormData: {},
      functionHeader: {
        searchVal: ''
      },
      selectData: [],
      defaultForm: { createTime: ['2023-09-01 00:00:00', dayjs().format('YYYY-MM-DD HH:mm:ss')] },
      isPageLoaded:false,
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 220 - 65
    }
  },
  activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
  mounted() {
    this.isPageLoaded = true
  },

  methods: {
    async addOrder(row) {
      const res = await api.addOrderParams(row)
      if (res) {
        this.$router.push({ path: 'prod-plan/prod-plan-create?type=addOrder', query: { row: JSON.stringify(res) }})
      }
    },
    reSearch(data, val) {
      if (val === 1) {
        this.searchFormData = {}
      }
      setTimeout(() => {
        this.$refs.mTable.setTableData(true)
      }, this.searchFormData?.createTime?.length === 2 ? 300 : 0)
    },

    // 列表
    async getTableData(condition, callback) {
      const { page } = condition
      const { businessUnitName, materialsSearch, projectSearch, createTime, status, hasProcessFlow, hasProject} = this.searchFormData
      const params = {
        status,
        hasProcessFlow,
        hasProject,
        businessUnitName,
        materialsSearch,
        projectSearch,
        productionPlanNumber: this.functionHeader.searchVal,
        createTimeStart: createTime ? createTime[0] : null,
        createTimeEnd: createTime ? createTime[1] : null
      }
      const res = await api.preOrderList({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.orders {
  padding: 10px;
}
::v-deep .custom-sel {
  div {
    min-width: 100%;
  }
}
</style>
