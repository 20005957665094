var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    { attrs: { type: "text" }, on: { click: _vm.download } },
    [_vm._v("下载")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }