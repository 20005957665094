<template>
  <el-button type="text"  @click="download">下载</el-button>
</template>
<script>
import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'
import { saveAs } from 'file-saver'
import ImageModule from 'docxtemplater-image-module-free'
import api from '@/api/production/work-order'
import {debounceMixin}from '@/mixin/debounceMixin'
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback)
}
export default {
  props: {
    id: {
      type: [String, Number],
      default: -1
    },

  },
  mixins:[debounceMixin],
  data() {
    return {
      templateData: {
        "materialsName": "成品",
        "specifications": "规格",
        "produceOrderNumber": "order-0119-02",
        "batchNumber": "PCM2401190002",
        "createTime": "2024-01-19 13:48:42",
        "materialsReceiveList": [
          {
            "head": "主要零件",
            "first": "按键板",
            "second": "主控板",
            "three": "",
            "four": "",
            "five": "",
            "six": ""
          },
          {
            "head": "追溯号",
            "first": "PCM2401180002",
            "second": "PCM2401180003",
            "three": "",
            "four": "",
            "five": "",
            "six": ""
          }
        ],
        "stepList": [
          {
            "procedureName": "工序1",
            "procedureParam": "焊接温度554,烙铁编号ltbh123",
            "stepRecordList": [
              {
                "description": "检查成品外观，长度",
                "remark": "外观无瑕疵，长度大于1M",
                "dataRecord": "外观是否无瑕疵真的没有无瑕疵,长度10",
                "status": "",
                "id": 63
              },
              {
                "description": "检查包装是否完整",
                "remark": "包装完整",
                "dataRecord": "检验包装时间2024-01-19 13:49",
                "status": "",
                "id": 64
              },
              {
                "description": "检查成品外观，长度",
                "remark": "外观无瑕疵，长度大于1M",
                "dataRecord": "外观是否无瑕疵真的没有无瑕疵,长度10",
                "status": "",
                "id": 65
              },
              {
                "description": "检查包装是否完整",
                "remark": "包装完整",
                "dataRecord": "检验包装时间2024-01-19 13:49",
                "status": "",
                "id": 66
              },
              {
                "description": "检查包装是否完整",
                "remark": "包装完整",
                "dataRecord": "检验包装时间2024-01-19 13:49",
                "status": "",
                "id": 67
              },
              {
                "description": "检查成品外观，长度",
                "remark": "外观无瑕疵，长度大于1M",
                "dataRecord": "外观是否无瑕疵真的没有无瑕疵,长度10",
                "status": "",
                "id": 65
              },
              {
                "description": "检查包装是否完整",
                "remark": "包装完整",
                "dataRecord": "检验包装时间2024-01-19 13:49",
                "status": "",
                "id": 66
              },
            ],
            "operatorUrl": "",
            "mutualInspectionUrl": "",
            "inspectionUrl": "",
            "description": "操作内容",
            "remark": "检验标准",
            "dataRecord": "数值记录",
            "status": "状态标识",
            "operatorSignatureTitle": '操作员签字',
            "mutualInspectionSignatureTitle": '互检员签字',
            "inspectionSignatureTitle": '质检员签字',

            "operatorSignature": "https://oss.mubyte.cn/mes2-lite/zhangchuang/2024/01/17/17054689240771432.jpg",
            "mutualInspectionSignature": "https://oss.mubyte.cn/mes2-lite/test/2024/01/22/17058930521703140.png",
            "inspectionSignature": "https://oss.mubyte.cn/mes2-lite/zhangchuang/2024/01/17/17054689240771432.jpg"
          },
          {
            "procedureName": "工序2",
            "procedureParam": "",
            "stepRecordList": [
              {
                "description": "检查印字正确",
                "remark": "印字与订单相符",
                "dataRecord": "印字是否正确正确",
                "status": "",
                "id": 65
              }
            ],
            "operatorUrl": "",
            "mutualInspectionUrl": "",
            "inspectionUrl": "",
            "description": "操作内容",
            "remark": "检验标准",
            "dataRecord": "数值记录",
            "status": "状态标识",
            "operatorSignatureTitle": '操作员签字',
            "mutualInspectionSignatureTitle": '互检员签字',
            "inspectionSignatureTitle": '质检员签字',
            "operatorSignature": "https://oss.mubyte.cn/mes2-lite/test/2024/01/22/17058930521703140.png",
            "mutualInspectionSignature": "https://oss.mubyte.cn/mes2-lite/zhangchuang/2024/01/17/17054689240771432.jpg",
            "inspectionSignature": "https://oss.mubyte.cn/mes2-lite/zhangchuang/2024/01/17/17054689240771432.jpg"
          }
        ]
      },
      productProcessCardDetail: {},
      processInspectionReportDetail:{},
      finishedInspectionReportDetail:{},
      getPassReportDetail:{}

    }
  },
  mounted() {
  },
  methods: {
    download() {
      this.debouncedClickHandler(() => {
        console.log('id:',this.id)
      this.getProductProcessCard(this.id)
   this.getProcessInspectionReport(this.id)
   this.getFinishProductInspectionReport(this.id)
   this.getPassReport(this.id)
        // 在这里处理按钮点击事件的逻辑
      });
     

    },
    exportProductProcedureDetail() {
      const self = this
      loadFile('/wordTemplate/productProcedureDetail.docx',
        function (error, content) {
          if (error) {
            throw error
          }

          const imageOpts = {
            getImage: function (tagValue, tagName) {
              return new Promise(function (resolve, reject) {
                PizZipUtils.getBinaryContent(tagValue, function (error, content) {
                  if (error) {
                    return reject(error);
                  }
                  return resolve(content);
                });
              });
            },
            getSize: function (img, tagValue, tagName) {
              // FOR FIXED SIZE IMAGE :
              return [80, 80];
            }
          }

          var imageModule = new ImageModule(imageOpts);
          const zip = new PizZip(content)
          const doc = new Docxtemplater().loadZip(zip).setOptions({
            // delimiters: { start: "[[", end: "]]" },
            paragraphLoop: true,
            linebreaks: true,
          }).attachModule(imageModule).compile()
          doc.renderAsync(self.productProcessCardDetail).then(function () {
            const out = doc.getZip().generate({
              type: "blob",
              mimeType: 'application/vnd.openxmlformatsofficedocument.wordprocessingml.document',
            });
            saveAs(out, `${self.productProcessCardDetail.materialsName}-${self.productProcessCardDetail.batchNumber}-产品流程卡.docx` );
          });
        })

    },
    exportProcessInspectionReport() {
      const self = this
      loadFile('/wordTemplate/processInspectionReport.docx',
        function (error, content) {
          if (error) {
            throw error
          }

          const imageOpts = {
            getImage: function (tagValue, tagName) {
              return new Promise(function (resolve, reject) {
                PizZipUtils.getBinaryContent(tagValue, function (error, content) {
                  if (error) {
                    return reject(error);
                  }
                  return resolve(content);
                });
              });
            },
            getSize: function (img, tagValue, tagName) {
              // FOR FIXED SIZE IMAGE :
              return [80, 80];
            }
          }

          var imageModule = new ImageModule(imageOpts);
          const zip = new PizZip(content)
          const doc = new Docxtemplater().loadZip(zip).setOptions({
            // delimiters: { start: "[[", end: "]]" },
            paragraphLoop: true,
            linebreaks: true,
          }).attachModule(imageModule).compile()
          doc.renderAsync(self.processInspectionReportDetail).then(function () {
            const out = doc.getZip().generate({
              type: "blob",
              mimeType: 'application/vnd.openxmlformatsofficedocument.wordprocessingml.document',
            });
            saveAs(out,`${self.processInspectionReportDetail.materialsName}-${self.processInspectionReportDetail.batchNumber}-过程检验报告单.docx`);
          });
        })

    },
    exportFinishedInspectionReport() {
      const self = this
      loadFile('/wordTemplate/finishedInspectionReport.docx',
        function (error, content) {
          if (error) {
            throw error
          }

          const imageOpts = {
            getImage: function (tagValue, tagName) {
              return new Promise(function (resolve, reject) {
                PizZipUtils.getBinaryContent(tagValue, function (error, content) {
                  if (error) {
                    return reject(error);
                  }
                  return resolve(content);
                });
              });
            },
            getSize: function (img, tagValue, tagName) {
              // FOR FIXED SIZE IMAGE :
              return [80, 80];
            }
          }

          var imageModule = new ImageModule(imageOpts);
          const zip = new PizZip(content)
          const doc = new Docxtemplater().loadZip(zip).setOptions({
            // delimiters: { start: "[[", end: "]]" },
            paragraphLoop: true,
            linebreaks: true,
          }).attachModule(imageModule).compile()
          doc.renderAsync(self.finishedInspectionReportDetail).then(function () {
            const out = doc.getZip().generate({
              type: "blob",
              mimeType: 'application/vnd.openxmlformatsofficedocument.wordprocessingml.document',
            });
            saveAs(out, `${self.finishedInspectionReportDetail.materialsName}-${self.finishedInspectionReportDetail.batchNumber}-成品检报告单.docx`);
          });
        })

    },
    exportPassReport() {
      const self = this
      loadFile('/wordTemplate/passReport.docx',
        function (error, content) {
          if (error) {
            throw error
          }

          const imageOpts = {
            getImage: function (tagValue, tagName) {
              return new Promise(function (resolve, reject) {
                PizZipUtils.getBinaryContent(tagValue, function (error, content) {
                  if (error) {
                    return reject(error);
                  }
                  return resolve(content);
                });
              });
            },
            getSize: function (img, tagValue, tagName) {
              // FOR FIXED SIZE IMAGE :
              return [80, 80];
            }
          }

          var imageModule = new ImageModule(imageOpts);
          const zip = new PizZip(content)
          const doc = new Docxtemplater().loadZip(zip).setOptions({
            // delimiters: { start: "[[", end: "]]" },
            paragraphLoop: true,
            linebreaks: true,
          }).attachModule(imageModule).compile()
          doc.renderAsync(self.getPassReportDetail).then(function () {
            const out = doc.getZip().generate({
              type: "blob",
              mimeType: 'application/vnd.openxmlformatsofficedocument.wordprocessingml.document',
            });
            saveAs(out, `${self.getPassReportDetail.materialsName}-${self.getPassReportDetail.batchNumber}-产品放行单.docx`);
          });
        })

    },
    async getProductProcessCard(id) {
      const res = await api.getProductProcessCard({
        id
      })
      if (res) {
        this.productProcessCardDetail = res
        this.exportProductProcedureDetail();
  
      }
    },
    async getProcessInspectionReport(id) {
      const res = await api.getProcessInspectionReport({
        id
      })
      if (res) {
        this.processInspectionReportDetail = res
        this.exportProcessInspectionReport();
      }
    },
    async getFinishProductInspectionReport(id) {
      const res = await api.getFinishProductInspectionReport({
        id
      })
      if (res) {
        this.finishedInspectionReportDetail = res
      this.exportFinishedInspectionReport();
      }
    },
    async getPassReport(id) {
      const res = await api.getPassReport({
        id
      })
      if (res) {
        this.getPassReportDetail = res
      this.exportPassReport();
      }
    },
  }
}
</script>
