<template>
  <div class="searchs">
    <!-- 基本信息数据 -->
    <el-col :span="8">
      <span class="bot-ti">生产计划号：{{ detailData.producePlanNumber }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">生产订单号：{{ detailData.produceOrderNumber }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">生产批次码：{{ detailData.batchNumber }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">物料编码：{{ detailData.materialsCode }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">物料名称：{{ detailData.materialsName }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">规格：{{ detailData.materialsSpecifications }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">单位：{{ detailData.materialsUnit }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">计划数量：{{ detailData.quantity }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">实际开始时间：{{ detailData.actualStartTime }}</span>
    </el-col>
    <el-col :span="8">
      <span class="bot-ti">实际结束时间：{{ detailData.actualEndTime }}</span>
    </el-col>
    <el-col v-if="isShow" :span="8">
      <span class="bot-ti">工艺：{{ detailData.processFlowName }}</span>
    </el-col>
    <el-col v-if="isShow" :span="8">
      <span class="bot-ti">产线：{{ detailData.areaName }}</span>
    </el-col>
  </div>
</template>
<script>
export default {
  props: {
    detailData: {
      type: Object,
      default: () => ({})
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    fixData(val) {
      if (val && val.length > 0) {
        const data = val.map(item => item.batchNumber)
        return data.join()
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .searchs{
   .bot-ti{
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      display:inline-block;
      margin-bottom:16px;
      word-break: break-all;
   }
 }
</style>
