
import { outSourceStatus } from '@/config/options.config'
export const searchFormList = [
  {
    key: 'status',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '状态',
      multiple: true,
      filterable: true,
      collapseTags: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: outSourceStatus
      }
    ]
  },

  {
    colSpan: 6,
    key: 'materialsId',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '物料名称'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'procedureId',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '工序名称'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
]
