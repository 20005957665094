var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForms", {
            ref: "searchForms",
            on: { resetData: _vm.resetData, searchData: _vm.searchTable }
          })
        ],
        1
      ),
      _c(
        "FunctionHeader",
        {
          ref: "functionHeader",
          staticClass: "func-header",
          staticStyle: { padding: "0 0 10px 0" },
          attrs: {
            "search-title": "请输入生产订单号",
            tags: !_vm.selectionData.length ? _vm.functionHeader.tags : [],
            "export-name": _vm.functionHeader.exportName,
            "show-export": !_vm.selectionData.length ? true : false,
            "export-params": _vm.functionHeader.exportParams,
            "import-name": !_vm.selectionData.length
              ? _vm.functionHeader.importName
              : ""
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectionData.length,
                  expression: "selectionData.length"
                }
              ],
              attrs: { slot: "all" },
              slot: "all"
            },
            [
              _vm.permission("Export")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.batchExport()
                        }
                      }
                    },
                    [_vm._v("批量导出")]
                  )
                : _vm._e(),
              _vm.permission("BatchDel")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.allDel()
                        }
                      }
                    },
                    [_vm._v("批量删除")]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "0 10px",
                    display: "inline-block",
                    "font-size": "12px",
                    color: "#393D60"
                  }
                },
                [
                  _vm._v(" 已选 "),
                  _c("span", { staticStyle: { color: "#607FFF" } }, [
                    _vm._v(_vm._s(_vm.getSelectionDataLength))
                  ]),
                  _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "small" },
                  on: { click: _vm.resetSelectData }
                },
                [_vm._v("清空")]
              )
            ],
            1
          )
        ]
      ),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columnsAll,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        on: { "selection-change": _vm.selectionChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "quantity",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-start",
                    "align-items": "center"
                  }
                },
                [
                  _c("div", { staticStyle: { display: "inline-block" } }, [
                    _vm._v(_vm._s(row.quantity))
                  ]),
                  _vm.memberCode === "reagold" &&
                  (row.warehouseQuantity ||
                    row.sitQuantity ||
                    row.doingQuantity)
                    ? _c("div", { staticClass: "tooltip" }, [
                        row.warehouseQuantity
                          ? _c("div", { staticClass: "tip-tit" }, [
                              _vm._v("仓库：" + _vm._s(row.warehouseQuantity))
                            ])
                          : _vm._e(),
                        row.sitQuantity
                          ? _c("div", { staticClass: "tip-tit" }, [
                              _vm._v("线边库：" + _vm._s(row.sitQuantity))
                            ])
                          : _vm._e(),
                        row.doingQuantity
                          ? _c("div", { staticClass: "tip-tit" }, [
                              _vm._v("在制：" + _vm._s(row.doingQuantity))
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ]
              )
            }
          },
          {
            key: "produceOrderNumber",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.isShowChild
                    ? _c("NewPageOpen", {
                        attrs: {
                          path: { name: "orderDetail", query: { id: row.id } },
                          text: row.produceOrderNumber
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          },
          {
            key: "materialsCode",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      return _vm.viewMaterialsDetail(row.materialsId)
                    }
                  }
                },
                [_vm._v(_vm._s(row.materialsCode))]
              )
            }
          },
          {
            key: "source",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _c("div", [_vm._v(_vm._s(_vm.orderSource[row.source].name))])
              ])
            }
          },
          {
            key: "priority",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "tag-priority",
                    style: {
                      color: _vm.priorityStatus[row.priority]
                        ? _vm.priorityStatus[row.priority].color
                        : "rgb(74, 79, 118)",
                      background:
                        (_vm.priorityStatus[row.priority]
                          ? _vm.priorityStatus[row.priority].color
                          : "rgb(74, 79, 118)") + "20"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.priorityStatus[row.priority]
                            ? _vm.priorityStatus[row.priority].name
                            : "普通"
                        ) +
                        " "
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    style: {
                      color:
                        row.status === 0
                          ? "#FA6400"
                          : row.status === 2
                          ? "#607FFF"
                          : "#00AB29"
                    }
                  },
                  [_vm._v(_vm._s(_vm.fixStatus(row.status)))]
                )
              ])
            }
          },
          {
            key: "drawingStatus",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    style: {
                      color: _vm.pageStatus[row.drawingStatus]
                        ? _vm.pageStatus[row.drawingStatus].color
                        : ""
                    }
                  },
                  [_vm._v(_vm._s(_vm.pageStatus[row.drawingStatus].name))]
                )
              ])
            }
          },
          {
            key: "bomStatus",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    style: {
                      color: _vm.pageStatus[row.bomStatus]
                        ? _vm.pageStatus[row.bomStatus].color
                        : ""
                    }
                  },
                  [_vm._v(_vm._s(_vm.pageStatus[row.bomStatus].name))]
                )
              ])
            }
          },
          {
            key: "orderProcess",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c("el-progress", {
                    attrs: {
                      "stroke-width": 10,
                      percentage:
                        _vm.fixNumber(row) >= 100 ? 100 : _vm.fixNumber(row),
                      format: _vm.fixNumber(row, 1)
                    }
                  })
                ],
                1
              )
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.memberCode === "reagold" || _vm.memberCode === "mubai"
                    ? [
                        _vm.planId === 1
                          ? [
                              row.status !== 2 &&
                              _vm.permission("planAddCreatePlan", false) &&
                              _vm.isShowChild
                                ? _c("NewPageOpen", {
                                    attrs: {
                                      path: {
                                        name: "planAdd",
                                        query: { id: row.id }
                                      },
                                      text: "创建计划"
                                    }
                                  })
                                : _c(
                                    "a",
                                    { staticClass: "li-btn color-btns" },
                                    [_vm._v("创建计划")]
                                  )
                            ]
                          : [
                              (+row.status === 0 || +row.status === 3) &&
                              _vm.permission("planAddCreatePlan", false) &&
                              _vm.isShowChild
                                ? _c("NewPageOpen", {
                                    attrs: {
                                      path: {
                                        name: "planAdd",
                                        query: { id: row.id }
                                      },
                                      text: "创建计划"
                                    }
                                  })
                                : _c(
                                    "a",
                                    { staticClass: "li-btn color-btns" },
                                    [_vm._v("创建计划")]
                                  )
                            ]
                      ]
                    : [
                        row.quantity !== row.planQuantity &&
                        _vm.permission("planAddCreatePlan", false) &&
                        _vm.isShowChild
                          ? _c("NewPageOpen", {
                              attrs: {
                                path: {
                                  name: "planAdd",
                                  query: { id: row.id }
                                },
                                text: "创建计划"
                              }
                            })
                          : _c("a", { staticClass: "li-btn color-btns" }, [
                              _vm._v("创建计划")
                            ])
                      ],
                  _vm.permission("planAddCreatePlan", false) && row.status !== 2
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  row.status === 0 || row.status === 1 || row.status === 3
                    ? _c(
                        "el-dropdown",
                        {
                          on: {
                            command: function(command) {
                              _vm.more(row, command)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _vm._v(" 更多"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _vm.permission("Modify") && row.status === 0
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "modify" } },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              (_vm.permission("Modify") && row.status === 1) ||
                              row.status === 3
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "singleModify" } },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm.permission("Del") && row.status === 0
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "del" } },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: { title: "确认图纸" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [_c("span", [_vm._v("确认图纸已上传吗？")])]
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "确认BOM" },
          on: { onOk: _vm.submitFormBom },
          model: {
            value: _vm.visibleBom,
            callback: function($$v) {
              _vm.visibleBom = $$v
            },
            expression: "visibleBom"
          }
        },
        [_c("span", [_vm._v("确认BOM已上传吗？")])]
      ),
      _c("edit-materials", { ref: "editMaterials" }),
      _c(
        "MDialog",
        {
          attrs: { title: "修改" },
          on: { onOk: _vm.modifyForm },
          model: {
            value: _vm.showModify,
            callback: function($$v) {
              _vm.showModify = $$v
            },
            expression: "showModify"
          }
        },
        [
          _c(
            "div",
            { staticClass: "dialogItem" },
            [
              _c("span", [_vm._v("交期：")]),
              _c("el-date-picker", {
                staticStyle: { width: "90%" },
                attrs: {
                  type: "date",
                  placeholder: "请选择",
                  "value-format": "yyyy-MM-dd",
                  format: "yyyy-MM-dd"
                },
                model: {
                  value: _vm.deliveryTime,
                  callback: function($$v) {
                    _vm.deliveryTime = $$v
                  },
                  expression: "deliveryTime"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialogItem" },
            [
              _c("span", [_vm._v("数量：")]),
              _c("el-input-number", {
                staticStyle: { width: "90%" },
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  step: 1,
                  "step-strictly": true
                },
                model: {
                  value: _vm.quantity,
                  callback: function($$v) {
                    _vm.quantity = $$v
                  },
                  expression: "quantity"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }