var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "orderList" })
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("创建计划")])
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "detail-card" },
        [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("订单号：" + _vm._s(_vm.detailData.produceOrderNumber))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("物料编码：" + _vm._s(_vm.detailData.materialsCode))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("物料名称：" + _vm._s(_vm.detailData.materialsName))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v(
                    "规格：" + _vm._s(_vm.detailData.materialsSpecifications)
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("单位：" + _vm._s(_vm.detailData.materialsUnit))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("生产订单数量：" + _vm._s(_vm.detailData.quantity))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("已计划数量：" + _vm._s(_vm.detailData.planQuantity))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v(
                    "报工数量：" + _vm._s(_vm.detailData.reportWorkQuantity)
                  )
                ])
              ])
            ],
            1
          ),
          _c("el-divider", { staticClass: "e-divider" }),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-form",
                  attrs: {
                    "label-position": "right",
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "计划数量：", prop: "quantity" }
                            },
                            [
                              _vm.planId === 0
                                ? _c("el-input-number", {
                                    attrs: {
                                      min: 1,
                                      max:
                                        _vm.detailData.quantity -
                                        _vm.detailData.planQuantity,
                                      "controls-position": "right",
                                      step: 1
                                    },
                                    model: {
                                      value: _vm.ruleForm.quantity,
                                      callback: function($$v) {
                                        _vm.$set(_vm.ruleForm, "quantity", $$v)
                                      },
                                      expression: "ruleForm.quantity"
                                    }
                                  })
                                : _c("el-input-number", {
                                    attrs: {
                                      min: 1,
                                      "controls-position": "right",
                                      step: 1
                                    },
                                    model: {
                                      value: _vm.ruleForm.quantity,
                                      callback: function($$v) {
                                        _vm.$set(_vm.ruleForm, "quantity", $$v)
                                      },
                                      expression: "ruleForm.quantity"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "工艺：", prop: "processFlowId" }
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "all-ipt",
                                  model: {
                                    value: _vm.ruleForm.processFlowName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "processFlowName",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.processFlowName"
                                  }
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "append" },
                                    [
                                      _c("el-button", {
                                        attrs: { icon: "el-icon-more" },
                                        on: { click: _vm.selectProcessFlows }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产线：", prop: "areaId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.areaId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "areaId", $$v)
                                    },
                                    expression: "ruleForm.areaId"
                                  }
                                },
                                _vm._l(_vm.areaIds, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "优先级：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.priority,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "priority", $$v)
                                    },
                                    expression: "ruleForm.priority"
                                  }
                                },
                                _vm._l(_vm.priorityStatus, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: index }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "交期：", prop: "deliveryTime" }
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "请选择",
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.ruleForm.deliveryTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "deliveryTime", $$v)
                                  },
                                  expression: "ruleForm.deliveryTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "计划时间：" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetimerange",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  placeholder: "请选择",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  format: "yyyy-MM-dd HH:mm:ss"
                                },
                                model: {
                                  value: _vm.ruleForm.dates,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "dates", $$v)
                                  },
                                  expression: "ruleForm.dates"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.isBatchManager
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "生产批次码",
                                    prop: "batchNumber"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.ruleForm.batchNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "batchNumber",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.batchNumber"
                                    }
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        top: "22px",
                                        right: "0"
                                      },
                                      attrs: { type: "text" },
                                      on: { click: _vm.addBatch }
                                    },
                                    [_vm._v("自动生成")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "order-footer" }, [
        _c("div", { staticClass: "footer-le" }),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v("取消")
            ]),
            _c(
              "el-button",
              {
                attrs: { type: "primary", loading: _vm.saveLoading },
                on: { click: _vm.submitForms }
              },
              [_vm._v("确认并生成计划")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c("SelectProcessFlow", {
        ref: "processFlow",
        attrs: {
          visible: _vm.processFlowVisible,
          "select-process-and-version-ids": _vm.processIdAndVersionId,
          "materials-id": _vm.detailData.materialsId
        },
        on: {
          "update:visible": function($event) {
            _vm.processFlowVisible = $event
          },
          submit: _vm.selectFlow
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }