// 列表
export const columnsAll = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'status',
    label: '状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    minWidth: 120
  },
  {
    prop: 'procedureCode',
    label: '工序编码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '工单编号',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 150,
    slotName: 'produceWorkOrderNumber'
  },
  {
    prop: 'produceOrderNumber',
    label: '订单编号',
    hiddenSearch: true,
    sortable: 'custom',
    slotName: 'produceOrderNumber',
    minWidth: 180
  },
  {
    prop: 'reviewer',
    label: '审核人',
    minWidth: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reviewTime',
    label: '审核时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'creator',
    label: '申请人',
    minWidth: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建日期',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 142
  }
]
