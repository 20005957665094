<template>
  <div class="orderAdd">
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-form
          v-if="['edit','add','addOrder','addModuleOrder'].includes($route.query.type)"
          ref="ruleForm"
          label-position="right"
          :model="ruleForm"
          :rules="rules"
          label-width="135px"
          class="demo-form-inline"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="生产工单编号" prop="productionPlanNumber">
                <el-input
                  v-model="ruleForm.productionPlanNumber"
                  placeholder=""
                  :disabled="['edit','view','addOrder'].includes($route.query.type)"
                />
                <el-button
                  v-if="['add','addModuleOrder'].includes($route.query.type)"
                  style="position:absolute;top: 22px;right:0"
                  type="text"
                  @click="getCode"
                >自动生成
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="工单类型" prop="type">
                <el-select v-model="ruleForm.type" placeholder="请选择" @change="changeWorkType">
                  <el-option
                    v-for="item in workType"
                    :key="item.key"
                    :label="item.name"
                    :value="item.key"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="产出物料" prop="materialsId">
                <el-select
                  v-model="ruleForm.materialsId"
                  v-selectScrollEnd="finishedMaterialsLoadMore"
                  filterable
                  remote
                  reserve-keyword
                  :remote-method="remoteFinishedMethod"
                  placeholder="请选择"
                  :disabled="['edit','addModuleOrder'].includes($route.query.type)"
                  @change="selMaterial"
                  clearable>
                  <el-option
                    v-for="item in finishedMaterials"
                    :key="item.id"
                    :label="item.specifications ? item.fullLabel +' / '+ item.specifications : item.fullLabel"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <div style="display:flex;justify-content: flex-end;position: relative">
                <div v-show="ruleForm.type == 1" style="position:absolute;left: 10px;right: 10px;">
                  <el-checkbox v-model="isSingleProcess" style="margin-left:10px" @change="handleSingleProcessChange"
                               :disabled="['edit'].includes($route.query.type)"></el-checkbox>
                  <div style="font-size:12px;margin-top:2px;">单工序</div>
                </div>
                <div :style="{width:ruleForm.type == 1 ? '95%':'100%'}">
                  <el-form-item label="工艺路线" :prop="isSingleProcess ? '': 'processFlowFullName'">
                    <el-cascader
                      ref="cascader"
                      v-model="ruleForm.processFlowFullName"
                      style="width:100%"
                      :options="processFlows"
                      :props="{lazy: true,lazyLoad: loadOptions,label:'processFlowFullName',value:'id'}"
                      @change="handleChange"
                      :disabled="isSingleProcess"
                    />
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <el-form-item label="工单产量" prop="quantity">
                <el-input-number
                  v-model="ruleForm.quantity"
                  controls-position="right"
                  :value="1"
                  :min="0"
                  :step="1"
                  @blur="(e)=>formatNum(e,ruleForm,'quantity',5)"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="工单优先级" prop="priority">
                <el-select v-model="ruleForm.priority" placeholder="请选择">
                  <el-option
                    v-for="item in priorityList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="项目生产节点" prop="projectNode"
                            :rules="{required: isShow, message: '请选择项目生产节点', trigger: 'blur'}">
                <el-cascader
                  ref="myCascader"
                  v-model="ruleForm.projectNode"
                  style="width:100%"
                  :options="projectList"
                  clearable
                  @change="selProject"
                  :props="{lazy: true,lazyLoad: loadProjectOptions,label:'projectName',value:'projectId'}"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="负责人">
                <el-select
                  v-model="ruleForm.principalId"
                  v-selectScrollEnd="userLoadMore"
                  filterable
                  remote
                  reserve-keyword
                  :remote-method="userRemoteMethod"
                  placeholder="请选择"
                  clearable
                  multiple
                  collapse-tags
                  @change="selUser"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.id"
                    :label="item.userFullName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              
              <el-form-item label="业务部门">
                <el-select v-model="ruleForm.businessUnit" placeholder="请选择" clearable>
                  <el-option
                    v-for="item in businessUnitList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-show="ruleForm.type == 1">
              <el-form-item label="上阶工单">
                <el-select
                  v-model="ruleForm.upWorkOrderId"
                  v-selectScrollEnd="moduleMaterialsLoadMore"
                  filterable
                  remote
                  reserve-keyword
                  :remote-method="remoteMethod"
                  placeholder="请选择"
                  clearable
                  @change="selUpWorkOrder"
                >
                  <el-option
                    v-for="item in moduleMaterials"
                    :key="item.producePlanId"
                    :label="item.fullLabel"
                    :value="item.producePlanId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="计划开工时间" prop="planStartTime">
                <el-date-picker
                  v-model="ruleForm.planStartTime"
                  type="datetime"
                  placeholder="计划开工时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  :default-time="'00:00:00'"
                  clearable
                  style="width:100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="计划完工时间" prop="planEndTime">
                <el-date-picker
                  v-model="ruleForm.planEndTime"
                  type="datetime"
                  placeholder="计划完工时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  :default-time="'23:59:59'"
                  clearable
                  style="width:100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="备注：">
                <el-input v-model="ruleForm.remark" type="textarea" :rows="1"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form
          v-else
          ref="ruleForm"
          label-position="right"
          :model="ruleForm"
          :rules="rules"
          label-width="138px"
          class="demo-form-inline"
        >
          <!-- 详情 -->
          <el-row type="flex" justify="start" style="flex-flow:row wrap">
            <el-col v-for="item in detailBaseFields" :key="item.key" :span="6">
              <el-form-item :label="item.name">
                <el-button
                  v-if="item.key==='materialsId'"
                  type="text"
                  @click="viewMaterialsDetail(getDetailVal(item.key))"
                >{{ getDetailVal('materialsName') }}
                </el-button>
                <div v-else>{{ getDetailVal(item.key) }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <el-tabs
      v-model="activeName"
      class="detail-card detail-tabs"
      type="border-card"
      @tab-click="tabClick"
    >
      <el-tab-pane label="工艺路线" name="1">
        <div>
          <div class="detail-card">
            <div class="card-tit">基本信息</div>
            <div class="card-bot">
              <el-form label-position="right" label-width="110px" class="demo-form-inline">
                <!-- 详情 -->
                <el-row>
                  <el-col v-for="item in columnsBase" :key="item.label" :span="6">
                    <el-form-item :label="item.label+'：'">
                      <div>{{ getValue(item) }}</div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
          <div class="detail-card">
            <div class="card-tit">工艺路线图</div>
            <div class="card-bot process-card">
              <process-flow
                ref="processFlow"
                :base-form="{form:baseFormData}"
                :read-only="true"
                :process-flow-detail="processFlowVersion"
              />
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="['edit','view'].includes($route.query.type)" label="生产任务" name="2">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="搜索工序定位码/编号/名称"
          style="padding: 10px 0 10px 0;"
          class="func-header"
          :tags="functionHeader.tags"
          @search="$refs.taskTable.setTableData(true)"
        >
          <div style="margin-bottom: 10px">
            <el-button type="primary" size="small" @click="batchEdit(1)" v-show="!isEdit">批量编辑</el-button>
            <el-button v-show="isEdit" size="small" @click="batchEdit(0)" style="margin-left: 0px;">取消</el-button>
            <el-button v-show="isEdit" type="primary" size="small" @click="batchSave()">保存</el-button>
            <el-button type="primary" size="small" @click="allExport()">全部导出</el-button>
          </div>
        </FunctionHeader>
        <MTable
          ref="taskTable"
          :columns="productionTaskColumn"
          :height="500"
          :set-data-method="getTaskData"
        >
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="productionStatus" slot-scope="{row }">
            {{ row.productionStatus === 2 ? '已完成' : row.productionStatus === 1 ? '进行中' : '待开始' }}
          </div>
          <NewPageOpen
            slot="materialsCode"
            slot-scope="{ row }"
            :path="{ name: 'materialsDetail', query: {id: row.materialsId}}"
            :text="row.materialsCode"
          />
          <NewPageOpen
            slot="procedureCode"
            slot-scope="{ row }"
            :path="{ name: 'procedureDetail', query: {id: row.procedureId}}"
            :text="row.procedureCode"
          />
          <template slot="planOutputQuantity" slot-scope="{ row }">
            <div>{{ row.planOutputQuantity }}</div>
          </template>
          <template slot="planTime" slot-scope="{ row }">
            <div v-if="!isEdit">{{ row.planTime[0] || row.planTime[1] ? row.planTime.join(' 至 ') : '-' }}</div>
            <el-date-picker
              v-else
              v-model="row.planTime"
              type="daterange"
              :clearable="false"
              range-separator="至"
              start-placeholder="计划开工时间"
              end-placeholder="完工时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
            />
          </template>
          <div slot="executorName" slot-scope="{row }">
            {{ row.userList.length ? row.userList.map(item => item.name).join(',') :'-' }}
         </div>
          <template slot="remark" slot-scope="{ row }">
            <div v-if="!isEdit">{{ row.remark }}</div>
            <el-input v-else v-model="row.remark"/>
          </template>

          <template slot="action" slot-scope="{ row }">
            <el-button type="text" @click="onReportLog(row.id)">报工记录</el-button>
          </template>
        </MTable>
      </el-tab-pane>
      <el-tab-pane v-if="['edit','view'].includes($route.query.type)" label="报工记录" name="3">
        <MTable ref="mTable" :columns="logColumns" :height="300" :set-data-method="getReportLog">
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="userType" slot-scope="{row }">{{ row.userType === 0 ? '正式' : '外包' }}
          </div>
          <div slot="planTime" slot-scope="{row }">{{ row.planStartTime }} 至 {{ row.planEndTime }}
          </div>
        </MTable>
      </el-tab-pane>
      <el-tab-pane v-if="['edit','view'].includes($route.query.type)" label="质检项目" name="4">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="请输入定位码/检验项目"
          :tags="functionHeader.tags"
          @search="searchInspectEntity(functionHeader.searchVal)"
        >
        </FunctionHeader>
        <div class="m-table">
          <el-table :data="BOMList" :show-header="true">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.inspectEntryList" class="ellipsis-table">
                  <el-table-column prop="index" label="序号" width="80">
                    <template slot-scope="scope">
                      {{ scope.$index + 1 }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="functionCode" label="功能码"></el-table-column>
                  <el-table-column prop="inspectionEntryName" label="检验项目"></el-table-column>
                  <el-table-column
                    prop="inspectionStandard"
                    label="检验标准"
                    width="200"
                  ></el-table-column>
                  <el-table-column prop="inspectionMethod" label="检测方法"></el-table-column>

                  <el-table-column prop="parameterType" label="检测值类型">
                    <template slot-scope="scope">
                      {{ scope.row.parameterType | parameterTypeName }}
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="defaultValue" label="默认值"></el-table-column>
                  <el-table-column prop="oossiblevalue" label="可能值"></el-table-column>
                  <el-table-column prop="standardalue:" label="标准值"></el-table-column>

                  <el-table-column prop="upperLimit" label="上偏差"></el-table-column>
                  <el-table-column prop="lowerLimit" label="下偏差"></el-table-column>
                  <el-table-column prop="limitType" label="偏差类型"></el-table-column> -->
                  <el-table-column prop="actualValue1" label="自检实测值"></el-table-column>
                  <el-table-column prop="actualValue2" label="专检实测值"></el-table-column>
                  <el-table-column prop="dutyUser" label="生产人"></el-table-column>
                  <el-table-column prop="dutyTime" label="生产时间"></el-table-column>
                  <el-table-column prop="checkUser" label="检验人"></el-table-column>
                  <el-table-column prop="checkTime" label="检验时间"></el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="serialsCode" label="工序定位码"></el-table-column>
            <el-table-column prop="procedureName" label="工序名称"></el-table-column>
          </el-table>
        </div>

      </el-tab-pane>
      <el-tab-pane v-if="['edit','view'].includes($route.query.type) && gdqtShow" :label="`工单齐套（${completeRate}%）`"
                   name="5">
        <div style="display: flex;justify-content: flex-end;margin-bottom: 10px;font-size: 14px;">
          最近查询时间：{{ queryAt }}
        </div>
        <MTable ref="mTable" :columns="completeRateColumns" :height="300" :set-data-method="getCompleteRateList">
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
        </MTable>
      </el-tab-pane>
      <el-tab-pane v-if="['edit','view'].includes($route.query.type) && gxwlShow" :label="`工序物料`" name="6">
        <div class="">
          <FunctionHeader2
            ref="functionHeader"
            v-model="functionProcessHeader.searchVal"
            :tags="functionProcessHeader.tags"
            search-title="请输入物料名称/编号/规格"
            :export-name="functionProcessHeader.exportName"
            :export-params="functionProcessHeader.exportParams"
            @search="getProcedureMaterialsListPage()"
          >
            <el-select v-model="processId" clearable placeholder="选择工序" @change="selectProcess"
                       style="margin-left:10px">
              <el-option
                v-for="item in processList"
                :key="item.procedureId"
                :label="item.procedureFullName"
                :value="item.procedureId"
              />
            </el-select>
          </FunctionHeader2>
          <div class="m-table">
            <el-table ref="table" :data="dataProcess" :show-header="true" :height="tableHeight"
                      :default-expand-all="isExpand">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-table :ref="`expandTableRef${props.$index}`" :data="props.row.children"
                            class="ellipsis-table" row-key="id"
                            @selection-change="(data) => handleSelectionChange(data, props.$index)">
                    <el-table-column  :reserve-selection="true" :selectable="selectable">
                    </el-table-column>
                    <el-table-column prop="index" label="序号">
                      <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="materialsType" label="物料类型">
                      <template slot-scope="scope">
                        {{ scope.row.materialsType }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="materialsCode" label="物料编号"></el-table-column>
                    <el-table-column prop="materialsName" label="物料名称" width="200"></el-table-column>
                    <el-table-column prop="specifications" label="物料规格"></el-table-column>

                    <el-table-column prop="isKeyMaterials" label="是否关键物料">
                      <template slot-scope="scope">
                        {{ scope.row.isKeyMaterials ? '是' : '否' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="mainUnitName" label="主单位"></el-table-column>
                    <el-table-column prop="requirementQuantity" label="需求数量"></el-table-column>
                    <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>

                    <el-table-column prop="notReceiveQuantity" label="未领数量"></el-table-column>
                    <el-table-column prop="appliedUnissuedQuantity" label="申请未发数量"></el-table-column>
                    <el-table-column v-if="ruleForm.status != 1" prop="stockQuantity" label="库存数"></el-table-column>
                    <el-table-column v-if="ruleForm.status != 1" prop="allocatedStockQuantity" label="分配数"></el-table-column>

                    <el-table-column prop="requireDate" label="需求日期"></el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column prop="procedureName" label="工序名称"></el-table-column>
              <el-table-column prop="procedureLocationCode" label="工序定位码"></el-table-column>
              <el-table-column prop="requirementQuantity" label="需求数量"></el-table-column>
              <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>
              <el-table-column prop="notReceiveQuantity" label="未领数量"></el-table-column>
              <el-table-column v-if="ruleForm.status != 1" prop="allocatedStockQuantity" label="分配数"></el-table-column>
              <el-table-column prop="supplyQuantity" label="供应数"></el-table-column>
              <el-table-column prop="sitCompleteRate" label="线边齐套率">
                <template slot-scope="scope">
                  {{ scope.row.sitCompleteRate}}%
                </template>
              </el-table-column>
              <el-table-column prop="completeRate" label="齐套率">
                <template slot-scope="scope">
                  {{ scope.row.completeRate}}%
                </template>
              </el-table-column>
              <el-table-column prop="keyMaterialsRequirementQuantity" label="关键料数量"></el-table-column>
              <el-table-column prop="keyMaterialsSitCompleteRate" label="关键料线边齐套率">
                <template slot-scope="scope">
                  {{ scope.row.keyMaterialsSitCompleteRate}}%
                </template>
              </el-table-column>
              <el-table-column prop="keyMaterialsCompleteRate" label="关键料齐套率">
                <template slot-scope="scope">
                  {{ scope.row.keyMaterialsCompleteRate}}%
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="page-container" style="text-align: end;padding-right: 15px">
            <el-pagination
              :current-page="page.currentPage"
              :page-sizes="page.pageSizes"
              :page-size="page.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>

      </el-tab-pane>
      <el-tab-pane v-if="['edit','view'].includes($route.query.type) && gdwlShow" :label="`工单物料`" name="7">

        <div class="bottom">
          <FunctionHeader2
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            search-title="请输入名称/编号/规格"
            :tags="functionHeader.tags"
            :export-params="functionHeader.exportParams"
            @search="$refs.mTable.setTableData(true)"
          >
          </FunctionHeader2>
          <MTable ref="mTable" :columns="columnsWork" :set-data-method="getWorkTableData"
                  @selection-change="selectionChange" :columns-setting="false">
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="materialsType" slot-scope="{ row }">
              <div>{{ row.materialsType }}</div>
            </div>
            <div slot="completeRate" slot-scope="{ row }">{{ row.completeRate }}%</div>
            <div slot="isKeyMaterials" slot-scope="{ row }">{{ row.isKeyMaterials === 1 ? '是': '否' }}</div>
          </MTable>

        </div>

      </el-tab-pane>


    </el-tabs>
    <div class="order-footer">
      <div/>
      <div class="footer-ri">
        <el-button @click="returnList">{{ this.type !== 'view' ? '取 消' : '返 回' }}</el-button>
        <el-button v-if="this.type!=='view'" type="primary" :loading="buttonLoading" @click="save">确 定</el-button>
      </div>
    </div>
    <div class="lines"/>
    <!-- 指派任务-->
    <MDialog v-model="logVisible" :confirm-btn="false" title="报工记录">
      <MTable
        ref="mTable"
        :columns="logItemColumns"
        :height="500"
        :set-data-method="getReportItemLog"
      >
        <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
        <div slot="userType" slot-scope="{row }">{{ row.userType === 0 ? '正式' : '外包' }}
        </div>
        <div slot="planTime" slot-scope="{row }">{{ row.planStartTime }} 至 {{ row.planEndTime }}
        </div>
      </MTable>
    </MDialog>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import api from '@/api/production/prod-plan'
import api2 from '@/api/information/materials-require'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import processApi from '@/api/information/production/process'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import papi from '@/api/production/project'
import {
  detailBaseFields,
  productionTaskColumn,
  divisionOptions,
  divisionOptionsMaps,
  logItemColumns,
  logColumns,
  insepctionProjectColumns,
  workType,
  executionDepartment,
  workTypeMaps,
  executionDepartmentMaps,
  progressStatusMaps,
  workStatusMaps,
  completeRateColumns
} from './data'
import {base} from '@/views/information/production/process/detail/columns'
import FunctionHeader from '@/components/FunctionHeader'
import FunctionHeader2 from '@/components/FunctionHeader/index'
import {inspectionNewItemType} from '@/config/options.config'
import {columnsOccupyMaterials, columnsWork} from '@/views/information/materials-require/detail/columns'
import projectApi from '@/api/projectManagement/project'

const validateProcess = (rule, value, callback) => {
  if (value?.length < 2) {
    callback(new Error('请选择工艺路线!'))
  } else {
    callback()
  }
}
export default {
  name: 'ProdPlanCreate',
  components: {ProcessFlow, NewPageOpen, FunctionHeader, FunctionHeader2},
  filters: {
    parameterTypeName(val) {
      return inspectionNewItemType.find((item) => item.id === val).name
    }
  },
  data() {
    return {
      columnsWork: [
        {
          prop: 'index',
          label: '序号',
          sortable: true,
          minWidth: 80,
          hiddenSearch: true,
          slotName: 'index'
        },
        {
          prop: 'materialsType',
          label: '物料类型',
          sortable: false,
          hiddenSearch: true,
          minWidth: 120
        },
        {
          prop: 'materialsCode',
          label: '物料编码',
          sortable: false,
          hiddenSearch: true,
          minWidth: 150
        },
        {
          prop: 'materialsName',
          label: '物料名称',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'specifications',
          label: '物料规格',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'mainUnitName',
          label: '主单位',
          sortable: false,
          hiddenSearch: true,
          minWidth: 100
        },
        {
          prop: 'requirementQuantity',
          label: '需求数量',
          sortable: false,
          hiddenSearch: true,
          minWidth: 100
        },
        {
          prop: 'receivedQuantity',
          label: '已领数量',
          sortable: false,
          hiddenSearch: true,
          minWidth: 100
        },
        {
          prop: 'notReceiveQuantity',
          label: '未领数量',
          sortable: false,
          hiddenSearch: true,
          minWidth: 100
        },
        {
          prop: 'appliedUnissuedQuantity',
          label: '申请未发数量',
          sortable: false,
          hiddenSearch: true,
          minWidth: 120
        },
        {
          prop: 'supplyQuantity',
          label: '供应数',
          sortable: false,
          hiddenSearch: true,
          minWidth: 120
        },
        {
          prop: 'stockQuantity',
          label: '库存数',
          sortable: false,
          hiddenSearch: true,
          minWidth: 120
        },
        {
          prop: 'allocatedStockQuantity',
          label: '分配数',
          sortable: false,
          hiddenSearch: true,
          minWidth: 120
        },
        {
          prop: 'completeRate',
          label: '齐套率',
          sortable: false,
          hiddenSearch: true,
          slotName:'completeRate',
          minWidth: 120
        },
        {
          prop: 'isKeyMaterials',
          label: '是否关键料',
          sortable: false,
          hiddenSearch: true,
          slotName:'isKeyMaterials',
          minWidth: 120
        },
        {
          prop: 'requireDate',
          label: '需求日期',
          sortable: false,
          hiddenSearch: true,
          minWidth: 120
        }
      ],
      functionProcessHeader: {
        searchVal: '',
        importName: '',
        exportName: '',
        exportParams: {},
        tags: [],
      },
      gdqtShow: false,
      gxwlShow: false,
      gdwlShow: false,
      isShow: true,
      logColumns,
      completeRateColumns,
      insepctionProjectColumns,
      logVisible: false,
      divisionOptions,
      divisionOptionsMaps,
      productionTaskColumn,
      logItemColumns,
      workType,
      workTypeMaps,
      progressStatusMaps,
      workStatusMaps,
      executionDepartment,
      executionDepartmentMaps,
      activeName: '1',
      loading: false,
      finishedMaterials: [],
      moduleMaterials: [],
      userList: [],
      materialParams: {status: 0, page: 1, size: 20, search: '', materialsId: ''},
      moduleMaterialParams: {page: 1, size: 20, search: '', producePlanId: ''},
      userParams: {page: 1, size: 20, search: '', userIds: ''},
      projectParams: {search: ''},
      areaList: [],
      processFlows: [],
      BOMList: [],
      BOMList2: [],
      processFlowVersionAll: {},
      ruleForm: {
        productionPlanNumber: '',
        materialsId: '',
        quantity: '',
        processFlowFullName: '',
        priority: '',
        planTime: '',
        type: '',
        executiveUnit: '',
        businessUnit: null,
        upWorkOrderId: '',
        singleProcedure: '',
        principalId: [],
        remark: '',
        planStartTime: '',
        planEndTime: '',
        project: '',
        projectId: '',
        planAssemblyCompletionTime: '',
        deliveryTime: '',
        projectNode: ''
      },
      isSingleProcess: false,
      rules: {
        productionPlanNumber: [
          {required: true, message: '请输入工单编号', trigger: 'blur'}
        ],
        materialsId: [
          {required: true, message: '请选择产出物料', trigger: 'blur'}
        ],
        processFlowFullName: [
          {required: true, validator: validateProcess, trigger: 'blur'}
        ],
        quantity: [
          {required: true, message: '请输入需求量', trigger: 'blur'}
        ],
        priority: [
          {required: true, message: '请选择优先级', trigger: 'blur'}
        ],
        planStartTime: [
          {required: true, message: '请选择计划开工时间', trigger: 'blur'}
        ],
        planEndTime: [
          {required: true, message: '请选择计划完工时间', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择工单类型', trigger: 'blur'}
        ]
      },
      baseFormData: {},
      processFlowVersion: {},
      columnsBase: base,
      detailBaseFields,
      hasUpWorkOrder: false,
      projectList: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: true,
            tagName: 'el-button',
            props: {type: 'primary', size: 'small'},
            style: {width: 'auto', background: '#607FFF'},
            innerText: '全部导出',
            on: {
              click: () => this.export()
            }
          }
        ],
        exportName: 'exportProductionPlanInspectionEntity',
        exportParams: {}
      },
      userInfo: {},
      isEdit: false,
      taskData: [],
      completeRate: 0,
      queryAt: '-',
      buttonLoading: false,
      occupyList: [],
      selectionDatas: [],
      originProcessSelectionDatas: [],
      processSelectionDatas: [],
      columnsOccupyMaterials,
      moduleList: [],
      processList: [],
      dataProcess: [],
      page: {
        default: 20,
        pageSize: 20,
        pageSizes: [10, 20, 30, 50, 100, 150, 200],
        total: 0,
        currentPage: 1
      },
      processId: '',
      moduleId: '',
      isChooseProcess: false,
      processFlowId: '',
      processFlowVersionId: '',
      isChooseModule: false,
      isExpand: false,
      params: {},
      businessUnitList:[]
    }
  },
  computed: {
    priorityList() {
      return this.$store.state.user.priorityList.map((item, idx) => {
        return {name: item.name, id: idx}
      })
    },
    priorityMap() {
      return this.$store.state.user.priorityList.reduce((pre, cur, idx) => ({...pre, [idx]: cur.name}), {})
    },
    tableHeight() {
      let {height} = this.$attrs
      const maxHeight = this.$attrs['max-height'] || this.$attrs.maxHeight
      if (maxHeight) return null
      if (!height) height = this.$store.state.app.contentHeight - 56 - 44 - 12 - 37 - 40 - 25 - 200
      return this.showPage ? height - 44 : height - 5
    }
  },
  async created() {
    this.params = this.$route.query
    // 判断是否有工单齐套的权限(目前只有诺倬力有)
    const autList = [
      ...JSON.parse(JSON.parse(sessionStorage.userInfo).authority).checked,
      ...JSON.parse(JSON.parse(sessionStorage.userInfo).authority).half
    ]
    if (autList.includes(this.$route.name + 'gdqt')) {
      this.gdqtShow = true
    }
    if (autList.includes(this.$route.name + 'gxwl')) {
      this.gxwlShow = true
    }
    if (autList.includes(this.$route.name + 'gdwl')) {
      this.gdwlShow = true
    }

    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    this.type = this.$route.query.type
    this.getBusinessUnitList()
    switch (this.type) {
      case 'edit':
      case 'view':
        await this.getDetail()
        this.getListProcessFlows(this.ruleForm.materialsId)
        this.materialParams = {status: 0, page: 1, size: 20, search: '', materialsId: this.ruleForm.materialsId}
        this.moduleMaterialParams = {page: 1, size: 20, search: '', producePlanId: this.ruleForm.upWorkOrderId}
        this.userParams = {
          page: 1,
          size: 20,
          search: '',
          userIds: this.ruleForm.principalId.length ? this.ruleForm.principalId.join(',') : ''
        }
        this.projectParams = {search: ''}
        this.getFinishedMaterials(this.materialParams)
        this.getUserList(this.userParams)
        this.getModulesMaterials(this.moduleMaterialParams)
        await this.getProjectList(this.projectParams)
        this.getInspectEntity()
        this.getProjectNode()
        break
      case 'add':
        this.getFinishedMaterials(this.materialParams)
        // 获取负责人
        this.getUserList(this.userParams)
        this.getProjectList(this.projectParams)
        this.ruleForm.type = 0
        this.ruleForm.priority = 2
        break
      case 'addModuleOrder':
        const row = JSON.parse(this.$route.query.row)
        const {quantity, type, materialsId, upWorkOrderId} = row
        if (type === 1) {
          this.materialParams.status = 1
          this.getModulesMaterials({...this.moduleMaterialParams, producePlanId: upWorkOrderId})
          this.isShow = false
        } else {
          this.materialParams.status = 0
          this.isShow = true
        }
        if (upWorkOrderId) this.hasUpWorkOrder = true
        this.getFinishedMaterials({...this.materialParams, materialsId: materialsId})
        this.ruleForm = {...this.ruleForm, type, quantity, materialsId, upWorkOrderId}
        this.getListProcessFlows(materialsId)
        // 获取负责人
        this.getUserList(this.userParams)
        this.getProjectList(this.projectParams)
        break
      case 'addOrder': {
        const row = JSON.parse(this.$route.query.row)
        const {
          productionPlanNumber,
          businessUnit,
          materialsId,
          type,
          projectId,
          planStartTime,
          planEndTime,
          planAssemblyCompletionTime,
          deliveryTime,
          principalId,
          quantity,
          planNodeId
        } = row
        this.materialParams.status = 1
        if (type === 1) {
          this.isShow = false
        } else {
          this.isShow = true
        }
        this.getFinishedMaterials({...this.materialParams, materialsId: materialsId})
        this.getUserList({...this.userParams, userIds: principalId})

        this.ruleForm = {
          ...this.ruleForm,
          productionPlanNumber,
          businessUnit,
          materialsId,
          type,
          projectId,
          planStartTime,
          planEndTime,
          planAssemblyCompletionTime,
          deliveryTime,
          principalId,
          quantity,
          planNodeId,
          projectNode: [projectId, planNodeId]
        }
        this.ruleForm.type = 0
        this.ruleForm.priority = 2
        this.getListProcessFlows(materialsId)
        await this.getProjectList({...this.projectParams})
        this.getProjectNode()
        break
      }
      default:
        break
    }
    if(this.params.type === 'view' || this.params.type === 'edit'){
      await this.getProcedureMaterialsListPage()
      await this.getProcessList()
      await this.getModuleList()
    }

    //处理结案某些字段隐藏 ————————Start————————
    if(this.ruleForm.status === 1){
      const noShowArr = ['stockQuantity','allocatedStockQuantity']
      const orginColumns = JSON.parse(JSON.stringify(this.columnsWork))
      orginColumns.forEach( p => {
        if(noShowArr.includes(p.prop)){
          p.oneFlagT = true
        }
      })
      this.$set(this,'columnsWork', orginColumns)
    }
    //处理结案某些字段隐藏 ————————End————————
  },
  methods: {
    tabClick() {

    },
      // 业务部门
      async getBusinessUnitList() {
      const res = await projectApi.getBusinessUnitList()
      if (res) {
          this.businessUnitList = res
      }
    },
    selectionChange(data) {
      this.selectionDatas = data
      this.occupyList = data
    },
    async getWorkTableData(condition, callback) {
      const {page, search, order} = condition
      const searchCondition = {
        receiptId: this.params.id,
        receiptType: 0,
        search: this.functionHeader.searchVal
      }
      this.functionHeader.exportParams = searchCondition
      const res = await api2.getWorkOrderListPage({
        page: page.currentPage,
        size: page.pageSize,
        ...searchCondition
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getProcedureMaterialsListPage()
    },
    // 当前页变化
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.getProcedureMaterialsListPage()
    },
    // 获取工序下拉列表
    async getProcessList() {
      const res = await api2.getProcessList({receiptId: this.params.id})
      if (res) {
        this.processList = res
      }
    },
    // 获取模组下拉列表
    async getModuleList() {
      // let processCondition = {}
      // if (this.isChooseProcess) {
      //   processCondition = {
      //     procedureId: this.processId,
      //     procedureLocationCode: this.procedureLocationCode
      //   }
      // }
      // const res = await api2.getModuleList({
      //   processFlowId: this.processFlowId,
      //   processFlowVersionId: this.processFlowVersionId, ...processCondition
      // })
      // if (res) {
      //   this.moduleList = res
      // }
    },
    // 选择工序
    async selectProcess(val) {
      this.isChooseProcess = val
      this.moduleList = []
      this.moduleId = []
      this.getModuleList()
      await this.getProcedureMaterialsListPage()
      if (val || this.isChooseModule) {
        this.isExpand = true
      } else {
        this.isExpand = false
      }
      this.toggleRowExpansionForAll(this.dataProcess, this.isExpand)
    },
    // 递归展开收起
    toggleRowExpansionForAll(data, isExpansion) {
      data.forEach(item => {
        this.$refs.table.toggleRowExpansion(item, isExpansion)
        if (item.children) {
          this.toggleRowExpansionForAll(item.children, isExpansion)
        }
      })
    },
    // 选择模组
    // async selectModule(val) {
    //   this.isChooseModule = val.length
    //   await this.getProcedureMaterialsListPage()
    //   if (this.isChooseModule || this.isChooseProcess) {
    //     this.isExpand = true
    //   } else {
    //     this.isExpand = false
    //   }
    //   this.toggleRowExpansionForAll(this.dataProcess, this.isExpand)
    //   if (this.isChooseModule) {
    //
    //     this.dataProcess.forEach((item, index) => {
    //       let expandTable = 'expandTableRef' + index
    //       item.children.forEach((items, indexs) => {
    //         this.$nextTick(() => {
    //           if (!items.selectable) {
    //             this.$refs[expandTable].toggleRowSelection(items, true)
    //           }
    //         })
    //       })
    //     })
    //   } else {
    //     this.processSelectionDatas = []
    //   }
    // },
    async getProcedureMaterialsListPage() {
      const searchCondition = {
        receiptId: this.params.id,
        receiptType: 0,
        search: this.functionProcessHeader.searchVal
      }
      let processCondition = {}
      let moduleCondition = {}
      if (this.isChooseProcess) {
        processCondition = {
          procedureId: this.processId,
          procedureLocationCode: this.procedureLocationCode
        }
      }
      if (this.isChooseModule) {
        moduleCondition = {
          moduleMaterialsIds: this.moduleId.join(',')
        }
      }

      this.functionProcessHeader.exportParams = searchCondition
      const res = await api2.getProcedureMaterialsListPage(
        {
          page: this.page.currentPage,
          size: this.page.pageSize,
          ...searchCondition,
          ...processCondition,
          ...moduleCondition
        })
      if (res) {
        this.dataProcess = res.records.map((item) => {
          return {
            ...item,
            children: item.children.map((items) => {
              return {
                ...items,
                selectable: items.notReceiveQuantity - items.appliedUnissuedQuantity > 0 ? false : true
              }
            })
          }
        })
        this.page.total = res.total
      }
    },
    getProjectNode() {
      const currentData = this.projectList.find(item => item.projectId === this.ruleForm.projectNode[0])
      if (currentData) {
        const nodes = Object.keys(currentData.planNodeList).length ? currentData.planNodeList.map(item => ({
          projectId: item.id,
          projectName: item.planName,
          leaf: true
        })) : []
        this.$set(currentData, 'children', nodes)
      }
    },
    async allExport() {
      var params = {
        procedure: this.functionHeader.searchVal,
        productionPlanId: this.$route.query.id
      }
      const res = await papi.exportProductionTask(params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
    },
    async export() {
      var params = {
        id: this.$route.query.id
      }
      const res = await api.exportProductionPlanInspectionEntity(params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `工单质检项目-${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    handleSingleProcessChange() {
      this.baseFormData = {}
      this.versionList = []
      this.ruleForm.processFlowFullName = []
      this.processFlowVersion = {}
    },
    changeWorkType(val) {
      this.isSingleProcess = false
      this.finishedMaterials = []
      this.moduleMaterials = []
      if (val === 1) {
        this.materialParams = {status: 1, page: 1, size: 20, search: ''}
        this.moduleMaterialParams = {page: 1, size: 20, search: ''}
        this.getModulesMaterials(this.moduleMaterialParams)
        this.isShow = false
      } else {
        this.materialParams = {status: 0, page: 1, size: 20, search: ''}
        this.isShow = true
      }
      console.log(this.materialParams)
      this.getFinishedMaterials(this.materialParams)
    },
    onReportLog(id) {
      this.productionTaskId = id
      this.logVisible = true
    },
    batchEdit(type) {
      if (type) {
        this.isEdit = true
      } else {
        this.isEdit = false
        this.$refs.taskTable.setTableData(true)
      }
    },
    async batchSave() {
      const nodes = this.taskData.map(item => ({
        id: item.id,
        remark: item.remark,
        planStartTime: item.planTime ? item.planTime[0] : null,
        planEndTime: item.planTime ? item.planTime[1] : null
      }))
      const res = await api.batchUpdatePoductionTask(nodes)
      if (res) {
        this.$message.success('操作成功')
        this.$refs.taskTable.setTableData(true)
        this.isEdit = false
      }
    },
    // 获取生产任务下报工记录
    async getReportItemLog(condition, callback) {
      const {page} = condition
      const res = await api.productionTaskReportWorkList({
        page: page.currentPage,
        limit: page.pageSize,
        productionTaskId: this.productionTaskId
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    // 获取工单下报工记录
    async getReportLog(condition, callback) {
      const {page} = condition
      const res = await api.productionPlanReportWorkList({
        page: page.currentPage,
        limit: page.pageSize,
        productionPlanId: this.$route.query.id
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },

    // 获取质检项目记录
    async getInspectEntity() {

      const res = await api.productionPlanInspectEntity({
        id: this.$route.query.id
      })
      if (res) {
        this.processFlowVersionAll = res
        this.BOMList2 = this.processFlowVersionAll.procedureList
        this.BOMList = JSON.parse(JSON.stringify(this.BOMList2))
      }
    },

    searchInspectEntity(val) {
      if (val.trim() == '') {
        this.BOMList = JSON.parse(JSON.stringify(this.BOMList2))
        return
      }

      var arr = JSON.parse(JSON.stringify(this.BOMList2))

      var res = []
      arr.forEach(element => {
        var flag = false
        if (element.serialsCode == val) {
          flag = true
          res.push(element)
        } else {
          if (element.inspectEntryList != null) {
            var inspectionList = JSON.parse(JSON.stringify(element.inspectEntryList))
            var resItem = JSON.parse(JSON.stringify(element))
            resItem.inspectEntryList = []
            var flag2 = false
            inspectionList.forEach(element2 => {
              if (element2.inspectionEntryName == val) {
                resItem.inspectEntryList.push(element2)
                flag2 = true
              }
            })
            if (flag2) {
              res.push(resItem)
            }
          }
        }
      })
      this.BOMList = res
    },

    async getTaskData(condition, callback) {
      const {page} = condition
      const res = await api.getTaskList({
        procedure: this.functionHeader.searchVal,
        page: page.currentPage,
        size: page.pageSize,
        productionPlanId: this.$route.query.id
      })
      if (res) {
        res.records.forEach(item => {
          item.planTime = [item.planStartTime ? item.planStartTime : '', item.planEndTime ? item.planEndTime : '']
        })
        this.taskData = res.records
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    async getCompleteRateList(condition, callback) {
      if (this.gdqtShow) {
        const {page} = condition
        const res = await api.getCompleteRateListPage({
          page: page.currentPage,
          limit: page.pageSize,
          productionPlanId: this.$route.query.id
        })
        if (res) {
          this.completeRate = res.completeRate
          this.queryAt = res.queryAt
          callback({
            total: res.pageInfo.total,
            content: res.pageInfo.records
          })
        }
        callback()
      }
    },
    formatNum(e, row, key, num = 5) {
      console.log(e.target.value)
      const val = e.target.value + ''
      if (!val.includes('.')) return
      const [val1, val2] = val.split('.')
      row[key] = val1 + '.' + val2.slice(0, num)
    },
    getDetailVal(key) {
      if (key === 'priority') return this.priorityMap[this.ruleForm[key]]
      if (key === 'businessUnit') return this.divisionOptionsMaps[this.ruleForm[key]]
      if (key === 'type') return this.workTypeMaps[this.ruleForm[key]]
      if (key === 'workOrderTempo') return this.progressStatusMaps[this.ruleForm[key]]
      if (key === 'executiveUnit') return this.executionDepartmentMaps[this.ruleForm[key]]
      if (key === 'status') return this.workStatusMaps[this.ruleForm[key]]
      return this.ruleForm[key] || '-'
    },
    // 修改保存质检项目
    onSave() {

    },
    // 选择成品物料触发
    selMaterial(id) {
      this.baseFormData = {}
      this.versionList = []
      this.ruleForm.processFlowFullName = []
      this.processFlowVersion = {}
      if (id) {
        this.getListProcessFlows(id)
      } else {
        this.materialParams.page = 1
        this.materialParams.search = ''
        this.finishedMaterials = []
        this.getFinishedMaterials(this.materialParams)
      }
    },
    // 选择上阶工单
    selUpWorkOrder(id) {
      if (!id) {
        this.moduleMaterialParams.page = 1
        this.moduleMaterialParams.producePlanId = ''
        this.moduleMaterialParams.search = ''
        this.moduleMaterials = []
        this.getModulesMaterials(this.moduleMaterialParams)
      }
    },
    selUser(id) {
      if (!id.length) {
        this.userParams.page = 1
        this.userParams.userIds = ''
        this.userParams.search = ''
        this.userList = []
        this.getUserList(this.userParams)
        this.ruleForm.businessUnit = ''
      }else{
        const currentData = this.userList.find(item=>item.id === id[0])
         if(currentData){
          this.ruleForm.businessUnit = currentData.organizationId
         }
      }
    },
    selProject(val) {
      if (!val.length) {
        this.$refs.myCascader.$refs.panel.checkedValue = [] // 清空选中值
        this.$refs.myCascader.$refs.panel.clearCheckedNodes() // 清空级联选择器选中状态
        this.$refs.myCascader.$refs.panel.activePath = [] // 清除高亮
        this.$refs.myCascader.$refs.panel.syncActivePath() // 初始化（只展示一级节点）
        // this.projectParams.page = 1
        // this.projectParams.projectId = ''
        // this.projectParams.search = ''
        // this.projectList = []
        // this.ruleForm.deliveryTime = ''
        // this.ruleForm.planAssemblyCompletionTime = ''
        // this.ruleForm.planStartTime = ''
        // this.ruleForm.planEndTime = ''
        // this.ruleForm.principalId = []
        // this.userList = []
        // this.getUserList({...this.userParams,userIds:''})
        this.getProjectList(this.projectParams)
      } else {
        // const currentData = this.projectList.find(item=>item.projectId === val[0])
        //  if(currentData){
        //   const { deliveryTime, planAssemblyCompletionTime,planStartTime,planEndTime,principalId } = currentData
        //   this.ruleForm = { ...this.ruleForm, deliveryTime, planAssemblyCompletionTime,planStartTime,planEndTime}
        //   this.ruleForm.principalId = principalId ? principalId.toString().split(',').map(Number):[]
        //   this.userList = []
        //   this.getUserList({...this.userParams,userIds:principalId})
        //  }
      }
    },
    // 获取工序基本信息值
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && !['areas', 'materialsFullName'].includes(row.prop)) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },

    // 产出物料下拉触底分页加载
    finishedMaterialsLoadMore() {
      if (this.finishedMaterials.length === this.materialsTotal) return
      this.materialParams.page++
      this.getFinishedMaterials(this.materialParams)
    },
    //  成品物料下拉触底分页加载
    moduleMaterialsLoadMore() {
      if (this.moduleMaterials.length === this.moduleMaterialsTotal) return
      this.moduleMaterialParams.page++
      this.getModulesMaterials(this.moduleMaterialParams)
    },
    // 用户下拉触底分页加载
    userLoadMore() {
      if (this.userList.length === this.userListTotal) return
      this.userParams.page++
      this.getUserList(this.userParams)
    },
    // 远程搜索产出物料
    remoteFinishedMethod(val) {
      this.finishedMaterials.length = 0
      this.materialParams = {
        page: 1,
        size: 10,
        search: val
      }
      this.getFinishedMaterials(this.materialParams)
    },
    // 远程搜索上阶工单
    remoteMethod(val) {
      this.moduleMaterials.length = 0
      this.moduleMaterialParams = {
        page: 1,
        size: 10,
        search: val
      }
      this.getModulesMaterials(this.moduleMaterialParams)
    },
    // 远程搜索用户
    userRemoteMethod(val) {
      this.userList.length = 0
      this.userParams = {
        page: 1,
        size: 10,
        search: val
      }
      this.getUserList(this.userParams)
    },
    // 获取产出物料
    async getFinishedMaterials(params) {
      const res = await processApi.getFinishedMaterials(params)
      if (res) {
        this.materialsTotal = res.total
        this.finishedMaterials.push(...res.records)
      }
    },
    // 获取成品物料
    async getModulesMaterials(params) {
      const res = await processApi.getModulesMaterials(params)
      if (res) {
        this.moduleMaterialsTotal = res.total
        this.moduleMaterials.push(...res.records)
      }
    },
    // 获取用户下拉列表
    async getUserList(params) {
      const res = await processApi.getUserList(params)
      if (res) {
        this.userListTotal = res.total
        this.userList.push(...res.records)
      }
    },
    // 获取项目列表
    async getProjectList(params) {
      const res = await processApi.getProjectList(params)
      if (res) {
        this.projectList = res
      }
    },
    // 获取详情
    async getDetail() {
      const res = await api.getPlanDetail(this.$route.query.id)
      if (res) {
        const {
          planStartTime,
          principalId,
          planEndTime,
          processFlowId,
          processFlowVersionId,
          projectId,
          planNodeId
        } = res
        this.ruleForm = {
          ...res,
          principalId: principalId ? principalId.split(',').map(Number) : [],
          processFlowFullName: [processFlowId, processFlowVersionId],
          planTime: [planStartTime, planEndTime],
          projectNode: [projectId, planNodeId]
        }
        this.isSingleProcess = res.singleProcedure ? true : false
        this.isShow = res.type === 1 ? false : true
      }
    },

    // 获取记录编号
    async getCode() {
      const res = await api.getProductionPlanNumber()
      if (res) {
        this.ruleForm.productionPlanNumber = res
      }
    },

    // 获取工艺路线
    async getListProcessFlows(materialsId) {

      const res = await api.getListProcessFlows({size: 10000, materialsId})
      if (res) {
        this.processFlows = res.records || []
        setTimeout(async () => {
          if (this.$route.query.id) {
            const flow = this.processFlows.find(item => {
              return item.id === this.ruleForm.processFlowFullName[0]
            })
            const nodes = await this.getVersion(flow.id)
            this.$set(flow, 'children', nodes)
          }
        })
      }
    },

    // 懒加载二级选项
    async loadOptions(node, resolve) {
      if (!this.processFlows.length) return
      const {level, value} = node
      node.children = []
      const res = await api.getProcessFlowDetails(value)
      if (res) {
        const nodes = res.versionList.map(item => ({
          id: item.id,
          processFlowFullName: item.version,
          leaf: level >= 1
        }))
        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        resolve(nodes)
      }
    },
    async loadProjectOptions(node, resolve) {
      if (!this.projectList.length) return
      const {level, value} = node
      node.children = []
      const currentData = this.projectList.find(item => item.projectId === value)
      const nodes = currentData.planNodeList ? currentData.planNodeList.map(item => ({
        projectId: item.id,
        projectName: item.planName,
        leaf: level >= 1
      })) : []
      resolve(nodes)
    },
    // 获取详情之后设置版本默认值需要手动请求下拉选项
    async getVersion(flowId, level = 1) {
      const res = await api.getProcessFlowDetails(flowId)
      if (res) {
        const nodes = res.versionList.map(item => ({
          id: item.id,
          processFlowFullName: item.version,
          leaf: level >= 1
        }))
        this.handleChange([flowId, this.ruleForm.processFlowFullName[1]])
        return nodes
      }
    },

    // 工艺路线
    handleChange(val) {
      this.selectProcessChange(val[0], val[1])
    },

    // 获取工艺详情
    async selectProcessChange(id1, id2) {
      const res = await processApi.getProcessDetail({id: id1})
      if (res) {
        this.baseFormData = res
        this.versionList = res.versionList
        const currentVersion = res.versionList.find(item => item.id === id2)
        if (currentVersion) {
          this.processFlowVersion = this._.cloneDeep(currentVersion)
        } else {
          this.processFlowVersion = res.processFlowVersion
        }
      }
    },

    save() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.buttonLoading = true
          const singleProcedure = this.isSingleProcess ? 1 : 0
          const principalId = this.ruleForm.principalId ? this.ruleForm.principalId.join(',') : ''
          const {
            productionPlanNumber,
            materialsId,
            quantity,
            priority,
            processFlowFullName: [processFlowId, processFlowVersionId],
            projectNode: [projectId, planNodeId],
            planStartTime,
            planEndTime,
            businessUnit,
            deliveryTime,
            type,
            executiveUnit,
            upWorkOrderId,
            remark,
            planAssemblyCompletionTime
          } = this.ruleForm
          const apis = {
            add: api.createPlan,
            addOrder: api.createPlan,
            addModuleOrder: api.createPlan,
            edit: api.modifyPlan
          }
          const types = this.type
          const params = {
            productionPlanNumber,
            materialsId,
            quantity,
            priority,
            processFlowId,
            processFlowVersionId,
            planStartTime,
            planEndTime,
            businessUnit,
            deliveryTime,
            type,
            executiveUnit,
            projectId,
            planNodeId,
            singleProcedure,
            upWorkOrderId,
            principalId,
            remark,
            planAssemblyCompletionTime
          }
          types === 'edit' && (params.id = this.$route.query.id)
          if (!this.ruleForm.projectId && this.ruleForm.type === 1) {
            this.$confirm('未填项目，是否保存?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              const res = await apis[types](params)
              if (res) {
                this.buttonLoading = false
                this.$message.success('保存成功')
                this.$router.push({name: 'prodPlanList'})
              }
            })
          } else {
            const res = await apis[types](params)
            if (res) {
              this.buttonLoading = false
              this.$message.success('保存成功')
              this.$router.push({name: 'prodPlanList'})
            }
          }
        } else {
          return false
        }
      })
    },

    returnList() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .my-col {
  .el-form-item {
    margin-bottom: 2px;

    .el-form-item__label {
      padding-right: 0;
    }
  }
}

::v-deep {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .el-form-item {
    margin-bottom: 20px;
  }

  .el-date-editor .el-range-separator,
  .el-date-editor .el-range__icon {
    line-height: 26px;
  }

  .el-input-number {
    height: 32px;
    line-height: 32px;
    width: 100%;
  }

  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }

  .el-input-number.is-controls-right .el-input-number__increase,
  .el-input-number.is-controls-right .el-input-number__decrease {
    line-height: 15px;
  }

  .el-tag .el-icon-close {
    position: absolute;
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    vertical-align: middle;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}

.el-tags {
  padding: 0 20px 0 10px;
  position: relative;
}

::v-deep .custom-sel {
  div {
    min-width: 100%;
  }
}

::v-deep .el-table {
  .cell {
    display: flex;
    align-items: center;
  }
}

.orderAdd {
  width: 100%;
  height: 100%;
  //  position:relative;
  .input-new-tag {
    width: 130px;
  }

  .button-new-tag {
    height: 32px;
  }

  .detail-card {
    margin-bottom: 20px;
  }

  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }

  ::v-deep .el-select {
    width: 100%;
  }

  .e-btn {
    margin-bottom: 10px;
  }

  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;

    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;

      span {
        margin-right: 45px;
        display: inline-block;
      }
    }

    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
