export const stationColums = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'reportWorkQuantity',
    label: '工单批次数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '拆单数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantity'
  }
]

