
import { transformColumnsToForm } from '@/utils'
const columnsAll = [
  {
    prop: 'taskIds',
    label: '工序',
    hiddenSearch: true,
    sortable: false,
    width: 150,
    slotName: 'isRepair',
    form: { index: 0, colSpan: 24, tagName: 'el-select', required: true, options: [], props: { multiple: true }}
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    form: { index: 1, colSpan: 24, tagName: 'el-input', required: false, props: { type: 'textarea' }}
  }
]
const columns = [
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    form: { index: 0, colSpan: 24, tagName: 'el-input', required: false, props: { type: 'textarea' }}
  }
]
export const dialogFormList = transformColumnsToForm(columnsAll)
export const dialogFormLists = transformColumnsToForm(columns)
