<template>
  <div id="apps">
    <!-- 左中 -->
    <div id="containers-left" ref="myImg" :class="!fullscreen ? 'app-left-full' : showTip ? 'app-left' : 'app-width'">
      <header class="top-bar">
        <el-form :inline="true" size="small" class="gatter-form">
          <div class="schedule-title">排程到产线</div>
          <div class="form-li">
            <div class="common-date">
              <div class="today" @click="nowMonth">今天</div>
              <img src="@/assets/plans/left-arro.png" @click="addMonth(2)">
              <div class="date-tit">{{ fixTime(commonDate) }}</div>
              <img src="@/assets/plans/right-arro.png" @click="addMonth(1)">
            </div>
          </div>
          <div class="form-ri">
            <el-form-item label="">
              <el-select
                v-model="scale"
                style="width: 250px"
                size="small"
                @change="selectScale"
              >
                <el-option
                  v-for="item in scaleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </header>
      <div ref="container" class="containers">
        <v-gantt-chart
          v-loading="loading"
          :start-time="times[0]"
          :end-time="times[1]"
          :cell-width="cellWidth"
          :cell-height="cellHeight"
          :time-lines="timeLines"
          :title-height="titleHeight"
          :scale="scale"
          :title-width="titleWidth"
          show-current-time
          :hide-header="hideHeader"
          :data-key="dataKey"
          :array-keys="arrayKeys"
          :scroll-to-postion="positionA"
          :datas="datasA"
          :scroll-to-time="scrollToTime"
          @scrollLeft="scrollLeftA"
        >
          <!-- 原来的格式 -->
          <template v-slot:block="{ data, item , getPositonOffset, getWidthAbout2Times }">
            <Test
              :data="data"
              :update-time-lines="updateTimeLines"
              :cell-height="cellHeight"
              :current-time="currentTime"
              :item="item"
              :get-positon-offset="getPositonOffset"
              :get-width-about2-times="getWidthAbout2Times"
              @modifys="modify"
            />
          </template>

          <template v-slot:title>
            <div class="common-header">
              <div class="header-le">
                <div class="le-img" :style="{background: showBtn ? '' : '#F8F8F8'}" @click="changeSpread">
                  <el-tooltip v-if="showBtn" class="item" content="仅展示所有任务的产线" placement="top">
                    <img class="ri-img" src="@/assets/plans/down-arro.png">
                  </el-tooltip>
                  <img v-else class="ri-img" src="@/assets/plans/up-arro.png" alt="">
                </div>
                <el-popover
                  ref="popover"
                  v-model="showPopover"
                  :width="215"
                  :offset="20"
                  style="padding: 12p 12px 0"
                  @hide="show = true"
                >
                  <div class="popover-content-top">
                    <el-button @click="cancel">取消</el-button>
                    <el-button @click="saveColumns">确定</el-button>
                  </div>
                  <div class="popover-content-center">
                    <el-checkbox v-model="allChecked" @change="selectAllColumns">全选</el-checkbox>
                    <el-button class="invert" type="text" @click="invertSelectColumns">
                      <img src="@/assets/table_filter_images/fanxuan@2x.png" alt="反选" width="13px">
                      <span>反选</span>
                    </el-button>
                  </div>
                  <div class="popover-content-footer" :style="{maxHeight: popoverContentMaxHeight + 'px'}">
                    <el-checkbox-group v-model="checkedList" class="check-wrapper">
                      <el-checkbox v-for="(item, index) in settingColumns" :key="index" :label="item.id">{{ item.parentArea }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-popover>
                <el-button ref="columnsSettingBtn" v-popover:popover type="text" style="padding: 0">
                  <div class="showMore" :style="{background: show ? '' : '#F8F8F8'}" @click="changeMore">
                    <span>筛选产线</span>
                    <i v-if="show" class="el-icon-caret-bottom icons" />
                    <i v-else class="el-icon-caret-top" />
                  </div>
                </el-button>
              </div>

              <div class="header-ri" @click="toggleFullScreen" >
                <el-tooltip class="item" effect="dark" content="放大" placement="top" v-if="fullscreen">
                   <img class="ri-img" src="@/assets/narrow-amplification/amplification.png" alt="">
                </el-tooltip>
                <el-tooltip v-if="!fullscreen" class="item" content="缩小" placement="top">
                  <img class="ri-img" src="@/assets/narrow-amplification/narrow.png">
                </el-tooltip>
              </div>
            </div>
          </template>
          <template v-slot:left="{ data }">
            <TestLeft :data="data" @selectLine="selectLine" />
          </template>
        </v-gantt-chart>
        <div class="tip-img" @click="selectImg">
          <img v-if="showTip" src="@/assets/plans/spread.png">
          <img v-else src="@/assets/plans/retract.png" alt="">
        </div>
      </div>

    </div>
    <!-- 右边栏 -->
    <div class="app-right">
      <schedule-right v-if="showTip" ref="schedules" :area-id="areaId" :area-name="areaName" @modify="modify" />
    </div>
    <!-- 修改计划 -->
    <MDialog
      v-model="dialogVisible"
      title="修改计划"
      width="80%"
      @onOk="dialogSubmit"
    >
      <div class="edit-form">
        <div v-if="dialogFormData.status === 0 || dialogFormData.status === 2 && isStatus" class="form-btn">
          <div class="li-btn" @click="fixWorkOrder(dialogFormData)">下发至工单</div>
        </div>
        <div class="form-top">
          <base-data :detail-data="dialogFormData" :is-show="dialogFormData.status === 0 || dialogFormData.status === 2 && isStatus ? false : true" />
        </div>
        <MFormBuilder
          ref="dialogFormBuild"
          :form-data="dialogFormData"
          :form-list="dialogFormData.status === 0 || dialogFormData.status === 2 && isStatus ? dialogFormList : haveDialogFormList"
          :custom-rules="rules"
          :disabled="formDisabled"
        >
          <div slot="process">
            <el-input v-model="params.processFlowName" class="all-ipt">
              <template slot="append">
                <el-button icon="el-icon-more" @click="selectProcessFlows" />
              </template>
            </el-input>
          </div>
        </MFormBuilder>
      </div>
    </MDialog>
    <!-- 选择工艺路线 -->
    <SelectProcessFlow
      ref="processFlow"
      :visible.sync="processFlowVisible"
      :select-process-and-version-ids="processIdAndVersionId"
      :proce-type="proceType"
      @submit="selectFlow"
    />
  </div>
</template>

<script>
import Test from './components/test.vue'
import TestLeft from './components/test-left.vue'
import TestTimeline from './components/test-timeline.vue'
import ScheduleRight from './components/schedule-right.vue'
import BaseData from './components/base-data.vue'
import dayjs from 'dayjs'
import api from '@/api/quality/area'
import apis from '@/api/production/plans'
import { getUUid } from '@/utils/index'
import { Encrypt } from '@/utils/sercet'
import screenfull from 'screenfull'
import { dialogFormList, haveDialogFormList } from './components/form-list'
import SelectProcessFlow from '@/components/SelectProcessFlow/SelectProcessFlow'

const scaleList = `60, 1440, 10080, 43200, 525600`
  .split(',')
  .map(n => {
    const value = parseInt(n)
    let label
    if (value < 60) {
      label = value + '分钟'
    } else if (value >= 60 && value < 1440) {
      label = '小时视图'
    } else if (value >= 1440 && value < 10080) {
      label = '日视图'
    } else if (value >= 10080 && value < 43200) {
      label = '周视图'
    } else if (value >= 43200 && value < 525600) {
      label = '月视图'
    } else {
      label = '年视图'
    }
    return {
      value,
      label
    }
  })
export default {
  name: 'PlanSchedules',
  components: { Test, TestLeft, TestTimeline, ScheduleRight, BaseData, SelectProcessFlow },
  data() {
    return {
      commonDate: dayjs().format('YYYY-MM'),
      scrollTop: 0,
      dialogFormList,
      haveDialogFormList,
      loading: false,
      processFlowVisible: false,
      processIdAndVersionId: [],
      params: {
        processFlowName: '',
        processFlowId: undefined,
        processFlowVersionId: undefined
      },
      isStatus: true,
      dialogVisible: false,
      isType: undefined,
      columnsBase: [],
      dialogFormData: {},
      formDisabled: false,
      rules: {
        processFlowId: [
          { required: true, message: '请选择工艺', trigger: 'change' }
        ],
        areaId: [
          { required: true, message: '请选择产线', trigger: 'change' }
        ],
        deliveryTime: [
          { required: true, message: '请选择交期', trigger: 'change' }
        ]
      },
      fullscreen: true,
      styleObject: {
        height: this.height
      },
      param: 'NoSchedule',
      areaId: null,
      areaName: '',
      scheduleList: [],
      showPopover: false,
      allChecked: false,
      popoverContentMaxHeight: 350,
      settingColumns: [],
      checkedList: [],
      showBtn: true,
      show: true,
      timeLines: [],
      currentTime: dayjs(),
      cellWidth: 100,
      cellHeight: 46,
      titleHeight: 40,
      titleWidth: 250,
      scale: 1440,
      times: [
        dayjs().startOf('month').hour(0).minute(0)
          .toString(),
        dayjs().endOf('month').hour(23).minute(59)
          .toString()
      ],
      rowNum: 100,
      colNum: 10,
      datasA: [],
      datasB: [],
      dataKey: 'id',
      scaleList: scaleList,
      scrollToTime: dayjs().hour(0).minute(0).toString(),
      scrollToPostion: { x: 10000, y: 10000 },
      hideHeader: false,
      hideSecondGantt: false,
      arrayKeys: ['gtArray'],
      scrollToY: 0,
      positionB: {},
      positionA: {},
      lineData: [],
      showTip: true,
      formOptions: {
        flowerOptions: []
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(dayjs().set('hour', 8).minute(0))
              end.setTime(dayjs().set('hour', 20).minute(0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      blockWidth: this.$store.state.app.contentWidth - 265 - 247 - 366 - 39,
      currentDate: '',
      isHave: null,
      proceType: 0
    }
  },
  computed: {
    width() {
      return this.$store.state.app.contentWidth
    },
    height() {
      return this.$store.state.app.contentHeight - 289 - 48 - 192
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    currentSelectIdx() {
      return this.$store.state.user.currentSelectIdx
    }
  },
  watch: {
    colNum: 'getLineData',
    times: 'getLineData',
    scrollToY(val) {
      // this.positionA = { x: val }
    },
    showTip(val) {
      if (val) {
        this.blockWidth = this.width - 265 - 247 - 366 - 39
      } else {
        this.blockWidth = this.width - 265 - 247 - 39
      }
      this.getScale()
    },
    fullscreen(val) {
      if (val) {
        this.blockWidth = this.width - 265 - 247 - 366 - 39
        this.showTip = true
      } else {
        this.blockWidth = this.width - 215 - 27
      }
      this.getScale()
    }
    // scale() {
    //   this.scrollToTime = this.scale === 60
    //     ? dayjs().subtract(3, "hour").minute(0).toString()
    //     : dayjs().hour(0).minute(0).toString()
    // }
  },
  async beforeMount() {
    await this.processFlowList()
  },
  created() {
    this.$store.dispatch('user/currentSelectIdx', {})
  },
  mounted() {
    this.currentDate = dayjs().format('YYYY-MM-DD HH:mm:ss')
    this.getData()
  },
  methods: {
    getScale() {
      const list = new Map([
        [60, 70],
        [1440, 70],
        [10080, parseInt(this.blockWidth / 5) < 120 ? 120 : parseInt(this.blockWidth / 5)],
        [43200, parseInt(this.blockWidth / 3)],
        [525600, this.blockWidth]
      ])
      this.cellWidth = list.get(this.scale)
    },
    async selectScale() {
      await this.getScale()
      const totalTime = new Map([
        [60, [
          dayjs(this.commonDate).startOf('month').hour(0).minute(0).toString(),
          dayjs(this.commonDate).endOf('month').hour(23).minute(0).toString()
        ]
        ],
        [1440, [
          dayjs(this.commonDate).startOf('month').hour(0).minute(0).toString(),
          dayjs(this.commonDate).endOf('month').hour(23).minute(59).toString()
        ]
        ],
        [10080, [
          dayjs(this.commonDate).startOf('week').hour(0).minute(0).toString(),
          dayjs(this.commonDate).endOf('month').hour(23).minute(59).toString()
        ]
        ],
        [43200, [
          dayjs(this.commonDate).startOf('month').hour(0).minute(0).toString(),
          dayjs(this.commonDate).add(2, 'month').endOf('month').hour(23).minute(59).toString()
        ]
        ],
        [525600, [
          dayjs(this.commonDate).startOf('year').hour(0).minute(0).toString(),
          dayjs(this.commonDate).endOf('year').hour(23).minute(59).toString()
        ]
        ]
      ])
      this.times = totalTime.get(this.scale)
    },
    getMonths() {
      this.times = [
        dayjs(this.commonDate).startOf('month').hour(0).minute(0).toString(),
        dayjs(this.commonDate).add(2, 'month').endOf('month').hour(23).minute(59).toString()
      ]
    },
    getYear() {
      this.times = [
        dayjs(this.commonDate).startOf('year').hour(0).minute(0).toString(),
        dayjs(this.commonDate).endOf('year').hour(23).minute(59).toString()
      ]
    },
    getweek() {
      this.times = [
        dayjs(this.commonDate).startOf('week').hour(0).minute(0).toString(),
        dayjs(this.commonDate).endOf('month').hour(23).minute(59).toString()
      ]
    },
    getDays() {
      this.times = [
        dayjs(this.commonDate).startOf('month').hour(0).minute(0).toString(),
        dayjs(this.commonDate).endOf('month').hour(23).minute(59).toString()
      ]
    },
    getCommon() {
      if (this.scale === 525600) {
        this.getYear()
        return false
      }
      if (this.scale === 43200) {
        this.getMonths()
        return false
      }
      if (this.scale === 10080) {
        this.getweek()
        return false
      }
      if (this.scale === 1440) {
        this.getDays()
        return false
      }
      this.getDays()
    },
    addMonth(type) {
      if (type === 1) {
        this.commonDate = dayjs(this.commonDate).add(1, 'month').format('YYYY-MM')
      } else {
        this.commonDate = dayjs(this.commonDate).subtract(1, 'month').format('YYYY-MM')
      }
      this.getCommon()
    },
    nowMonth() {
      this.commonDate = dayjs().format('YYYY-MM')
      this.getCommon()
      this.scrollToTime = this.scale === 60 ? dayjs().subtract(3, 'hour').minute(0).toString() : dayjs().hour(0).minute(0).toString()
    },
    fixTime(time) {
      return time.substring(0, 4) + '年' + time.substring(5, 7) + '月'
    },
    compareDate(d1, d2) {
      if (d1 && d2) {
        return ((new Date(d1.replace(/\-/g, '\/'))) >= (new Date(d2.replace(/\-/g, '\/'))))
      }
      return false
    },
    // 选择产线
    processFlowChange(val) {
      this.dialogFormList[3].children[0].options = this.formOptions.flowerOptions.filter(item => item.id === val)[0].areaList
      this.$refs.dialogFormBuild.setForm({
        areaId: ''
      })
    },
    // 工艺弹窗
    selectProcessFlows() {
      if (this.params.processFlowId) {
        this.processIdAndVersionId = [this.params.processFlowId, this.params.processFlowVersionId]
        this.$refs.processFlow.selectProcessChange(this.params.processFlowId)
      }
      this.processFlowVisible = true
    },
    selectFlow(data) {
      console.log(data, '选中id')
      this.dialogFormList[3].children[0].options = data.processData.areaList
      Object.assign(this.params, {
        processFlowName: data.processData.name,
        processFlowId: data.processFlowVersion.processFlowId,
        processFlowVersionId: data.processFlowVersion.id
      })
      this.$refs.dialogFormBuild.setForm({
        processFlowId: data.processFlowVersion.processFlowId,
        processFlowVersionId: data.processFlowVersion.id,
        areaId: ''
      })
      this.processFlowVisible = false
    },
    selectImg() {
      if (!this.showTip && this.$refs.schedules !== undefined) {
        this.$refs.schedules.getScheduleList()
      }
      this.showTip = !this.showTip
    },
    selectLine(val) {
      this.areaId = val.id
      this.areaName = val.name
    },
    cancel() {
      this.showPopover = false
      this.allChecked = false
      this.show = false
      setTimeout(this.resetSettingFields, 500)
    },
    async saveColumns() {
      await this.getLineData()
      this.showPopover = false
      this.allChecked = false
      this.show = false
    },
    selectAllColumns(val) {
      this.checkedList = val ? this.settingColumns.map(item => item.id) : []
    },
    invertSelectColumns() {
      this.checkedList = this.settingColumns
        .filter(item => this.checkedList.indexOf(item.id) === -1)
        .map(item => item.id)
    },
    changeMore() {
      this.show = !this.show
    },
    changeSpread() {
      this.showBtn = !this.showBtn
      if (this.showBtn) {
        this.checkedList = []
        this.getData()
        return false
      }
      this.getLineData()
    },
    updateTimeLines(timeA, timeB) {
      this.timeLines = [
        {
          time: timeA,
          text: '自定义'
        },
        {
          time: timeB,
          text: '测试',
          color: '#747e80'
        }
      ]
    },
    scrollLeftA(val) {
      this.positionB = { x: val }
    },
    scrollLeftB(val) {
      this.positionA = { x: val }
    },
    // 获取全部数据
    async getData() {
      const data = {
        isExist: 0
      }
      const res = await api.getLine(data)
      if (res) {
        this.settingColumns = res
        this.lineData = res
        this.getLineLists()
      }
    },
    async getLineData() {
      const data = {
        isExist: this.showBtn ? 0 : 1,
        idList: this.checkedList
      }
      const res = await api.getLine(data)
      if (res) {
        this.lineData = res
        this.getLineLists()
      }
    },
    // 根据产线返回计划
    async getLineLists() {
      this.loading = true
      const obj = this.settingColumns.length > 0 ? this.settingColumns.map(item => item.id) : []
      const data = this.checkedList.length > 0 ? this.checkedList : obj

      const params = {
        beginTime: dayjs(`${this.times[0]}`).format('YYYY-MM-DD HH:00'),
        endTime: dayjs(`${this.times[1]}`).format('YYYY-MM-DD HH:59')
      }
      const res = await apis.getLineList(data, params)
      if (res) {
        const datas = this._.cloneDeep(this.lineData)
        const data = []
        if (datas.length === 0) {
          return false
        }
        datas.map(item => {
          res.map(items => {
            if (item.id === items.areaId) {
              data.push({
                id: items.areaId,
                name: item.parentArea,
                producePlanList: items.producePlanList ? items.producePlanList.filter(item => item.isPlan === 1 && item.produceStatus !== 2) : []
              })
            }
          })
        })
        let str = []
        const strs = data.map(item => {
          if (item.producePlanList.length > 0) {
            str = item.producePlanList.map(items => {
              return {
                start: new Date(items.planStartTime).toString(),
                end: new Date(items.planEndTime).toString(),
                ...items
              }
            })
          } else {
            str = []
          }
          return {
            ...item,
            gtArray: str
          }
        })
        let currentAry = []
        const start = dayjs(`${this.times[0]}`).format('YYYY-MM-DD HH:00')
        const end = dayjs(`${this.times[1]}`).format('YYYY-MM-DD HH:59')
        const timeData = strs.map(item => {
          if (item.gtArray.length > 0) {
            currentAry = this.getTimes(item.gtArray).filter(items => this.compareDate(items.minTime, start) && !this.compareDate(items.minTime, end))
          } else {
            currentAry = []
          }
          return {
            id: item.id,
            name: item.name,
            producePlanList: item.producePlanList,
            gtArray: currentAry
          }
        })
        if (!this.showBtn) {
          this.datasA = timeData.filter(item => item.gtArray.length !== 0)
          this.checkedList = this.datasA.length > 0 ? this.datasA.map(item => item.id) : []
        } else {
          this.datasA = timeData
        }
        if (this.scale === 60) {
          this.scrollToTime = dayjs().subtract(3, 'hour').minute(0).toString()
        } else {
          this.scrollToTime = dayjs().hour(0).minute(0).toString()
        }
        this.loading = false
        const dataIdx = this.datasA.length > 0 ? this.datasA.filter(item => parseInt(item.id) === parseInt(this.currentSelectIdx.id)) : []
        const index = dataIdx.length > 0 ? this.currentSelectIdx : { id: timeData[0].id, name: timeData[0].name }
        this.selectLine(index)
      }
    },
    compare(key) {
      return function(obj1, obj2) {
        if (obj1[key] < obj2[key]) {
          return -1
        } else if (obj1[key] === obj2[key]) {
          return 0
        } else {
          return 1
        }
      }
    },
    getTimes(data) {
      let minTimes
      let maxTimes
      const newGtArray = data.map(item => {
        if (item.actualStartTime) {
          // 实际开始时间小于计划开始时间 && 计划开始时间和计划结束时间都大于当前时间
          if (this.compareDate(item.planStartTime, item.actualStartTime) &&
            this.compareDate(item.planStartTime, this.currentDate) &&
            this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.actualStartTime
            maxTimes = item.planEndTime
          }
          // 实际开始时间小于计划开始时间 && 计划开始时间小于当前时间 && 计划结束时间大于当前时间
          if (this.compareDate(item.planStartTime, item.actualStartTime) &&
            !this.compareDate(item.planStartTime, this.currentDate) &&
            this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.actualStartTime
            maxTimes = item.planEndTime
          }
          // 实际开始时间小于计划开始时间 && 计划结束时间和计划开始时间都小于当前时间
          if (this.compareDate(item.planStartTime, item.actualStartTime) &&
            !this.compareDate(item.planStartTime, this.currentDate) &&
            this.compareDate(this.currentDate, item.planEndTime)
          ) {
            minTimes = item.actualStartTime
            maxTimes = this.currentDate
          }
          // 计划结束时间大于当前时间 && 实际开始时间大于计划开始时间
          if (this.compareDate(item.actualStartTime, item.planStartTime) &&
            this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.planStartTime
            maxTimes = item.planEndTime
          }
          // 计划结束时间小于当前时间  && 实际开始时间大于计划开始时间
          if (!this.compareDate(item.actualStartTime, item.planEndTime) &&
            this.compareDate(item.actualStartTime, item.planStartTime) &&
            !this.compareDate(item.planEndTime, this.currentDate)
          ) {
            minTimes = item.planStartTime
            maxTimes = this.currentDate
          }
          // 实际开始时间大于计划结束时间和开始时间
          if (this.compareDate(item.actualStartTime, item.planEndTime) &&
            !this.compareDate(item.planEndTime, this.currentDate) &&
            !this.compareDate(item.planStartTime, this.currentDate)
          ) {
            minTimes = item.planStartTime
            maxTimes = this.currentDate
          }
        }
        if (!item.actualStartTime) {
          minTimes = item.planStartTime ? item.planStartTime : ''
          maxTimes = item.planEndTime ? item.planEndTime : ''
        }
        return {
          ...item,
          minTime: minTimes,
          maxTime: maxTimes
        }
      })
      newGtArray.sort(this.compare('minTime'))
      return newGtArray
    },
    // 原生js全屏
    screen() {
      // let element = document.documentElement;//设置后就是我们平时的整个页面全屏效果
      const element = document.getElementById('containers-left')// 设置后就是   id==con_lf_top_div 的容器全屏
      if (!this.fullscreen) {
        if (document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msExitFullscreenElement
        ) {
          document.exitFullscreen()
        }
        if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        }
        if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
      if (this.fullscreen) {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        }
        if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        }
        if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        }
        if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = false
    },
    // 全屏
    toggleFullScreen() {
      // if (!screenfull.isEnabled) {
      //   this.$messsage.error('您的浏览不能全屏')
      //   return false
      // }
      // screenfull.toggle(this.$refs.myImg)
      this.fullscreen = !this.fullscreen
    },
    // 修改计划详情
    async modify(row) {
      this.isType = row.type
      this.isHave = row.isHave
      const res = await apis.getPlanDetail(row.id)
      if (res) {
        this.dialogFormData = res
        this.dialogFormList[3].children[0].options = this.formOptions.flowerOptions.filter(item => item.id === res.processFlowId)[0].areaList
        Object.assign(this.params, {
          processFlowName: res.processFlowName,
          processFlowId: res.processFlowId,
          processFlowVersionId: res.processFlowVersionId
        })
        this.dialogFormList[0].children[0].options = this.priorityStatus.map((item, index) => {
          return {
            ...item,
            id: index
          }
        })
        this.proceType = res.isRepair === 0 ? 0 : 1
        this.dialogVisible = true
      }
    },
    // 工艺
    async processFlowList() {
      const res = await apis.getFlowers({
        isGetAreaList: 1,
        isGetVersionList: 1
      })
      if (res) {
        this.formOptions.flowerOptions = res
      }
    },
    // 修改保存
    dialogSubmit(callback) {
      callback()
      const { form } = this.$refs.dialogFormBuild
      console.log('shuju=>', form)
      if (this.isHave === 1) {
        if (form.planStartTime === '' || form.planStartTime === null || form.planEndTime === '' || form.planEndTime === null) {
          this.$message.error('开始时间和结束时间必填')
          return false
        }
      }
      if ((form.planStartTime && form.planEndTime) && (this.compareDate(form.planStartTime, form.planEndTime))) {
        this.$message.error('开始时间不能大于或等于结束时间')
        return false
      }
      this.$refs.dialogFormBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await apis.modifyPlan(this.createResetData(form))
          if (res) {
            this.$message.success('修改成功')
            this.dialogVisible = false
            this.save()
            if (this.showBtn) {
              this.getData()
              return false
            }
            this.getLineData()
          }
        }
      })
    },
    save() {
      if (this.isType === 1) {
        this.$refs.schedules.getScheduleList()
      }
    },
    createSetData(row) {
      return {
        areaId: row.areaId,
        priority: row.priority,
        processFlowVersionId: row.processFlowVersionId,
        processFlowId: row.processFlowId,
        deliveryTime: row.deliveryTime ? row.deliveryTime : '',
        planStartTime: row.planStartTime ? row.planStartTime : '',
        planEndTime: row.planEndTime ? row.planEndTime : '',
        remark: row.remark
      }
    },
    createResetData(row) {
      const obj = {
        id: row.id
      }
      return Object.assign(this.createSetData(row), obj)
    },
    // 下发至工单
    async fixWorkOrder(row) {
      const ids = []
      ids.push(row.id)
      const res = await apis.getPage(ids)
      if (res) {
        this.isStatus = false
        this.$message.success('下发成功')
        this.$refs.schedules.getScheduleList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-li {
  display: flex;
  justify-content: flex-end;
}
.schedule-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 700;
  color: #393D60;
  line-height: 33px;
}
.common-date {
  display: flex;
  justify-content: center;
  .today {
    width: 60px;
    height: 31px;
    border-radius: 4px;
    border: 1px solid #A4B3C6;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    line-height: 31px;
    text-align: center;
    margin-right: 6px;
    cursor: pointer;
  }
  img {
    width: 32px;
    height: 33px;
    display: block;
    margin: 0 12px;
    cursor: pointer;
  }
  .date-tit {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    line-height: 33px;
    width: 110px;
    text-align: center;
  }
}
body {
  font: 12px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#apps {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  position: relative;
}
.top-bar {
  padding-left: 10px;
}
label {
  margin-left: 10px;
}
input {
  width: 40px;
  height: 20px;
  vertical-align: middle;
}
input[type="range"] {
  width: 100px;
}
::v-deep {
  .gantt-header-title {
    width: 215px!important;
  }
  .gantt-leftbar-wrapper {
    width: 214px!important;
  }
  .gantt-leftbar-item {
    border-bottom: 1px solid #ececec;
  }
  .gantt-timeline{
    margin-left: 0!important;
  }
  .gantt-scroll-x {
    margin-left: 215px!important;
    width: calc(100% - 215px)!important;
  }
  .gantt-markline-area {
    margin-left: 215px!important;
  }
  .el-checkbox__label {
    font-size: 12px;
  }
  .el-popover, .el-popper {
    padding: 12px 12px 0;
  }
  .m-radio-group .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    width: 90%;
  }
}
// 修改计划
.edit-form {
  .form-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    .li-btn {
      width: 88px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #607FFF;
      cursor: pointer;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 32px;
      text-align: center;
    }
  }
  .form-top {
    padding: 20px 20px 0;
    background: #F7F9FC;
    border-radius: 4px;
    display: flex;
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4F76;
    }
  }
}
// 左边头部
.common-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-right: 1px solid #DBE1E7;
  .header-le {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 12px;
    .le-img {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 15px;
        height: 15px;
        display: block;
      }
      &:hover {
        background:#F8F8F8;
      }
    }
  }
  .header-ri {
    margin-right: 10px;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
      display: block;
    }
  }
}
.showMore {
  padding: 0 10px;
  height: 40px;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #607FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  .icons {
    color: #DDE3EB;
  }
  &:hover {
    background:#F8F8F8;
    i {
      color: #607FFF;
    }
  }
}
// 筛选产线
.popover-content-top {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  button {
    padding: 8px 20px;
    border-radius: 4px;
    border-color: #DEDEEE;
    color: #8A8CA5;
    &:last-child {
      border-color: #607FFF;
      color: #607FFF;
    }
  }
}
.popover-content-center {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  background: #F8F8FB;
  height: 30px;
  button.invert {
    span {
      color: #8A8CA5;
    }
    img {
      position: relative;
      top: 2px;
      right: 10px;
    }
  }
}
.popover-content-footer {
  width: calc(100% + 24px);
  position: relative;
  left: -12px;
  max-height: 400px;
  overflow-y: scroll;
  .el-checkbox-group {
    // overflow: hidden;
    .el-checkbox {
      // width: 100%;
      padding: 8px 8px 8px 0px;
      cursor: pointer;
      // &:hover {
      //   // background: #ECF0F4;
      // }
    }
  }
}
.containers {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
  position: relative;
  .tip-img {
    width: 10px;
    height: 38px;
    display: block;
    position: absolute;
    top: calc(50%);
    right: -10px;
    cursor: pointer;
    img {
      width: 10px;
      height: 38px;
      display: block;
    }
  }
}
.app-left {
  width: calc(100% - 366px);
  background: #fff;
}
.app-width {
  width: 100%;
}
.app-left-full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background: #fff;
  width: 100%;
  z-index: 2000;
}
// 右边栏样式
.app-right {
  width: 366px;
  background: #F0F2F5;
  height: 100%;
  // height: calc(100% + 20px);
  overflow-y: hidden;

}
.el-slider {
  width: 100px;
}
/* 样式 */
.gatter-form{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
</style>
