<template>
  <div class="orders">
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="stockSearchFormList"
        :form-data.sync="searchFormData"
        style="padding: 0"
        :expand-limit="7"
        @search="reSearch"
      >
        <SelectRemoteSearch
          v-for="item in searchFormStockSelList"
          :key="item.key"
          :slot="item.key"
          :ref="item.key"
          :search-name="item.searchName||'search'"
          class="select"
          clearable
          :size-name="stockApiMaps[item.key].sizeName||'limit'"
          :placeholder="stockApiMaps[item.key].placeholder"
          :multiple="!stockApiMaps[item.key].notMultiple"
          :option-val="stockApiMaps[item.key].optionVal"
          :option-label="stockApiMaps[item.key].optionLabel"
          :api="stockApiMaps[item.key].api"
          collapse-tags
          @change="val => {
            handleSelect(item.key, val)
          }
          "
        />
        <div slot="lossScale" style="display:flex;align-items:center">
          <span style="word-break: keep-all;">损耗进度：</span>
          <el-input-number v-model="searchFormData.lossScaleStart" style="width:40%" :min="0" :max="searchFormData.lossScaleEnd" :controls="false" />
          ~
          <el-input-number v-model="searchFormData.lossScaleEnd" style="width:40%" :min="searchFormData.lossScaleStart" :max="100" :controls="false" />
        </div>
      </SearchForm>
    </div>
    <FunctionHeader
      ref="FunctionHeader"
      v-model="functionHeader.searchVal"
      search-title="请输入唯一编码查询"
      :tags="!selectIds.length ? functionHeader.tags :[]"
      style="padding: 10px 0 10px 0;"
      class="func-header"
      :import-name="!selectIds.length ? 'moldStockImport' :''"
      :exportName="!selectIds.length ? functionHeader.exportName : ''"
      :show-cover="true"
      @search="$refs.mTable.setTableData(true)"
    >
    <div slot="all" v-show="selectIds.length">
          <el-button v-if="permission('OutStock')" size="small" type="primary"  @click="outStock()">批量出库</el-button>
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('onExport')">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ selectIds.length }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetData">清空</el-button>
      </div>
    </FunctionHeader>
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="data=>selectIds = data.map(item => item.id)">
      <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
      <div slot="lossScale" slot-scope="{ row }">
        <el-progress :percentage="row.lossScale" :color="row.lossScaleStatus==='Normal'?'#409eff':row.lossScaleStatus==='Warning'?'#e6a23c':'#f56c6c'" />
      </div>
      <div slot="lossScaleStatus" slot-scope="{ row }">{{ warnMap[row.lossScaleStatus] }}</div>
      <div slot="isScrap" slot-scope="{ row }">{{ scrapMap[row.isScraped] }}</div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission('Edit')" type="text" @click="onEdit(row)">编辑</el-button>
        <el-button v-if="permission('Record')" type="text" @click="onRecord(row)">报工记录</el-button>
      </div>
    </MTable>

    <MDialog v-model="modalShow" width="500px" :append-to-body="true" title="编辑" @onOk="submitForm">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
        <el-form-item label="损耗进度（%）：" prop="lossScale">
          <el-input-number v-model="ruleForm.lossScale" placeholder="请输入损耗进度" :min="0" controls-position="right" style="width:100%;" @blur="ruleForm.lossScale=sliceVal(ruleForm.lossScale,2)" />
        </el-form-item>
        <el-form-item label="报废状态：" prop="isScraped">
          <el-select v-model="ruleForm.isScraped" placeholder="请选择报废状态" style="width:100%">
            <el-option v-for="item in scrapOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
    </MDialog>

    <MDialog v-model="recordShow" :need-cancel-btn="false" :need-sub-btn="false" width="900px" :append-to-body="true" title="报工记录">
      <MTable :columns="columnsRecord" :height="600" :set-data-method="getRecordTableData">
        <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
        <el-tooltip slot="moldInstances" slot-scope="{row}" :content="row.moldInstances" placement="top">
          <el-button type="text">{{ row.moldInstances }}</el-button>
        </el-tooltip>
        <el-tooltip slot="procedureName" slot-scope="{row}" :content="`${row.procedureName} / ${row.procedureCode} / ${ row.procedureSerialCode||'-'}`" placement="top">
          <el-button type="text">{{ row.procedureName }} / {{ row.procedureCode }} / {{ row.procedureSerialCode||'-' }}</el-button>
        </el-tooltip>
      </MTable>
    </MDialog>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import api from '@/api/mould/index'
import reportApi from '@/api/production/reportRecords'
import exportApi from '@/api/exportAndImport/export'
import { sliceVal } from '@/utils'
import FunctionHeader from '@/components/FunctionHeader/ZCIndex.vue'
import SearchForm from '@/components/SearchForm/SearchForm'
import { stockSearchFormList, stockColumnsAll, scrapOptions, warnOptions, scrapMap, stockApiMaps, searchFormStockSelList, warnMap, columnsRecord } from './data'

export default {
  name: 'MouldStock',
  components: { FunctionHeader, SearchForm },
  data() {
    return {
      warnMap,
      stockApiMaps,
      warnOptions,
      stockSearchFormList,
      searchFormStockSelList,
      scrapMap,
      showImport: false,
      modalShow: false,
      scrapOptions,
      ruleForm: {
        lossScale: null,
        isScraped: null
      },
      rules: {
        lossScale: [
          { required: true, message: '请输入损耗进度', trigger: 'change' }
        ],
        isScraped: [
          { required: true, message: '请选择报废状态', trigger: 'change' }
        ]
      },
      searchFormData: {
        lossScaleStart: '',
        lossScaleEnd: ''
      },
      functionHeader: {
        searchVal: '',
        exportName: 'exportMouldStockReport',
        exportParams: {},
        tags: [
          {
            hidden: !this.permission('InStock'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            innerText: '入库',
            on: {
              click: () => this.$router.push({ path: 'mould-inStock' })
            }
          }
        ]
      },
      columns: stockColumnsAll,
      searchData: {},
      selectIds: [],
      materialParams: { status: 0, page: 1, size: 10, search: '' },
      recordShow: false,
      columnsRecord,
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 55
    }
  },
  watch: {
    'selectIds.length'(val) {
      this.functionHeader.tags[1].props.disabled = !val
    }
  },

  methods: {
    sliceVal,
    handleSelect(key, val) {
      this.searchFormData[key] = val
      this.$refs.mTable.setTableData(true)
    },
    reSearch(data, val) {
      if (val === 1) {
        this.searchFormData = {
          lossScaleStart: '',
          lossScaleEnd: ''
        }
        const refs = ['categoryIds', 'createBy']
        refs.forEach(ref => { this.$refs[ref][0].value = '' })
      }
      this.$refs.mTable.setTableData(true)
    },
    // 新增
    onEdit(row) {
      const { isScraped, id, lossScale } = row
      this.ruleForm = { isScraped, id, lossScale }
      this.modalShow = true
    },
    onRecord({ id }) {
      this.recordShow = true
      this.moldInstanceIds = id
    },
    async submitForm() {
      const flag = await this.$refs.ruleForm.validate()
      if (flag) {
        const { id, lossScale, isScraped } = this.ruleForm
        const res = await api.editStock({ id, lossScale, isScraped })
        if (res) {
          this.$message.success('操作成功')
          this.modalShow = false
          this.$refs.mTable.setTableData(true)
        }
      }
    },
    // 出库
    outStock() {
      this.$confirm(`出库后无法撤回，当前选中 ${this.selectIds.length} 条库存`, '请确认是否进行出库', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.outStock({ ids: this.selectIds })
        if (res) this.$message.success('操作成功')
        this.$refs.mTable.setTableData(true)
        this.$refs.mTable.tableRefs().clearSelection()
      })
    },

    allMore(command) {
      const functions = {
        onExport: () => this.onExport(false, this.selectIds)
      }
      functions[command]()
    },

    // 报工记录
    async getRecordTableData(condition, callback) {
      const { page, order } = condition
      // 导出参数
      const res = await reportApi.getRecordsListPage({
        page: page.currentPage,
        limit: page.pageSize,
        moldInstanceIds: this.moldInstanceIds
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },

    // 列表
    async getTableData(condition, callback) {
      const { page, order } = condition
      const { moldClazzName, categoryIds, createBy: createByIds, inStockTime, moldClazzSpec, lossScaleStart, lossScaleEnd, isScraped, lossScaleStatus } = this.searchFormData
      const params = {
        sort: order,
        searchVal: this.functionHeader.searchVal,
        moldClazzName,
        moldCategoryIds: categoryIds?.join(','),
        createByIds: createByIds?.join(','),
        moldClazzSpec,
        lossScaleStart,
        lossScaleEnd,
        lossScaleStatus,
        isScraped,
        createAtStart: inStockTime ? inStockTime[0] : '',
        createAtEnd: inStockTime ? inStockTime[1] : ''
      }
      // 导出参数
      this.functionHeader.exportParams = params
      const res = await api.getMouldStockList({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },

    // 导出
    async onExport(isAll, ids) {
      const params = isAll ? this.functionHeader.exportParams : { ids }
      const res = await exportApi.exportMouldStockReport(params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `模具库存-${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
    },

    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    resetData() {
      this.selectIds = []
      this.$refs.mTable.tableRefs().clearSelection()
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep .el-progress-bar{
  width: 90%;
}

::v-deep .custom-sel {
  div {
    min-width: 100%;
  }
}
.orders {
  padding: 10px;
  .faultStatuss {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    height: 22px;
    line-height: 22px;
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
    display: inline-block;
  }
  .li-btn {
    color: #607fff;
    font-weight: 500;
  }
  .color-btns {
    color: #c4c4c4;
  }
  .searchs {
    padding-left: 0;
    padding-top: 0;
  }
  .input-select {
    width: 100px;
    display: block;
  }
  .input-with-select {
    width: 100%;
  }
  .all-operator {
    height: 32px;
    margin-left: 10px;
    ::v-deep {
      .el-button--primary,
      .el-button-group .el-button {
        height: 32px;
      }
    }
  }
  .func-header {
    ::v-deep .el-input--suffix .el-input__inner {
      height: 32px;
    }
  }
}
</style>
