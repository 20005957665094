var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "SearchForm",
            {
              ref: "searchForm",
              staticStyle: { padding: "0" },
              attrs: {
                "form-list-extend": _vm.stockSearchFormList,
                "form-data": _vm.searchFormData,
                "expand-limit": 7
              },
              on: {
                "update:formData": function($event) {
                  _vm.searchFormData = $event
                },
                "update:form-data": function($event) {
                  _vm.searchFormData = $event
                },
                search: _vm.reSearch
              }
            },
            [
              _vm._l(_vm.searchFormStockSelList, function(item) {
                return _c("SelectRemoteSearch", {
                  key: item.key,
                  ref: item.key,
                  refInFor: true,
                  staticClass: "select",
                  attrs: {
                    slot: item.key,
                    "search-name": item.searchName || "search",
                    clearable: "",
                    "size-name": _vm.stockApiMaps[item.key].sizeName || "limit",
                    placeholder: _vm.stockApiMaps[item.key].placeholder,
                    multiple: !_vm.stockApiMaps[item.key].notMultiple,
                    "option-val": _vm.stockApiMaps[item.key].optionVal,
                    "option-label": _vm.stockApiMaps[item.key].optionLabel,
                    api: _vm.stockApiMaps[item.key].api,
                    "collapse-tags": ""
                  },
                  on: {
                    change: function(val) {
                      _vm.handleSelect(item.key, val)
                    }
                  },
                  slot: item.key
                })
              }),
              _c(
                "div",
                {
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { slot: "lossScale" },
                  slot: "lossScale"
                },
                [
                  _c("span", { staticStyle: { "word-break": "keep-all" } }, [
                    _vm._v("损耗进度：")
                  ]),
                  _c("el-input-number", {
                    staticStyle: { width: "40%" },
                    attrs: {
                      min: 0,
                      max: _vm.searchFormData.lossScaleEnd,
                      controls: false
                    },
                    model: {
                      value: _vm.searchFormData.lossScaleStart,
                      callback: function($$v) {
                        _vm.$set(_vm.searchFormData, "lossScaleStart", $$v)
                      },
                      expression: "searchFormData.lossScaleStart"
                    }
                  }),
                  _vm._v(" ~ "),
                  _c("el-input-number", {
                    staticStyle: { width: "40%" },
                    attrs: {
                      min: _vm.searchFormData.lossScaleStart,
                      max: 100,
                      controls: false
                    },
                    model: {
                      value: _vm.searchFormData.lossScaleEnd,
                      callback: function($$v) {
                        _vm.$set(_vm.searchFormData, "lossScaleEnd", $$v)
                      },
                      expression: "searchFormData.lossScaleEnd"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "FunctionHeader",
        {
          ref: "FunctionHeader",
          staticClass: "func-header",
          staticStyle: { padding: "10px 0 10px 0" },
          attrs: {
            "search-title": "请输入唯一编码查询",
            tags: !_vm.selectIds.length ? _vm.functionHeader.tags : [],
            "import-name": !_vm.selectIds.length ? "moldStockImport" : "",
            exportName: !_vm.selectIds.length
              ? _vm.functionHeader.exportName
              : "",
            "show-cover": true
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectIds.length,
                  expression: "selectIds.length"
                }
              ],
              attrs: { slot: "all" },
              slot: "all"
            },
            [
              _vm.permission("OutStock")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.outStock()
                        }
                      }
                    },
                    [_vm._v("批量出库")]
                  )
                : _vm._e(),
              _vm.permission("Export")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.allMore("onExport")
                        }
                      }
                    },
                    [_vm._v("批量导出")]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "0 10px",
                    display: "inline-block",
                    "font-size": "12px",
                    color: "#393D60"
                  }
                },
                [
                  _vm._v(" 已选 "),
                  _c("span", { staticStyle: { color: "#607FFF" } }, [
                    _vm._v(_vm._s(_vm.selectIds.length))
                  ]),
                  _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "small" },
                  on: { click: _vm.resetData }
                },
                [_vm._v("清空")]
              )
            ],
            1
          )
        ]
      ),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        on: {
          "selection-change": function(data) {
            return (_vm.selectIds = data.map(function(item) {
              return item.id
            }))
          }
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "lossScale",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c("el-progress", {
                    attrs: {
                      percentage: row.lossScale,
                      color:
                        row.lossScaleStatus === "Normal"
                          ? "#409eff"
                          : row.lossScaleStatus === "Warning"
                          ? "#e6a23c"
                          : "#f56c6c"
                    }
                  })
                ],
                1
              )
            }
          },
          {
            key: "lossScaleStatus",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(_vm._s(_vm.warnMap[row.lossScaleStatus]))
              ])
            }
          },
          {
            key: "isScrap",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(_vm._s(_vm.scrapMap[row.isScraped]))
              ])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.permission("Edit")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.onEdit(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                  _vm.permission("Record")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.onRecord(row)
                            }
                          }
                        },
                        [_vm._v("报工记录")]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: { width: "500px", "append-to-body": true, title: "编辑" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "损耗进度（%）：", prop: "lossScale" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请输入损耗进度",
                      min: 0,
                      "controls-position": "right"
                    },
                    on: {
                      blur: function($event) {
                        _vm.ruleForm.lossScale = _vm.sliceVal(
                          _vm.ruleForm.lossScale,
                          2
                        )
                      }
                    },
                    model: {
                      value: _vm.ruleForm.lossScale,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "lossScale", $$v)
                      },
                      expression: "ruleForm.lossScale"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "报废状态：", prop: "isScraped" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择报废状态" },
                      model: {
                        value: _vm.ruleForm.isScraped,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "isScraped", $$v)
                        },
                        expression: "ruleForm.isScraped"
                      }
                    },
                    _vm._l(_vm.scrapOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            "need-cancel-btn": false,
            "need-sub-btn": false,
            width: "900px",
            "append-to-body": true,
            title: "报工记录"
          },
          model: {
            value: _vm.recordShow,
            callback: function($$v) {
              _vm.recordShow = $$v
            },
            expression: "recordShow"
          }
        },
        [
          _c("MTable", {
            attrs: {
              columns: _vm.columnsRecord,
              height: 600,
              "set-data-method": _vm.getRecordTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "moldInstances",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-tooltip",
                    { attrs: { content: row.moldInstances, placement: "top" } },
                    [
                      _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v(_vm._s(row.moldInstances))
                      ])
                    ],
                    1
                  )
                }
              },
              {
                key: "procedureName",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content:
                          row.procedureName +
                          " / " +
                          row.procedureCode +
                          " / " +
                          (row.procedureSerialCode || "-"),
                        placement: "top"
                      }
                    },
                    [
                      _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v(
                          _vm._s(row.procedureName) +
                            " / " +
                            _vm._s(row.procedureCode) +
                            " / " +
                            _vm._s(row.procedureSerialCode || "-")
                        )
                      ])
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }