<template>
  <div class="memberRouteSetting_container" :style="{height: chromeHeight + 'px'}">
    <div class="container_left">
      <div class="side-bar-container">
        <div class="container-left">
          <!--          头部logo-->
          <div class="left-top">
            <img src="https://oss.mubyte.cn/mes2-lite/mom/2024/04/19/17134894917296296.png"/>
          </div>
          <!--          下面导航菜单-->
          <div class="left-center">
            <ul>
              <template v-for="item in menuList">
                <li
                  :key="item.name"
                  :class="$route.name === item.name ? 'active' : 'inactive'"
                  @click="openSecond(item)"
                >
                  <div class="img-wrapper"><img :src="item.meta.icon"/></div>
                  <span>{{ item.meta.title }}</span>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container_right">
      <RouterView style="width: 100%;height: 100%" />
    </div>
  </div>
</template>
<script>
import { baseRoutes } from '@/router'

export default {
  name: 'OP_HOME',
  data() {
    return {
      menuList: []
    }
  },
  computed: {
    chromeHeight() {
      return document.documentElement.clientHeight
    }
  },
  async created() {
    let a = baseRoutes.find(item => item.name === "operationalBackOffice").children || []
    a = a.filter(p => p.meta.specFlag === 2) || []
    this.menuList = a[0].children
  },
  mounted() {
  },
  methods: {
    openSecond(item){
      if(item.name !== this.$route.name){
        this.$router.push({
          name: item.name
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.memberRouteSetting_container {
  width: 100%;
  box-sizing: border-box;
  display: flex;

  .container_left {
    width: 12%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 1px;

    .side-bar-container {
      display: inline-flex;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      background: linear-gradient(to bottom, #D0DAFF, #CFE2FB, #CDD9E6);

      .container-left {
        display: flex;
        width: 100%;
        flex-direction: column;

        &.closed {
          ul {
            width: 50px;
          }

          .left-top img {
            width: 24px;
          }

          .left-bottom {
            .arrow img {
              transform: rotate(180deg);
            }
          }
        }
      }

      .left-top {
        margin-top: 18px;
        padding-left: 10px;

        img {
          width: 120px;
        }
      }

      .left-bottom {
        width: 100%;
        height: 40px;
        padding: 10px 16px;
        display: flex;
        transition: all .5s;
        justify-content: space-evenly;

        img {
          cursor: pointer;
        }
      }

      .left-center {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 0;
      }

      ul,
      li {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      ul {
        flex: 1;
        height: 0;
        position: relative;
        transition: all .5s;
        overflow-y: auto;
        display: inline-block;
      }

      li {
        height: 40px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        margin: 11px 0;
        cursor: pointer;
        color: #393d60;

        .img-wrapper {
          width: 30.66px;
          text-align: center;
          display: inline-flex;
          align-items: center;

          img {
            padding-right: 8px;
            height: 14px;
          }
        }

        span {
          position: relative;
          top: 1px;
          word-break: keep-all;
        }

        &:nth-child(3),
        &:nth-child(6) {
          img {
            position: relative;
            top: 1px;
          }
        }
      }

      li.active {
        background: linear-gradient(90deg, #5C76F1 0%, #7233EC 100%);
        position: relative;
        color: #ffffff;

        .img-wrapper {
          width: 30.66px;
          text-align: center;
          display: inline-flex;
          align-items: center;
          filter: brightness(100);

          img {
            padding-right: 8px;
            height: 14px;
          }
        }

        &:hover {
          background: linear-gradient(90deg, #5C76F1 0%, #7233EC 100%);
        }
      }

      li:hover {
        background: #E7EDF4;
      }

      li.has-children {
        &::after {
          content: '';
          position: absolute;
          top: 16px;
          right: 14px;
          transform: rotate(45deg);
          border-color: #ccc;
          border-top: 2px solid;
          border-right: 2px solid;
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .container_right {
    width: 88%;
    height: 100%;
    box-sizing: border-box;
  }
}

</style>
