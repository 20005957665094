var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "role-wrap" }, [
    _c(
      "div",
      { staticClass: "orders" },
      [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c(
              "SearchForm",
              {
                ref: "searchForm",
                staticStyle: { padding: "0" },
                attrs: {
                  "form-list-extend": _vm.upDownWorkRecordFormList,
                  "form-data": _vm.searchFormData
                },
                on: {
                  "update:formData": function(data) {
                    return (_vm.searchFormData = data)
                  },
                  search: _vm.reSearch
                }
              },
              [
                _c("SelectRemoteSearch", {
                  ref: "procedureIds",
                  staticClass: "select",
                  attrs: {
                    slot: "procedureIds",
                    placeholder: "工序名称/编码",
                    multiple: "",
                    "option-val": "id",
                    "option-label": "fullName",
                    api: _vm.api.procedureList,
                    "collapse-tags": ""
                  },
                  on: {
                    change: function(val) {
                      _vm.handleSelect("procedureIds", val)
                    }
                  },
                  slot: "procedureIds"
                })
              ],
              1
            )
          ],
          1
        ),
        _c("FunctionHeader", {
          ref: "functionHeader",
          staticClass: "func-header",
          staticStyle: { padding: "10px 0 10px 0" },
          attrs: {
            "search-title": "请输入生产工单号/项目编号名称",
            tags: !_vm.selectData.length ? _vm.functionHeader.tags : []
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c("MTable", {
          ref: "mTable",
          staticClass: "table-left",
          attrs: {
            columns: _vm.upDownWorkColumn,
            height: _vm.height,
            "set-data-method": _vm.getTableData,
            "columns-key": _vm.columnsKey
          },
          on: {
            "selection-change": function(data) {
              return (_vm.selectData = data)
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "direction",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.upDownWorkStatusMaps[row.direction] || "-") +
                        " "
                    )
                  ])
                }
              },
              {
                key: "workHoursType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.workHoursTypeMaps[row.workHoursType] || "-"
                        ) +
                        " "
                    )
                  ])
                }
              },
              {
                key: "upWorkTime",
                fn: function(ref) {
                  var row = ref.row
                  return row.isEdit
                    ? _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "default-time": "00:00:00",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: row.upWorkTime,
                          callback: function($$v) {
                            _vm.$set(row, "upWorkTime", $$v)
                          },
                          expression: "row.upWorkTime"
                        }
                      })
                    : _c("div", [_vm._v(" " + _vm._s(row.upWorkTime))])
                }
              },
              {
                key: "downWorkTime",
                fn: function(ref) {
                  var row = ref.row
                  return row.isEdit
                    ? _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "default-time": "00:00:00",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: row.downWorkTime,
                          callback: function($$v) {
                            _vm.$set(row, "downWorkTime", $$v)
                          },
                          expression: "row.downWorkTime"
                        }
                      })
                    : _c("div", [_vm._v(" " + _vm._s(row.downWorkTime))])
                }
              },
              {
                key: "singleUpWorkWorkHours",
                fn: function(ref) {
                  var row = ref.row
                  return row.isEdit
                    ? _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: row.singleUpWorkWorkHours,
                          callback: function($$v) {
                            _vm.$set(row, "singleUpWorkWorkHours", $$v)
                          },
                          expression: "row.singleUpWorkWorkHours"
                        }
                      })
                    : _c("div", [
                        _vm._v(" " + _vm._s(row.singleUpWorkWorkHours))
                      ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.permission("Edit") && row.direction === 1
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle(
                                      row.isEdit ? "save" : "edit",
                                      row
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.isEdit ? "保存" : "编辑"))]
                            )
                          ]
                        : _vm._e(),
                      row.isEdit
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  row.isEdit = false
                                }
                              }
                            },
                            [_vm._v("取消")]
                          )
                        : _vm._e(),
                      _vm.permission("Del") && row.direction === 1
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle("del", row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }
              }
            ],
            null,
            true
          )
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }