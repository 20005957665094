// 列表
export const columnsAll = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'warehouseType',
    label: '仓库',
    sortable: false,
    hiddenSearch: true,
    slotName: 'warehouseType',
    width: 200
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'procedureName',
    label: '工序',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    minWidth: 300
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantity',
    label: '投产数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'width',
    label: '宽度',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'steelGrade',
    label: '钢卷号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'coilNo',
    label: '牌号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'operator',
    label: '操作人',
    minWidth: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '关联工单',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 200,
    slotName: 'produceWorkOrderNumber'
  },
  {
    prop: 'workOrderQuantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: false,
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 200
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    slotName: 'remark',
    width: 120
  }
]
