// 列表
export const columnsAll = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 150
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    minWidth: 300
  },
  {
    prop: 'moldInstances',
    label: '所用模具',
    slotName: 'moldInstances',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'materialsUnit',
    label: '物料单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'operator',
    label: '操作人',
    minWidth: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'frequencyName',
    label: '班次',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operateTime',
    label: '报工时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '关联工单',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 200,
    slotName: 'produceWorkOrderNumber'
  },
  {
    prop: 'workOrderQuantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: false,
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 200
  },
  {
    prop: 'stationName',
    label: '报工工位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'procedureDetails',
    label: '工序参数',
    hiddenSearch: true,
    sortable: false,
    slotName: 'procedureDetails'
  },
  {
    prop: 'checkFinishTime',
    label: '检验完成时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'spendWorkingHours',
    label: '实际工时（小时）',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'convertValue',
    label: '米数',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'width',
    label: '宽度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'thickness',
    label: '厚度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'steelGrade',
    label: '钢卷号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'coilNo',
    label: '牌号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    slotName: 'remark',
    width: 120
  }
]
