<template>
  <div class="name" @click="selectLine(data)">
    <span>{{ data.name }}</span>
  </div>
</template>

<script>
export default {
  name: 'TestLeft',
  props: {
    data: Object
  },
  methods: {
    selectLine(datas) {
      this.$emit('selectLine', datas)
    }
  }
}
</script>

<style scoped lang="scss">
.name {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  height: calc(100% + 1px);
  width: 100%;
  padding: 0 10px;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid #ececec;
  cursor: pointer;
  &:hover {
    background: rgba(230, 232, 239, 0.8);
  }
  span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #393D60;
    line-height: 20px;
    word-wrap: break-word;
  }
}
.colorBar {
  width: 10px;
  height: 100%;
}

.carId {
  flex: 1;
}

.type {
  padding: 0 5px 0 0;
  font-size: 1.2rem;
}
</style>
