var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchFormTask", {
            ref: "searchForm",
            attrs: { "form-option": _vm.formOptions },
            on: { resetData: _vm.resetData, searchData: _vm.searchTable }
          })
        ],
        1
      ),
      _c("el-divider", { staticClass: "e-divider" }),
      _c(
        "div",
        { staticClass: "materials-info" },
        [
          _c(
            "FunctionHeader",
            {
              ref: "functionHeader",
              staticClass: "functionHeader",
              attrs: {
                "search-title": "工单号/工序名称/生产批次码",
                "columns-key": _vm.columnsKey,
                "show-export": !_vm.selectData.length ? true : false,
                "export-name": _vm.functionHeader.exportName,
                "export-params": _vm.functionHeader.exportParams
              },
              on: {
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectData.length,
                      expression: "selectData.length"
                    }
                  ],
                  attrs: { slot: "all" },
                  slot: "all"
                },
                [
                  _vm.permission("OperatorTaskExport")
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.batchExport()
                            }
                          }
                        },
                        [_vm._v("批量导出")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "0 10px",
                        display: "inline-block",
                        "font-size": "12px",
                        color: "#393D60"
                      }
                    },
                    [
                      _vm._v(" 已选 "),
                      _c("span", { staticStyle: { color: "#607FFF" } }, [
                        _vm._v(_vm._s(_vm.getSelectionDataLength))
                      ]),
                      _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: { click: _vm.resetSelectData }
                    },
                    [_vm._v("清空")]
                  )
                ],
                1
              )
            ]
          ),
          _c("MTable", {
            ref: "mTable",
            staticClass: "mTable",
            attrs: {
              columns: _vm.operationTask,
              "set-data-method": _vm.getTableDatas,
              height: _vm.height,
              "columns-key": _vm.columnsKey
            },
            on: { "selection-change": _vm.selectionChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "stationEquipment",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewStationEquipment(row)
                        }
                      }
                    },
                    [_vm._v("查看")]
                  )
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    row.status || row.status == 0
                      ? _c(
                          "div",
                          {
                            style: {
                              color: [
                                "#FA6400",
                                "#00AB29",
                                "#607FFF",
                                "#ff797a"
                              ][row.status]
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.produceTaskStatus[row.status].name)
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                }
              },
              {
                key: "materialsCode",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewMaterialsDetail(row.materialsId)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.materialsCode))]
                  )
                }
              },
              {
                key: "produceOrderNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.isShowChild
                        ? _c("NewPageOpen", {
                            attrs: {
                              path: {
                                name: "orderDetail",
                                query: { id: row.produceOrderId }
                              },
                              text: row.produceOrderNumber
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "produceWorkOrderNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      row.procedureClass === 1
                        ? _c("img", {
                            staticClass: "icon-img",
                            attrs: {
                              src: require("@/assets/plans/repair-icon-have.png")
                            }
                          })
                        : _vm._e(),
                      _vm.isShowChild
                        ? _c("NewPageOpen", {
                            attrs: {
                              path: {
                                name: "workDetail",
                                query: { id: row.produceWorkOrderId }
                              },
                              text: row.produceWorkOrderNumber
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "batchNumberList",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.fixData(row.produceWorkOrder.batchNumberList)
                        )
                      )
                    ])
                  ])
                }
              },
              {
                key: "currentProcedure",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.produceWorkOrder.produceWorkOrderTaskList
                              ? _vm.fixProduceData(
                                  row.produceWorkOrder.produceWorkOrderTaskList,
                                  "procedureName"
                                )
                              : ""
                          ) +
                          " "
                      )
                    ])
                  ])
                }
              },
              {
                key: "currentProcedureGroupName",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.produceWorkOrderTaskList
                              ? _vm.fixProduceData(
                                  row.produceWorkOrder.produceWorkOrderTaskList,
                                  "procedureGroupName"
                                )
                              : ""
                          ) +
                          " "
                      )
                    ])
                  ])
                }
              },
              {
                key: "procedureClass",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.procedureClass[row.procedureClass].name)
                      )
                    ])
                  ])
                }
              },
              {
                key: "procedureType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.procedureType[row.procedureType]
                            ? _vm.procedureType[row.procedureType].name
                            : ""
                        ) + " "
                      )
                    ])
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    { staticClass: "align-btn" },
                    [
                      row.isArrival === 0 &&
                      _vm.permission("OutSource") &&
                      row.status !== 3
                        ? _c(
                            "a",
                            {
                              staticClass: "color-btns",
                              on: {
                                click: function($event) {
                                  return _vm.outSources(row)
                                }
                              }
                            },
                            [_vm._v("外协")]
                          )
                        : _c("a", { staticClass: "color-gray" }, [
                            _vm._v("外协")
                          ]),
                      _vm.permission("OutSource")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      row.isDeparture !== 1
                        ? _c(
                            "span",
                            [
                              row.isIssue !== 1 &&
                              row.isAssign !== 1 &&
                              _vm.permission("OperatorTaskDownStation") &&
                              row.status !== 3
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "color-btns wi-btns",
                                      on: {
                                        click: function($event) {
                                          return _vm.stationClick(row, 1)
                                        }
                                      }
                                    },
                                    [_vm._v("下发至工位")]
                                  )
                                : row.isIssue !== 1 &&
                                  row.isAssign !== 1 &&
                                  _vm.permission("OperatorTaskDownStation") &&
                                  row.status === 3
                                ? _c("a", { staticClass: "color-gray" }, [
                                    _vm._v("下发至工位")
                                  ])
                                : row.isIssue === 1 &&
                                  row.isAssign !== 1 &&
                                  _vm.permission("OperatorTaskDownStation") &&
                                  row.status !== 3
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "color-btn ",
                                      on: {
                                        click: function($event) {
                                          return _vm.stationClick(row, 2)
                                        }
                                      }
                                    },
                                    [_vm._v("已下发")]
                                  )
                                : row.isIssue === 1 &&
                                  row.isAssign !== 1 &&
                                  _vm.permission("OperatorTaskDownStation") &&
                                  row.status === 3
                                ? _c("a", { staticClass: "color-gray" }, [
                                    _vm._v("已下发")
                                  ])
                                : _vm.permission("OperatorTaskDownStation")
                                ? _c("a", { staticClass: "color-gray" }, [
                                    _vm._v("已下发")
                                  ])
                                : _vm._e(),
                              _vm.permission("OperatorTaskDownStation")
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  })
                                : _vm._e(),
                              _vm.permission("OperatorTaskAssignTask")
                                ? [
                                    row.isAssign === 1 && row.status !== 3
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "color-btn",
                                            on: {
                                              click: function($event) {
                                                return _vm.assignClick(row, 2)
                                              }
                                            }
                                          },
                                          [_vm._v("已指派")]
                                        )
                                      : (row.isAssign !== 1 &&
                                          row.procedureIssueMode === 1 &&
                                          row.isIssue === 0) ||
                                        row.status === 3
                                      ? _c("a", { staticClass: "color-gray" }, [
                                          _vm._v("指派任务")
                                        ])
                                      : _c(
                                          "a",
                                          {
                                            staticClass: "color-btns wi-btn",
                                            on: {
                                              click: function($event) {
                                                return _vm.assignClick(row, 1)
                                              }
                                            }
                                          },
                                          [_vm._v("指派任务")]
                                        )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      row.isDeparture === 1
                        ? _c(
                            "span",
                            [
                              row.isIssue !== 1 &&
                              row.isAssign !== 1 &&
                              _vm.permission("OperatorTaskDownStation")
                                ? _c("a", { staticClass: "color-gray" }, [
                                    _vm._v("下发至工位")
                                  ])
                                : _vm._e(),
                              row.isIssue === 1 &&
                              row.isAssign !== 1 &&
                              _vm.permission("OperatorTaskDownStation")
                                ? _c("a", { staticClass: "color-gray" }, [
                                    _vm._v("已下发")
                                  ])
                                : _vm._e(),
                              _vm.permission("OperatorTaskDownStation")
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  })
                                : _vm._e(),
                              _vm.permission("OperatorTaskAssignTask")
                                ? [
                                    row.isAssign !== 1
                                      ? _c("a", { staticClass: "color-gray" }, [
                                          _vm._v("指派任务")
                                        ])
                                      : _vm._e(),
                                    row.isAssign === 1
                                      ? _c("a", { staticClass: "color-gray" }, [
                                          _vm._v("已指派")
                                        ])
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "外协申请" },
          on: { onOk: _vm.saveOutSource },
          model: {
            value: _vm.outSourceVisible,
            callback: function($$v) {
              _vm.outSourceVisible = $$v
            },
            expression: "outSourceVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "dialogFormBuilds",
            attrs: {
              "form-data": _vm.dialogFormDatas,
              "form-list": _vm.dialogFormLists
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          ref: "modelStation",
          staticStyle: { height: "90%" },
          attrs: {
            title: "下发至工位",
            "append-to-body": true,
            showFooter: _vm.showDetail
          },
          on: { onOk: _vm.dialogSubmit },
          model: {
            value: _vm.dialogVisible,
            callback: function($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "all-flexs" },
            [
              _c("div", { staticClass: "model-tit" }, [
                _vm._v("可下发数量：" + _vm._s(_vm.taskQunlity) + " 个")
              ]),
              !_vm.showDetail
                ? _c(
                    "el-button",
                    {
                      staticClass: "cancel-btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAssign(0)
                        }
                      }
                    },
                    [_vm._v("取消下发")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.showDetail
            ? _c(
                "div",
                { staticClass: "all-form" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        size: "small",
                        multiple: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.objectStation,
                        callback: function($$v) {
                          _vm.objectStation = $$v
                        },
                        expression: "objectStation"
                      }
                    },
                    _vm._l(_vm.stationList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                        nativeOn: {
                          click: function($event) {
                            return _vm.selectStation(item)
                          }
                        }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "all-btn",
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addStation }
                    },
                    [_vm._v("添加工位")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("MTable", {
            ref: "sTable",
            attrs: {
              height: 250,
              "show-page": false,
              data: _vm.addStationList,
              columns: _vm.columnsStation,
              "columns-setting": false
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "quantity",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        !_vm.showDetail
                          ? _c("span", [_vm._v(_vm._s(row.quantity))])
                          : _vm._e(),
                        _vm.showDetail
                          ? _c("el-input-number", {
                              attrs: { "controls-position": "right" },
                              model: {
                                value: row.quantity,
                                callback: function($$v) {
                                  _vm.$set(row, "quantity", $$v)
                                },
                                expression: "row.quantity"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    return _vm.showDetail
                      ? _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.delTask(row.stationId)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            title: "指派任务",
            "append-to-body": true,
            "show-footer": false
          },
          on: { onOk: _vm.assignSubmit },
          model: {
            value: _vm.assignVisible,
            callback: function($$v) {
              _vm.assignVisible = $$v
            },
            expression: "assignVisible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "all-btns" },
            [
              !_vm.showAssign
                ? _c(
                    "el-button",
                    {
                      staticClass: "cancel-btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAssign(0)
                        }
                      }
                    },
                    [_vm._v("取消下发")]
                  )
                : _vm._e(),
              _vm.showAssign
                ? _c(
                    "el-button",
                    {
                      staticClass: "btns",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAssign(2)
                        }
                      }
                    },
                    [_vm._v("取消下发及指派")]
                  )
                : _vm._e(),
              _vm.showAssign
                ? _c(
                    "el-button",
                    {
                      staticClass: "btns",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAssign(1)
                        }
                      }
                    },
                    [_vm._v("仅取消指派")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("MTable", {
            ref: "mTables",
            attrs: {
              height: 300,
              "show-page": false,
              data: _vm.addStationList,
              columns: _vm.columnsStation,
              "columns-setting": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "quantity",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [_vm._v(_vm._s(row.quantity))])
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c(
                    "div",
                    {},
                    [
                      !row.userList
                        ? _c(
                            "el-button",
                            {
                              staticClass: "li-btn",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.assignStation(row, 1, $index)
                                }
                              }
                            },
                            [_vm._v("指派任务")]
                          )
                        : _vm._e(),
                      row.userList
                        ? _c(
                            "el-button",
                            {
                              staticClass: "color-btn li-btn",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.assignStation(row, 2, $index)
                                }
                              }
                            },
                            [_vm._v("已指派")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "指派任务" },
          on: { onOk: _vm.submitUser },
          model: {
            value: _vm.assignVisibleNo,
            callback: function($$v) {
              _vm.assignVisibleNo = $$v
            },
            expression: "assignVisibleNo"
          }
        },
        [
          _vm.showAssign
            ? _c(
                "div",
                { staticClass: "all-flexss" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "cancel-btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAssign(1)
                        }
                      }
                    },
                    [_vm._v("取消指派")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("SelectUserAndUserGroup", {
            ref: "userAndUserGroup",
            attrs: {
              "is-assign": false,
              "user-options": _vm.userOptions,
              "user-group-options": _vm.userGroupOptions
            },
            model: {
              value: _vm.transferSelectIds,
              callback: function($$v) {
                _vm.transferSelectIds = $$v
              },
              expression: "transferSelectIds"
            }
          })
        ],
        1
      ),
      _c("edit-materials", { ref: "editMaterials" }),
      _c("TableDialog", {
        attrs: {
          title: "查看工位/设备",
          show: _vm.workVisible,
          columns: _vm.columnsStations,
          "table-data": _vm.workList
        },
        on: {
          "update:show": function($event) {
            _vm.workVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }