<template>
  <div class="searchs">
    <!-- 顶部折叠搜索 -->
    <el-row>
      <el-col :md="18" :gutter="10">
        <el-row :gutter="10" type="flex" justify="start" style="flex-flow:row wrap">
          <el-col :md="6">
            <el-select v-model="searchFormData.status" placeholder="生产进度" filterable multiple clearable collapse-tags @change="searchData">
              <el-option
                v-for="item in productionStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <SelectMultipleMaterialsNew ref="multipleMaterial" @typeChange="searchData" />
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.priority" placeholder="计划优先级" filterable multiple clearable collapse-tags @change="searchData">
              <el-option
                v-for="(item, index) in priorityStatus"
                :key="index"
                :label="item.name"
                :value="index"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <select-procedure-search v-if="memberCode === 'reagold'" :list="getCurrentProcedureList" @getValues="getValues" />
            <el-select v-else v-model="searchFormData.currentProcedureIds" collapse-tags placeholder="当前所处工序" filterable multiple clearable @change="searchData">
              <el-option
                v-for="item in getCurrentProcedureList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="!show" type="flex" justify="start" style="flex-flow:row wrap" :gutter="10">
          <el-col :md="6">
            <el-cascader
              v-model="searchFormData.procedureClassIds"
              placeholder="工序分类"
              :options="currentProcedureClassList"
              :props="{ label: 'name', value: 'id', multiple: true, checkStrictly: false}"
              collapse-tags
              clearable
              style="width:100%"
            />
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.currentProcedureGroupIds" collapse-tags placeholder="当前所处工序段" filterable multiple clearable @change="currentGroupIdChange">
              <el-option
                v-for="item in currentProcedureGroupNames"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.source" placeholder="工单来源" clearable @change="searchData">
              <el-option
                v-for="item in taskSource"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.isRepair" placeholder="工单属性" clearable @change="searchData">
              <el-option
                v-for="item in procedureClass"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.processFlowId" placeholder="工艺" clearable filterable @change="searchData">
              <el-option
                v-for="item in processFlowList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="selectFlower(item)"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.processFlowVersionId" placeholder="工艺版本" clearable @change="searchData">
              <el-option
                v-for="item in versionList"
                :key="item.id"
                :label="item.version"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.areaId" placeholder="产线" clearable filterable @change="searchData">
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.delayDate" placeholder="是否延期" clearable @change="searchData">
              <el-option
                v-for="item in isDelay"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-date-picker
              v-model="searchFormData.deliveryTime"
              type="daterange"
              range-separator="至"
              start-placeholder="交期时间"
              end-placeholder="交期时间"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col :md="6">
            <el-date-picker
              v-model="searchFormData.createTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="创建时间"
              end-placeholder="创建时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col :md="6">
            <el-date-picker
              v-model="searchFormData.planStartTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="计划开始时间"
              end-placeholder="计划开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col :md="6">
            <el-date-picker
              v-model="searchFormData.planEndTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="计划结束时间"
              end-placeholder="计划结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col :md="6">
            <el-date-picker
              v-model="searchFormData.actualStartTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="实际开始时间"
              end-placeholder="实际开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col :md="6">
            <el-date-picker
              v-model="searchFormData.actualEndTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="实际结束时间"
              end-placeholder="实际结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col :md="6" class="all-btns">
        <el-button size="small" type="primary" @click="searchData">查询</el-button>
        <el-button size="small" @click="resetData">重置</el-button>
        <div class="showMore" :style="{background: show ? '' : '#F8F8F8'}" @click="changeMore">
          <span>高级筛选</span>
          <i v-if="show" class="el-icon-caret-bottom icons" />
          <i v-else class="el-icon-caret-top" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { taskSource, productionStatus, procedureClass, isDelay, isSuspend } from '@/config/options.config'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import { getUUid } from '@/utils'
import dayjs from 'dayjs'
import CURDSelectApi from '@/api/CURDSelect'
import SelectProcedureSearch from '@/components/SelectProcedureSearch/SelectProcedureSearch'
export default {
  components: { SelectProcedureSearch, SelectMultipleMaterialsNew },
  props: {
    formOption: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isSuspend,
      isDelay,
      procedureClass,
      taskSource,
      productionStatus,
      show: true,
      processFlowList: [],
      areaList: [],
      versionList: [],
      orderNumbers: [],
      currentProcedureClassList: [],
      currentProcedures: [],
      currentProcedureGroupNames: [],
      searchFormData: {
        source: '',
        status: [0, 1],
        produceOrderNumber: undefined,
        processFlowVersionId: '',
        currentProcedureIds: '',
        procedureClassIds: '',
        processFlowId: '',
        areaId: '',
        isPaused: '',
        produceOrderId: []
      },
      selectKeyword: '0',
      searchDatas: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = dayjs().format('YYYY-MM-DD 23:59:59')
            const start = dayjs().format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '昨天',
          onClick(picker) {
            const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
            const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    getCurrentProcedureList() {
      return this.currentProcedures.filter(item =>
        !this.searchFormData.currentProcedureGroupIds ||
        !this.searchFormData.currentProcedureGroupIds.length ||
        this.searchFormData.currentProcedureGroupIds.includes(item.groupId)
      )
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    this.processFlowList = this.formOption.flowerOptions
    this.currentProcedures = this.formOption.operatorOptions
    this.areaList = this.formOption.areaOptions
    this.currentProcedureClassList = this.formOption.getCurrentProcedureClassList
    this.getProcedureGroupNameList()
  },
  methods: {
    getValues(val) {
      this.searchFormData.currentProcedureIds = val
    },
    async getProcedureGroupNameList() {
      const res = await CURDSelectApi.getProcedureGroupList()
      if (res) {
        this.currentProcedureGroupNames = res
      }
    },
    currentGroupIdChange() {
      this.searchFormData.currentProcedureIds = []
    },
    selectFlower(row) {
      this.versionList = row.versionList
      this.searchFormData.processFlowVersionId = ''
    },
    changeMore() {
      this.show = !this.show
    },
    // 顶部查询
    searchData() {
      const obj = {}
      let isDelay = 0
      const searchDatas = []
      let currentProcedureIds = []
      let procedureClassIds = []
      Object.keys(this.searchFormData).forEach(item => {
        if (Array.isArray(this.searchFormData[item])) {
          if (this.searchFormData[item].length > 0) {
            if (item === 'produceOrderId' || item === 'priority' || item === 'status') {
              searchDatas.push({
                id: getUUid(),
                fieldName: item,
                fieldType: 'number',
                fieldValue: this.searchFormData[item].join('^'),
                operator: 'in'
              })
            } else if (item === 'procedureClassIds') {
              procedureClassIds = this.searchFormData[item].map(items => this._.last(items)).join('^')
            } else if (item === 'currentProcedureIds') {
              currentProcedureIds = this.searchFormData[item].join('^')
            } else if (item === 'currentProcedureGroupIds') {
              currentProcedureIds = this.getCurrentProcedureList.map(c => c.id).join('^')
            } else if (item === 'deliveryTime') {
              searchDatas.push({
                id: getUUid(),
                fieldName: item,
                fieldType: 'date',
                maxValue: `${dayjs(this.searchFormData[item][1]).format('YYYY-MM-DD')}`,
                minValue: `${dayjs(this.searchFormData[item][0]).format('YYYY-MM-DD')}`,
                operator: 'range'
              })
            } else {
              searchDatas.push({
                id: getUUid(),
                fieldName: item,
                fieldType: 'date',
                maxValue: `${dayjs(this.searchFormData[item][1]).format('YYYY-MM-DD')} 23:59:59`,
                minValue: `${dayjs(this.searchFormData[item][0]).format('YYYY-MM-DD')} 00:00:00`,
                operator: 'range'
              })
            }
          }
        } else if (this.searchFormData[item] || this.searchFormData[item] === 0) {
          if (item === 'delayDate') {
            isDelay = this.searchFormData[item]
          } else {
            searchDatas.push({
              id: getUUid(),
              fieldName: item,
              fieldType: typeof this.searchFormData[item],
              fieldValue: item === 'currentProcedure' ? 'procedureId\":' + this.searchFormData[item] + ',' : this.searchFormData[item],
              operator: typeof this.searchFormData[item] === 'number' ? 'eq' : 'like'
            })
          }
        }
      })
      // console.log('搜索数据', searchDatas)
      const keyword = this.$refs.multipleMaterial.keyword.code
      this.$emit('searchData', searchDatas, this.selectKeyword, keyword, currentProcedureIds, isDelay, procedureClassIds)
    },
    // 顶部重置
    resetData() {
      this.searchFormData = {
        source: '',
        status: '',
        produceOrderNumber: undefined,
        processFlowVersionId: '',
        currentProcedure: '',
        processFlowId: '',
        areaId: '',
        isPaused: ''
      }
      this.$refs.multipleMaterial.keyword = ''
      this.$emit('resetData')
    }
  }
}
</script>

<style scoped lang="scss">
 .searchs{
    padding-top: 10px;

    .input-with-select{
      width: 100%;
      .input-select{
        width: 105px;
      }
   }
    ::v-deep{
      .el-col-md-5, .el-col-md-6{
        margin-bottom: 10px;
      }
     .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
        width: 100%;
        height: 32px;
      }
      .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width: 100%;
        height: 32px;
      }
      .el-select{
        width: 100%;
      }
       .el-input--suffix .el-input__inner {
        height:32px;
      }
      .el-button-group .el-button{
        height:32px;
      }
      .el-date-editor .el-range-separator, .el-date-editor .el-range__icon {
        line-height: 27px;
      }
   }
  .showMore{
    width: 88px;
    height: 40px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    .icons{
     color: #DDE3EB;
    }
    &:hover{
      background:#F8F8F8;
      i{ color: #607FFF;}
    }
  }
  .all-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
     ::v-deep{
      .el-button{
        height: 32px;
        line-height: 4px;
      }
    }
  }
}
</style>
