var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plan-detail-wrapper" },
    [
      _vm.permission("plansModifyMaterial") && _vm.baseFormData.status !== 2
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { size: "small", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.modifyMaterial()
                }
              }
            },
            [_vm._v("编辑")]
          )
        : _vm._e(),
      _vm.activeName === "plans"
        ? _c("MTable", {
            ref: "mTableM",
            attrs: {
              height: 350,
              "set-data-method": _vm.planMaterials,
              columns: _vm.noplans,
              "columns-setting": false
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "index",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                }
              ],
              null,
              false,
              843682904
            )
          })
        : _vm._e(),
      _c(
        "MDialog",
        {
          attrs: { title: "编辑计划用料", width: "80%" },
          on: { onOk: _vm.saveMaterials },
          model: {
            value: _vm.plansVisible,
            callback: function($$v) {
              _vm.plansVisible = $$v
            },
            expression: "plansVisible"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.slectMaterial }
                },
                [_vm._v("添加物料")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.delMaterial }
                },
                [_vm._v("删除")]
              )
            ],
            1
          ),
          _c("MTable", {
            ref: "planTable",
            staticStyle: { "margin-bottom": "5px" },
            attrs: {
              "show-page": false,
              "highlight-current-row": true,
              height: _vm.heights,
              columns: _vm.plans,
              data: _vm.planMaterialsList
            },
            on: { "selection-change": _vm.selectionChanges },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "procedureName",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "flex-start"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { color: "#f00", width: "20px" } },
                        [_vm._v("*")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "170px" },
                          attrs: { placeholder: "请选择", filterable: "" },
                          on: {
                            change: function($event) {
                              return _vm.selectProceduce(row, $index)
                            }
                          },
                          model: {
                            value: row.procedureId,
                            callback: function($$v) {
                              _vm.$set(row, "procedureId", $$v)
                            },
                            expression: "row.procedureId"
                          }
                        },
                        _vm._l(_vm.procedureList, function(item) {
                          return _c("el-option", {
                            key: item.procedureId,
                            attrs: {
                              label: item.procedureName,
                              value: item.procedureId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "materialsQuantity",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "98%" },
                        attrs: {
                          min: 0,
                          "controls-position": "right",
                          step: 1
                        },
                        model: {
                          value: row.materialsQuantity,
                          callback: function($$v) {
                            _vm.$set(row, "materialsQuantity", $$v)
                          },
                          expression: "row.materialsQuantity"
                        }
                      })
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c("select-materials", {
        attrs: { visible: _vm.showMaterials },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }