<template>
  <div class="orders">
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data="searchFormData"
        style="padding: 0"
        @search="searchTable"
      />
    </div>
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      search-title="请输入工单号/订单号"
      :tags="!selectData.length ? functionHeader.tags :[]"
      :export-name="functionHeader.exportName"
      :export-params="functionHeader.exportParams"
      :show-export = "!selectData.length ? true : false"
      style="padding: 10px 0 10px 0;margin-left: -10px;"
      class="func-header"
      @search="$refs.mTable.setTableData(true)"
    >
      <div slot="all" v-show="selectData.length">
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="batchExport()">批量导出</el-button>
        <el-button v-if="permission('BatchCheck')" type="primary" size="small" @click="allCheck()">批量通过</el-button>
        <el-button v-if="permission('BatchCancel')" size="small" type="primary"  @click="allCancel()">批量驳回</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
      </div>
    </FunctionHeader>
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="produceWorkOrderNumber" slot-scope="{ row }">
        <NewPageOpen v-if="isShowChild" :path="{ name: 'workDetail', query: {id: row.produceWorkOrderId}}" :text="row.produceWorkOrderNumber" />
      </div>
      <div slot="produceOrderNumber" slot-scope="{ row }">
        <NewPageOpen v-if="isShowChild" :path="{ name: 'orderDetail', query: {id: row.produceWorkOrderId}}" :text="row.produceOrderNumber" />
      </div>
      <div slot="status" slot-scope="{ row }">
        <div class="faultStatuss" :style="{ color: outSourceStatus[row.status] ? outSourceStatus[row.status].color : '', background: outSourceStatus[row.status] ? outSourceStatus[row.status].background : ''}">{{ outSourceStatus[row.status].name }}</div>
      </div>
      <div slot="action" slot-scope="{ row }">
        <a v-if="permission('BatchCheck') && row.status === 0" class="li-btn" @click="allCheck(1, row)">通过</a>
        <a v-else class="li-btn color-btns">通过</a>
        <el-divider v-if="permission('BatchCheck')" direction="vertical" />
        <a v-if="permission('BatchCancel') && row.status === 0" class="li-btn" @click="allCancel(1, row)">驳回</a>
        <a v-else class="li-btn color-btns">驳回</a>
        <el-divider v-if="permission('BatchCancel')" direction="vertical" />
        <a v-if="permission('Cancel') && row.status === 1" class="li-btn" @click="cancels(row)">撤销</a>
        <a v-else class="li-btn color-btns">撤销</a>
      </div>
    </MTable>
  </div>
</template>
<script>
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { searchFormList } from './form-list'
import { outSourceStatus } from '@/config/options.config'
import api from '@/api/production/outSource'
import { columnsAll } from './columns'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import processApi from '@/api/information/production/process'
import workApi from '@/api/production/work-order'
import materApi from '@/api/information/materials'
import stationApi from '@/api/information/production/station'
import equipmentApi from '@/api/equipment'
import { export_json_to_excel2 } from '@/utils/plug/to-excel'
import fixCommonPage from '@/mixin/fix-common-page'

export default {
  name: 'OutSourceLists',
  components: { NewPageOpen, FunctionHeader, SearchForm },
  mixins: [fixCommonPage],
  data() {
    return {
      api,
      searchFormList,
      outSourceStatus,
      visible: false,
      searchFormData: [],
      searchFormData: {},
      functionHeader: {
        searchVal: '',
        exportName: 'exportOutSource',
        tags: [],
        exportParams: {}
      },
      columns: columnsAll,
      searchDatas: [],
      selectionData: [],
      selectId: '',
      allMaterialsList: [],
      keyword: '',
      selectKeyword: '1',
      show: false,
      groupList: [],
      formOptions: {
        orderOptions: [],
        proceduresOptions: []
      },
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          },
          {
              id: getUUid(),
              fieldName: 'isVirtualMaterials',
              fieldType: 'number',
              fieldValue: 0,
              operator: 'eq',
            }
        ]
        ))
      },
      selectData: [],
      selectionDataList: [],
      batchParam: {},
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 15
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionDataList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    this.getMatertialPages()
    this.getOperatorPages()
  },
  methods: {
    allMore(command) {
      const functions = {
        allCheck: () => { this.allCheck() },
        allCancel: () => { this.allCancel() },
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    selectionChange(data) {
      this.selectData = data.map(item => item.id)
      this.selectionDataList = data
    },
    resetSelectData() {
      this.selectionDataList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionDataList = this.selectionDataList.filter((item) => item.selectable)
    },
    batchExport() {
      const ids = [
        {
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: this.selectData.join('^'),
          operator: 'in'
        }
      ]
      const params = {
        searchVal: Encrypt(JSON.stringify(ids))
      }
      this.$refs.functionHeader.export(params)
    },
    fixData(val) {
      if (val) {
        return val.split('^').join('；')
      }
    },

    // 物料
    async getMatertialPages() {
      const res = await materApi.getMaterialsList(this.defaultSearch)
      if (res) {
        this.searchFormList[1].children[0].options = res
      }
    },
    // 工序
    async getOperatorPages() {
      const res = await workApi.getOperatorPage()
      if (res) {
        this.searchFormList[2].children[0].options = res
      }
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      console.log(search, this.searchDatas)
      const params = {
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchDatas]))
      }
      // 导出
      this.functionHeader.exportParams = params
      const res = await api.getOutsourcingRequestListPage({
        page: page.currentPage,
        limit: page.pageSize,
        ...params
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    // 撤销
    async cancels(row) {
      this.$confirm('您确定要撤销吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.cancelOutsourcingRequest({ requestIds: row.id })
        if (res) {
          this.$message.success('撤销成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    // 批量通过
    async allCheck(type, row) {
      if (type === 1) {
        const res = await api.reviewOutsourcingRequest({ requestIds: row.id })
        if (res) {
          this.$message.success('通过成功')
          this.$refs.mTable.setTableData()
        }
        return false
      }
      if (this.selectData.length > 0) {
        const data = this.selectData.join('^')
        const res = await api.reviewOutsourcingRequest({ requestIds: data })
        if (res) {
          this.$message.success('通过成功')
          this.$refs.mTable.setTableData()
        }
      } else {
        this.$message.error('请选择要通过的数据')
      }
    },
    // 批量驳回
    async allCancel(type, row) {
      if (type === 1) {
        const res = await api.rejectOutsourcingRequest({ requestIds: row.id })
        if (res) {
          this.$message.success('驳回成功')
          this.$refs.mTable.setTableData()
        }
        return false
      }
      if (this.selectData.length > 0) {
        const data = this.selectData.join('^')
        const res = await api.rejectOutsourcingRequest({ requestIds: data })
        if (res) {
          this.$message.success('驳回成功')
          this.$refs.mTable.setTableData()
        }
      } else {
        this.$message.error('请选择要驳回的数据')
      }
    },
    // 查询d
    searchTable(data, type) {
      this.searchDatas = data
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
 .orders{
    padding: 10px;
    .faultStatuss {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      height: 22px;
      line-height: 22px;
      border-radius: 4px;
      padding: 0 5px;
      text-align: center;
      display: inline-block;
    }
    .li-btn{
      color:#607FFF;
      font-weight: 500;
    }
    .color-btns{
      color:#c4c4c4;
    }
    .searchs{
      padding-left: 0;
      padding-top: 0;
    }
    .input-select{
      width:100px;
      display:block;
      }
    .input-with-select{
      width:100%;
    }
    .all-operator{
      height: 32px;
      margin-left: 10px;
      ::v-deep {
        .el-button--primary, .el-button-group .el-button{
          height: 32px;
        }
      }
    }
    .func-header {
      ::v-deep .el-input--suffix .el-input__inner{
        height: 32px;
      }
    }
 }
 ::v-deep .customer-content div {
   width: 100%;
 }
 .tip-tit {
   height: 18px;
   line-height: 18px;
 }
 div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #ECEEF2;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #393D60;
  margin-left: 10px;
}

div.tooltip:before, div.tooltip:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #ECEEF2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #ECEEF2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.params-card {
  border: 1px solid #ECECF7;
  margin-bottom: 20px;
  .header {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background: #ECECF7;
  }
  .body {
    width: 100%;
    .bot-ti{
      // height: 36px;
      line-height:36px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      display: inline-block;
      margin-bottom: 16px;
      margin-left: 20px;
      word-break: break-all;
      .imgs{
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 10px;
        img{
        width: 100px;
        height: 100px;
        display: inline-block;
        }
      }
    }
  }
}
</style>
