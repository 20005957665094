var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          staticStyle: { display: "none" },
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _vm.permission("WorkOrderList")
            ? _c("el-radio-button", { attrs: { label: "WorkOrder" } }, [
                _vm._v("生产工单")
              ])
            : _vm._e(),
          _vm.permission("OperatorTaskList")
            ? _c("el-radio-button", { attrs: { label: "OperatorTask" } }, [
                _vm._v("自制任务")
              ])
            : _vm._e(),
          _vm.permission("OutSourceTaskList")
            ? _c("el-radio-button", { attrs: { label: "OutSourceTask" } }, [
                _vm._v("外协任务")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.params.radio === "WorkOrder" && _vm.permission("WorkOrderList")
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "top" },
                [
                  _c("SearchFormOrder", {
                    ref: "searchForms",
                    attrs: { "form-option": _vm.formOptions },
                    on: {
                      resetData: _vm.resetData,
                      searchData: _vm.searchTable
                    }
                  })
                ],
                1
              ),
              _c("el-divider", { staticClass: "e-divider" }),
              _c(
                "div",
                { staticClass: "materials-info" },
                [
                  _c(
                    "FunctionHeader",
                    {
                      ref: "functionHeader",
                      staticClass: "functionHeaders",
                      style: {
                        marginLeft: _vm.selectData.length ? 0 : "-10px"
                      },
                      attrs: {
                        "export-name": _vm.functionHeader.exportName,
                        "export-params": _vm.functionHeader.exportParams,
                        "search-title": "批次码/工单号/订单号/生产计划号",
                        tags: _vm.functionHeader.tags,
                        "show-export": !_vm.selectData.length ? true : false
                      },
                      on: {
                        search: function($event) {
                          return _vm.$refs.mTables.setTableData(true)
                        }
                      },
                      model: {
                        value: _vm.functionHeader.searchVal,
                        callback: function($$v) {
                          _vm.$set(_vm.functionHeader, "searchVal", $$v)
                        },
                        expression: "functionHeader.searchVal"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selectData.length,
                              expression: "selectData.length"
                            }
                          ],
                          attrs: { slot: "all" },
                          slot: "all"
                        },
                        [
                          _vm.permission("BatchPrint")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.batchCode()
                                    }
                                  }
                                },
                                [_vm._v("批量打印")]
                              )
                            : _vm._e(),
                          _vm.permission("Export")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.batchExport()
                                    }
                                  }
                                },
                                [_vm._v("批量导出")]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                margin: "0 10px",
                                display: "inline-block",
                                "font-size": "12px",
                                color: "#393D60"
                              }
                            },
                            [
                              _vm._v(" 已选 "),
                              _c(
                                "span",
                                { staticStyle: { color: "#607FFF" } },
                                [_vm._v(_vm._s(_vm.getSelectionDataLength))]
                              ),
                              _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: { click: _vm.resetSelectData }
                            },
                            [_vm._v("清空")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("MTable", {
                    ref: "mTables",
                    staticClass: "mTable",
                    attrs: {
                      columns: _vm.columnsAll,
                      "set-data-method": _vm.getTableData,
                      height: _vm.height
                    },
                    on: { "selection-change": _vm.selectionChange },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "materialsCode",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.viewMaterialsDetail(
                                      row.materialsId
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.materialsCode))]
                            )
                          }
                        },
                        {
                          key: "workProcess",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _c("el-progress", {
                                  attrs: {
                                    "stroke-width": 10,
                                    percentage:
                                      _vm.fixNumber(row) >= 100
                                        ? 100
                                        : _vm.fixNumber(row),
                                    format: _vm.fixNumber(row, 1)
                                  }
                                })
                              ],
                              1
                            )
                          }
                        },
                        {
                          key: "produceWorkOrderNumber",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                row.isRepair === 0 && row.repairRecordList
                                  ? _c("img", {
                                      staticClass: "icon-img",
                                      attrs: {
                                        src: require("@/assets/plans/repair-icon.png")
                                      }
                                    })
                                  : _vm._e(),
                                row.isRepair === 1
                                  ? _c("img", {
                                      staticClass: "icon-img",
                                      attrs: {
                                        src: require("@/assets/plans/repair-icon-have.png")
                                      }
                                    })
                                  : _vm._e(),
                                _vm.isShowChild
                                  ? _c("NewPageOpen", {
                                      attrs: {
                                        path: {
                                          name: "workDetail",
                                          query: { id: row.id }
                                        },
                                        text: row.produceWorkOrderNumber
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }
                        },
                        {
                          key: "currentProcedure",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    row.produceWorkOrderTaskList
                                      ? _vm.fixProduceData(
                                          row.produceWorkOrderTaskList,
                                          "procedureName"
                                        )
                                      : ""
                                  )
                                )
                              ])
                            ])
                          }
                        },
                        {
                          key: "currentProcedureGroupName",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    row.produceWorkOrderTaskList
                                      ? _vm.fixProduceData(
                                          row.produceWorkOrderTaskList,
                                          "procedureGroupName"
                                        )
                                      : ""
                                  )
                                )
                              ])
                            ])
                          }
                        },
                        {
                          key: "source",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _c("div", [
                                _vm._v(_vm._s(_vm.taskSource[row.source].name))
                              ])
                            ])
                          }
                        },
                        {
                          key: "produceOrderNumber",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _vm.isShowChild
                                  ? _c("NewPageOpen", {
                                      attrs: {
                                        path: {
                                          name: "orderDetail",
                                          query: { id: row.produceOrderId }
                                        },
                                        text: row.produceOrderNumber
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }
                        },
                        {
                          key: "priority",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _c(
                                "div",
                                {
                                  staticClass: "tag-priority",
                                  style: {
                                    color: _vm.priorityStatus[row.priority]
                                      ? _vm.priorityStatus[row.priority].color
                                      : "rgb(74, 79, 118)",
                                    background:
                                      (_vm.priorityStatus[row.priority]
                                        ? _vm.priorityStatus[row.priority].color
                                        : "rgb(74, 79, 118)") + "20"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.priorityStatus[row.priority]
                                          ? _vm.priorityStatus[row.priority]
                                              .name
                                          : "普通"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          }
                        },
                        {
                          key: "status",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: [
                                      "#FA6400",
                                      "#00AB29",
                                      "#607FFF",
                                      "#ff797a"
                                    ][row.status]
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.productionStatus[row.status] &&
                                          _vm.productionStatus[row.status].name
                                      )
                                  )
                                ]
                              )
                            ])
                          }
                        },
                        {
                          key: "processFlowName",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showProcess(row)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(row.processFlowName))]
                                )
                              ],
                              1
                            )
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var row = ref.row
                            return _vm.permission(_vm.params.radio + "Del")
                              ? _c(
                                  "div",
                                  { staticClass: "all-oper" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "li-btn",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.printWorkOrder(row)
                                          }
                                        }
                                      },
                                      [_vm._v("打印")]
                                    ),
                                    row.isPaused === 0 &&
                                    ![2, 3].includes(row.status)
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "li-btn",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.modifyPause(0, row)
                                              }
                                            }
                                          },
                                          [_vm._v("暂停")]
                                        )
                                      : _vm._e(),
                                    row.isPaused === 1 &&
                                    ![2, 3].includes(row.status)
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "li-btn",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.modifyPause(1, row)
                                              }
                                            }
                                          },
                                          [_vm._v("恢复")]
                                        )
                                      : _vm._e(),
                                    _vm.showDownLoadBtn
                                      ? _c("DownloadDocx", {
                                          attrs: { id: row.id }
                                        })
                                      : _vm._e(),
                                    _vm.permission(_vm.params.radio + "Del") &&
                                    row.status === 0
                                      ? _c("DelPopover", {
                                          staticClass: "li-btn",
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          on: {
                                            onOk: function(callback) {
                                              return _vm.del(row, callback)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.params.radio === "OperatorTask" && _vm.permission("OperatorTaskList")
        ? _c(
            "div",
            [_c("work-task", { attrs: { radio: _vm.params.radio } })],
            1
          )
        : _vm._e(),
      _vm.params.radio === "OutSourceTask" &&
      _vm.permission("OutSourceTaskList")
        ? _c(
            "div",
            [_c("work-out-source", { attrs: { radio: _vm.params.radio } })],
            1
          )
        : _vm._e(),
      _c(
        "MDialog",
        {
          attrs: { title: "工艺路线", "show-footer": false, width: "85%" },
          on: {
            onOk: function($event) {
              _vm.processVisible = false
            }
          },
          model: {
            value: _vm.processVisible,
            callback: function($$v) {
              _vm.processVisible = $$v
            },
            expression: "processVisible"
          }
        },
        [
          _c("single-process-flow", {
            ref: "processFlow",
            attrs: {
              "current-procedure-names": _vm.currentProcedureName,
              "read-only": !_vm.saveButton,
              "all-work-order-task-list": _vm.allWorkTask ? _vm.allWorkTask : []
            }
          })
        ],
        1
      ),
      _c("edit-materials", { ref: "editMaterials" }),
      _c("PrintOrder", {
        ref: "printCodes",
        attrs: { "common-ids": _vm.commonIds }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }