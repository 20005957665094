<template>
  <div class="work-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange" style="display:none;">
      <el-radio-button v-if="permission('WorkOrderList')" label="WorkOrder">生产工单</el-radio-button>
      <el-radio-button v-if="permission('OperatorTaskList')" label="OperatorTask">自制任务</el-radio-button>
      <el-radio-button v-if="permission('OutSourceTaskList')" label="OutSourceTask">外协任务</el-radio-button>
    </el-radio-group>
    <div v-if="params.radio === 'WorkOrder' && permission('WorkOrderList')">
      <div class="top">
        <SearchFormOrder
          ref="searchForms"
          :form-option="formOptions"
          @resetData="resetData"
          @searchData="searchTable"
        />
      </div>
      <el-divider class="e-divider" />
      <div class="materials-info">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          search-title="批次码/工单号/订单号/生产计划号"
          class="functionHeaders"
          :tags="functionHeader.tags"
          :style="{ marginLeft : selectData.length ? 0 : '-10px'}"
          :show-export = "!selectData.length ? true : false"
          @search="$refs.mTables.setTableData(true)"
        >
          <div slot="all" v-show="selectData.length">
            <el-button v-if="permission('BatchPrint')" type="primary" size="small" @click="batchCode()">批量打印</el-button>
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="batchExport()">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
          </div>
        </FunctionHeader>
        <MTable
          ref="mTables"
          :columns="columnsAll"
          :set-data-method="getTableData"
          class="mTable"
          :height="height"
          @selection-change="selectionChange"
        >
          <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
          <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
          <div slot="workProcess" slot-scope="{ row }">
            <el-progress
              :stroke-width="10"
              :percentage="fixNumber(row) >= 100 ? 100 : fixNumber(row)"
              :format="fixNumber(row, 1)"
            />
          </div>
          <div slot="produceWorkOrderNumber" slot-scope="{ row }">
            <img v-if="row.isRepair === 0 && row.repairRecordList" src="@/assets/plans/repair-icon.png" class="icon-img">
            <img v-if="row.isRepair === 1" src="@/assets/plans/repair-icon-have.png" class="icon-img">
            <NewPageOpen v-if="isShowChild" :path="{ name: 'workDetail', query: {id: row.id}}" :text="row.produceWorkOrderNumber" />
          </div>
          <div slot="currentProcedure" slot-scope="{ row }">
            <div>{{ row.produceWorkOrderTaskList ? fixProduceData(row.produceWorkOrderTaskList, 'procedureName') : '' }}</div>
          </div>
          <div slot="currentProcedureGroupName" slot-scope="{ row }">
            <div>{{ row.produceWorkOrderTaskList ? fixProduceData(row.produceWorkOrderTaskList, 'procedureGroupName') : '' }}</div>
          </div>
          <div slot="source" slot-scope="{ row }">
            <div>{{ taskSource[row.source].name }}</div>
          </div>
          <div slot="produceOrderNumber" slot-scope="{ row }">
            <NewPageOpen v-if="isShowChild" :path="{ name: 'orderDetail', query: {id: row.produceOrderId}}" :text="row.produceOrderNumber" />
          </div>
          <div slot="priority" slot-scope="{ row }">
            <div
              :style="{ color: priorityStatus[row.priority] ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)', background: `${priorityStatus[row.priority] ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)'}20`}"
              class="tag-priority"
            >
              {{ priorityStatus[row.priority] ? priorityStatus[row.priority].name : '普通' }}
            </div>
          </div>
          <div slot="status" slot-scope="{ row }">
            <div :style="{color:['#FA6400','#00AB29','#607FFF','#ff797a'][row.status]}"> {{ productionStatus[row.status]&&productionStatus[row.status].name }}</div>
          </div>
          <div slot="processFlowName" slot-scope="{ row }">
            <el-button type="text" @click="showProcess(row)">{{ row.processFlowName }}</el-button>
          </div>

          <div v-if="permission(params.radio + 'Del')" slot="action" slot-scope="{ row }" class="all-oper">
            <el-button type="text" class="li-btn" @click="printWorkOrder(row)">打印</el-button>
            <el-button v-if="row.isPaused === 0 && ![2,3].includes(row.status)" type="text" class="li-btn" @click="modifyPause(0, row)">暂停</el-button>
            <el-button v-if="row.isPaused === 1 && ![2,3].includes(row.status)" type="text" class="li-btn" @click="modifyPause(1, row)">恢复</el-button>
            <DownloadDocx v-if="showDownLoadBtn" :id="row.id"></DownloadDocx>


            <DelPopover v-if="permission(params.radio + 'Del') && row.status === 0" style="margin-left:10px" class="li-btn" @onOk="(callback) => del(row, callback)" />
          </div>
        </MTable>
      </div>
    </div>
    <div v-if="params.radio === 'OperatorTask' && permission('OperatorTaskList')">
      <work-task :radio="params.radio" />
    </div>
    <div v-if="params.radio === 'OutSourceTask' && permission('OutSourceTaskList')">
      <work-out-source :radio="params.radio" />
    </div>
    <!-- 工艺路线 -->
    <MDialog v-model="processVisible" title="工艺路线" :show-footer="false" width="85%" @onOk="processVisible = false">
      <single-process-flow ref="processFlow" :current-procedure-names="currentProcedureName" :read-only="!saveButton" :all-work-order-task-list="allWorkTask ? allWorkTask : []" />
      <!-- <process-flow ref="processFlow" :read-only="!saveButton" :process-flow-detail="processFlowVersion" :showVersion="true"/> -->
    </MDialog>
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
    <!-- 打印工单 -->
    <PrintOrder ref="printCodes" :common-ids="commonIds" />
  </div>
</template>
<script>
import SearchFormOrder from './components/search-form-order'
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index'
import { columnsAll } from './columns'
import api from '@/api/production/work-order'
import apis from '@/api/production/plans'
import { getUUid, setTreeData } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { taskSource, productionStatus } from '@/config/options.config'
import WorkTask from './work-task'
import WorkOutSource from './work-out-source'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import processApi from '@/api/information/production/process'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import PrintOrder from '@/components/PrintCode/printWorkOrder'
import singleProcessFlow from '@/components/singleProcessFlow/singleProcessFlow'
import dayjs from 'dayjs'
import { export_json_to_excel2 } from '@/utils/plug/to-excel'
import fixCommonPage from '@/mixin/fix-common-page'
import pApi from '@/api/information/production/procedure'
import DownloadDocx from './components/download-docx/download-docx'

export default {
  name: 'WorkList',
  components: { singleProcessFlow, PrintOrder, WorkOutSource, NewPageOpen, EditMaterials, FunctionHeader, SearchFormOrder, DelPopover, WorkTask, ProcessFlow ,DownloadDocx},
  mixins: [viewMaterialsDetail, fixCommonPage],
  data() {
    return {
      api,
      currentProcedureName: '',
      procedureClassIds: '',
      saveButton: false,
      commonIds: 'print',
      selectData: [],
      columnsAll,
      taskSource,
      productionStatus,
      searchDatas: [
        {
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: '0^1',
          operator: 'in'
        }
      ],
      selectionData: [],
      currentType: 1,
      sorts: '',
      params: {
        radio: 'WorkOrder'
      },
      functionHeader: {
        searchVal: '',
        exportName: 'exportTask',
        exportParams: {},
        tags: []
      },
      selectKeyword: '1',
      formOptions: {
        operatorOptions: [],
        flowerOptions: [],
        areaOptions: [],
        getCurrentProcedureClassList: []
      },
      keyword: '',
      processVisible: false,
      saveButton: false,
      processFlowVersion: {},
      currentProcedureIds: [],
      sortList: [
        {
          id: '1',
          name: '按计划开始时间最早'
        },
        {
          id: '2',
          name: '按交期最早'
        },
        {
          id: '3',
          name: '按优先级最高'
        },
        {
          id: '4',
          name: '按优先级最低'
        }
      ],
      sortData: '',
      isDelay: 0,
      taskData: [],
      lists: [],
      allWorkTask: [],
      allWorkOrderTaskList: [],
      batchParam: {},
      showDownLoadBtn:false,
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 48 - 12
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  created(){
    this.params.radio = this.$route.meta.nameFlage ? this.$route.meta.nameFlage : 'WorkOrder'
  },
  mounted() {
    this.processFlowList()
    this.getOperatorPages()
    this.getAreaList()
    this.getProcedureClassList()
    let memberCode=sessionStorage.getItem('memberCode')
    if ( memberCode=== 'zhangchuang'||memberCode=== 'test'||memberCode=== 'apifox'||memberCode=== 'zhanghao'||memberCode=== 'falab'||memberCode=== 'dmdz') {
      this.showDownLoadBtn = true
    }
  },
  methods: {
    async forceEnd({ id }) {
      const res = await api.forceCloseWorkOrder(id)
      if (res) {
        this.$message.success('操作成功')
        this.$refs.mTables.setTableData(true)
      }
    },
    async getProcedureClassList() {
      const res = await pApi.getProcedureClassList()
      if (res) {
        this.formOptions.getCurrentProcedureClassList = setTreeData(res)
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.currentProcedureClassList = setTreeData(res)
        }
      }
    },
    fixNumber(row, format) {
      if (!row.reportWorkQuantity || !row.quantity) {
        if (format) {
          return () => {
            return 0 + '%'
          }
        } else {
          return 0
        }
      } else {
        if (format) {
          return () => {
            return parseInt((row.reportWorkQuantity / row.quantity * 100).toFixed(2)) + '%'
          }
        } else {
          return parseInt((row.reportWorkQuantity / row.quantity * 100).toFixed(2))
        }
      }
    },
    allMore(command) {
      const functions = {
        batchCode: () => { this.batchCode() },
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    batchExport() {
      const data = this.selectData.map(item => item.id).join('^')
      const ids = [
        {
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: data,
          operator: 'in'
        }
      ]
      const params = {
        searchVal: Encrypt(JSON.stringify(ids))
      }
      this.$refs.functionHeader.export(params)
    },
    async getProduceLists(id) {
      const res = await api.getProduceAllLists({
        workOrderIds: id
      })
      if (res) {
        this.allWorkOrderTaskList = res
      }
    },
    async getProduceProcessList(row) {
      const res = await api.getProduceProcessList({
        id: row.id
      })
      if (res) {
        this.allWorkTask = res
      }
    },
    selectionChange(data) {
      this.selectData = data
    },
    resetSelectData() {
      this.selectData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTables.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectData = this.selectData.filter((item) => item.selectable)
    },
    async batchCode() {
      const data = this._.cloneDeep(this.selectData)
      if (data.length > 0) {
        const ids = this.selectData.map(item => item.id).join('^')
        await this.getProduceLists(ids)
        const info = data.map(item => {
          return {
            ...item,
            priority: this.priorityStatus[item.priority].name,
            taskData: this.allWorkOrderTaskList[item.id]
          }
        })
        this.commonIds = `${this.selectData[0]}print`
        this.$refs.printCodes.prints(info)
      } else {
        this.$message.error('请选择要打印的工单')
      }
    },
    // 打印工单
    async printWorkOrder(row) {
      this.commonIds = `${row.produceWorkOrderNumber}print`
      await this.getProduceLists(row.id)
      const info = this._.cloneDeep(row)
      info.priority = this.priorityStatus[info.priority].name
      info.deliveryTime = info.deliveryTime.substring(0, 10)
      info.taskData = this.allWorkOrderTaskList[row.id]
      this.$refs.printCodes.print(info)
    },
    async modifyPause(val, item) {
      if (val === 0) {
        const res = await api.pauseWorkOrder(item.id)
        if (res) {
          this.$refs.mTables.setTableData()
        }
      } else {
        const res = await api.startWorkOrder(item.id)
        if (res) {
          this.$refs.mTables.setTableData()
        }
      }
    },
    jumpOrderDetail(row) {
      this.$router.push({ name: 'orderDetail', query: { id: row.produceOrderId }})
    },
    fixProduceData(val, key) {
      const list = val.filter(item => item[key]).map(item => item[key])
      return list.length === 1 ? list[0] : list.join('，')
    },
    async showProcess(row) {
      await this.getProduceProcessList(row)
      this.currentProcedureName = row.currentProcedureName
      // await this.getProcessDetailById(row.processFlowId, row.processFlowVersionId)
      this.processVisible = true
    },
    // 获取产线
    async getAreaList() {
      const search = [
        {
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: 2,
          operator: 'eq'
        },
        {
          id: getUUid(),
          fieldName: 'isEnabled',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }
      ]
      const res = await processApi.getAreaList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.formOptions.areaOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.areaList = res
        }
      }
    },
    // 获取工艺详情
    async getProcessDetailById(id, versionId) {
      const res = await processApi.getProcessDetail({ id: id, versionId: versionId })
      if (res) {
        this.processFlowVersion = res.processFlowVersion
      }
    },
    fixData(val) {
      if (val && val.length > 0) {
        const data = val.map(item => item.batchNumber)
        return data.join()
      } else {
        return ''
      }
    },
    // 排序
    handleCommand() {
      const titles = new Map([
        ['1', '+planStartTime'],
        ['2', '+deliveryTime'],
        ['3', '+priority'],
        ['4', '-priority']
      ])
      this.sorts = titles.get(this.sortData)
      this.$refs.mTables.setTableData()
    },
    // 工序
    async getOperatorPages() {
      const res = await api.getOperatorPage()
      if (res) {
        this.formOptions.operatorOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.currentProcedures = res
        }
      }
    },
    // 选择工艺
    async processFlowList() {
      const res = await apis.getFlowers({
        isGetAreaList: 1,
        isGetVersionList: 1
      })
      if (res) {
        this.formOptions.flowerOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.processFlowList = res
        }
      }
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      console.log(search, this.searchDatas)
      const params = {
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        materialsSearchType: this.selectKeyword,
        materialsSearchValue: this.keyword,
        currentProcedureIds: this.currentProcedureIds,
        procedureClassIds: this.procedureClassIds,
        isDelay: this.isDelay,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchDatas]))
      }
      // 导出
      this.functionHeader.exportParams = params
      const res = await api.getPlanpage({
        page: page.currentPage,
        limit: page.pageSize,
        ...params
      })
      if (res) {
        const data = res.records.map(item => ({
          ...item,
          actualStartTime: item.actualStartTime,
          actualEndTime: item.actualEndTime,
          remark: item.producePlan ? item.producePlan.remark : '',
          createTime: item.createTime,
          creator: item.producePlan ? item.producePlan.creator : ''
        })
        )
        this.lists = data
        callback({
          total: res.total,
          content: data
        })
        this.total = res.total
      }
      callback()
    },
    radioChange() {
      if (this.params.radio === 'WorkOrder') {
        this.searchDatas = [
          {
            id: getUUid(),
            fieldName: 'status',
            fieldType: 'number',
            fieldValue: '0^1',
            operator: 'in'
          }
        ]
        this.selectData = []
        this.processFlowList()
        this.getOperatorPages()
      }
    },
    // 查询
    searchTable(data, val, keyword, ids, isDelay, procedureClassIds) {
      this.searchDatas = data
      this.keyword = keyword
      this.selectKeyword = val
      this.currentProcedureIds = ids
      this.procedureClassIds = procedureClassIds
      this.isDelay = isDelay
      this.$refs.mTables.setTableData(true)
    },
    async del(row) {
      const res = await api.deletePlan({ id: row.id })
      if (res) {
        this.$message.success('删除成功')
        this.$refs.mTables.tableRefs().clearSelection()
        this.$refs.mTables.setTableData()
      }
    },
    // 重置
    resetData() {
      this.searchDatas = []
      this.keyword = ''
      this.selectKeyword = '1'
      this.currentProcedureIds = []
      this.$refs.mTables.setTableData(true)
    },
    jumpDetail(row) {
      this.$router.push({ path: 'work-detail', query: { id: row.id }})
    }
  }
}
</script>

<style scoped lang="scss">
.work-wrap {
  width: 100%;
  padding: 10px;
  .icon-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 19px;
    height: 19px;
    display: block;
  }
  ::v-deep{
    .e-divider{
    margin: 0px 0 0 0;
    }
  }
  .mTable{
  ::v-deep .el-button{
    padding: 0 0px;
  }
}
  .li-btn{
    // margin-right:10px;
    color:#607FFF;
    text-align:center;
    font-weight: 500;
  }
  .input-select{
    width:120px;
    display:block;
  }
  .input-with-select{
    width:100%;
  }
  .searchs{
    padding-left: 0;
  }
  .color-btns{
    color:#c4c4c4;
  }
  .all-oper{
    text-align:center;
    // display: flex;
    // justify-content: center;
  }
}
.functionHeaders{
  margin-left: 0px;
  ::v-deep .el-input--suffix .el-input__inner{
    height: 32px;
  }
}
.all-operator{
  height:32px;
  ::v-deep {
    .el-button--primary, .el-button-group .el-button{
      height: 32px;
    }
  }
}

</style>
