var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "MDialog",
        {
          attrs: { title: "发起返修", width: "80%" },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c(
            "div",
            { staticClass: "batchImport" },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.stepActive,
                    "finish-status": "success",
                    "process-status": "finish"
                  }
                },
                [
                  _c("el-step", {
                    attrs: {
                      title: "选择返修批次",
                      description: "请选择返修批次并输入数量"
                    }
                  }),
                  _c("el-step", {
                    attrs: {
                      title: "确定返修工艺路线",
                      description: "请确定返修需经历的工艺路线"
                    }
                  }),
                  _c("el-step", {
                    attrs: {
                      title: "确认返修",
                      description: "请确认并生成相应返修计划、及返修批次码"
                    }
                  })
                ],
                1
              ),
              _vm.stepActive === 0
                ? _c("div", [
                    _c("div", { staticClass: "repair-detail" }, [
                      _c(
                        "div",
                        { staticClass: "repair-num" },
                        [
                          _c("div", { staticClass: "num-label" }, [
                            _vm._v("请选择或扫描批次码：")
                          ]),
                          _c(
                            "el-select",
                            {
                              staticClass: "num-select",
                              attrs: {
                                placeholder: "请选择或扫描批次码",
                                filterable: ""
                              },
                              model: {
                                value: _vm.formData.batchNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "batchNumber", $$v)
                                },
                                expression: "formData.batchNumber"
                              }
                            },
                            _vm._l(_vm.repairList, function(item) {
                              return _c("el-option", {
                                key: item.batchNumber,
                                attrs: {
                                  label: item.batchNumber,
                                  value: item.batchNumber
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.selectBatch(item)
                                  }
                                }
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "num-btn",
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.save }
                            },
                            [_vm._v("确认")]
                          )
                        ],
                        1
                      ),
                      _vm.isShow
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "repair-num" },
                                [
                                  _c("div", { staticClass: "num-label" }, [
                                    _vm._v("请选择发生返修的工序：")
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        filterable: ""
                                      },
                                      model: {
                                        value: _vm.formData.procedureName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "procedureName",
                                            $$v
                                          )
                                        },
                                        expression: "formData.procedureName"
                                      }
                                    },
                                    _vm._l(_vm.procedureList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.procedureName,
                                          value: item.procedureName
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.selectProcedure(item)
                                          }
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("MTable", {
                                ref: "mTable",
                                attrs: {
                                  data: _vm.formData.repairStationList,
                                  height: 150,
                                  columns: _vm.stationColums,
                                  "columns-setting": false,
                                  "show-page": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "quantity",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return _c(
                                          "div",
                                          {},
                                          [
                                            _c("el-input-number", {
                                              attrs: {
                                                min: 1,
                                                max: row.baoQuantity,
                                                "controls-position": "right",
                                                step: 1
                                              },
                                              model: {
                                                value: row.initQuantity,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "initQuantity",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.initQuantity"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  865293674
                                )
                              }),
                              _c("div", { staticClass: "repair-nums" }, [
                                _vm._v(" 返修数量：" + _vm._s(_vm.nums) + " ")
                              ]),
                              _vm.formData.repairParallelProcedureList.length >
                              0
                                ? _c("div", { staticClass: "mul-num" }, [
                                    _c("div", { staticClass: "tit" }, [
                                      _vm._v(
                                        "请勾选已报工该返修批次的并行工序："
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "mul-check" },
                                      _vm._l(
                                        _vm.formData
                                          .repairParallelProcedureList,
                                        function(item) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.id,
                                              staticClass: "check-li"
                                            },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value: item.checked,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "checked",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.checked"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.procedureName)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "li-right" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "ri-tit" },
                                                    [_vm._v("报工数量：")]
                                                  ),
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      min: 1,
                                                      max: item.baoQuantity,
                                                      "controls-position":
                                                        "right",
                                                      step: 1
                                                    },
                                                    model: {
                                                      value: item.initQuantity,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "initQuantity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.initQuantity"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm.stepActive === 1
                ? _c("div", [
                    _vm.showProcess
                      ? _c("div", { staticClass: "repair-detail" }, [
                          _c(
                            "div",
                            { staticClass: "repair-num" },
                            [
                              _c("div", { staticClass: "num-label" }, [
                                _vm._v("请选择返修工艺路线：")
                              ]),
                              _c(
                                "el-input",
                                {
                                  staticClass: "num-select",
                                  model: {
                                    value: _vm.formData.processFlowName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "processFlowName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.processFlowName"
                                  }
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "append" },
                                    [
                                      _c("el-button", {
                                        attrs: { icon: "el-icon-more" },
                                        on: { click: _vm.selectProcessFlows }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "repair-num" }, [
                            _c("div", { staticClass: "num-label" }),
                            _c("img", {
                              staticClass: "num-img",
                              attrs: { src: require("@/assets/plans/add.png") },
                              on: { click: _vm.addProcess }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "num-tits",
                                on: { click: _vm.addProcess }
                              },
                              [_vm._v("新增返修工艺路线")]
                            )
                          ])
                        ])
                      : _vm._e(),
                    !_vm.showProcess
                      ? _c(
                          "div",
                          { staticClass: "repair-detail" },
                          [
                            _c("div", { staticClass: "repair-tit" }, [
                              _vm._v("确认返修工艺路线图")
                            ]),
                            _c("process-flow", {
                              ref: "processFlow",
                              attrs: {
                                "area-id-list": _vm.areaIdList,
                                "read-only": _vm.saveButton,
                                "process-flow-details": _vm.processFlowVersion,
                                "produce-work-order-id": _vm.produceWorkOrderId
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.stepActive === 2
                ? _c("div", [
                    _c("div", { staticClass: "repair-detail" }, [
                      _c("div", { staticClass: "repair-num" }, [
                        _c("div", { staticClass: "num-label num-width" }, [
                          _vm._v("需返修批次码：")
                        ]),
                        _c("div", { staticClass: "num-dec" }, [
                          _vm._v(_vm._s(_vm.formData.batchNumber))
                        ])
                      ]),
                      _c("div", { staticClass: "repair-num" }, [
                        _c("div", { staticClass: "num-label num-width" }, [
                          _vm._v("返修数量：")
                        ]),
                        _c("div", { staticClass: "num-dec colorNum" }, [
                          _vm._v(_vm._s(_vm.nums))
                        ])
                      ]),
                      _c("div", { staticClass: "repair-num" }, [
                        _c("div", { staticClass: "num-label num-width" }, [
                          _vm._v("返修结算工序：")
                        ]),
                        _c("div", { staticClass: "num-dec" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formData.procedureName))
                          ]),
                          _c("span", { staticClass: "spas" }, [
                            _vm._v(
                              "原工单工序任务从返修结算工序的后道工序任务开始扣除返修数量"
                            )
                          ])
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "repair-num" },
                        [
                          _c("div", { staticClass: "num-label num-width" }, [
                            _c("span", { staticClass: "icon-color" }, [
                              _vm._v("*")
                            ]),
                            _vm._v("产线：")
                          ]),
                          _c(
                            "el-select",
                            {
                              staticClass: "num-select",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formData.areaId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "areaId", $$v)
                                },
                                expression: "formData.areaId"
                              }
                            },
                            _vm._l(_vm.areaIds, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "repair-num" },
                        [
                          _c("div", { staticClass: "num-label num-width" }, [
                            _c("span", { staticClass: "icon-color" }, [
                              _vm._v("*")
                            ]),
                            _vm._v("交期：")
                          ]),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              type: "date",
                              placeholder: "请选择",
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd"
                            },
                            model: {
                              value: _vm.formData.deliveryTime,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "deliveryTime", $$v)
                              },
                              expression: "formData.deliveryTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "repair-num" },
                        [
                          _c("div", { staticClass: "num-label num-width" }, [
                            _vm._v("计划开始时间：")
                          ]),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "请选择开始时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm"
                            },
                            model: {
                              value: _vm.formData.planStartTime,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "planStartTime", $$v)
                              },
                              expression: "formData.planStartTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "repair-num" },
                        [
                          _c("div", { staticClass: "num-label num-width" }, [
                            _vm._v("计划结束时间：")
                          ]),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "请选择结束时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm"
                            },
                            model: {
                              value: _vm.formData.planEndTime,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "planEndTime", $$v)
                              },
                              expression: "formData.planEndTime"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.stepActive === 3
                ? _c("div", [
                    _c("div", { staticClass: "repair-detail" }, [
                      _c("img", {
                        staticClass: "img-sucs",
                        attrs: {
                          src: "https://oss.mubyte.cn/static/data-success.png"
                        }
                      }),
                      _c("div", { staticClass: "suc-tits" }, [
                        _vm._v("新增返修计划成功")
                      ]),
                      _c("div", { staticClass: "li-all" }, [
                        _c("div", { staticClass: "li-le" }, [
                          _vm._v("新返修批次码：" + _vm._s(_vm.batchNumber))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "li-ri",
                            on: { click: _vm.printCode }
                          },
                          [_vm._v("打印")]
                        )
                      ]),
                      _c("div", { staticClass: "li-all" }, [
                        _c("div", { staticClass: "li-le" }, [
                          _vm._v("返修计划号：" + _vm._s(_vm.producePlanNumber))
                        ]),
                        _c(
                          "div",
                          { staticClass: "li-ri", on: { click: _vm.goPlan } },
                          [_vm._v("去生产计划列表")]
                        )
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mubai-dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.stepActive !== 3
                ? _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v(_vm._s(_vm.cancelText[_vm.stepActive]))
                  ])
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.submitLoading,
                    disabled: _vm.uploading
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(_vm._s(_vm.confirmTextFun()))]
              )
            ],
            1
          )
        ]
      ),
      _c("SelectProcessFlow", {
        ref: "processFlow",
        attrs: {
          visible: _vm.processFlowVisible,
          "select-process-and-version-ids": _vm.processIdAndVersionId,
          "proce-type": _vm.proceType
        },
        on: {
          "update:visible": function($event) {
            _vm.processFlowVisible = $event
          },
          submit: _vm.selectFlow
        }
      }),
      _vm.memberCode === "reagold"
        ? _c("PrintBatchNumber", {
            ref: "printCode",
            attrs: { "common-id": _vm.commonId }
          })
        : _vm.memberCode === "kiki"
        ? _c("PrintBatchNumberKiKi", {
            ref: "printCode",
            attrs: { "common-id": _vm.commonId }
          })
        : _c("PrintCode", {
            ref: "printCode",
            attrs: { "common-id": _vm.commonId }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }