var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "procedure-detail-wrapper" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "workList" })
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("工单详情")])
          ]
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", loading: _vm.buttonLoading },
                on: { click: _vm.startRepair }
              },
              [_vm._v(" 发起返修 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", loading: _vm.buttonLoading },
                on: { click: _vm.printOrder }
              },
              [_vm._v(" 打印 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", loading: _vm.buttonLoading },
                on: { click: _vm.delTask }
              },
              [_vm._v(" 删除 ")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "procedure-detail-body" },
        [
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _c("BaseData", {
                  ref: "BaseData",
                  attrs: { "detail-data": _vm.baseFormData }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("生产物料")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _c("MTable", {
                  ref: "mTable1",
                  attrs: {
                    "show-page": false,
                    height: 150,
                    columns: _vm.columnsBase,
                    data: _vm.materialsList,
                    "columns-setting": false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("工艺路线")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _c("process-flow", {
                  ref: "processFlow",
                  attrs: {
                    "read-only": !_vm.saveButton,
                    "process-flow-detail": _vm.processFlowVersion
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("产品图纸及附件")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _vm._m(0),
                _c("br"),
                _c("MTable", {
                  ref: "mTable2",
                  attrs: {
                    "show-page": false,
                    height: 150,
                    columns: _vm.fileAndDrawings,
                    data: _vm.drawingsList,
                    "columns-setting": false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "fileName",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _c(
                            "a",
                            {
                              staticStyle: { color: "#607FFF" },
                              attrs: {
                                href: row.url,
                                download: row.name,
                                target: "_blank"
                              }
                            },
                            [_vm._v(_vm._s(row.name))]
                          ),
                          _c("img", {
                            staticStyle: {
                              width: "11px",
                              "margin-left": "5px"
                            },
                            attrs: {
                              src: require("@/assets/information/procedure/附件@2x.png")
                            }
                          })
                        ])
                      }
                    }
                  ])
                }),
                _vm._m(1),
                _c("br"),
                _c("MTable", {
                  ref: "mTable3",
                  attrs: {
                    "show-page": false,
                    height: 150,
                    data: _vm.filesList,
                    columns: _vm.fileAndDrawings,
                    "columns-setting": false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "fileName",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _c(
                            "a",
                            {
                              staticStyle: { color: "#607FFF" },
                              attrs: {
                                href: row.url,
                                download: row.name,
                                target: "_blank"
                              }
                            },
                            [_vm._v(_vm._s(row.name))]
                          ),
                          _c("img", {
                            staticStyle: {
                              width: "11px",
                              "margin-left": "5px"
                            },
                            attrs: {
                              src: require("@/assets/information/procedure/附件@2x.png")
                            }
                          })
                        ])
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "批次信息", name: "first" } },
                [
                  _c(
                    "FunctionHeader",
                    {
                      staticStyle: { "padding-top": "0" },
                      attrs: {
                        tags: _vm.functionHeader.tags,
                        "hidden-search": true
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { slot: "all" },
                          slot: "all"
                        },
                        [
                          _c(
                            "el-dropdown",
                            {
                              attrs: { "split-button": "" },
                              on: {
                                command: function(command) {
                                  _vm.allMore(command)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _vm._v(" 批量操作 ")
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown"
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "batchPrint" } },
                                    [_vm._v("打印")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "allDel" } },
                                    [_vm._v("删除")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("MTable", {
                    ref: "mTable4",
                    attrs: {
                      height: 300,
                      data: _vm.batchNumberList,
                      columns: _vm.batchNumbers,
                      "show-page": false,
                      "columns-setting": false
                    },
                    on: { "selection-change": _vm.selectionChange },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "action",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            { staticClass: "all-btns" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.printCode(row)
                                    }
                                  }
                                },
                                [_vm._v("打印批次码")]
                              ),
                              _c("el-divider", {
                                attrs: { direction: "vertical" }
                              }),
                              row.isUse !== 1
                                ? _c("DelPopover", {
                                    staticClass: "li-btn",
                                    on: {
                                      onOk: function(callback) {
                                        return _vm.del(row, callback)
                                      }
                                    }
                                  })
                                : _c(
                                    "el-button",
                                    { attrs: { type: "text", disabled: "" } },
                                    [_vm._v("删除")]
                                  )
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "投产记录", name: "second" } },
                [
                  _c("FunctionHeader", {
                    staticStyle: { "padding-top": "0" },
                    attrs: {
                      "export-name": "exportReceiveMaterials",
                      "hidden-search": true,
                      "export-params": _vm.searchData
                    }
                  }),
                  _c("MTable", {
                    ref: "mTable5",
                    attrs: {
                      "set-data-method": _vm.getReceiveMaterialsList,
                      height: 300,
                      columns: _vm.receiveMaterials,
                      "columns-setting": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "name",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(_vm._s(row.materials.name))
                          ])
                        }
                      },
                      {
                        key: "code",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(_vm._s(row.materials.code))
                          ])
                        }
                      },
                      {
                        key: "specifications",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(_vm._s(row.materials.specifications))
                          ])
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "报工记录", name: "third" } },
                [
                  _c("FunctionHeader", {
                    staticStyle: { "padding-top": "0" },
                    attrs: {
                      "export-name": "exportReportWork",
                      "hidden-search": true,
                      "export-params": _vm.searchData
                    }
                  }),
                  _c("MTable", {
                    ref: "mTable6",
                    attrs: {
                      "set-data-method": _vm.getTaskList,
                      height: 300,
                      columns: _vm.reportWork,
                      "columns-setting": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "action",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.scanWorkRecord(row)
                                    }
                                  }
                                },
                                [_vm._v("查看报工记录")]
                              ),
                              _c("el-divider", {
                                attrs: { direction: "vertical" }
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.scanWasteRecord(row)
                                    }
                                  }
                                },
                                [_vm._v("查看报废历史")]
                              )
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "返修记录", name: "four" } },
                [
                  _c("FunctionHeader", {
                    staticStyle: { "padding-top": "0" },
                    attrs: {
                      "export-name": "exportRepairRecord",
                      "hidden-search": true,
                      "export-params": _vm.searchData
                    }
                  }),
                  _c("MTable", {
                    ref: "mTable7",
                    attrs: {
                      "set-data-method": _vm.getRepairRecordList,
                      height: 300,
                      columns: _vm.repairRecord,
                      "columns-setting": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "produceWorkOrderId",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.jumpDetail(row)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(row.repairWorkOrderNumber))]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "repairWorkQuantity",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.getWorkQuantity(row)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(row.repairWorkQuantity))]
                              )
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("repair-model", {
        attrs: {
          visible: _vm.repairVisible,
          "repair-list": _vm.repairList,
          "produce-work-order-id": parseInt(_vm.params.id),
          "old-process-flow-id": _vm.baseFormData.processFlowId
        },
        on: {
          "update:visible": function($event) {
            _vm.repairVisible = $event
          },
          loadWord: _vm.getRepairRecordList
        }
      }),
      _c("TableDialog", {
        attrs: {
          title: "查看工位数量",
          show: _vm.stationVisible,
          columns: _vm.stationColums,
          "table-data": _vm.stationList
        },
        on: {
          "update:show": function($event) {
            _vm.stationVisible = $event
          }
        }
      }),
      _c("batch-number", {
        attrs: {
          "add-visible": _vm.addVisible,
          "base-form-data": _vm.baseFormData
        },
        on: {
          "update:addVisible": function($event) {
            _vm.addVisible = $event
          },
          "update:add-visible": function($event) {
            _vm.addVisible = $event
          },
          sumbitBatch: _vm.sumbitBatch
        }
      }),
      _c("TableDialog", {
        attrs: {
          title: "查看报工记录",
          show: _vm.workVisible,
          columns: _vm.workColumns,
          "table-data": _vm.workList
        },
        on: {
          "update:show": function($event) {
            _vm.workVisible = $event
          },
          scanDetail: _vm.scanDetail
        }
      }),
      _c("TableDialog", {
        attrs: {
          title: "查看报废历史",
          show: _vm.wasteVisible,
          columns: _vm.wasteColumns,
          "table-data": _vm.wasteList
        },
        on: {
          "update:show": function($event) {
            _vm.wasteVisible = $event
          }
        }
      }),
      _c(
        "MDialog",
        {
          attrs: {
            value: _vm.show,
            title: "查看上传工序参数",
            "show-footer": false
          },
          on: {
            close: function($event) {
              _vm.show = false
            }
          }
        },
        _vm._l(_vm.groupList, function(row) {
          return _c("div", { key: row.name, staticClass: "params-card" }, [
            _c("div", { staticClass: "header flex-sbc" }, [
              _c("div", [_vm._v("参数组标题：" + _vm._s(row.name))])
            ]),
            _c(
              "div",
              { staticClass: "body" },
              _vm._l(row.fromData, function(item) {
                return _c("div", { key: item.id }, [
                  item.type !== 4
                    ? _c("span", { staticClass: "bot-ti" }, [
                        _vm._v(
                          _vm._s(item.key) +
                            "：" +
                            _vm._s(_vm.fixData(item.value))
                        )
                      ])
                    : _c(
                        "span",
                        { staticClass: "bot-ti" },
                        [
                          _vm._v(_vm._s(item.key) + "： "),
                          _vm._l(_vm.fixArr(item.value), function(items) {
                            return _c(
                              "a",
                              {
                                key: items,
                                staticClass: "imgs",
                                attrs: { href: items, target: "_blank" }
                              },
                              [_c("img", { attrs: { src: items } })]
                            )
                          })
                        ],
                        2
                      )
                ])
              }),
              0
            )
          ])
        }),
        0
      ),
      _c("PrintOrder", { ref: "printCodes" }),
      _vm.memberCode === "reagold"
        ? _c("PrintBatchNumber", { ref: "printCode" })
        : _vm.memberCode === "kiki"
        ? _c("PrintBatchNumberKiKi", { ref: "printCode" })
        : _c("PrintCode", { ref: "printCode" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: { src: require("@/assets/information/procedure/图纸@2x.png") }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("图纸")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bt20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: {
          src: require("@/assets/information/procedure/其他附件@2x.png")
        }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("其他附件")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }