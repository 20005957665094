export const fastColumns = [
  {
    prop: 'index',
    label: '序号',
    width: 80,
    sortable: false,
    hiddenSearch: true,
    slotName: 'index'
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    width: 100
  }
]

