<template>
  <div class="orders">
    <div class="top">
      <SearchForms ref="searchForms" @resetData="resetData" @searchData="searchTable" />
    </div>
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      search-title="请输入生产订单号"
      :tags="!selectionData.length ? functionHeader.tags : []"
      :export-name="functionHeader.exportName"
      :show-export = "!selectionData.length ? true : false"
      :export-params="functionHeader.exportParams"
      :import-name="!selectionData.length ? functionHeader.importName : '' "
      style="padding: 0 0 10px 0;"
      class="func-header"
      @search="$refs.mTable.setTableData(true)"
    >
    <div slot="all" v-show="selectionData.length" >
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="batchExport()">批量导出</el-button>
        <el-button v-if="permission('BatchDel')" type="primary" size="small" @click="allDel()">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
      </div>
    </FunctionHeader>
    <MTable ref="mTable" :columns="columnsAll" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="quantity" slot-scope="{ row }" style="display: flex;justify-content: flex-start;align-items: center">
        <div style="display: inline-block;">{{ row.quantity }}</div>
        <div v-if="memberCode === 'reagold' && (row.warehouseQuantity || row.sitQuantity || row.doingQuantity)" class="tooltip">
          <div v-if="row.warehouseQuantity" class="tip-tit">仓库：{{ row.warehouseQuantity }}</div>
          <div v-if="row.sitQuantity" class="tip-tit">线边库：{{ row.sitQuantity }}</div>
          <div v-if="row.doingQuantity" class="tip-tit">在制：{{ row.doingQuantity }}</div></div>
      </div>
      <div slot="produceOrderNumber" slot-scope="{ row }">
        <NewPageOpen v-if="isShowChild" :path="{ name: 'orderDetail', query: {id: row.id}}" :text="row.produceOrderNumber" />
      </div>
      <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
      <div slot="source" slot-scope="{ row }">
        <div>{{ orderSource[row.source].name }}</div>
      </div>
      <div slot="priority" slot-scope="{ row }">
        <div
          :style="{ color: priorityStatus[row.priority] ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)', background: `${priorityStatus[row.priority] ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)'}20`}"
          class="tag-priority"
        >
          {{ priorityStatus[row.priority] ? priorityStatus[row.priority].name : '普通' }}
        </div>
      </div>
      <div slot="status" slot-scope="{ row }">
        <div :style="{color: row.status === 0 ? '#FA6400' : row.status === 2 ? '#607FFF' : '#00AB29'}">{{ fixStatus(row.status) }}</div>
      </div>
      <div slot="drawingStatus" slot-scope="{ row }">
        <div :style="{color: pageStatus[row.drawingStatus] ? pageStatus[row.drawingStatus].color : ''}">{{ pageStatus[row.drawingStatus].name }}</div>
      </div>
      <div slot="bomStatus" slot-scope="{ row }">
        <div :style="{color: pageStatus[row.bomStatus] ? pageStatus[row.bomStatus].color : ''}">{{ pageStatus[row.bomStatus].name }}</div>
      </div>
      <div slot="orderProcess" slot-scope="{ row }">
        <el-progress
          :stroke-width="10"
          :percentage="fixNumber(row) >= 100 ? 100 : fixNumber(row)"
          :format="fixNumber(row, 1)"
        />
      </div>
      <div slot="action" slot-scope="{ row }">
        <template v-if="memberCode === 'reagold' || memberCode === 'mubai'">
          <template v-if="planId === 1">
            <NewPageOpen v-if="row.status !== 2 && permission('planAddCreatePlan', false) && isShowChild" :path="{ name: 'planAdd', query: { id: row.id }}" text="创建计划" />
            <a v-else class="li-btn color-btns">创建计划</a>
          </template>
          <template v-else>
            <NewPageOpen v-if="(+row.status === 0 || +row.status === 3) && permission('planAddCreatePlan', false) && isShowChild" :path="{ name: 'planAdd', query: { id: row.id }}" text="创建计划" />
            <a v-else class="li-btn color-btns">创建计划</a>
          </template>
        </template>
        <template v-else>
          <NewPageOpen v-if="row.quantity !== row.planQuantity && permission('planAddCreatePlan', false) && isShowChild" :path="{ name: 'planAdd', query: { id: row.id }}" text="创建计划" />
          <a v-else class="li-btn color-btns">创建计划</a>
        </template>
        <el-divider v-if="permission('planAddCreatePlan', false) && row.status !== 2" direction="vertical" />
        <el-dropdown v-if="row.status === 0 || row.status === 1 || row.status === 3" @command="(command) => { more(row, command) }">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item v-if="permission('ConfirmDraw') && row.drawingStatus === 0" command="save">确认图纸</el-dropdown-item>
            <el-dropdown-item v-if="permission('ConfirmBom') && row.bomStatus === 0" command="confirmBom">确认BOM</el-dropdown-item> -->
            <el-dropdown-item v-if="permission('Modify') && row.status === 0" command="modify">修改</el-dropdown-item>
            <el-dropdown-item v-if="permission('Modify') && row.status === 1 || row.status === 3" command="singleModify">修改</el-dropdown-item>
            <el-dropdown-item v-if="permission('Del') && row.status === 0" command="del">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </MTable>
    <!-- 确认图纸 -->
    <MDialog v-model="visible" title="确认图纸" @onOk="submitForm">
      <span>确认图纸已上传吗？</span>
    </MDialog>
    <!-- 确认bom-->
    <MDialog v-model="visibleBom" title="确认BOM" @onOk="submitFormBom">
      <span>确认BOM已上传吗？</span>
    </MDialog>
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
    <!-- 修改交期 -->
    <MDialog v-model="showModify" title="修改" @onOk="modifyForm">
      <div class="dialogItem">
        <span>交期：</span>
        <el-date-picker v-model="deliveryTime" type="date" placeholder="请选择" value-format="yyyy-MM-dd" format="yyyy-MM-dd" style="width:90%" />

      </div>

      <div  class="dialogItem">
        <span>数量：</span>
        <el-input-number v-model="quantity"  controls-position="right" :min="0" :step="1" :step-strictly="true"  style="width: 90%" />
      </div>
    </MDialog>
  </div>
</template>
<script>
import FunctionHeader from '@/components/FunctionHeader'
import SearchForms from './search-forms'
import { orderSource, orderStatus, pageStatus } from '@/config/options.config'
import api from '@/api/production/orders'
import { columnsAll } from './columns'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import fixCommonPage from '@/mixin/fix-common-page'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import baseSettingApi from '@/api/sets/base-setting'
import userApi from '@/api/sets/user/user'
import conventionMixin from '@/mixin/conventional-page'
import SelectMultipleMaterials from '@/components/SelectMultipleMaterials/SelectMultipleMaterials'

export default {
  name: 'OrderList',
  components: { NewPageOpen, EditMaterials, FunctionHeader, SearchForms, SelectMultipleMaterials },
  mixins: [viewMaterialsDetail, fixCommonPage, conventionMixin],
  data() {
    return {
      api,
      orderSource,
      orderStatus,
      pageStatus,
      visible: false,
      searchFormData: [],
      searchFormDatas: {},
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('orderAddAdd', false),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ],
        exportName: 'exportOrders',
        importName: 'orderList',
        exportParams: {}
      },
      searchFormList:[],
      columnsAll,
      searchDatas: [
        {
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: '0^1^3',
          operator: 'in'
        }
      ],
      selectionData: [],
      selectId: '',
      allMaterialsList: [],
      materialsName:'',
      keyword: '',
      selectKeyword: '1',
      deliveryTime: '',
      quantity:0,
      showModify: false,
      currentId: undefined,
      sortList: [
        {
          id: '1',
          name: '按优先级最高'
        },
        {
          id: '2',
          name: '按优先级最低'
        },
        {
          id: '3',
          name: '按交期最早'
        }
      ],
      sortData: '1',
      sorts: '+priority',
      isFirst: true,
      visibleBom: false,
      selectionDataList: [],
      planId: 0,
      itemData:{},
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionDataList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    if (['mubai', 'reagold'].includes(sessionStorage.getItem('memberCode'))) {
      this.getPlansConfig()
    }
  },
  activated() {
    this.$refs.mTable.setTableData()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  methods: {
    resetSelectData() {
      this.selectionDataList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionDataList = this.selectionDataList.filter((item) => item.selectable)
    },
    async getPlansConfig() {
      const res = await baseSettingApi.getCode({ code: 'plan_total_need_review' })
      if (res) {
        this.planId = +res.value
      }
    },
    batchExport() {
      const ids = [
        {
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: this.selectionData.join('^'),
          operator: 'in'
        }
      ]
      const params = {
        searchVal: Encrypt(JSON.stringify(ids))
      }
      this.$refs.functionHeader.export(params)
    },
    fixNumber(row, format) {
      if (!row.reportWorkQuantity || !row.quantity) {
        if (format) {
          return () => {
            return 0 + '%'
          }
        } else {
          return 0
        }
      } else {
        if (format) {
          return () => {
            return Number((row.reportWorkQuantity / row.quantity * 100).toFixed(0)) + '%'
          }
        } else {
          return Number((row.reportWorkQuantity / row.quantity * 100).toFixed(0))
        }
      }
    },

    // 排序
    handleCommand() {
      const titles = new Map([
        ['1', '+priority'],
        ['2', '-priority'],
        ['3', '+deliveryTime'],
        ['4', '-deliveryTime']
      ])
      this.sorts = titles.get(this.sortData)
      this.$refs.mTable.setTableData(true)
    },
    fixStatus(val) {
      const result = this.orderStatus.filter(item => item.id === val)
      return result[0].name
    },
    jumpDetail(row) {
      this.$router.push({ path: 'order-detail', query: { id: row.id }})
    },
    typeChange(val) {
      this.selectKeyword = val
    },
    // 新增
    add() {
      this.$router.push({ path: 'order-add' })
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      // console.log(search, this.searchDatas)
      let sorts = order
      if (this.memberCode === 'reagold' && this.isFirst) {
        sorts = this.sorts
      }
      const params = {
        sort: sorts,
        searchKeyword: this.functionHeader.searchVal,
        materialsSearchType: this.selectKeyword,
        materialsSearchValue: this.keyword,
        materialsName:this.materialsName,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchDatas]))
      }
      // 导出
      this.functionHeader.exportParams = params
      const res = await api.getOrderpage({
        page: page.currentPage,
        limit: page.pageSize,
        ...params
      })
      if (res) {
        this.isFirst = false
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    // 批量删除
    async allDel() {
      if (this.selectionData.length > 0) {
        const res = await api.deleteOrders(this.selectionData)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
      } else {
        this.$message.error('请选择要删除的数据')
      }
    },
    selectionChange(data) {
      this.selectionData = data.map(item => item.id)
      this.selectionDataList = data
    },
    // 创建计划
    addPlan(row) {
      this.$router.push({ path: 'plan-add', query: { id: row.id }})
    },
    // 确认图纸
    async submitForm(done) {
      await api.getPage({ id: this.selectId })
      this.$message.success('确认成功')
      this.$refs.mTable.setTableData()
      this.visible = false
      done()
    },
    // 确认BOM
    async submitFormBom(done) {
      await api.getBom({ id: this.selectId })
      this.$message.success('确认成功')
      this.$refs.mTable.setTableData()
      this.visibleBom = false
      done()
    },
    // 更多操作
    more(row, command) {
      console.log(row)
      const functions = {
        save: () => { this.save(row) },
        confirmBom: () => { this.confirmBom(row) },
        del: () => { this.del(row) },
        modify: () => { this.modify(row) },
        singleModify: () => { this.singleModify(row) }
      }
      functions[command]()
    },
    // 只修改交期
    singleModify(item) {
      this.showModify = true
      this.deliveryTime = item.deliveryTime
      this.currentId = item.id,
      this.itemData = item,
      this.quantity = item.quantity
    },
    async modifyForm(done) {
      done()
      // if (this.deliveryTime === '' || this.deliveryTime === null) {
      //   this.$message.error('请选择交期')
      //   return false
      // }
      const data = {
        id: this.currentId,
        deliveryTime: this.deliveryTime,
        quantity:this.quantity
      }
      const res = await api.modifyOrder(data)
      if (res) {
        this.showModify = false
        this.$refs.mTable.setTableData()
      }
    },
    save(row) {
      this.visible = true
      this.selectId = row.id
    },
    confirmBom(row) {
      this.visibleBom = true
      this.selectId = row.id
    },
    allMore(command) {
      const functions = {
        allDel: () => { this.allDel() },
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    async del(row) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.deleteOrder({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    modify(row) {
      this.$router.push({ path: 'order-add', query: { id: row.id }})
    },
    // 查询d
    searchTable(data, val, keyword,materialsName) {
      this.searchDatas = data
      this.keyword = keyword
      this.selectKeyword = val
      this.materialsName = materialsName
      this.$refs.mTable.setTableData(true)
    },
    // 重置
    resetData() {
      this.searchDatas = []
      this.selectKeyword = ''
      this.keyword = ''
      this.materialsName = ''
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
 .orders{
    padding: 10px;
    ::v-deep {
      .el-progress__text {
        font-size: 12px !important;
      }
    }
    .li-btn{
      margin-right:10px;
      color:#607FFF;
    }
    .color-btns{
      color:#c4c4c4;
      margin-right: 0;
    }
    .searchs{
      padding-left: 0;
      padding-top: 0;
    }
    .input-select{
      width: 100px;
      display: block;
      }
    .input-with-select{
      width: 100%;
    }

    .all-operator{
      height: 32px;
      margin: 0 10px;
      ::v-deep {
        .el-button--primary, .el-button-group .el-button{
          height: 32px;
        }
      }
    }
    .func-header {
      ::v-deep .el-input--suffix .el-input__inner{
        height: 32px;
      }
    }

 }
 ::v-deep .customer-content div {
   width: 100%;
 }
 .tip-tit {
   height: 18px;
   line-height: 18px;
 }
 div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #ECEEF2;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #393D60;
  margin-left: 10px;
}

div.tooltip:before, div.tooltip:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #ECEEF2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #ECEEF2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.dialogItem{
  margin-bottom: 20px;
}
</style>
