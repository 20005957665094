var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedule-wrap" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForms", {
            ref: "searchForms",
            attrs: { "form-option": _vm.formOptions, "is-have": _vm.isHave },
            on: { resetData: _vm.resetData, searchData: _vm.searchTable }
          })
        ],
        1
      ),
      _c("el-divider", { staticClass: "e-divider" }),
      _c(
        "div",
        { staticClass: "materials-info" },
        [
          _c(
            "FunctionHeader",
            {
              ref: "functionHeader",
              attrs: {
                "search-title": "请输入工单编号/项目编号/名称",
                tags: !_vm.selectionData.length ? _vm.functionHeader.tags : []
              },
              on: {
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectionData.length,
                      expression: "selectionData.length"
                    }
                  ],
                  attrs: { slot: "all" },
                  slot: "all"
                },
                [
                  _vm.permission("Export")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.batchExport()
                            }
                          }
                        },
                        [_vm._v("批量导出")]
                      )
                    : _vm._e(),
                  _vm.permission("Del")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.onDel(_vm.selectionData)
                            }
                          }
                        },
                        [_vm._v("批量删除")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "0 10px",
                        display: "inline-block",
                        "font-size": "12px",
                        color: "#393D60"
                      }
                    },
                    [
                      _vm._v(" 已选 "),
                      _c("span", { staticStyle: { color: "#607FFF" } }, [
                        _vm._v(_vm._s(_vm.getSelectionDataLength))
                      ]),
                      _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: { click: _vm.resetSelectData }
                    },
                    [_vm._v("清空")]
                  )
                ],
                1
              )
            ]
          ),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columnsAll,
              "set-data-method": _vm.getTableData,
              height: _vm.height
            },
            on: { "selection-change": _vm.selectionChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "materialsCode",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewMaterialsDetail(row.materialsId)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.materialsCode))]
                  )
                }
              },
              {
                key: "workProcess",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.onWorkProcess(row.id, 0)
                        }
                      }
                    },
                    [
                      _c("el-progress", {
                        attrs: {
                          percentage: row.productionTempo,
                          color: _vm.progressStatusColorMaps[row.workOrderTempo]
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "requisitionTemp",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.onWorkProcess(row.id, 1)
                        }
                      }
                    },
                    [
                      _c("el-progress", {
                        attrs: {
                          percentage: row.materialsRequisitionTempo,
                          color: _vm.progressStatusColorMaps[row.workOrderTempo]
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(_vm.workStatusMaps[row.status]) + " ")
                  ])
                }
              },
              {
                key: "progressStatus",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {
                      style: {
                        color:
                          row.workOrderTempo === 1
                            ? "#F5A623"
                            : row.workOrderTempo === 2
                            ? "#F20909"
                            : "#606266"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.progressStatusMaps[row.workOrderTempo]) +
                          " "
                      )
                    ]
                  )
                }
              },
              {
                key: "executionDepartment",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.executionDepartmentMaps[row.executiveUnit]) +
                        " "
                    )
                  ])
                }
              },
              {
                key: "priority",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tag-priority",
                          style: {
                            color: _vm.priorityStatus[row.priority]
                              ? _vm.priorityStatus[row.priority].color
                              : "rgb(74, 79, 118)",
                            background:
                              (_vm.priorityStatus[row.priority]
                                ? _vm.priorityStatus[row.priority].color
                                : "rgb(74, 79, 118)") + "20"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.priorityStatus[row.priority]
                                  ? _vm.priorityStatus[row.priority].name
                                  : "普通"
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  )
                }
              },
              {
                key: "businessUnit",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.divisionOptionsMaps[row.businessUnit]) +
                        " "
                    )
                  ])
                }
              },
              {
                key: "workType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(_vm.workTypeMaps[row.type]) + " ")
                  ])
                }
              },
              {
                key: "productionPlanNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    row.isRepair === 1
                      ? _c("img", {
                          staticClass: "icon-img",
                          attrs: {
                            src: require("@/assets/plans/repair-icon-have.png")
                          }
                        })
                      : _vm._e(),
                    _c(
                      "a",
                      {
                        staticClass: "li-btn",
                        on: {
                          click: function($event) {
                            return _vm.onHandlePlan("view", row.id)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.productionPlanNumber))]
                    )
                  ])
                }
              },
              {
                key: "processFlowName",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.showProcess(row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.processFlowName))]
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      row.status === -1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.startDo(row.id)
                                }
                              }
                            },
                            [_vm._v("开工")]
                          )
                        : _vm._e(),
                      _vm.permission("Over") && [0].includes(row.status)
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.onOver(row.id)
                                }
                              }
                            },
                            [_vm._v("结案")]
                          )
                        : _vm._e(),
                      _vm.permission("Edit") && [-1].includes(row.status)
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.onHandlePlan("edit", row.id)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm.permission("Del") && [-1].includes(row.status)
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.onDel([row.id])
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "请选择产线", confirmButtonText: "开工" },
          on: { onOk: _vm.addOrEditSubmit },
          model: {
            value: _vm.visiableStart,
            callback: function($$v) {
              _vm.visiableStart = $$v
            },
            expression: "visiableStart"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuilds",
            attrs: {
              "form-data": _vm.dataForAdd,
              "form-list": _vm.formListForStart
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "工艺路线", "show-footer": false },
          on: {
            onOk: function($event) {
              _vm.processVisible = false
            }
          },
          model: {
            value: _vm.processVisible,
            callback: function($$v) {
              _vm.processVisible = $$v
            },
            expression: "processVisible"
          }
        },
        [
          _c("process-flow", {
            ref: "processFlow",
            attrs: {
              "read-only": true,
              "process-flow-detail": _vm.processFlowVersion,
              "show-version": true
            }
          })
        ],
        1
      ),
      _c("edit-materials", { ref: "editMaterials" }),
      _c(
        "MDialog",
        {
          attrs: {
            title: _vm.progressType,
            "show-footer": false,
            width: "870px"
          },
          on: {
            onOk: function($event) {
              _vm.processVisible = false
            }
          },
          model: {
            value: _vm.visibleProgress,
            callback: function($$v) {
              _vm.visibleProgress = $$v
            },
            expression: "visibleProgress"
          }
        },
        [
          _c(
            "div",
            { staticClass: "detail-box" },
            _vm._l(_vm.progressDetail, function(item, idx) {
              return _c(
                "div",
                { key: idx, staticClass: "item-box" },
                _vm._l(item, function(i) {
                  return _c(
                    "div",
                    {
                      key: i.procedureLocationCode,
                      staticClass: "item",
                      class: ["item-wait", "item-running", "item-finished"][
                        i.productionStatus
                      ]
                    },
                    [
                      i.productionStatus === 0
                        ? _c("img", {
                            staticClass: "sign",
                            attrs: {
                              src: require("@/assets/plans/progress-0.png")
                            }
                          })
                        : _vm._e(),
                      i.productionStatus === 1
                        ? _c("img", {
                            staticClass: "sign",
                            attrs: {
                              src: require("@/assets/plans/progress-1.png")
                            }
                          })
                        : _vm._e(),
                      i.productionStatus === 2
                        ? _c("img", {
                            staticClass: "sign",
                            attrs: {
                              src: require("@/assets/plans/progress-2.png")
                            }
                          })
                        : _vm._e(),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: i.procedureName,
                            placement: "top"
                          }
                        },
                        [_c("div", [_vm._v(_vm._s(i.procedureName))])]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "progress-bg",
                          staticStyle: { bottom: "5px !important" }
                        },
                        [
                          _c("div", {
                            staticClass: "progress-bar",
                            style: { width: i.productionTempo + "%" }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "progress-bg" }, [
                        _c("div", {
                          staticClass: "apply-bar",
                          style: { width: i.materialsRequisitionTempo + "%" }
                        })
                      ])
                    ],
                    1
                  )
                }),
                0
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }