var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.showModify,
        title: _vm.titles[_vm.handleType],
        width: "600px",
        "show-close": false
      }
    },
    [
      ["edit", "add"].includes(_vm.handleType)
        ? _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "成品物料", prop: "materialsId" } },
                [
                  ["add"].includes(_vm.handleType)
                    ? _c("SelectRemoteSearch", {
                        ref: "materialsId",
                        staticClass: "select",
                        attrs: {
                          clearable: "",
                          placeholder: "请选择成品物料",
                          "option-val": "id",
                          "option-label": "fullLabel",
                          "size-name": "size",
                          api: _vm.processApi.getFinishedMaterials,
                          params: { status: 0 },
                          "collapse-tags": ""
                        },
                        on: {
                          change: function(val) {
                            return _vm.handleSelect("material", val)
                          }
                        },
                        model: {
                          value: _vm.ruleForm.materialsId,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "materialsId", $$v)
                          },
                          expression: "ruleForm.materialsId"
                        }
                      })
                    : _c("div", [_vm._v(_vm._s(_vm.ruleForm.materialsName))])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "工艺路线", prop: "processFlowId" } },
                [
                  ["add"].includes(_vm.handleType)
                    ? _c("SelectRemoteSearch", {
                        ref: "processFlowId",
                        staticClass: "select",
                        attrs: {
                          disabled: !_vm.ruleForm.materialsId,
                          "super-disabled": !_vm.ruleForm.materialsId,
                          clearable: "",
                          placeholder: "请选择工艺路线",
                          "option-val": "id",
                          "option-label": "name",
                          api: _vm.processApi.getProcessList,
                          "collapse-tags": "",
                          params: _vm.processParams
                        },
                        on: {
                          change: function(val) {
                            return _vm.handleSelect("processFlowId", val)
                          }
                        },
                        model: {
                          value: _vm.ruleForm.processFlowId,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "processFlowId", $$v)
                          },
                          expression: "ruleForm.processFlowId"
                        }
                      })
                    : _c("div", [_vm._v(_vm._s(_vm.ruleForm.processFlowName))])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本", prop: "version" } },
                [
                  ["add"].includes(_vm.handleType)
                    ? _c("SelectRemoteSearch", {
                        ref: "version",
                        staticClass: "select",
                        attrs: {
                          disabled: !_vm.ruleForm.processFlowId,
                          "super-disabled": !_vm.ruleForm.processFlowId,
                          clearable: "",
                          placeholder: "请选择版本",
                          "option-val": "procedureList",
                          "option-label": "version",
                          api: _vm.processApi.getProcessDetail,
                          "collapse-tags": "",
                          params: _vm.procedureParams,
                          "result-field-name": "versionList"
                        },
                        on: {
                          change: function(val) {
                            return _vm.handleSelect("version", val)
                          }
                        },
                        model: {
                          value: _vm.ruleForm.version,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "version", $$v)
                          },
                          expression: "ruleForm.version"
                        }
                      })
                    : _c("div", [
                        _vm._v(_vm._s(_vm.ruleForm.processFlowVersion))
                      ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "工序", prop: "flowProcedureId" } },
                [
                  ["add"].includes(_vm.handleType)
                    ? _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择工序",
                            disabled: !_vm.ruleForm.version
                          },
                          model: {
                            value: _vm.ruleForm.flowProcedureId,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "flowProcedureId", $$v)
                            },
                            expression: "ruleForm.flowProcedureId"
                          }
                        },
                        _vm._l(_vm.procedureList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.procedureName, value: item.id }
                          })
                        }),
                        1
                      )
                    : _c("div", [_vm._v(_vm._s(_vm.ruleForm.procedureName))])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合格品单价", prop: "qualifiedPrice" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", step: 0.01 },
                    on: {
                      blur: function($event) {
                        _vm.ruleForm.qualifiedPrice = _vm.sliceVal(
                          _vm.ruleForm.qualifiedPrice,
                          2
                        )
                      }
                    },
                    model: {
                      value: _vm.ruleForm.qualifiedPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "qualifiedPrice", $$v)
                      },
                      expression: "ruleForm.qualifiedPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "unqualifiedPrice" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("不良品单价"),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "注：可设为负数，表示不良品需要罚钱。",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      )
                    ],
                    1
                  ),
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", step: 0.01 },
                    on: {
                      blur: function($event) {
                        _vm.ruleForm.unqualifiedPrice = _vm.sliceVal(
                          _vm.ruleForm.unqualifiedPrice,
                          2
                        )
                      }
                    },
                    model: {
                      value: _vm.ruleForm.unqualifiedPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "unqualifiedPrice", $$v)
                      },
                      expression: "ruleForm.unqualifiedPrice"
                    }
                  })
                ],
                1
              ),
              _vm.handleType === "edit"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "历史数据如何处理", prop: "isInspect" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false },
                          model: {
                            value: _vm.ruleForm.isInspect,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "isInspect", $$v)
                            },
                            expression: "ruleForm.isInspect"
                          }
                        },
                        [_vm._v("历史数据保留不影响")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true },
                          model: {
                            value: _vm.ruleForm.isInspect,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "isInspect", $$v)
                            },
                            expression: "ruleForm.isInspect"
                          }
                        },
                        [_vm._v("重新计算所选时间范围内的数据")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ruleForm.isInspect && _vm.handleType === "edit"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "时间", prop: "time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          "value-format": "yyyy-MM-dd",
                          format: "yyyy-MM-dd",
                          clearable: ""
                        },
                        model: {
                          value: _vm.ruleForm.time,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "time", $$v)
                          },
                          expression: "ruleForm.time"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      ["del"].includes(_vm.handleType)
        ? _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "220px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "修改后历史工资数据如何处理",
                    prop: "isInspect"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: false },
                      model: {
                        value: _vm.ruleForm.isInspect,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "isInspect", $$v)
                        },
                        expression: "ruleForm.isInspect"
                      }
                    },
                    [_vm._v("历史数据保留不影响")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: true },
                      model: {
                        value: _vm.ruleForm.isInspect,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "isInspect", $$v)
                        },
                        expression: "ruleForm.isInspect"
                      }
                    },
                    [_vm._v("重新计算所选时间范围内的数据")]
                  )
                ],
                1
              ),
              _vm.ruleForm.isInspect
                ? _c(
                    "el-form-item",
                    { attrs: { label: "时间", prop: "time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          "value-format": "yyyy-MM-dd",
                          format: "yyyy-MM-dd",
                          clearable: ""
                        },
                        model: {
                          value: _vm.ruleForm.time,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "time", $$v)
                          },
                          expression: "ruleForm.time"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.hidePop } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.onSave }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }