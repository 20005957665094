
// 列表
export const columns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    // slotName: 'materialsCode',
    minWidth: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'processFlowCode',
    label: '工艺路线编码',
    sortable: false,
    hiddenSearch: true
    // slotName: 'processFlowCode'
  },
  {
    prop: 'processFlowName',
    label: '工艺路线名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'processFlowVersion',
    label: '版本',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureCode',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'qualifiedPrice',
    label: '合格品单价/元',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'unqualifiedPrice',
    label: '不良品单价/元',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'createByName',
    label: '创建人',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    label: '创建时间',
    prop: 'createAt',
    hiddenSearch: true,
    sortable: false,
    minWidth: 220
  },
  {
    label: '操作',
    slotName: 'action',
    hiddenSearch: true,
    sortable: false,
    fixed: 'right',
    width: 120
  }
]
