<template>
  <div class="item">
    <div class="left">
      <div class="title" v-if="judgeField('batchNumber')">
        {{ itemInfo.batchNumber || "--" }}
        <!-- 优先级：{{
          itemInfo.produceWorkOrderTaskLockStationMapping
            ? itemInfo.produceWorkOrderTaskLockStationMapping.priority
            : ""
        }}, id:{{
          itemInfo.produceWorkOrderTaskLockStationMapping
            ? itemInfo.produceWorkOrderTaskLockStationMapping.id
            : ""
        }} -->
      </div>
      <div class="itemBar" v-if="judgeField('leftNumber')">
        <div>成品相关数量（{{ itemInfo.mainUnitName }}）：</div>
        <span
          >{{ itemInfo.workOrderQuantity }}/{{ itemInfo.sourceQuantity }}/{{
            itemInfo.produceWorkOrder.reportWorkQuantity
          }}</span
        >
      </div>
      <div class="itemBar" v-if="judgeField('materialsInfo')">
        <div>成品：</div>
        <div class="itemRight">
          <span>{{ itemInfo.specifications || "" }}</span>
          <span class="dividerColor">{{
            itemInfo.specifications ? "|" : ""
          }}</span>
          <span>{{ itemInfo.materialsCode || "" }}</span>
          <span class="dividerColor">{{
            itemInfo.materialsCode ? "|" : ""
          }}</span>

          <span>{{ itemInfo.materialsName || "" }}</span>
        </div>
      </div>
      <!-- <div class="itemBar">
        <div>成品名称：</div>
        
      </div>
      <div class="itemBar">
        <div>成品规格：</div>
      </div> -->
      <div class="itemBar" v-if="judgeField('produceWorkOrderNumber')">
        <div>生产工单：</div>
        <span>{{ itemInfo.produceWorkOrderNumber || "--" }}</span>
      </div>
      <div class="itemBar" v-if="judgeField('customerName')">
        <div>客户：</div>
        <span>{{ itemInfo.customerName || "--" }}</span>
      </div>
      <div class="itemBar" v-if="judgeField('deliveryTime')">
        <div>交期：</div>
        <span>{{ itemInfo.produceWorkOrder.deliveryTime || "--" }}</span>
      </div>
    </div>
    <div class="right">
      <div class="title" v-if="judgeField('procedureName')">
        {{ itemInfo.procedureName || "--" }}
        <span
          class="status"
          :style="{
            color: statusList[itemInfo.status].color,
            background: statusList[itemInfo.status].background,
          }"
          >{{ statusList[itemInfo.status].name }}</span
        >
      </div>
      <div class="itemBar" v-if="judgeField('midMaterial')">
        <div>中间品：</div>
        <span>{{
         itemInfo.produceWorkOrderTaskOutputMaterials? itemInfo.produceWorkOrderTaskOutputMaterials.materials
            ? itemInfo.produceWorkOrderTaskOutputMaterials.materials.name
            : "--":'--'
        }}</span>
      </div>
      <div class="itemBar" v-if="judgeField('rightNumber')">
        <div>中间品相关数量（{{ itemInfo.mainUnitName }}）：</div>
        <span
          >{{
            itemInfo.produceWorkOrderTaskOutputMaterials
              ? itemInfo.produceWorkOrderTaskOutputMaterials.quantity
                ? itemInfo.produceWorkOrderTaskOutputMaterials.quantity
                : 0
              : 0
          }}/{{
            itemInfo.outputReportWorkQuantity
              ? itemInfo.outputReportWorkQuantity
              : 0
          }}
          ({{ itemInfo.reportWorkQuantity }})<em
            v-if="!isShowCheckBox"
            class="el-icon-edit editClor"
            @click="toEditCount"
          ></em
        ></span>
      </div>

      <div class="itemBar" v-if="judgeField('sourceQuantity')">
        <div>上道工序产出数量：</div>
        <span
          >{{ itemInfo.sourceQuantity || "--" }}
         </span>
      </div>
      <div class="itemBar" v-if="judgeField('remark')">
        <div>备注：</div>
        <span
          >{{ itemInfo.remark || "--" }}
          <em
            v-if="!isShowCheckBox"
            class="el-icon-edit editClor"
            @click="toEditRemark"
          ></em
        ></span>
      </div>
      
      <div v-for="(item, index) in itemInfo.produceWorkOrderTaskFieldMappingList" :key="index">
        <div v-if="item.type === 5&&item.isShow" class="itemBar">
          <div>{{ item.fieldName }}：</div>
          <span
            >{{ item.value ?item.value:'--'}}
            <em
              v-if="!isShowCheckBox"
              class="el-icon-edit editClor"
              @click="toEditField(item)"
            ></em
          ></span>
        </div>
      </div>
      

      <div
        class="time"
        :style="{
          color: statusList[itemInfo.status].color,
          background: statusList[itemInfo.status].background,
        }"
      >
        {{ itemInfo.planEndTime.slice(0, 10) }}
      </div>
    </div>
    <MDialog
      v-model="editDialogRemarkVisible"
      title="编辑备注"
      @onOk="updateRemark"
    >
      <MFormBuilder
        ref="dialogFormBuildsRemark"
        :form-data="dialogFormDatas"
        :form-list="dialogFormLists"
      />
    </MDialog>
    <MDialog
      v-model="editDialogCountVisible"
      title="编辑数量"
      @onOk="updateCount"
    >
      <MFormBuilder
        ref="dialogFormBuildsCount"
        :form-data="dialogFormDatasCount"
        :form-list="dialogFormListsCount"
      />
    </MDialog>
    <MDialog
      v-model="fieldVisible"
      :title="`编辑${modifyField.fieldName}`"
      @onOk="submitFieldForm"
    >
      <el-input
        v-model="modifyField.text"
        placeholder="请输入"
        style="padding-right: 15px"
      />
    </MDialog>
  </div>
</template>
  <script>
import { transformColumnsToForm } from "@/utils";
import stationScheduleApi from "@/api/station-schedule/station-schedule";
import { sliceVal } from "@/utils";

import log from "@/api/log";

export default {
  name: "scheduleListItem",
  props: ["itemInfo", "isShowCheckBox"],
  data() {
    return {
      fieldSet: new Set(),
      modifyField: {
        text: "",
        fieldName: "",
      },
      fieldVisible: false,
      fieldItemInfo:null,
      editDialogRemarkVisible: false,
      editDialogCountVisible: false,
      columns: [
        {
          prop: "remark",
          label: "备注",
          hiddenSearch: true,
          sortable: false,
          form: {
            index: 0,
            colSpan: 24,
            tagName: "el-input",
            required: false,
            props: { type: "textarea" },
            attrs: { maxlength: 200, "show-word-limit": true, rows: "3" },
          },
        },
      ],
      columnsCount: [
        {
          prop: "quantity",
          label: "数量",
          hiddenSearch: true,
          sortable: false,
          form: {
            index: 0,
            colSpan: 8,
            tagName: "el-input",
            required: false,
            props: { type: "number" },
            attrs: {},
          },
        },
      ],
      dialogFormLists: null,
      dialogFormListsCount: null,

      dialogFormDatas: {},
      dialogFormDatasCount: {},
      statusList: [
        {
          id: 0,
          name: "未开始",
          color: "#FA6400",
          background: "rgba(250, 100, 0, 0.1)",
        },
        {
          id: 1,
          name: "进行中",
          color: "#0091FF",
          background: "rgba(0, 145, 255, 0.1)",
        },
        {
          id: 2,
          name: "已完成",
          color: "#9597AE",
          background: "rgba(149, 151, 174, 0.1)",
        },
      ],
      showCustomFieldList: [
        {
          id: 123,
          fieldId: 123,
          fieldCode: "customer",
          fieldName: "字段1",
          value: "客户字段的值1",
        },

        {
          id: 123,
          fieldId: 123,
          fieldCode: "customer",
          fieldName: "字段2",
          value: "客户字段的值2",
        },
      ],
    };
  },
  mounted() {
    this.dialogFormLists = transformColumnsToForm(this.columns);
    this.dialogFormListsCount = transformColumnsToForm(this.columnsCount);
    this.getFieldSet(this.itemInfo.produceWorkOrderTaskFieldMappingList)

    console.log(this.itemInfo);
  },
  methods: {
    sliceVal,
   async saveOrUpdateFieldValue(item,value){
      const res = await stationScheduleApi.saveOrUpdateFieldValue({
            id: item.id,
            produceWorkOrderTaskId:item.produceWorkOrderTaskId,
            fieldId:item.fieldId,
            value,
            fieldCode:item.fieldCode,
            fieldName:item.fieldName
          });
          if (res) {
            this.$message.success("修改成功");

            this.fieldVisible = false;
            // this.$refs.mTable.setTableData()
            this.$emit("editSuccess");
          }
    },
    getFieldSet(array) {
      if(array.length<=0){
        return
      }
      array.forEach((item) => {
        // 检查条件
        if (item.type >= 1 && item.type <= 4 && item.isShow) {
          // 将 fieldCode 添加到 Set 中
          this.fieldSet.add(item.fieldCode);
        }
      });
    },
    judgeField(code){
      if(this.fieldSet.has(code)){
        return true
      }else{
        return false
      }
    },
    getShowCustomField() {},
    submitFieldForm() {
      console.log(this.modifyField);
      this.saveOrUpdateFieldValue(this.fieldItemInfo,this.modifyField.text)

    },
    toEditField(item) {
      this.fieldVisible = true;
      this.modifyField.text = item.value;
      this.modifyField.fieldName = item.fieldName;
      this.fieldItemInfo = item
    },
    toEditRemark() {
      this.dialogFormDatas = {
        remark: this.itemInfo.remark,
      };
      // this.dialogFormDatasCount={
      //   quantity:this.itemInfo.produceWorkOrderTaskOutputMaterials.quantity
      // }
      this.editDialogRemarkVisible = true;
    },
    toEditCount() {
      this.dialogFormDatasCount = {
        quantity: this.itemInfo.produceWorkOrderTaskOutputMaterials.quantity,
      };
      this.editDialogCountVisible = true;
    },
    updateRemark() {
      const { form } = this.$refs.dialogFormBuildsRemark;
      this.$refs.dialogFormBuildsRemark.formRefs().validate(async (valid) => {
        if (valid) {
          const remark = form.remark ? form.remark : "";
          console.log(remark);
          const res = await stationScheduleApi.updateRemark({
            id: this.itemInfo.id,
            remark,
          });
          if (res) {
            this.$message.success("备注修改成功");

            this.editDialogRemarkVisible = false;
            // this.$refs.mTable.setTableData()
            this.$emit("editSuccess");
          }
        }
      });
    },
    updateCount() {
      const { form } = this.$refs.dialogFormBuildsCount;
      this.$refs.dialogFormBuildsCount.formRefs().validate(async (valid) => {
        if (valid) {
          const quantity = form.quantity ? form.quantity : "";
          console.log(quantity);
          const res = await stationScheduleApi.updateCount({
            id: this.itemInfo.produceWorkOrderTaskOutputMaterials.id,
            quantity,
          });

          // const res = await oApis.saveOutsourcingRequest(this.taskId, remark)
          if (res) {
            this.$message.success("数量修改成功");
            this.editDialogCountVisible = false;
            this.$emit("editSuccess");
          }
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
.item {
  display: flex;
  width: 690px;
  width: 100%;
  border: solid 1px #eee;
  background-color: white;

  .left {
    width: 280px;
    padding: 13px 20px;
    border-right: 1px solid #eceef2;
  }
  .right {
    flex: 1;
    padding: 13px 20px;
    position: relative;
    .time {
      position: absolute;
      top: 0;
      right: 0;
      width: 94px;
      height: 23px;
      line-height: 23px;
      text-align: center;
      // background: rgba(250, 100, 0, 0.1);
      border-radius: 0px 0px 0px 10px;
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      // color: #fa6400;
    }
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    color: #393d60;
    margin-bottom: 6px;
    .status {
      padding: 3px 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border-radius: 4px;
      text-align: center;
      margin-left: 2px;
    }
  }
  .itemBar {
    display: flex;
    margin-bottom: 4px;
    div {
      color: #9597ae;
      font-size: 12px;
    }
    span {
      flex: 1;
      color: #393d60;

      font-weight: 400;
      font-size: 12px;
    }
    .itemRight {
      flex: 1;
    }
    .dividerColor {
      display: inline-block;
      margin: 0 2px;
      color: #607fff;
    }
    .editClor {
      display: inline-block;
      margin-left: 6px;
      width: 20px;
      color: #607fff;
      cursor: pointer;
    }
  }
}
.item:hover {
  background-color: #fdfdfd;
  cursor: move;
}
</style>