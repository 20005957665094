<template>
  <div>
    <MDialog v-model="modalShow" title="添加批次" width="80%" height="80%" @onOk="submitForms">
      <div class="top">
        <SearchForm ref="searchForm" :immediately-query="true" :form-list-extend="searchFormList" />
      </div>
      <div class="bottom">
        <MTable
          ref="mTable"
          :data="dataSourceFront && batchNumberData"
          :columns="fastColumns"
          :height="height"
          :show-page="false"
        >
          <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
          <div slot="batchNumber" slot-scope="{ row }" class="batch-number-columns">
            <span>{{ row.batchNumber }}</span>
          </div>
          <div slot="action" slot-scope="{ row }">
            <DelPopover @onOk="() => del(row)" />
          </div>
        </MTable>
      </div>
    </MDialog>
    <MDialog v-model="visible" width="400px" title="生成批次码" @onOk="submitForm">
      <MFormBuilder ref="formBuild" label-position="left" :form-list="formList" />
    </MDialog>

  </div>
</template>

<script>
import DelPopover from '@/components/DelPopover/index.vue'
import { fastColumns } from './columns'
import SearchForm from '@/components/SearchForm/SearchForm'
import api from '@/api/information/print-label/batch-number'
import { formList, searchFormList, getFunctionList } from './form-list'

export default {
  name: 'BatchNumber',
  components: { SearchForm, DelPopover },
  props: {
    addVisible: {
      type: Boolean,
      default: false
    },
    baseFormData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      select: '',
      fastColumns,
      modalShow: false,
      formList,
      searchFormList,
      visible: false,
      formOptions: {},
      materialVisible: false,
      printCodes: [],
      dataSourceFront: true,
      batchNumberData: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 257 - 77 - 40 - 22
    }
  },
  watch: {
    addVisible(val) {
      this.modalShow = val
      if (val) {
        this.batchNumberData = []
      }
    },
    modalShow(val) {
      if (!val) {
        this.$emit('update:addVisible', false)
      }
    }
  },
  beforeMount() {
    getFunctionList({
      generateBatchNumber: this.generateBatchNumber,
      addBatchNumber: this.addBatchNumber
    })
  },
  methods: {
    // 添加批次码是否存在
    async isBatch() {
      if (this.batchNumberData.some((d) => d.batchNumber === this.$refs. searchForm.form.batchNumber)) {
        return this.$message.error('该批次码已存在')
      }
      const res = await api.deatilBatchNumber({ batchNumber: this.$refs.searchForm.form.batchNumber })
      if (res) {
        if (res.materialsId !== this.baseFormData.materials.id) {
          this.$message.error('该批次码的物料与当前工单里的物料不匹配')
        } else {
          this.batchNumberData.push(res)
        }
      } else {
        const res = await api.addBatchNumber({
          batchNumber: this.$refs.searchForm.form.batchNumber,
          materialsId: this.baseFormData.materials.id,
          materialsCode: this.baseFormData.materials.code
        })
        if (res) {
          this.$refs.searchForm.setForm({ batchNumber: '' })
          this.batchNumberData.push(res)
          this.$message.success('添加成功')
        }
      }
    },
    submitForms(done) {
      if (this.batchNumberData.length > 0) {
        this.$emit('sumbitBatch', this.batchNumberData)
      } else {
        this.$message.info('请添加批次码')
      }
      done()
    },
    async del(row) {
      const res = await api.delBatchNumber({ id: row.id })
      if (res) {
        this.$message.success('删除成功')
        this.batchNumberData = this.batchNumberData.filter(item => item.id !== row.id)
      }
    },
    // 添加批次码
    addBatchNumber() {
      if (this.$refs.searchForm.form.batchNumber) {
        this.isBatch()
      } else {
        return this.$message.info('请输入批次码')
      }
    },
    // 自动生成批次码
    generateBatchNumber() {
      this.visible = true
    },
    async submitForm(callback) {
      const { form } = this.$refs.formBuild
      if (!form.quantity) {
        callback()
        return this.$message.info('请输入数量')
      }
      const res = await api.batchAddBatchNumber({
        materialsId: this.baseFormData.materials.id,
        materialsCode: this.baseFormData.materials.code,
        generateCount: form.quantity
      })
      if (res) {
        this.visible = false
        this.batchNumberData.push(...res)
        this.$message.success('添加成功')
        callback(false)
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}
.m-radio-group {
  margin: 20px 20px 0 20px;
}
.batch-number-wrap {
  width: 100%;
  .top {
    ::v-deep {
      .search-form-wrap {
        form {
          .el-row {
            .el-col:last-child {
              .el-form-item .el-form-item__content {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    padding: 20px;
  }
}

.placeholder-div {
  height: 83px;
  padding: 20px 20px 0 20px;
  border-bottom: 1px solid #DDE3EB;
}

::v-deep input[classname=select-materials] {
  display: none;
}

::v-deep .el-input-group--prepend {
  width: 100%;
  height: 40px;
}

::v-deep .el-input-group__prepend {
  background: #FFFFFF;
  width: calc(100% - 56px);

  > div {
    width: calc(100% + 40px);
  }

  .input-prepend {
    width: 100%;
    color: #495060;
  }
}

::v-deep .el-input-group__append {
  width: 56px;
}
::v-deep {
  .el-table .new-columns {
    background: #ECFAFF;
  }
}
.batch-number-columns {
  display: inline-flex;
  align-items: center;
  img {
    height: 11px;
    padding-left: 5px;
  }
}
</style>
