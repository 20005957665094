var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedule-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _vm.permission("NoScheduleList")
            ? _c("el-radio-button", { attrs: { label: "NoSchedule" } }, [
                _vm._v("未排程")
              ])
            : _vm._e(),
          _vm.permission("HaveScheduleList")
            ? _c("el-radio-button", { attrs: { label: "HaveSchedule" } }, [
                _vm._v("已排程")
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SearchForms", {
                ref: "searchForms",
                attrs: {
                  "form-option": _vm.formOptions,
                  "is-have": _vm.isHave
                },
                on: { resetData: _vm.resetData, searchData: _vm.searchTable }
              })
            ],
            1
          ),
          _c("el-divider", { staticClass: "e-divider" }),
          _c(
            "div",
            { staticClass: "materials-info" },
            [
              _c(
                "FunctionHeader",
                {
                  ref: "functionHeader",
                  style: { marginLeft: _vm.selectionData.length ? 0 : "-10px" },
                  attrs: {
                    "export-name": _vm.functionHeader.exportName,
                    "export-params": _vm.functionHeader.exportParams,
                    "show-export": !_vm.selectionData.length ? true : false,
                    radio: _vm.params.radio,
                    "search-title": "请输入生产计划号/订单号",
                    "columns-key": _vm.columnsKey
                  },
                  on: {
                    search: function($event) {
                      return _vm.$refs.mTable.setTableData(true)
                    }
                  },
                  model: {
                    value: _vm.functionHeader.searchVal,
                    callback: function($$v) {
                      _vm.$set(_vm.functionHeader, "searchVal", $$v)
                    },
                    expression: "functionHeader.searchVal"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectionData.length,
                          expression: "selectionData.length"
                        }
                      ],
                      attrs: { slot: "all" },
                      slot: "all"
                    },
                    [
                      _vm.permission(_vm.params.radio + "DownWorkOrder")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.allXia()
                                }
                              }
                            },
                            [_vm._v("批量下发")]
                          )
                        : _vm._e(),
                      _vm.permission(_vm.params.radio + "Stock")
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.batchStock()
                                }
                              }
                            },
                            [_vm._v("批量备料")]
                          )
                        : _vm._e(),
                      _vm.permission(_vm.params.radio + "Export")
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.batchExport()
                                }
                              }
                            },
                            [_vm._v("批量导出")]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            margin: "0 10px",
                            display: "inline-block",
                            "font-size": "12px",
                            color: "#393D60"
                          }
                        },
                        [
                          _vm._v(" 已选 "),
                          _c("span", { staticStyle: { color: "#607FFF" } }, [
                            _vm._v(_vm._s(_vm.getSelectionDataLength))
                          ]),
                          _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: { click: _vm.resetSelectData }
                        },
                        [_vm._v("清空")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm.permission(_vm.params.radio + "List")
                ? _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      columns: _vm.columnsAll,
                      "set-data-method": _vm.getTableData,
                      height: _vm.height,
                      "columns-key": _vm.columnsKey
                    },
                    on: { "selection-change": _vm.selectionChange },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var row = ref.row
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "source",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _c("div", [
                                _vm._v(_vm._s(_vm.planSource[row.source].name))
                              ])
                            ])
                          }
                        },
                        {
                          key: "materialsCode",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.viewMaterialsDetail(
                                      row.materialsId
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.materialsCode))]
                            )
                          }
                        },
                        {
                          key: "priority",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "tag-priority",
                                    style: {
                                      color: _vm.priorityStatus[row.priority]
                                        ? _vm.priorityStatus[row.priority].color
                                        : "rgb(74, 79, 118)",
                                      background:
                                        (_vm.priorityStatus[row.priority]
                                          ? _vm.priorityStatus[row.priority]
                                              .color
                                          : "rgb(74, 79, 118)") + "20"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.priorityStatus[row.priority]
                                            ? _vm.priorityStatus[row.priority]
                                                .name
                                            : "普通"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          }
                        },
                        {
                          key: "status",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _c("div", [
                                _vm._v(_vm._s(_vm.planStatus[row.status].name))
                              ])
                            ])
                          }
                        },
                        {
                          key: "materialPreparationStatus",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                row.materialPreparationStatus ||
                                row.materialPreparationStatus === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tag-priority",
                                        style: {
                                          color: _vm.preparationStatus[
                                            row.materialPreparationStatus
                                          ].color
                                            ? _vm.preparationStatus[
                                                row.materialPreparationStatus
                                              ].color
                                            : "rgb(74, 79, 118)",
                                          background:
                                            (_vm.preparationStatus[
                                              row.materialPreparationStatus
                                            ].color
                                              ? _vm.preparationStatus[
                                                  row.materialPreparationStatus
                                                ].color
                                              : "rgb(74, 79, 118)") + "20"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.preparationStatus[
                                                row.materialPreparationStatus
                                              ].name
                                                ? _vm.preparationStatus[
                                                    row
                                                      .materialPreparationStatus
                                                  ].name
                                                : "待下发"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          }
                        },
                        {
                          key: "type",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.productionType[row.type].name)
                                )
                              ])
                            ])
                          }
                        },
                        {
                          key: "isRepair",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(row.isRepair === 0 ? "普通" : "返修")
                                )
                              ])
                            ])
                          }
                        },
                        {
                          key: "producePlanNumber",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              row.isRepair === 1
                                ? _c("img", {
                                    staticClass: "icon-img",
                                    attrs: {
                                      src: require("@/assets/plans/repair-icon-have.png")
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "a",
                                {
                                  staticClass: "li-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.modify(row, 1)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(row.producePlanNumber))]
                              )
                            ])
                          }
                        },
                        {
                          key: "produceStatus",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      row.produceStatus === 0
                                        ? "#FA6400"
                                        : row.produceStatus === 1
                                        ? "#00AB29"
                                        : "#607fff"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.productionStatus[row.produceStatus]
                                          .name
                                      )
                                  )
                                ]
                              )
                            ])
                          }
                        },
                        {
                          key: "processFlowName",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showProcess(row)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(row.processFlowName))]
                                )
                              ],
                              1
                            )
                          }
                        },
                        {
                          key: "produceOrderNumber",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _c("NewPageOpen", {
                                  attrs: {
                                    path: {
                                      name: "orderDetail",
                                      query: { id: row.produceOrderId }
                                    },
                                    text: row.produceOrderNumber
                                  }
                                })
                              ],
                              1
                            )
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _vm.permission(_vm.params.radio + "Stock")
                                  ? [
                                      row.materialPreparationStatus !== 0
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "li-btn",
                                              on: {
                                                click: function($event) {
                                                  return _vm.stock(row)
                                                }
                                              }
                                            },
                                            [_vm._v("备料")]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              staticClass: "li-btn color-btns"
                                            },
                                            [_vm._v("备料")]
                                          )
                                    ]
                                  : _vm._e(),
                                _vm.permission(_vm.params.radio + "Stock")
                                  ? _c("el-divider", {
                                      attrs: { direction: "vertical" }
                                    })
                                  : _vm._e(),
                                _vm.permission(
                                  _vm.params.radio + "DownWorkOrder"
                                )
                                  ? [
                                      row.status === 0 || row.status === 2
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "li-btn",
                                              on: {
                                                click: function($event) {
                                                  return _vm.fixWorkOrder(
                                                    row,
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("下发")]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              staticClass: "li-btn color-btns"
                                            },
                                            [_vm._v("下发")]
                                          )
                                    ]
                                  : _vm._e(),
                                _vm.permission(
                                  _vm.params.radio + "DownWorkOrder"
                                )
                                  ? _c("el-divider", {
                                      attrs: { direction: "vertical" }
                                    })
                                  : _vm._e(),
                                _vm.permission(_vm.params.radio + "Modify")
                                  ? [
                                      row.status === 0 ||
                                      row.status === 2 ||
                                      row.status === 3
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "li-btn",
                                              on: {
                                                click: function($event) {
                                                  return _vm.modify(row)
                                                }
                                              }
                                            },
                                            [_vm._v("修改")]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              staticClass: "li-btn color-btns"
                                            },
                                            [_vm._v("修改")]
                                          )
                                    ]
                                  : _vm._e(),
                                _vm.permission(_vm.params.radio + "Modify")
                                  ? _c("el-divider", {
                                      attrs: { direction: "vertical" }
                                    })
                                  : _vm._e(),
                                _vm.permission(_vm.params.radio + "Del")
                                  ? [
                                      row.status === 0 || row.status === 2
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "li-btn",
                                              on: {
                                                click: function($event) {
                                                  return _vm.del(row)
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              staticClass: "li-btn color-btns"
                                            },
                                            [_vm._v("删除")]
                                          )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      2096298776
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            title: _vm.titles,
            "append-to-body": true,
            "show-footer": !_vm.formDisabled
          },
          on: { onOk: _vm.dialogSubmit },
          model: {
            value: _vm.dialogVisible,
            callback: function($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "dialogFormBuild",
              staticClass: "dialogFormBuilds",
              attrs: {
                "form-data": _vm.dialogFormData,
                "form-list": _vm.dialogFormList,
                "custom-rules": _vm.rules,
                disabled: _vm.formDisabled,
                "col-span": 12
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "process" }, slot: "process" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "all-ipt",
                      model: {
                        value: _vm.params.processFlowName,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "processFlowName", $$v)
                        },
                        expression: "params.processFlowName"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c("el-button", {
                            attrs: { icon: "el-icon-more" },
                            on: { click: _vm.selectProcessFlows }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            title: "修改计划时间",
            "append-to-body": true,
            width: "85%"
          },
          on: { onOk: _vm.dialogTimeSubmit },
          model: {
            value: _vm.dialogTimeVisible,
            callback: function($$v) {
              _vm.dialogTimeVisible = $$v
            },
            expression: "dialogTimeVisible"
          }
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addTime }
            },
            [_vm._v("批量填充")]
          ),
          _c("MTable", {
            ref: "mTables",
            staticStyle: { "margin-bottom": "10px" },
            attrs: {
              columns: _vm.columnsPlanTime,
              data: _vm.planList,
              height: 300,
              "show-page": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "planStartTime",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%", "margin-top": "6px" },
                        attrs: {
                          type: "datetime",
                          placeholder: "开始日期",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value: row.planStartTime,
                          callback: function($$v) {
                            _vm.$set(row, "planStartTime", $$v)
                          },
                          expression: "row.planStartTime"
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "planEndTime",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          placeholder: "结束日期",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value: row.planEndTime,
                          callback: function($$v) {
                            _vm.$set(row, "planEndTime", $$v)
                          },
                          expression: "row.planEndTime"
                        }
                      })
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "批量填充", "append-to-body": true },
          on: { onOk: _vm.dialogAllTimeSubmit },
          model: {
            value: _vm.dialogAllTimeVisible,
            callback: function($$v) {
              _vm.dialogAllTimeVisible = $$v
            },
            expression: "dialogAllTimeVisible"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "item-wrapper",
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center"
              }
            },
            [
              _c("div", { staticClass: "label" }, [_vm._v("计划时间：")]),
              _c(
                "div",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "计划开始时间",
                      "end-placeholder": "计划结束时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00", "23:59:59"],
                      clearable: ""
                    },
                    model: {
                      value: _vm.planAllTime,
                      callback: function($$v) {
                        _vm.planAllTime = $$v
                      },
                      expression: "planAllTime"
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "工艺路线", "show-footer": false },
          on: {
            onOk: function($event) {
              _vm.processVisible = false
            }
          },
          model: {
            value: _vm.processVisible,
            callback: function($$v) {
              _vm.processVisible = $$v
            },
            expression: "processVisible"
          }
        },
        [
          _c("process-flow", {
            ref: "processFlow",
            attrs: {
              "read-only": true,
              "process-flow-detail": _vm.processFlowVersion,
              "show-version": true
            }
          })
        ],
        1
      ),
      _c("SelectProcessFlow", {
        ref: "processFlow",
        attrs: {
          visible: _vm.processFlowVisible,
          "select-process-and-version-ids": _vm.processIdAndVersionId,
          "proce-type": _vm.proceType
        },
        on: {
          "update:visible": function($event) {
            _vm.processFlowVisible = $event
          },
          submit: _vm.selectFlow
        }
      }),
      _c("edit-materials", { ref: "editMaterials" }),
      _c(
        "MDialog",
        {
          attrs: { title: "备料" },
          on: { onOk: _vm.submitProcessForm },
          model: {
            value: _vm.stockVisible,
            callback: function($$v) {
              _vm.stockVisible = $$v
            },
            expression: "stockVisible"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "item-wrapper",
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center"
              }
            },
            [
              _c("div", { staticClass: "label" }, [_vm._v("备料方式：")]),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: {
                        display: "block",
                        "margin-bottom": "10px",
                        "margin-top": "10px"
                      },
                      model: {
                        value: _vm.tongRadio,
                        callback: function($$v) {
                          _vm.tongRadio = $$v
                        },
                        expression: "tongRadio"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticStyle: {
                            display: "block",
                            "margin-bottom": "10px"
                          },
                          attrs: { label: 0, value: "0" }
                        },
                        [_vm._v("工单整单备料（默认）")]
                      ),
                      _c("el-radio", { attrs: { label: 1, value: "1" } }, [
                        _vm._v("按工序备料")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          +_vm.tongRadio === 1
            ? _c(
                "div",
                {
                  staticClass: "item-wrapper",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-start",
                    "align-items": "center"
                  }
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("选择工序：")]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "全部",
                            filterable: "",
                            multiple: "",
                            clearable: "",
                            "collapse-tags": ""
                          },
                          model: {
                            value: _vm.produces,
                            callback: function($$v) {
                              _vm.produces = $$v
                            },
                            expression: "produces"
                          }
                        },
                        _vm._l(_vm.produceList, function(item) {
                          return _c("el-option", {
                            key: item.procedureId,
                            attrs: {
                              label: item.procedureName,
                              value: item.procedureId
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }