<template>
  <div class="orders">
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data.sync="searchFormData"
        style="padding: 0"
        @search="reSearch"
      >
        <SelectRemoteSearch
          v-for="item in searchFormSelList"
          :key="item.key"
          :slot="item.key"
          :ref="item.key"
          :size-name="apiMaps[item.key].sizeName||'limit'"
          class="select"
          clearable
          :search-name="item.searchName||'search'"
          :placeholder="apiMaps[item.key].placeholder"
          :multiple="!apiMaps[item.key].notMultiple"
          :option-val="apiMaps[item.key].optionVal"
          :option-label="apiMaps[item.key].optionLabel"
          :api="apiMaps[item.key].api"
          collapse-tags
          @change="val => {
            handleSelect(item.key, val)
          }
          "
        />
      </SearchForm>
    </div>
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      search-title="请输入模具名称"
      :tags="!selectIds.length ? functionHeader.tags :[]"
      style="padding: 10px 0 10px 0;"
      class="func-header"
      :exportName="!selectIds.length ? functionHeader.exportName : ''"
      :import-name="!selectIds.length ? 'moldDefineImport' :''"
      @search="$refs.mTable.setTableData(true)"
    >
      <div v-show="selectIds.length" slot="all">
        <el-button v-if="permission('Export')" size="small" type="primary" @click="allMore('onExport')">批量导出</el-button>
        <el-button v-if="permission('Del')" type="primary" size="small" @click="allMore('Del')">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ selectIds.length }}</span>
          / {{ total }}项
        </span>
        <el-button type="text" size="small" @click="resetData">清空</el-button>
      </div>
    </FunctionHeader>
    <MTable
      ref="mTable"
      :columns="columns"
      :height="height"
      :set-data-method="getTableData"
      @selection-change="data=>selectIds = data.map(item => item.id)"
    >
      <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
      <div slot="processFlowFullName" slot-scope="{ row }">
        <NewPageOpen
          :path="{ name: 'processDetail', query: {id: row.processFlowId}}"
          :text="row.processFlowFullName"
        />
      </div>
      <div slot="materialsFullName" slot-scope="{ row }">
        <NewPageOpen
          :path="{ name: 'materialsDetail', query: {id: row.materialsId}}"
          :text="row.materialsFullName"
        />
      </div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission('View')" type="text" @click="jump('view',row.id)">详情</el-button>
        <el-button v-if="permission('Edit')" type="text" @click="jump('edit',row.id)">编辑</el-button>
        <el-button v-if="permission('Del')" type="text" @click="onDel([row.id])">删除</el-button>
      </div>
    </MTable>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import api from '@/api/mould/index'
import exportApi from '@/api/exportAndImport/export'
import FunctionHeader from '@/components/FunctionHeader/ZCIndex.vue'
import SearchForm from '@/components/SearchForm/SearchForm'
import { searchFormSelList, searchFormList, columnsAll, apiMaps } from './data'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'

export default {
  name: 'MouldDefine',
  components: { NewPageOpen, FunctionHeader, SearchForm },
  data() {
    return {
      apiMaps,
      searchFormSelList,
      searchFormList,
      searchFormData: {},
      functionHeader: {
        searchVal: '',
        exportName: 'exportMouldDefineReport',
        exportParams: {},
        tags: [
          {
            hidden: !this.permission('Add', true),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            innerText: '新建',
            on: {
              click: () => this.jump('add')
            }
          },
          // {
          //   hidden: !this.permission('Export'),
          //   tagName: 'el-button',
          //   props: { type: 'primary', size: 'small' },
          //   style: { width: 'auto', background: '#607FFF', marginRight: '10px' },
          //   innerText: '全部导出',
          //   on: {
          //     click: () => this.onExport(true)
          //   }
          // }
        ]
      },
      columns: columnsAll,
      selectIds: [],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 15
    }
  },

  methods: {
    reSearch(data, val) {
      if (val === 1) {
        this.searchFormData = {}
        const refs = ['categoryIds', 'createBy', 'updateBy']
        refs.forEach(ref => { this.$refs[ref][0].value = '' })
      }
      this.$refs.mTable.setTableData(true)
    },
    handleSelect(key, val) {
      this.searchFormData[key] = val
      this.$refs.mTable.setTableData(true)
    },
    // 新增
    jump(type, id) {
      this.$router.push({ path: 'mould-handle', query: { type, id }})
    },

    allMore(command) {
      const functions = {
        onDel: () => this.onDel(this.selectIds),
        onExport: () => this.onExport(false, this.selectIds)
      }
      functions[command]()
    },

    // 列表
    async getTableData(condition, callback) {
      const { page, order } = condition
      const { categoryIds, createTime, updateTime, createBy: createByIds, updateBy: updateByIds, spec } = this.searchFormData
      const params = {
        sort: order,
        searchVal: this.functionHeader.searchVal,
        categoryIds: categoryIds?.join(','),
        createByIds: createByIds?.join(','),
        updateByIds: updateByIds?.join(','),
        spec,
        createAtStart: createTime ? createTime[0] : '',
        createAtEnd: createTime ? createTime[1] : '',
        updateAtStart: updateTime ? updateTime[0] : '',
        updateAtEnd: updateTime ? updateTime[1] : ''
      }
      // 导出参数
      this.functionHeader.exportParams = params
      const res = await api.getMouldList({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      if (res) {
        const records = res.records.map(item => ({ ...item, ...item.auditInfo }))
        console.log('res.records==', records)
        callback({
          total: res.total,
          content: records
        })
        this.total = res.total
      }
      callback()
    },

    // 删除
    async onDel(data) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delMould({ ids: data })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
      })
    },
    // 导出
    async onExport(isAll, ids) {
      const params = isAll ? this.functionHeader.exportParams : { ids }
      const res = await exportApi.exportMouldDefineReport(params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `模具定义-${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
    },

    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    resetData() {
      this.selectIds = []
      this.$refs.mTable.tableRefs().clearSelection()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .custom-sel {
  div {
    min-width: 100%;
  }
}
.orders {
  padding: 10px;
  .faultStatuss {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    height: 22px;
    line-height: 22px;
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
    display: inline-block;
  }
  .li-btn {
    color: #607fff;
    font-weight: 500;
  }
  .color-btns {
    color: #c4c4c4;
  }
  .searchs {
    padding-left: 0;
    padding-top: 0;
  }
  .input-select {
    width: 100px;
    display: block;
  }
  .input-with-select {
    width: 100%;
  }
  .all-operator {
    height: 32px;
    margin-left: 10px;
    ::v-deep {
      .el-button--primary,
      .el-button-group .el-button {
        height: 32px;
      }
    }
  }
  .func-header {
    ::v-deep .el-input--suffix .el-input__inner {
      height: 32px;
    }
  }
}
::v-deep .customer-content div {
  width: 100%;
}
.tip-tit {
  height: 18px;
  line-height: 18px;
}
div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #eceef2;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #393d60;
  margin-left: 10px;
}

div.tooltip:before,
div.tooltip:after {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #eceef2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #eceef2;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.params-card {
  border: 1px solid #ececf7;
  margin-bottom: 20px;
  .header {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background: #ececf7;
  }
  .body {
    width: 100%;
    .bot-ti {
      // height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 16px;
      margin-left: 20px;
      word-break: break-all;
      .imgs {
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 10px;
        img {
          width: 100px;
          height: 100px;
          display: inline-block;
        }
      }
    }
  }
}
</style>
