<template>
  <div class="materials-class">
    <div class="top">
      <div style="font-weight: 600;font-size: 14px;color: #393D60;">项目列表</div>
      <div class="add-materials-class" @click="add()"><i class="el-icon-circle-plus"/></div>
    </div>
    <div class="tree">
      <div class="input-wrapper" style="display: block">
        <div
          style="display: flex; justify-content: space-between; width: 248px;margin-bottom: 5px"
        >
          <el-select v-model="statusInfo" multiple placeholder="请选择状态" style="width: 100%" @change="search()">
            <el-option :key="0" label="待开始" :value="0"></el-option>
            <el-option :key="2" label="进行中" :value="2"></el-option>
            <el-option :key="4" label="已结案" :value="4"></el-option>
          </el-select>
        </div>

        <div
          style="display: flex; justify-content: space-between; width: 248px;margin-bottom: 5px"
        >
          <el-input
            v-model="searchVal"
            placeholder="请输入名称或编号"
            clearable
            @change="inputChange"
            @keyup.enter.native="search()"
          />
          <el-button
            icon="el-icon-search"
            type="primary"
            style="background: #607fff"
            size="small"
            @click="search()"
          >
            搜索
          </el-button>
        </div>
      </div>
      <Tree ref="zTrees" :view="view" :nodes="nodes" @command="command" @nodeClick="nodeClick"/>
    </div>
    <MDialog v-model="visible" :title="currentType" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList"/>
    </MDialog>
  </div>
</template>

<script>
import Tree from '@/components/Tree/Tree'
import api from '@/api/projectManagement/project-work-bench'

export default {
  name: 'ProjectNodeClass',
  components: {Tree},
  props: {
    view: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nodes: [],
      searchVal: '',
      statusInfo: '',
      currentClicked: '',
      visible: false,
      currentType: 'add',
      formData: {},
      formList: [
        {
          label: '物料分类名称',
          key: 'name',
          tagName: 'el-input',
          required: true
        },
        {
          label: '上级分类',
          key: 'parentId',
          tagName: 'el-select',
          props: {
            clearable: true
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              }
            }
          ]
        }
      ],
      materialsClassList: [{
        id: -1,
        name: ' '
      }]
    }
  },
  created() {
    if(localStorage.getItem('PLKMTT')){
      this.statusInfo = localStorage.getItem('PLKMTT').split(',').map(item => Number(item));
    }
  },
  mounted() {
    this.getMaterialsClassList()
  },
  methods: {
    inputChange(val) {
      if (!val) this.search()
    },
    search() {
      this.getMaterialsClassList()
    },
    async getMaterialsClassList() {
      const obt = {
        search: this.searchVal
      }
      if(this.statusInfo && this.statusInfo.length){
        localStorage.setItem("PLKMTT",this.statusInfo.join(','))
        obt.status = this.statusInfo.join(',')
      }
      const res = await api.getProjectWorkBenchList(obt)
      if (res) {
        this.nodes = []
        this.materialsClassList = []
        this.nodes = res.map(item => {
          return {
            ...item,
            isProjectTree: true,
            children: item.children ? item.children.map(items => {
              return {
                ...items,
                isProjectTree: true
              }
            }) : null
          }
        })
        this.materialsClassList = [...this.materialsClassList, ...res]
        let a = this.nodes.length ? this.nodes[0].id : ''
        let b = this.nodes.length ? this.nodes[0].status : ''
        this.$emit('setMaterialsClass', a, b)
      }
    },
    command(name, node) {
      this[name](node)
    },
    async add() {
      localStorage.removeItem('taskNodeList')
      this.$router.push({name: 'AddProject'})
    },
    viewDetail(node) {
      if (!node.parentId) {
        this.$router.push({name: 'ProjectManagementDetail', query: {id: node.id}})
      } else {
        if (node.requirementType === 0) {
          this.$router.push({name: 'salesOrderDetail', query: {id: node.id}})
        } else {
          this.$router.push({name: 'reserveStockOrderDetail', query: {id: node.id}})
        }
      }
    },
    modify(node) {
      this.formData = this.createResetData(node)
      this.formList[1].children[0].options = this.materialsClassList
        .filter(item => item.parentId !== node.id && node.id !== item.id)
      this.currentType = '修改物料分类'
      this.visible = true
    },
    del(node) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await api.delMaterialsClass({id: node.id})
        if (res) {
          this.$message.success('删除成功')
          await this.getMaterialsClassList()
        }
      })
    },
    nodeClick(treeId, node) {
      if (this.currentClicked === node.id || node.id === -1) this.currentClicked = ''
      this.currentClicked = node.id
      if (!node.parentId) {
        this.$emit('nodeClick', node)
      }
    },
    submitForm(callback) {
      const {form} = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async (valid) => {
        if (valid) {
          const type = this.currentType === '修改物料分类' ? 'modify' : 'add'
          const res = await api[`${type}MaterialsClass`](
            this.currentType === '修改物料分类' ? this.createResetData(form) : this.createSetData(form)
          )
          if (res) {
            this.$message.success(type === 'add' ? '新增成功' : '修改成功')
            this.visible = false
            await this.getMaterialsClassList()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(raw) {
      return {
        name: raw.name,
        parentId: raw.parentId || -1
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.materials-class {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  overflow: auto;

  .top {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: #DDE3EB;
    }

    .add-materials-class {
      i {
        color: rgb(96, 127, 255);
        cursor: pointer;
        font-size: 20px;
        position: relative;
        top: 1px;
      }
    }
  }

  .tree {
    height: calc(100% - 41px);
    padding: 10px 0 0 8px;
    overflow: auto;
  }

  .input-wrapper {
    padding-bottom: 4px;
    max-width: 400px;
    display: inline-flex;
    justify-content: space-between;
    position: relative;

    button {
      height: 32px;
    }

    .el-input {
      width: 165px;

      .el-input__inner {
        height: 32px;
      }
    }
  }
}
</style>
