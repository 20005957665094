<template>
    <div class="materials-wrap">
      <div class="bottom">
        <Split :offset.sync="split.offset" :height="splitHeight">
          <template slot="left">
            <RequirementsClass @nodeClick="setMaterialsClassId" :view="true"/>
          </template>
          <template slot="right">
            <div class="materials-info">
              <FunctionHeader
                ref="functionHeader"
                v-model="functionHeader.searchVal"
                search-title="节点名称"
                :tags="functionHeader.tags"
                @search="$refs.mTable.setTableData(true)"
              >
              <div slot="all">
                <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                  已选 
                  <span style="color:#607FFF">{{  exportSelectionIds.length }}</span>
                  / {{ total }}项
                </span>
        <el-button type="text" size="small" @click="resetSelectData" style="margin-top:-4px;">清空</el-button>
            </div>
              </FunctionHeader>
              <MTable ref="mTable" :columns="addColumns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="isTaskNode" slot-scope="{ row }" >
                <span>{{row.isTaskNode ? '是' : '否' }}</span>
              </div>
                <div slot="startTimeAttribute" slot-scope="{ row }">
                    <div v-if="row.startTimeAttribute != null">{{ startFinishTimeAttribute[row.startTimeAttribute].name }}</div>
                  </div>
                  <div slot="finishTimeAttribute" slot-scope="{ row }">
                    <div v-if="row.finishTimeAttribute != null">{{ startFinishTimeAttribute[row.finishTimeAttribute].name }}</div>
                  </div>
                <div slot="requirementType" slot-scope="{ row }">
                  {{ row.requirementType ? row.requirementType === 1 ? '备库需求':'预测需求':'销售订单' }}
                </div>
                <div slot="deliveryType" slot-scope="{ row }">
                  {{ row.deliveryType ? '分批交付':'统一交付' }}
                </div>
              </MTable>
            </div>
          </template>
        </Split>
      </div>
      <div class="order-footer">
        <div />
        <div class="footer-ri">
          <el-button @click="$router.back()">取 消 </el-button>
          <el-button type="primary" @click="submitChooseForm">确定</el-button>
        </div>
      </div>


      <MDialog v-model="visible" title="新增" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="templateFormList">
          <div slot="processFlowClassName">
          <CURDSelect
            :default-value="formData.planTypeId"
            api-name="PlanType"
            @change="selectName"
          />
        </div>
        <div slot="startTime">
          <el-date-picker v-show="showStartTime"
              v-model="planStartTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              style="width:100%"
              placeholder="开始时间"
              :disabled="isChooseStartAttr"
              @change="chooseStartTime"
            />
        <div style="display:flex" v-show="!showStartTime">
          <el-select v-model="startPlanId" style="width:100%" placeholder="请选择" @change="changeStartTimePlan" clearable filterable>
              <el-option
                v-for="item in planNodeTimeList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              />
            </el-select>
            <el-input-number style="margin-left:10px;"
              v-model="startTimeBasis"
              controls-position="right"
              class="min-input"
            />天
        </div>
        </div>
        <div slot="finishTime">
          <el-date-picker  v-show="showFinishTime"
              v-model="planFinishTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              style="width:100%"
              defaultTime='23:59:59'
              placeholder="完成时间"
              :disabled="isChooseFinishAttr"
              @change="chooseFinishTime"
            />
            <div style="display:flex" v-show="!showFinishTime">
          <el-select v-model="finishPlanId" style="width:100%" placeholder="请选择" @change="changeFinishTimePlan" clearable filterable>
              <el-option
                v-for="item in planNodeTimeList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id"
              />
            </el-select>
            <el-input-number style="margin-left:10px;"
              v-model="finishTimeBasis"
              controls-position="right"
              class="min-input"
            />天
        </div>
        </div>
          </MFormBuilder>
      </MDialog>
    </div>


  </template>
  
  <script>
  import FunctionHeader from '@/components/FunctionHeader'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { addColumns } from './columns'
  import { templateFormList,getFunctionList } from './form-list'
  import { getUUid } from '@/utils'
  import { Encrypt } from '@/utils/sercet'
  import Split from '@/components/Split/Split'
  import RequirementsClass from './components/RequirementsClass'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import batchExportFront from '@/mixin/batch-export-front'
  import fixCommonPage from '@/mixin/fix-common-page'
  import api from '@/api/projectManagement/task-nodes'
  import {startFinishTimeAttribute } from '@/config/options.config'
  import conventionMixin from '@/mixin/conventional-page'
  import CURDSelect from "@/components/CURDSelect/CURDSelect"
  import papi from '@/api/projectManagement/project';
  export default {
    name: 'AddTaskNodes',
    components: { NewPageOpen, RequirementsClass, Split, FunctionHeader, SearchForm,CURDSelect },
    mixins: [batchExportFront, fixCommonPage,conventionMixin],
    data() {
      return {
        api,
        functionHeader: {
          searchVal: '',
          tags: [
            {
              tagName: 'el-button',
              props: { type: 'primary', size: 'small' },
              style: { width: 'auto', background: '#607FFF' },
              innerText: '新增',
              on: {
                click: this.add
              }
            }
          ],
          importName: 'materials',
          exportName: 'exportMaterials',
          exportParams: {}
        },
        startFinishTimeAttribute,
        addColumns,
        templateFormList,
        searchFormData: {},
        searchFormatData: [],
        split: {
          offset: 15
        },
        materialsClassId: '',
        defaultSearch: {
          searchVal: Encrypt(JSON.stringify(
            [{
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }]
          ))
        },
        total:0,
        nodesList:[],
        parentIndex:-1,
        visible:false,
        showFinishTime:true,
      showStartTime:true,
      planStartTime:'',
      planFinishTime:'',
      planNodeTimeList:[],
      startTimeRelationPlanId:'',
      finishTimeRelationPlanId:'',
      startTimeBasis:0,
      finishTimeBasis:0,
      startPlanId:'',
      finishPlanId:'',
      startTimeRelationTimeType:'',
      finishTimeRelationTimeType:'',
      planStartDateTime:'',
      planFinishDateTime:'',
      isChooseFinishAttr:true,
      isChooseStartAttr:true,
      planNodeId:'',
      formData: {
          planTypeId: ""
        },
        params: {
          radio: 'TaskNodes'
        },
        currentType:'add'
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 45
      },
      splitHeight() {
        return this.$store.state.app.contentHeight - 235 + 10
      }
    },
    beforeMount() {
    getFunctionList({
      startTimeAttributeChange: this.startTimeAttributeChange,
      finishTimeAttributeChange:this.finishTimeAttributeChange
    })
  },
    mounted() {
        this.getBusinessUnitList()
      this.getUserList()
      this.getPlanNodeTimeList()
        this.nodesList = localStorage.getItem('taskNodeList') ? JSON.parse(localStorage.getItem('taskNodeList')):[]
        this.parentIndex = this.$route.query.parentIndex
    },
    methods: {
        async getBusinessUnitList() {
      const res = await papi.getBusinessUnitList()
      if (res) {
        this.templateFormList[2].children[0].options = res
      }
    },
      // 获取负责人
  async getUserList() {
    const res = await papi.getResponsible()
    if (res) {
      this.templateFormList[3].children[0].options = res
      this.principalLst = res
    }
  },
      async getPlanNodeTimeList(){
      const res = await api.getPlanNodeTimeList()
      if (res) {
        this.planNodeTimeList = res
      }
    },
      startTimeAttributeChange(val){
      if(val || val === 0){
        this.isChooseStartAttr = false
        this.showStartTime = val === 2 ? false : true
      }else{
          this.isChooseStartAttr = true
          this.showStartTime = true
        }
        this.$refs.formBuild.setForm({
            startTime: ''
      })
    },
      finishTimeAttributeChange(val){
        if(val || val === 0){
          this.isChooseFinishAttr = false
          this.showFinishTime = val === 2 ? false : true
        }else{
          this.isChooseFinishAttr = true
          this.showFinishTime = true
        }
        this.$refs.formBuild.setForm({
            finishTime: ''
      })
      },
      chooseStartTime(){
        this.$refs.formBuild.setForm({
            startTime: this.planStartTime
      })
    },
    chooseFinishTime(){
        this.$refs.formBuild.setForm({
        finishTime: this.planFinishTime,
      })
},
        selectName(item) {
      this.$refs.formBuild.setForm({
        planTypeId: item.id,
        planTypeName: item.name,
      });
    },
      changeStartTimePlan(val){
      const currentRow = this.planNodeTimeList.find(item => item.id === val)
      if(currentRow){
        this.startTimeRelationPlanId = currentRow.planId
        this.startTimeRelationTimeType = currentRow.timeType
        this.planStartDateTime = currentRow.dateTime
      }else{
          this.startTimeRelationPlanId = ''
          this.startTimeRelationTimeType = ''
          this.planStartDateTime = ''
        }
        this.$refs.formBuild.setForm({
            startTime: this.planStartDateTime
      })
    },
      changeFinishTimePlan(val){
        const currentRow = this.planNodeTimeList.find(item => item.id === val)
        if(currentRow){
          this.finishTimeRelationPlanId = currentRow.planId
          this.finishTimeRelationTimeType = currentRow.timeType
          this.planFinishDateTime = currentRow.dateTime
        }else{
          this.finishTimeRelationPlanId = ''
          this.finishTimeRelationTimeType = ''
          this.planFinishDateTime = ''
        }
        this.$refs.formBuild.setForm({
            finishTime: this.planFinishDateTime
      })
     },
     createSetData(raw) {
        return {
        planName: raw.planName,
        planTypeId: raw.planTypeId,
        startTimeAttribute: raw.startTimeAttribute,
        finishTimeAttribute: raw.finishTimeAttribute,
        startTime: raw.startTimeAttribute === 2 ? this.planStartDateTime: this.planStartTime,
        finishTime: raw.finishTimeAttribute === 2 ? this.planFinishDateTime:this.planFinishTime,
        principalId:raw.principalId ? raw.principalId.join(','):'',
        remark: raw.remark,
        startTimeRelationPlanId:raw.startTimeAttribute === 2 ?this.startTimeRelationPlanId :'',
        startTimeRelationTimeType:raw.startTimeAttribute === 2 ?this.startTimeRelationTimeType :'',
        startTimeBasis:raw.startTimeAttribute === 2 ? this.startTimeBasis :'',
        finishTimeRelationPlanId:raw.finishTimeAttribute === 2 ?this.finishTimeRelationPlanId :'',
        finishTimeRelationTimeType:raw.finishTimeAttribute === 2 ?this.finishTimeRelationTimeType :'',
        finishTimeBasis:raw.finishTimeAttribute === 2 ? this.finishTimeBasis :'',
        principalDepartId:raw.principalDepartId ? raw.principalDepartId.join(','):'',
        isTaskNode:raw.isTaskNode
        }
      },
        async submitChooseForm(){
            if (this.exportSelectionList.length === 0) {
        this.$message.info('请至少选择一条数据')
        return
      }
      this.exportSelectionList = this.exportSelectionList.map((item) => {
        return {
          ...item,
          id:'',
         startTimeBasis:0,
        finishTimeBasis:0,
        // startPlanId:'',
        // finishPlanId : '',
        // planStartDateTime:'',
        startTimeRelationPlanId : '',
        startTimeRelationTimeType : '',
        finishTimeRelationPlanId : '',
        finishTimeRelationTimeType : '',
        // planFinishDateTime : '',
        // oldStartTime : item.startTime,
        // oldFinishTime:item.finishTime,
        startTime : '',
        finishTime : '',
        }

      })
      if(this.$route.query.type === 'addParent'){
        this.nodesList = [...this.nodesList, ...this.exportSelectionList].map((item,index) =>{
        return {
            ...item,
            sort:index,
            treeId:item.taskId+ '-' +index,
            children: item.children || []
        }
      })
      localStorage.setItem('taskNodeList', JSON.stringify(this.nodesList))
      }else{
        this.nodesList = this.buildTree(this.nodesList, this.parentIndex, this.exportSelectionList)
        localStorage.setItem('taskNodeList', JSON.stringify(this.nodesList))
      }
      this.$router.back()
        },
        buildTree(nodes, parentIndex, exportSelectionList, parentTreeId = '') {
            return nodes.map((item, index) => {
                const treeId = parentTreeId ? parentTreeId + '-' + item.taskId + '-' + index : item.taskId + '-' + index ; // 在生成treeId时加入parentTreeId作为前缀
                if (item.treeId === parentIndex) {
                return {
                    ...item,
                    sort: index,
                    treeId: treeId,
                    children: [...item.children, ...exportSelectionList].map((childItem, childIndex) => {
                    const childTreeId = treeId + '-' + childIndex;
                    return {
                        ...childItem,
                        sort: childIndex,
                        treeId: childTreeId,
                        parentId: childItem.taskId,
                        children: childItem.children && childItem.children.length > 0 ? childItem.children : [] // 可以继续递归构建更多层级的子树
                    };
                    })
                };
                } else if (item.children && item.children.length > 0) {
                return {
                    ...item,
                    sort: index,
                    treeId: treeId,
                    children: this.buildTree(item.children, parentIndex, exportSelectionList, treeId)
                };
                } else {
                return {
                    ...item,
                    sort: index,
                    treeId: treeId,
                    children: []
                };
                }
            });
     },
        selectionChange(data) {
            this.exportSelectionList = data
            this.exportSelectionIds = data.map(item => item.id)
            this.$emit('chooseRequirements', data)
        },
      resetSelectData() {
        this.exportSelectionList.forEach(item => {
          if (!item.selectable) {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
          }
        })
        this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
      },
      setMaterialsClassId(id) {
        this.materialsClassId = id
        this.$refs.mTable.setTableData(true)
      },
      // 获取物料数据
      async getTableData(condition, callback) {
        const { page } = condition
        const searchCondition = {
          planTypeId: this.materialsClassId,
          search: this.functionHeader.searchVal,
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getTaskNodesListPage({
          page: page.currentPage,
          size: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records.map(item =>{
              return {
                ...item,
                taskId:item.id
              }
            })
          })
          this.total = res.total
        }
        callback()
      },
      // 查询物料
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      },
      add() {
        this.formData = {};
        this.planStartTime = ''
        this.planFinishTime = ''
        this.showStartTime = true
        this.showFinishTime = true
        this.startTimeBasis = 0
        this.finishTimeBasis = 0
        this.startPlanId = ''
        this.finishPlanId = ''
        this.isChooseStartAttr = true
        this.isChooseFinishAttr = true
        this.formData.planTypeId = this.materialsClassId
        this.visible = true;
      },
      // 删除物料
      del(row) {
        this.currentType = 'del'
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.delMaterials({ id: row.id })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData()
          }
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
  .materials-wrap {
    width: 100%;
  
    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
  
      .materials-info {
        padding: 10px;
      }
    }
  }
  .module-content >div{
              height: 34px;
            }
  </style>  