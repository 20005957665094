<template>
  <div style="background:#F0F2F5">
    <el-button
      icon="el-icon-plus"
      type="primary"
      size="medium"
      style="margin:0 0 10px 0 "
      @click="visible=true"
    >选择入库模具</el-button>
    <div class="orderAdd">
      <div class="detail-card">
        <div class="card-tit">已选模具</div>
        <div class="card-bot">
          <MTable
            ref="mUnitTable"
            :height="$store.state.app.contentHeight-300"
            :show-page="false"
            :data="tableList"
            :columns="columnsInStock"
            :columns-setting="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <el-input
              slot="code"
              v-model="row.code"
              slot-scope="{ row }"
              :maxlength="50"
              placeholder="请输入编码"
            />
            <el-button slot="codeHeader" style="width:fit-content" type="text" @click="setCode">批量生成
            </el-button>
            <template #lossScale="{row}">
              <el-input-number
                v-model="row.lossScale"
                :min="0"
                :max="100"
                controls-position="right"
                placeholder="请输入损耗进度"
                @blur="row.lossScale=sliceVal(row.lossScale,2)"
              />
            </template>
            <el-select
              slot="isScraped"
              v-model="row.isScraped"
              slot-scope="{ row }"
              placeholder="请选择报废状态"
            >
              <el-option
                v-for="item in scrapOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <template #action="{row,$index}">
              <div>
                <el-button type="text" @click="onCopy(row,$index)">复制</el-button>
                <el-button type="text" @click="tableList.splice($index,1)">删除</el-button>
              </div>
            </template>
          </MTable>
        </div>
      </div>

      <div class="order-footer">
        <div />
        <div class="footer-ri">
          <el-button @click="$router.go(-1)">取消</el-button>
          <el-button type="primary" @click="save">确定</el-button>
        </div>
      </div>
      <SelMould :visible.sync="visible" :select-moulds="tableList" @submitForm="getSelData" />
    </div>
  </div>
</template>
<script>
import { sliceVal } from '@/utils'
import SelMould from '@/components/SelMould/selMould.vue'
import api from '@/api/mould/index'
import { columnsInStock, scrapOptions } from './data'

export default {
  name: 'InStock',
  components: { SelMould },
  data() {
    return {
      columnsInStock,
      visible: false,
      scrapOptions,
      tableList: []
    }
  },
  methods: {
    sliceVal,
    async setCode() {
      const res = await api.getCode({ num: this.tableList.length })
      if (res) {
        this.tableList.forEach((item, idx) => {
          this.$set(item, 'code', res[idx])
        })
      }
    },
    onCopy(row, idx) {
      const newRow = this._.cloneDeep(row)
      newRow.code = ''
      this.tableList.splice(idx + 1, 0, newRow)
    },
    getSelData(data) {
      const newData = []
      const selMap = this.tableList.reduce((pre, cur) => (pre[cur.id] = cur) && pre, {})
      data.forEach(item => {
        item.clazzId = item.id
        !item.lossScale && (this.$set(item, 'lossScale', 0))
        this.$set(item, 'isScraped', !!item.isScraped)
        !selMap[item.id] ? newData.push(item) : newData.push(selMap[item.id])
      })
      this.tableList = newData
    },
    async save() {
      const data = []
      const tableList = this._.cloneDeep(this.tableList)
      for (let i = 0; i < tableList.length; i++) {
        const item = tableList[i]
        if (!item.code) return this.$message.warning('请完善唯一编码')
        item.id = item.code
        const { id, clazzId, lossScale, isScraped } = item
        data.push({ id, clazzId, lossScale, isScraped })
      }
      const res = await api.inStock(data)
      if (res) {
        this.$message.success('操作成功')
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .el-date-editor .el-range-separator,
  .el-date-editor .el-range__icon {
    line-height: 26px;
  }
  .el-input-number {
    height: 32px;
    line-height: 32px;
    width: 100%;
  }
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-input-number.is-controls-right .el-input-number__increase,
  .el-input-number.is-controls-right .el-input-number__decrease {
    line-height: 15px;
  }
  .el-tag .el-icon-close {
    position: absolute;
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    vertical-align: middle;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}
.el-tags {
  // width: 130px;
  // text-overflow: ellipsis;
  // overflow: hidden;
  /* width: 100%; */
  padding: 0 20px 0 10px;
  position: relative;
}
.orderAdd {
  width: 100%;
  height: 100%;
  .input-new-tag {
    width: 130px;
  }
  .button-new-tag {
    height: 32px;
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }
  ::v-deep .el-form-item__content {
    display: flex;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  .e-btn {
    margin-bottom: 10px;
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
