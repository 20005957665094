var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "orderList" })
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("新增订单")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-form-inline",
                attrs: {
                  "label-position": "right",
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "98px"
                }
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "订单来源：", prop: "source" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", disabled: "" },
                                model: {
                                  value: _vm.ruleForm.source,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "source", $$v)
                                  },
                                  expression: "ruleForm.source"
                                }
                              },
                              _vm._l(_vm.orderSource, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "订单号：",
                              prop: "produceOrderNumber"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.produceOrderNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "produceOrderNumber",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.produceOrderNumber"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "交期：", prop: "deliveryTime" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "date",
                                placeholder: "选择",
                                "value-format": "yyyy-MM-dd",
                                format: "yyyy-MM-dd"
                              },
                              model: {
                                value: _vm.ruleForm.deliveryTime,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "deliveryTime", $$v)
                                },
                                expression: "ruleForm.deliveryTime"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "优先级：" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.ruleForm.priority,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "priority", $$v)
                                  },
                                  expression: "ruleForm.priority"
                                }
                              },
                              _vm._l(_vm.priorityStatus, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: index }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户：" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.ruleForm.customerId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "customerId", $$v)
                                  },
                                  expression: "ruleForm.customerId"
                                }
                              },
                              _vm._l(_vm.userList, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注：" } },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", rows: 1 },
                              model: {
                                value: _vm.ruleForm.remark,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "remark", $$v)
                                },
                                expression: "ruleForm.remark"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "bot-li" }, [
                        _c("div", { staticClass: "li-tit" }, [
                          _vm._v("附件：")
                        ]),
                        _c(
                          "div",
                          { staticClass: "li-des" },
                          [
                            _c(
                              "el-upload",
                              _vm._b(
                                {
                                  ref: "upload",
                                  staticClass: "upload-demo",
                                  style: {
                                    width:
                                      _vm.fileList.length > 3
                                        ? "140px"
                                        : "150px"
                                  },
                                  attrs: {
                                    "file-list": _vm.fileList.slice(
                                      0,
                                      _vm.textFlg ? _vm.fileList.length : 3
                                    )
                                  }
                                },
                                "el-upload",
                                _vm.uploadProp,
                                false
                              ),
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "primary" } },
                                  [_vm._v("点击上传")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.fileList.length > 3,
                                    expression: "fileList.length > 3"
                                  }
                                ],
                                staticClass: "more",
                                on: {
                                  click: function($event) {
                                    _vm.textFlg = !_vm.textFlg
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.textFlg ? "收起" : "展开") +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "自定义标签：" } },
                          [
                            _vm._l(_vm.customTag, function(tag) {
                              return _c(
                                "el-tag",
                                {
                                  key: tag,
                                  staticClass: "el-tags",
                                  attrs: {
                                    closable: "",
                                    "disable-transitions": false
                                  },
                                  on: {
                                    close: function($event) {
                                      return _vm.handleClose(tag)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(tag) + " ")]
                              )
                            }),
                            _vm.inputVisible
                              ? _c("el-input", {
                                  ref: "saveTagInput",
                                  staticClass: "input-new-tag",
                                  attrs: { size: "small" },
                                  on: { blur: _vm.handleInputConfirm },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleInputConfirm($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.inputValue,
                                    callback: function($$v) {
                                      _vm.inputValue = $$v
                                    },
                                    expression: "inputValue"
                                  }
                                })
                              : _c(
                                  "el-button",
                                  {
                                    staticClass: "button-new-tag",
                                    attrs: { size: "small" },
                                    on: { click: _vm.showInput }
                                  },
                                  [_vm._v("+添加标签")]
                                )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("订单产品")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-button",
              {
                staticClass: "e-btn",
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.selectadd }
              },
              [_vm._v("选择添加物料")]
            ),
            _c("MTable", {
              ref: "mUnitTable",
              attrs: {
                height: 200,
                "show-page": false,
                data: _vm.orderList,
                columns: _vm.columnsOrder,
                "columns-setting": false
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "quantity",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c("el-input-number", {
                          attrs: {
                            min: 1,
                            "controls-position": "right",
                            step: 1
                          },
                          on: {
                            blur: function($event) {
                              row.quantity = _vm.sliceVal(row.quantity, 5)
                            }
                          },
                          model: {
                            value: row.quantity,
                            callback: function($$v) {
                              _vm.$set(row, "quantity", $$v)
                            },
                            expression: "row.quantity"
                          }
                        })
                      ],
                      1
                    )
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "li-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.delPlan()
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "order-footer" }, [
        _c("div", { staticClass: "footer-le" }),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v("取消")
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.submitForms } },
              [_vm._v("确认")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c("select-materials", {
        ref: "selectMaterials",
        attrs: {
          visible: _vm.showMaterials,
          single: true,
          "is-producible": true,
          "is-add": true
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials,
          addMaterial: _vm.addMaterial
        }
      }),
      _c("edit-materials", {
        ref: "editMaterials",
        on: { onSuccess: _vm.onSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }