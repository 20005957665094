var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchFormTask", {
            ref: "searchForm",
            attrs: { "form-option": _vm.formOptions, "is-out": true },
            on: { resetData: _vm.resetData, searchDatas: _vm.searchTable }
          })
        ],
        1
      ),
      _c("el-divider", { staticClass: "e-divider" }),
      _c(
        "div",
        { staticClass: "materials-info" },
        [
          _c("FunctionHeader", {
            ref: "functionHeader",
            staticClass: "functionHeader",
            attrs: {
              "search-title": "工单号/工序名称/生产批次码",
              "columns-key": _vm.columnsKey
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            staticClass: "mTable",
            attrs: {
              columns: _vm.outSourceTask,
              "set-data-method": _vm.getTableDatas,
              height: _vm.height,
              "columns-key": _vm.columnsKey
            },
            on: { "selection-change": _vm.selectionChanges },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    row.status || row.status == 0
                      ? _c(
                          "div",
                          {
                            style: {
                              color:
                                row.status === 0
                                  ? "#FA6400"
                                  : row.status === 1
                                  ? "#00AB29"
                                  : "#9597AE"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.produceTaskStatus[row.status].name)
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                }
              },
              {
                key: "materialsCode",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewMaterialsDetail(row.materialsId)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.materialsCode))]
                  )
                }
              },
              {
                key: "outWarehouseQuantityRecord",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        staticClass: "li-btn",
                        on: {
                          click: function($event) {
                            return _vm.outWarehouseQuantityViews(row, 1)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ])
                }
              },
              {
                key: "inWarehouseQuantityRecord",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        staticClass: "li-btn",
                        on: {
                          click: function($event) {
                            return _vm.outWarehouseQuantityViews(row, 0)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ])
                }
              },
              {
                key: "produceOrderNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.isShowChild
                        ? _c("NewPageOpen", {
                            attrs: {
                              path: {
                                name: "orderDetail",
                                query: { id: row.produceOrderId }
                              },
                              text: row.produceOrderNumber
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "produceWorkOrderNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      row.procedureClass === 1
                        ? _c("img", {
                            staticClass: "icon-img",
                            attrs: {
                              src: require("@/assets/plans/repair-icon-have.png")
                            }
                          })
                        : _vm._e(),
                      _vm.isShowChild
                        ? _c("NewPageOpen", {
                            attrs: {
                              path: {
                                path: "work-detail",
                                query: { id: row.produceWorkOrderId }
                              },
                              text: row.produceWorkOrderNumber
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "batchNumberList",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.fixData(row.produceWorkOrder.batchNumberList)
                        )
                      )
                    ])
                  ])
                }
              },
              {
                key: "currentProcedure",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          row.produceWorkOrder.produceWorkOrderTaskList
                            ? _vm.fixProduceData(
                                row.produceWorkOrder.produceWorkOrderTaskList,
                                "procedureName"
                              )
                            : ""
                        )
                      )
                    ])
                  ])
                }
              },
              {
                key: "currentProcedureGroupName",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          row.produceWorkOrderTaskList
                            ? _vm.fixProduceData(
                                row.produceWorkOrder.produceWorkOrderTaskList,
                                "procedureGroupName"
                              )
                            : ""
                        )
                      )
                    ])
                  ])
                }
              },
              {
                key: "procedureClass",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.procedureClass[row.procedureClass].name)
                      )
                    ])
                  ])
                }
              },
              {
                key: "procedureType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.procedureType[row.procedureType].name))
                    ])
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", { staticClass: "align-btn" }, [
                    row.isDeparture !== 1
                      ? _c(
                          "div",
                          [
                            _vm.permission("OperatorTaskAssignTask")
                              ? [
                                  row.isAssign === 1
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "color-btn",
                                          on: {
                                            click: function($event) {
                                              return _vm.assignClick(row, 2)
                                            }
                                          }
                                        },
                                        [_vm._v("已指派")]
                                      )
                                    : row.isAssign !== 1 &&
                                      row.procedureIssueMode === 1 &&
                                      row.isIssue === 0
                                    ? _c("a", { staticClass: "color-gray" }, [
                                        _vm._v("指派任务")
                                      ])
                                    : _c(
                                        "a",
                                        {
                                          staticClass: "color-btns wi-btn",
                                          on: {
                                            click: function($event) {
                                              return _vm.assignClick(row, 1)
                                            }
                                          }
                                        },
                                        [_vm._v("指派任务")]
                                      )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    row.isDeparture === 1
                      ? _c(
                          "div",
                          [
                            _vm.permission("OperatorTaskAssignTask")
                              ? [
                                  row.isAssign !== 1
                                    ? _c("a", { staticClass: "color-gray" }, [
                                        _vm._v("指派任务")
                                      ])
                                    : _vm._e(),
                                  row.isAssign === 1
                                    ? _c("a", { staticClass: "color-gray" }, [
                                        _vm._v("已指派")
                                      ])
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "指派任务" },
          on: { onOk: _vm.submitUser },
          model: {
            value: _vm.assignVisibleNo,
            callback: function($$v) {
              _vm.assignVisibleNo = $$v
            },
            expression: "assignVisibleNo"
          }
        },
        [
          _vm.showAssign
            ? _c(
                "div",
                { staticClass: "all-flexss" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "cancel-btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAssign(1)
                        }
                      }
                    },
                    [_vm._v("取消指派")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c("SelectUserAndUserGroup", {
            ref: "userAndUserGroup",
            attrs: {
              "is-assign": false,
              "user-options": _vm.userOptions,
              "user-group-options": _vm.userGroupOptions
            },
            model: {
              value: _vm.transferSelectIds,
              callback: function($$v) {
                _vm.transferSelectIds = $$v
              },
              expression: "transferSelectIds"
            }
          })
        ],
        1
      ),
      _c("edit-materials", { ref: "editMaterials" }),
      _c("TableDialog", {
        attrs: {
          title: "查看入库记录",
          show: _vm.inWarehouseVisible,
          columns: _vm.inWarehouseColumns,
          "table-data": _vm.inWarehouseList
        },
        on: {
          "update:show": function($event) {
            _vm.inWarehouseVisible = $event
          }
        }
      }),
      _c("TableDialog", {
        attrs: {
          title: "查看出库记录",
          show: _vm.outWarehouseVisible,
          columns: _vm.outWarehouseColumns,
          "table-data": _vm.outWarehouseList
        },
        on: {
          "update:show": function($event) {
            _vm.outWarehouseVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }