var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    [
      _vm._m(0),
      _c("tr", [
        _c(
          "td",
          {
            attrs: { colspan: "20", t: "s", id: "sjs-A2", v: "工位：Ф铜框绞" }
          },
          [_vm._v("工位：" + _vm._s(_vm.stationNm))]
        )
      ]),
      _c(
        "tr",
        [
          _vm.judgeField("batchNumber")
            ? _c("td", { attrs: { t: "s", id: "sjs-A3", v: "批次码" } }, [
                _vm._v(" 批次码 ")
              ])
            : _vm._e(),
          _vm.judgeField("produceWorkOrderNumber")
            ? _c("td", { attrs: { t: "s", id: "sjs-A3", v: "生产工单" } }, [
                _vm._v(" 生产工单 ")
              ])
            : _vm._e(),
          _vm.judgeField("materialsInfo")
            ? _c("td", { attrs: { t: "s", id: "sjs-B3", v: "成品" } }, [
                _vm._v(" 成品 ")
              ])
            : _vm._e(),
          _vm.judgeField("leftNumber")
            ? _c("td", { attrs: { t: "s", id: "sjs-C3", v: "数量" } }, [
                _vm._v("成品相关数量")
              ])
            : _vm._e(),
          _vm.judgeField("sourceQuantity")
            ? _c(
                "td",
                { attrs: { t: "s", id: "sjs-C3", v: "上道工序产出数量" } },
                [_vm._v("上道工序产出数量")]
              )
            : _vm._e(),
          _vm.judgeField("customerName")
            ? _c("td", { attrs: { t: "s", id: "sjs-E3", v: "客户" } }, [
                _vm._v("客户")
              ])
            : _vm._e(),
          _vm.judgeField("procedureName")
            ? _c("td", { attrs: { t: "s", id: "sjs-E3", v: "工序名" } }, [
                _vm._v("工序名")
              ])
            : _vm._e(),
          _vm.judgeField("deliveryTime")
            ? _c("td", { attrs: { t: "s", id: "sjs-E3", v: "交期" } }, [
                _vm._v("交期")
              ])
            : _vm._e(),
          _vm.judgeField("remark")
            ? _c("td", { attrs: { t: "s", id: "sjs-G3", v: "备注" } }, [
                _vm._v("备注")
              ])
            : _vm._e(),
          _vm.judgeField("midMaterial")
            ? _c("td", { attrs: { t: "s", id: "sjs-H3", v: "中间品" } }, [
                _vm._v("中间品")
              ])
            : _vm._e(),
          _vm.judgeField("rightNumber")
            ? _c("td", { attrs: { t: "s", id: "sjs-H3", v: "中间品" } }, [
                _vm._v("中间品相关数量")
              ])
            : _vm._e(),
          _vm.taskList.length > 0
            ? _vm._l(
                _vm.taskList[0][0].produceWorkOrderTaskFieldMappingList,
                function(item, index) {
                  return _c("div", { key: index }, [
                    item.type === 5 && item.isShow
                      ? _c("td", [_vm._v(" " + _vm._s(item.fieldName) + " ")])
                      : _vm._e()
                  ])
                }
              )
            : _vm._e(),
          _c(
            "td",
            { attrs: { colspan: "2", t: "s", id: "sjs-I3", v: "中间品数量" } },
            [_vm._v("中间品数量")]
          )
        ],
        2
      ),
      _vm._l(_vm.taskList, function(itemList, index1) {
        return [
          _c(
            "div",
            { key: index1 },
            [
              _vm._l(itemList, function(item, index2) {
                return _c(
                  "tr",
                  { key: index1 + "-" + index2 },
                  [
                    _vm.judgeField("batchNumber")
                      ? _c("td", [_vm._v(_vm._s(item.batchNumber || ""))])
                      : _vm._e(),
                    _vm.judgeField("produceWorkOrderNumber")
                      ? _c("td", [
                          _vm._v(_vm._s(item.produceWorkOrderNumber || ""))
                        ])
                      : _vm._e(),
                    _vm.judgeField("materialsInfo")
                      ? _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(item.materialsName || "") +
                              _vm._s(item.specifications ? "/" : "") +
                              _vm._s(item.specifications || "") +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _vm.judgeField("leftNumber")
                      ? _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(item.workOrderQuantity || "") +
                              "/" +
                              _vm._s(item.sourceQuantity || "") +
                              "/" +
                              _vm._s(item.produceWorkOrder.reportWorkQuantity) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _vm.judgeField("sourceQuantity")
                      ? _c("td", [
                          _vm._v(" " + _vm._s(item.sourceQuantity || "") + " ")
                        ])
                      : _vm._e(),
                    _vm.judgeField("customerName")
                      ? _c("td", [_vm._v(_vm._s(item.customerName || ""))])
                      : _vm._e(),
                    _vm.judgeField("procedureName")
                      ? _c("td", [_vm._v(_vm._s(item.procedureName || ""))])
                      : _vm._e(),
                    _vm.judgeField("deliveryTime")
                      ? _c("td", [
                          _vm._v(
                            _vm._s(item.produceWorkOrder.deliveryTime || "")
                          )
                        ])
                      : _vm._e(),
                    _vm.judgeField("remark")
                      ? _c("td", [_vm._v(_vm._s(item.remark || ""))])
                      : _vm._e(),
                    _vm.judgeField("midMaterial")
                      ? _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.produceWorkOrderTaskOutputMaterials
                                  ? item.produceWorkOrderTaskOutputMaterials
                                      .materials.name
                                  : ""
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _vm.judgeField("rightNumber")
                      ? _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.produceWorkOrderTaskOutputMaterials
                                  ? item.produceWorkOrderTaskOutputMaterials
                                      .quantity
                                    ? item.produceWorkOrderTaskOutputMaterials
                                        .quantity
                                    : 0
                                  : 0
                              ) +
                              "/" +
                              _vm._s(
                                item.outputReportWorkQuantity
                                  ? item.outputReportWorkQuantity
                                  : 0
                              ) +
                              " (" +
                              _vm._s(item.reportWorkQuantity) +
                              ") "
                          )
                        ])
                      : _vm._e(),
                    itemList.length > 0
                      ? _vm._l(
                          item.produceWorkOrderTaskFieldMappingList,
                          function(item, index) {
                            return _c("div", { key: index }, [
                              item.type === 5 && item.isShow
                                ? _c("td", [
                                    _vm._v(" " + _vm._s(item.value) + " ")
                                  ])
                                : _vm._e()
                            ])
                          }
                        )
                      : _vm._e(),
                    _c(
                      "td",
                      { attrs: { colspan: itemList.length > 1 ? "1" : "2" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.produceWorkOrderTaskOutputMaterials
                                ? item.produceWorkOrderTaskOutputMaterials
                                    .quantity
                                : ""
                            ) +
                            " "
                        )
                      ]
                    ),
                    index2 === 0 && itemList.length > 1
                      ? _c("td", { attrs: { rowspan: itemList.length } }, [
                          _vm._v(
                            " " + _vm._s(_vm.getTotalCount(itemList)) + " "
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              }),
              index1 !== _vm.taskList.length - 1
                ? _c("tr", { staticClass: "blank" })
                : _vm._e()
            ],
            2
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        { attrs: { colspan: "20", t: "s", id: "sjs-A1", v: "新疆中超施工单" } },
        [_vm._v("新疆中超施工单")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }