<template>
  <div class="searchs">
    <!-- 顶部折叠搜索 -->
    <el-row>
      <el-col :md="18" :gutter="10">
        <el-row :gutter="10" type="flex" justify="start" style="flex-flow:row wrap">
          <!-- <el-col :md="6">
            <el-select v-model="searchFormData.produceOrderId" placeholder="所属生产订单" filterable multiple clearable collapse-tags>
              <el-option
                v-for="item in orderNumbers"
                :key="item.id"
                :label="item.produceOrderNumber"
                :value="item.id"
              />
            </el-select>
          </el-col> -->
          <el-col :md="6">
            <el-select v-model="searchFormData.isStatus" placeholder="工序状态" filterable multiple clearable collapse-tags @change="searchData">
              <el-option label="待开始" :value="0" />
              <el-option label="进行中" :value="1" />
              <el-option label="已完成" :value="2" />
              <!-- <el-option label="强制结束" :value="3" /> -->
            </el-select>
          </el-col>
          <el-col :md="6">
            <SelectMultipleMaterialsNew ref="multipleMaterial" @typeChange="searchData" />
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.procedureId" placeholder="工序" filterable multiple clearable collapse-tags @change="searchData">
              <el-option
                v-for="item in getCurrentProcedureList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.procedureClass" placeholder="工序属性" clearable @change="searchData">
              <el-option
                v-for="item in procedureClass"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="!show" type="flex" justify="start" style="flex-flow:row wrap" :gutter="10">
          <el-col :md="6">
            <el-select v-model="searchFormData.currentProcedureGroupIds" collapse-tags placeholder="当前所处工序段" filterable multiple clearable @change="currentGroupIdChange">
              <el-option
                v-for="item in currentProcedureGroupNames"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <!-- <el-col :md="6">
            <el-select v-model="searchFormData.produceWorkOrderId" placeholder="生产工单" filterable multiple clearable collapse-tags>
              <el-option
                v-for="item in produceWorkOrderList"
                :key="item.id"
                :label="item.produceWorkOrderNumber"
                :value="item.id"
              />
            </el-select>
          </el-col> -->
          <el-col v-if="isOut" :md="6">
            <el-date-picker
              v-model="searchFormData.outSourcingBeginTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="预计入库时间"
              end-placeholder="预计入库时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col v-if="isOut" :md="6">
            <el-select v-model="searchFormData.supplierIds" placeholder="供应商" collapse-tags filterable multiple clearable @change="searchData">
              <el-option
                v-for="item in customersList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>

          <el-col :md="6">
            <el-select v-model="searchFormData.isIssue" placeholder="下发状态" clearable @change="searchData">
              <el-option
                v-for="item in issueList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.isAssign" placeholder="指派状态" clearable @change="searchData">
              <el-option
                v-for="item in assignList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.stationId" placeholder="工位" clearable filterable @change="searchData">
              <el-option
                v-for="item in stationList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <el-select v-model="searchFormData.equipmentId" placeholder="设备" clearable filterable @change="searchData">
              <el-option
                v-for="item in equipmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :md="6" class="all-btns">
        <el-button size="small" type="primary" @click="searchData">查询</el-button>
        <el-button size="small" @click="resetData">重置</el-button>
        <div class="showMore" :style="{background: show ? '' : '#F8F8F8'}" @click="changeMore">
          <span>高级筛选</span>
          <i v-if="show" class="el-icon-caret-bottom icons" />
          <i v-else class="el-icon-caret-top" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { procedureClass, procedureType } from '@/config/options.config'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import { getUUid } from '@/utils'
import dayjs from 'dayjs'
import CURDSelectApi from '@/api/CURDSelect'
export default {
  components: { SelectMultipleMaterialsNew },
  props: {
    formOption: {
      type: Object,
      default: () => ({})
    },
    isOut: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stationList: [],
      equipmentList: [],
      procedureClass,
      procedureType,
      show: true,
      currentProcedures: [],
      produceWorkOrderList: [],
      currentProcedureGroupNames: [],
      orderNumbers: [],
      searchFormData: {
        equipmentId: '',
        stationId: '',
        procedureClass: '',
        procedureType: '',
        procedureId: [],
        isStatus: [],
        produceWorkOrderId: [],
        produceOrderId: []
      },
      selectKeyword: '0',
      searchDatas: [],
      issueList: [
        {
          id: 0,
          name: '未下发'
        },
        {
          id: 1,
          name: '已下发'
        }
      ],
      assignList: [
        {
          id: 0,
          name: '未指派'
        },
        {
          id: 1,
          name: '已指派'
        }
      ],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = dayjs().format('YYYY-MM-DD 23:59:59')
            const start = dayjs().format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '昨天',
          onClick(picker) {
            const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
            const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  computed: {
    getCurrentProcedureList() {
      return this.currentProcedures.filter(item =>
        !this.searchFormData.currentProcedureGroupIds ||
        !this.searchFormData.currentProcedureGroupIds.length ||
        this.searchFormData.currentProcedureGroupIds.includes(item.groupId)
      )
    }
  },
  mounted() {
    this.stationList = this.formOption.stationOptions
    this.equipmentList = this.formOption.equipmentOptions
    this.currentProcedures = this.formOption.operatorOptions
    this.orderNumbers = this.formOption.orderOptions
    this.customersList = this.formOption.customersOptions
    this.getProcedureGroupNameList()
  },
  methods: {
    async getProcedureGroupNameList() {
      const res = await CURDSelectApi.getProcedureGroupList()
      if (res) {
        this.currentProcedureGroupNames = res
      }
    },
    currentGroupIdChange() {
      this.searchFormData.procedureId = []
    },
    changeMore() {
      this.show = !this.show
    },
    // 顶部查询
    searchData() {
      const obj = {}
      const searchDatas = []
      let supplierIds = []
      const date = []
      let statuses = ''
      let stationId = ''
      let equipmentId = ''
      Object.keys(this.searchFormData).forEach(item => {
        if (Array.isArray(this.searchFormData[item])) {
          if (item === 'isStatus') {
            statuses = this.searchFormData[item].join('^')
          }
          if (item === 'outSourcingBeginTime' && this.searchFormData[item].length > 0) {
            date[0] = `${dayjs(this.searchFormData[item][0]).format('YYYY-MM-DD')} 00:00:00`
            date[1] = `${dayjs(this.searchFormData[item][1]).format('YYYY-MM-DD')} 23:59:59`
          }
          if (item === 'supplierIds' && this.searchFormData[item].length > 0) {
            supplierIds = this.searchFormData[item].join('^')
          }
          if ((item === 'procedureId' || item === 'produceOrderId' || item === 'produceWorkOrderId' || item === 'currentProcedureGroupIds') && this.searchFormData[item].length > 0) {
            let fieldValue = this.searchFormData[item].join('^')
            let fieldName = item
            if (item === 'currentProcedureGroupIds') {
              fieldValue = this.getCurrentProcedureList.map(item => item.id).join('^')
              fieldName = 'procedureId'
            }

            searchDatas.push({
              id: getUUid(),
              fieldName,
              fieldType: 'number',
              fieldValue,
              operator: 'in'
            })
          }
        } else {
          if (item === 'stationId') {
            stationId = this.searchFormData[item]
          } else if (item === 'equipmentId') {
            equipmentId = this.searchFormData[item]
          } else if (this.searchFormData[item] || this.searchFormData[item] === 0) {
            searchDatas.push({
              id: getUUid(),
              fieldName: item,
              fieldType: typeof this.searchFormData[item],
              fieldValue: this.searchFormData[item],
              operator: typeof this.searchFormData[item] === 'number' ? 'eq' : 'like'
            })
          }
        }
      })
      const keyword = this.$refs.multipleMaterial.keyword.code
      this.$emit('searchData', searchDatas, this.selectKeyword, keyword, statuses, stationId, equipmentId)
      this.$emit('searchDatas', searchDatas, this.selectKeyword, keyword, supplierIds, date, statuses)
    },
    // 顶部重置
    resetData() {
      this.searchFormData = {
        procedureClass: '',
        procedureType: '',
        procedureId: [],
        isStatus: undefined,
        produceWorkOrderId: '',
        equipmentId: '',
        stationId: ''
      }
      this.$refs.multipleMaterial.keyword = ''
      this.$emit('resetData')
    }
  }
}
</script>

<style scoped lang="scss">
 .searchs{
   padding-top: 10px;

    .input-with-select{
      width:100%;
      .input-select{
        width:105px;
      }
   }
    ::v-deep{
      .el-col-md-6{
        margin-bottom:10px;
      }
      .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
        width: 100%;
        height: 32px;
      }
      .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width: 100%;
        height: 32px;
      }
      .el-select{
        width: 100%;
      }
       .el-input--suffix .el-input__inner {
        height:32px;
      }
      .el-button-group .el-button{
        height:32px;
      }
      .el-date-editor .el-range-separator, .el-date-editor .el-range__icon {
        line-height: 27px;
      }
   }
  .showMore{
    width: 88px;
    height: 40px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    .icons{
     color: #DDE3EB;
    }
    &:hover{
      background:#F8F8F8;
      i{ color: #607FFF;}
    }
  }
  .all-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    ::v-deep{
      .el-button{
        height: 32px;
        line-height: 4px;
      }
    }
  }
  .all-status{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    height:40px;
    .mulStatus{
      padding:0 30px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 16px;
      border: 1px solid #DDE3EB;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A4B3C6;
      line-height: 32px;
      margin-right:15px;
      cursor: pointer;

      &:last-child{
        margin-right: 0;
      }
    }
    .active{
        background: #EFF2FF;
        border: 1px solid #607FFF;
        color: #607FFF;
    }
  }

 }
</style>
