var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForms", {
            ref: "searchForms",
            attrs: { formOption: _vm.formOptions },
            on: { resetData: _vm.resetData, searchData: _vm.searchTable }
          })
        ],
        1
      ),
      _c(
        "FunctionHeader",
        {
          ref: "functionHeader",
          staticClass: "func-header",
          staticStyle: { padding: "0px 0 10px 0", "margin-left": "-10px" },
          attrs: {
            "search-title": "请输入工单号/批次码",
            tags: !_vm.selectData.length ? _vm.functionHeader.tags : [],
            "export-name": _vm.functionHeader.exportName,
            "export-params": _vm.functionHeader.exportParams,
            "show-export": !_vm.selectData.length ? true : false
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectData.length,
                  expression: "selectData.length"
                }
              ],
              attrs: { slot: "all" },
              slot: "all"
            },
            [
              _vm.permission("BatchExport")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.isEbatchExport()
                        }
                      }
                    },
                    [_vm._v("批量导出")]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "0 10px",
                    display: "inline-block",
                    "font-size": "12px",
                    color: "#393D60"
                  }
                },
                [
                  _vm._v(" 已选 "),
                  _c("span", { staticStyle: { color: "#607FFF" } }, [
                    _vm._v(_vm._s(_vm.getSelectionDataLength))
                  ]),
                  _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "small" },
                  on: { click: _vm.resetSelectData }
                },
                [_vm._v("清空")]
              )
            ],
            1
          )
        ]
      ),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns2,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        on: { "selection-change": _vm.selectionChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "produceWorkOrderNumber",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.isShowChild
                    ? _c("NewPageOpen", {
                        attrs: {
                          path: {
                            name: "workDetail",
                            query: { id: row.produceWorkOrderId }
                          },
                          text: row.produceWorkOrderNumber
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          },
          {
            key: "warehouseType",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      row.warehouseType === 1
                        ? row.sitWarehouseName
                        : row.warehouseName + "-" + row.warehouseLocationName
                    ) +
                    " "
                )
              ])
            }
          },
          {
            key: "procedureDetails",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _c(
                  "a",
                  {
                    staticClass: "li-btn",
                    on: {
                      click: function($event) {
                        return _vm.scanDetail(row)
                      }
                    }
                  },
                  [_vm._v("查看")]
                )
              ])
            }
          },
          {
            key: "moldInstances",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  row.moldInstances
                    ? _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: row.moldInstances,
                            placement: "top"
                          }
                        },
                        [
                          _c("el-button", { attrs: { type: "text" } }, [
                            _vm._v(
                              _vm._s(
                                row.moldInstances &&
                                  row.moldInstances.split("、")[0] + "..."
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _c("div", [_vm._v("-")])
                ],
                1
              )
            }
          },
          {
            key: "remark",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { content: row.remark, placement: "top-end" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-overflow": "ellipsis",
                            overflow: "hidden",
                            "white-space": "nowrap"
                          }
                        },
                        [_vm._v(_vm._s(row.remark) + " ")]
                      )
                    ]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: {
            value: _vm.show,
            title: "查看工序参数",
            "show-footer": false
          },
          on: {
            close: function($event) {
              _vm.show = false
            }
          }
        },
        _vm._l(_vm.groupList, function(row) {
          return _c("div", { key: row.name, staticClass: "params-card" }, [
            _c("div", { staticClass: "header flex-sbc" }, [
              _c("div", [_vm._v("参数组标题：" + _vm._s(row.name))])
            ]),
            _c(
              "div",
              { staticClass: "body" },
              _vm._l(row.fromData, function(item) {
                return _c("div", { key: item.id }, [
                  item.type !== 4
                    ? _c("span", { staticClass: "bot-ti" }, [
                        _vm._v(
                          _vm._s(item.key) +
                            "：" +
                            _vm._s(_vm.fixData(item.value))
                        )
                      ])
                    : _c(
                        "span",
                        { staticClass: "bot-ti" },
                        [
                          _vm._v(_vm._s(item.key) + "： "),
                          _vm._l(_vm.fixArr(item.value), function(items) {
                            return _c(
                              "a",
                              {
                                key: items,
                                staticClass: "imgs",
                                attrs: { href: items, target: "_blank" }
                              },
                              [_c("img", { attrs: { src: items } })]
                            )
                          })
                        ],
                        2
                      )
                ])
              }),
              0
            )
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }