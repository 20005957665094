var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "table-box" },
      [
        _c("FunctionHeader", {
          ref: "functionHeader",
          staticClass: "func-header",
          staticStyle: { padding: "10px 0 10px 0" },
          attrs: {
            tags: _vm.functionHeader.tags,
            "search-title": "请输入物料编码/名称"
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.columns,
            height: _vm.height,
            "set-data-method": _vm.getTableData
          },
          on: {
            "selection-change": function(data) {
              return (_vm.selectData = data)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function(ref) {
                var $index = ref.$index
                return _c("div", {}, [_vm._v(_vm._s($index + 1))])
              }
            },
            {
              key: "materialsCode",
              fn: function(ref) {
                var row = ref.row
                return _c("NewPageOpen", {
                  attrs: {
                    path: {
                      name: "materialsDetail",
                      query: { id: row.materialsId }
                    },
                    text: row.materialsCode
                  }
                })
              }
            },
            {
              key: "procedureCode",
              fn: function(ref) {
                var row = ref.row
                return _c("NewPageOpen", {
                  attrs: {
                    path: {
                      name: "procedureDetail",
                      query: { id: row.procedureId }
                    },
                    text: row.procedureCode
                  }
                })
              }
            },
            {
              key: "action",
              fn: function(ref) {
                var row = ref.row
                return _c(
                  "div",
                  {},
                  [
                    _vm.permission("Edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onHandle("edit", row)
                              }
                            }
                          },
                          [_vm._v("修改")]
                        )
                      : _vm._e(),
                    _vm.permission("Del")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onHandle("del", row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }
            }
          ])
        }),
        _c("PieceRateHandle", {
          ref: "editPop",
          attrs: { "handle-type": _vm.handleType, "edit-raw": _vm.editRaw }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }