// 列表
export const columnsAll = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'procedureName',
    label: '工序名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 150
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    minWidth: 300
  },
  {
    prop: 'moldInstances',
    label: '所用模具',
    slotName: 'moldInstances',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'materialsUnit',
    label: '物料单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'operator',
    label: '操作人',
    minWidth: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'frequencyName',
    label: '班次',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operateTime',
    label: '报工时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '关联工单',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 200,
    slotName: 'produceWorkOrderNumber'
  },
  {
    prop: 'produceOrderNumber',
    label: '所属生产订单',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 200
  },
  {
    prop: 'stationName',
    label: '报工工位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'procedureDetails',
    label: '工序参数',
    hiddenSearch: true,
    sortable: false,
    slotName: 'procedureDetails'
  },
  {
    prop: 'checkFinishTime',
    label: '检验完成时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'spendWorkingHours',
    label: '实际工时（小时）',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'convertValue',
    label: '米数',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'width',
    label: '宽度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'thickness',
    label: '厚度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'steelGrade',
    label: '钢卷号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'coilNo',
    label: '牌号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    slotName: 'remark',
    width: 120
  }
]
export const columnsOrder = [
 
  // {
  //   prop: 'id',
  //   label: 'ID',
  //   sortable: true,
  //   hiddenSearch: true,
  //   width: 80
  // },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    minWidth: 80
  },
  {
    prop: 'produceOrderNumber',
    label: '生产订单号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '订单状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status',

  },
  {
    prop: 'quantity',
    label: '生产订单数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  
  {
    prop: 'materialsSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
 
  {
    prop: 'materialsUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'deliveryTime',
    label: '交期',
    sortable: false,
    hiddenSearch: true
  }
]
export const columnsList =[
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'number',
    label: '定额单号',
    sortable: false,
    hiddenSearch: true,
    slotName:'number'
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 144
  }
]