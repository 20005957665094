<template>
  <div>
    <MDialog v-model="modalShow" title="减产" width="80%">
      <div class="batchImport">
        <!-- 减产 -->
        <div v-if="stepActive === 0">
          <div class="repair-detail">
            <div class="repair-num">
              <div class="num-label">当前工序：</div>
              <el-select
                v-if="baseFormData.isPartialOutbound === 1"
                v-model="formData.produceWorkOrderTaskId"
                filterable
                allow-create
                default-first-option
                placeholder="请选择或输入"
              >
                <el-option
                  v-for="(item, index) in taskList"
                  :key="index"
                  :label="item.procedureName"
                  :value="item.produceWorkOrderTaskId"
                  @click.native="selectProduceCode(item)"
                />
              </el-select>
              <div v-else class="num-dec">{{ baseFormData.produceWorkOrderTaskList ? fixProduceData(baseFormData.produceWorkOrderTaskList) : '' }}</div>
            </div>
            <div>
              <div class="repair-num">
                <div class="num-label">减产原因：</div>
                <el-select
                  v-model="formData.reason"
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择或输入"
                >
                  <el-option
                    v-for="(item, index) in reasonList"
                    :key="index"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </div>
              <div class="repair-num">
                <div class="num-label">选择减产批次：</div>
                <el-select v-model="formData.batchNumber" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, index) in list"
                    :key="index"
                    :label="item.batchNumber"
                    :value="item.batchNumber"
                    @click.native="selectBatch(item)"
                  />
                </el-select>
              </div>
              <MTable ref="mTable" :data="batchNumberList" :height="150" :columns="stationColums" :columns-setting="false" :show-page="false">
                <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
                <div slot="quantity" slot-scope="{ row }">
                  <el-input-number v-model="row.quantity" :min="0" :max="Number(row.reportWorkQuantity)" controls-position="right" :step="1" />
                </div>
              </MTable>

            </div>
          </div>
        </div>
        <div v-if="stepActive === 1">
          <div class="repair-detail">
            <img src="https://oss.mubyte.cn/static/data-success.png" class="img-sucs">
            <div class="suc-tits">减产完成</div>

          </div>
        </div>
      </div>
      <div slot="footer" class="mubai-dialog-footer">
        <el-button v-if="stepActive === 0" @click="cancel">{{ cancelText[stepActive] }}</el-button>
        <el-button type="primary" :loading="submitLoading" :disabled="uploading" @click="submitForm">{{ confirmTextFun() }}</el-button>
      </div>
    </MDialog>
  </div>
</template>
<script>
import { stationColums } from './columns'
import api from '@/api/production/operation-task'
import orderApi from '@/api/production/orders'

export default {
  name: 'ReductionModel',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    baseFormData: {
      type: Object,
      default: {}
    },
    taskList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      list: [],
      batchNumberList: [],
      reasonList: [],
      stationColums,
      saveButton: false,
      isShow: false,
      formData: {
        batchNumber: '',
        reason: '',
        produceWorkOrderTaskId: ''
      },
      modalShow: false,
      stepActive: 0,
      loading: false,
      submitLoading: false,
      uploading: false,
      excelContent: [],
      cancelText: {
        0: '取 消'
      },
      confirmText: {
        0: '确认',
        1: '完成'
      }
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 48 - 12 - 220
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  watch: {
    visible(val) {
      this.modalShow = val
      if (val) {
        this.stepActive = 0
        Object.assign(this.formData,
          {
            batchNumber: '',
            reason: '',
            produceWorkOrderTaskId: ''
          }
        )
        this.submitLoading = false
        this.loading = false
        if (this.baseFormData.isPartialOutbound === 1) {
          this.batchNumberList = []
          return false
        }
        if (this.baseFormData.isPartialOutbound === 0) {
          this.batchNumberList = []
          this.list = this.taskList[0].batchNumberList
        }
        // if (this.baseFormData.isPartialOutbound === 0 && this.baseFormData.produceWorkOrderTaskList && this.baseFormData.produceWorkOrderTaskList.length > 0) {
        //   this.formData.produceWorkOrderTaskId = this.baseFormData.produceWorkOrderTaskList[0].id
        // }
        // this.list = this.baseFormData.produceWorkOrderTaskList ? this.baseFormData.produceWorkOrderTaskList[0].inTimeBatchNumberList : []
        // this.formData.batchNumber = this.list && this.list.length > 0 ? this.list[0].batchNumber : ''
        // const data = this.list ? this.list[0] : ''
        // this.selectBatch(data)
      }
    },
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.modalShow = this.visible
    this.getReductionReasonList()
  },
  methods: {
    selectProduceCode(item) {
      this.list = item.batchNumberList
    },
    async getReductionReasonList() {
      const res = await api.getReductionReasonList()
      if (res) {
        this.reasonList = res
      }
    },
    fixProduceData(val) {
      const list = val.map(item => item.procedureName)
      return list.length === 1 ? list[0] : list.join('-')
    },
    selectBatch(item) {
      if (item) {
        this.batchNumberList = this._.cloneDeep([item])
        this.isShow = true
      }
    },
    async save() {
      const res = await api.saveReduction(Object.assign({}, this.formData, {
        produceWorkOrderId: this.baseFormData.id,
        quantity: this.batchNumberList[0].quantity
      }))
      if (res) {
        this.stepActive = 1
        this.submitLoading = false
      } else {
        this.submitLoading = false
      }
    },
    async submitForm() {
      if (!this.formData.batchNumber) {
        this.$message.error('请选择批次码')
        return false
      }
      if (this.formData.reason === '') {
        this.$message.error('请选择或输入减产原因')
        return false
      }
      if (this.batchNumberList[0].quantity === undefined || this.batchNumberList[0].quantity === 0) {
        this.$message.error('请填写减产数量')
        return false
      }
      if (this.stepActive === 0) {
        this.submitLoading = true
        await this.save()
        return true
      }
      if (this.stepActive === 1) {
        this.modalShow = false
        this.$emit('loadWord')
      }
    },
    cancel() {
      if (this.stepActive === 0) {
        this.modalShow = false
        return true
      }
    },
    confirmTextFun() {
      return this.confirmText[this.stepActive]
    }
  }
}
</script>

<style lang="scss" scoped>
.repair-detail {
  background: #F6F7F9;
  border-radius: 4px;
  padding: 20px;
  .repair-tit {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #4B4F77;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .img-sucs {
    width: 279px;
    height: 279px;
    margin: 25px auto 7px auto;
    display: block;
  }
  .suc-tits {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393D60;
    line-height: 20px;
    margin-top: -120px;
    text-align: center;
    margin-bottom: 98px;
  }
  .li-all {
    display: flex;
    justify-content: center;
    height: 32px;
    align-items: center;
    .li-le {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
      line-height: 20px;
      margin-right: 20px;
    }
    .li-ri {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .repair-num {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    .num-label {
      width: 160px;
      height: 32px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
      line-height: 32px;
      text-align: right;
    }
    .icon-color {
      color: #f00;
      margin-right: 5px;
    }
    .num-dec {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
      line-height: 32px;
      text-align: left;
      .spas {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9597AE;
        line-height: 17px;
        display: block;
      }
    }
    .colorNum {
      color: #f00;
    }
    .num-width {
      width: 100px;
    }
    .num-select {
      width: 250px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 4px;
    }
    .num-btn {
      width: 88px;
      height: 32px;
      margin-left: 20px;
    }
    .num-img {
      width: 18px;
      height: 18px;
      display: inline-block;
      cursor: pointer
    }
    .num-tits {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 20px;
      margin-left: 10px;
      cursor: pointer
    }
  }
  .repair-nums {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456B;
    line-height: 20px;
    margin: 20px 0 27px 0;
  }
  .mul-num {
    padding: 20px;
    border-radius: 4px;
    border: 1px dashed #9597AE;
    .tit {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #41456B;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .mul-check {
      .check-li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        ::v-deep {
          .el-checkbox__label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #41456B;
            line-height: 20px;
          }
        }
        .li-right {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .ri-tit {
            height: 32px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #41456B;
            line-height: 32px;
            width: 150px;
            text-align: right;
          }
        }
      }
    }
  }
}
.batchImport {

  .down-template {
    background: #F6F7F9;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0 20px 0;

    .down-le {
      width: 6px;
      background: #607FFF;
      border-radius: 4px 0px 0px 4px;
      margin-right: 20px;
      padding: 20px 0;
    }

    .down-ri {
      padding: 20px 0;

      .down-tit {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 20px;
        margin-bottom: 2px;
      }

      .down-des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9FA1B7;
        line-height: 20px;
      }

      .down-btn {
        margin-top: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 21px;
          height: 15px;
          color: #607FFF;;
        }

        .download {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #607FFF;
          line-height: 20px;
          margin-left: 8px;
        }

        .icons {
          color: #607FFF;
          display: inline-flex;
          align-items: center;

          ::v-deep .el-icon-upload {
            font-size: 22px;
            color: #607FFF;
          }
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0091FF;
          line-height: 20px;
          margin-left: 8px;
        }
      }
    }
  }

  .check-success {
    margin-top: 30px;

    .suc-top {
      background: rgba(0, 171, 41, 0.02);
      border-radius: 4px;
      border: 1px solid #00AB29;
      padding: 20px 0 20px 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A9ABBF;
        line-height: 22px;
      }
    }

    .imgs {
      width: 264px;
      height: 166px;
      margin: 43px auto 7px auto;
      display: block;
    }

    .check-tip {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A9ABBF;
      line-height: 20px;
      text-align: center;
    }
  }

  .check-error {
    margin-top: 30px;

    .err-top {
      background: rgba(224, 32, 32, 0.02);
      border-radius: 4px;
      border: 1px solid #E02020;
      padding: 20px 0 20px 25px;
      margin-bottom: 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A9ABBF;
        line-height: 22px;
      }
    }

    .font-color {
      color: #f00;
    }

    .font-wight {
      font-weight: 700;
    }

  }

  .suc-tit {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393D60;
    line-height: 20px;
    text-align: center;
    margin-top: -50px;
  }

  .check-tips {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A9ABBF;
    line-height: 20px;
    text-align: center;
  }

  .img-suc {
    width: 279px;
    height: 279px;
    margin: 125px auto 7px auto;
    display: block;
  }
}

.el-dialog__footer {
  padding: 0 !important;
  height: 57px;
}

.mubai-dialog-footer {
  position: absolute;
  height: 57px;
  width: 100%;
  border-top: 1px solid #DDE3EB;
  padding: 10px 20px;
  bottom: 0;
  background: #FFFFFF;

  button {
    padding: 10px 40px !important;

    &:first-child {
      background: #F8F8FB;
      color: #8A8CA5;
      transition: all .5s;

      &:hover {
        border-color: #dadaf6;
        background: #f0f0f8;
      }
    }

    &:last-child {
      background: $primary-color;
      color: #ffffff;
      transition: all .5s;

      &:hover {
        background: #7691fc;
      }
    }
  }
}

.center-content {
  text-align: center;
}
::v-deep {
  .has-error {
   background: rgba(224, 32, 32, 0.04);
  }
  .el-input-number{
    height:32px;
    line-height:32px;
    width: 100%;
  }

  .el-input__inner{
    height:32px;
    line-height:32px;
  }
  .el-input-number.is-controls-right .el-input-number__increase, .el-input-number.is-controls-right .el-input-number__decrease{
    line-height:15px;
  }
  .el-input__icon {
    line-height:32px;
  }
}
</style>
