export const searchFormList = [
  {
    colSpan: 4,
    key: 'orderId',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['orderId']
      }
    ]
  },
  {
    colSpan: 4,
    key: 'workshopIds',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['workshopIds']
      }
    ]
  },
  {
    colSpan: 4,
    key: 'lineIds',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['lineIds']
      }
    ]
  },
  {
    colSpan: 4,
    key: 'organizationIds',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['organizationIds']
      }
    ]
  },
  {
    colSpan: 4,
    key: 'userGroupIds',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['userGroupIds']
      }
    ]
  }
]
// 列表
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'jobNumber',
    label: '工号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'userName',
    label: '姓名',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'totalSalary',
    label: '总工资/元',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'qualifiedCount',
    label: '合格品报工数',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'qualifiedSalary',
    label: '合格品工资/元',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'unqualifiedCount',
    label: '不良品报工数',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'unqualifiedSalary',
    label: '不良品工资/元',
    hiddenSearch: true,
    sortable: false
  }
]
