var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "header-box" }, [
      _c("div", { staticClass: "left-box" }, [
        _c("div", { staticClass: "top-box" }, [
          _c("div", { staticClass: "sum-box" }, [
            _vm._v(" 本月累计工资/元 "),
            _c("div", { staticClass: "count-1" }, [
              _vm._v("¥" + _vm._s(_vm.detail.currentMonthTotal))
            ]),
            _vm._v(" 今日新增 "),
            _c("div", { staticClass: "count-2" }, [
              _vm._v("+¥" + _vm._s(_vm.detail.todayTotal))
            ])
          ]),
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/plans/salary1.png") }
          })
        ]),
        _c("div", { staticClass: "bottom-box" }, [
          _c("div", { staticClass: "type-box" }, [
            _c("img", {
              staticClass: "icon",
              attrs: { src: require("@/assets/plans/salary3.png") }
            }),
            _c("div", [
              _vm._v(" 合格品 "),
              _c(
                "p",
                {
                  staticStyle: {
                    color: "#00B42E",
                    "font-size": "28px",
                    "font-weight": "600"
                  }
                },
                [_vm._v(" ¥" + _vm._s(_vm.detail.currentMonthQualifiedTotal))]
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "type-box",
              staticStyle: { background: "rgba(224,32,32,0.1)" }
            },
            [
              _c("img", {
                staticClass: "icon",
                attrs: { src: require("@/assets/plans/salary2.png") }
              }),
              _c("div", [
                _vm._v(" 不良品 "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      color: "#E02020",
                      "font-size": "28px",
                      "font-weight": "600"
                    }
                  },
                  [
                    _vm._v(
                      " ¥" + _vm._s(_vm.detail.currentMonthUnqualifiedTotal)
                    )
                  ]
                )
              ])
            ]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: " right-box" },
        [
          _c("div", { staticClass: "chart-title" }, [_vm._v("月度工资总额")]),
          _c("ve-histogram", {
            attrs: {
              height: "240px",
              data: _vm.chartData,
              extend: _vm.barChartExtend
            }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "table-box" },
      [
        _c(
          "p",
          {
            staticStyle: {
              "font-size": "18px",
              "font-weight": "600",
              color: "#41456B",
              "margin-top": "0"
            }
          },
          [_vm._v("今日生产任务")]
        ),
        _c(
          "SearchForm",
          {
            ref: "searchForm",
            staticStyle: { padding: "0" },
            attrs: {
              "form-list-extend": _vm.searchFormList,
              "form-data": _vm.searchFormData
            },
            on: {
              "update:formData": function(data) {
                return (_vm.searchFormData = data)
              },
              search: _vm.reSearch
            }
          },
          _vm._l(_vm.searchFormList, function(item) {
            return _c("SelectRemoteSearch", {
              key: item.key,
              ref: item.key,
              refInFor: true,
              staticClass: "select",
              attrs: {
                slot: item.key,
                clearable: "",
                placeholder: _vm.apiMaps[item.key].placeholder,
                multiple: !_vm.apiMaps[item.key].notMultiple,
                "option-val": _vm.apiMaps[item.key].optionVal,
                "option-label": _vm.apiMaps[item.key].optionLabel,
                api: _vm.apiMaps[item.key].api,
                "collapse-tags": ""
              },
              on: {
                change: function(val) {
                  _vm.handleSelect(item.key, val)
                }
              },
              slot: item.key
            })
          }),
          1
        ),
        _c(
          "FunctionHeader",
          {
            ref: "functionHeader",
            staticClass: "func-header",
            staticStyle: { padding: "10px 0 10px 0" },
            attrs: {
              tags: _vm.functionHeader.tags,
              "search-title": "请输入工号/姓名"
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          },
          [
            _c(
              "div",
              { staticClass: "btn-box", attrs: { slot: "all" }, slot: "all" },
              [
                _c(
                  "div",
                  _vm._l(_vm.btns, function(btn) {
                    return _c(
                      "el-button",
                      {
                        key: btn.key,
                        style: { borderRadius: "14px" },
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.onClickTime(btn.key)
                          }
                        }
                      },
                      [_vm._v(_vm._s(btn.innerText))]
                    )
                  }),
                  1
                ),
                _c("el-date-picker", {
                  staticStyle: { width: "400px" },
                  attrs: {
                    type: "daterange",
                    "range-separator": "至",
                    "start-placeholder": "计划开工时间",
                    "end-placeholder": "完工时间",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    format: "yyyy-MM-dd HH:mm:ss"
                  },
                  model: {
                    value: _vm.functionHeader.time,
                    callback: function($$v) {
                      _vm.$set(_vm.functionHeader, "time", $$v)
                    },
                    expression: "functionHeader.time"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.columns,
            height: _vm.height,
            "set-data-method": _vm.getTableData
          },
          on: {
            "selection-change": function(data) {
              return (_vm.selectData = data)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function(ref) {
                var $index = ref.$index
                return _c("div", {}, [_vm._v(_vm._s($index + 1))])
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }