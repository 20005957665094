var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "memberRouteSetting_container",
      style: { height: _vm.chromeHeight + "px" }
    },
    [
      _c("div", { staticClass: "container_left" }, [
        _c("div", { staticClass: "side-bar-container" }, [
          _c("div", { staticClass: "container-left" }, [
            _vm._m(0),
            _c("div", { staticClass: "left-center" }, [
              _c(
                "ul",
                [
                  _vm._l(_vm.menuList, function(item) {
                    return [
                      _c(
                        "li",
                        {
                          key: item.name,
                          class:
                            _vm.$route.name === item.name
                              ? "active"
                              : "inactive",
                          on: {
                            click: function($event) {
                              return _vm.openSecond(item)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "img-wrapper" }, [
                            _c("img", { attrs: { src: item.meta.icon } })
                          ]),
                          _c("span", [_vm._v(_vm._s(item.meta.title))])
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "container_right" },
        [_c("RouterView", { staticStyle: { width: "100%", height: "100%" } })],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-top" }, [
      _c("img", {
        attrs: {
          src:
            "https://oss.mubyte.cn/mes2-lite/mom/2024/04/19/17134894917296296.png"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }