var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "role-wrap" }, [
    _c(
      "div",
      { staticClass: "orders" },
      [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c(
              "SearchForm",
              {
                ref: "searchForm",
                staticStyle: { padding: "0" },
                attrs: {
                  "form-list-extend": _vm.workingHoursFormList,
                  "form-data": _vm.searchFormData
                },
                on: {
                  "update:formData": function(data) {
                    return (_vm.searchFormData = data)
                  },
                  search: _vm.reSearch
                }
              },
              [
                _c("SelectRemoteSearch", {
                  ref: "procedureIds",
                  staticClass: "select",
                  attrs: {
                    slot: "procedureIds",
                    placeholder: "工序名称/编码",
                    multiple: "",
                    "option-val": "id",
                    "option-label": "fullName",
                    api: _vm.api.procedureList,
                    "collapse-tags": ""
                  },
                  on: {
                    change: function(val) {
                      _vm.handleSelect("procedureIds", val)
                    }
                  },
                  slot: "procedureIds"
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "FunctionHeader",
          {
            ref: "functionHeader",
            staticClass: "func-header",
            staticStyle: { padding: "10px 0 10px 0" },
            attrs: {
              "search-title": "请输入生产工单号/设备名称",
              tags: !_vm.selectData.length ? _vm.functionHeader.tags : []
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectData.length,
                    expression: "selectData.length"
                  }
                ],
                attrs: { slot: "all" },
                slot: "all"
              },
              [
                _vm.permission("Export")
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.batchExport(false)
                          }
                        }
                      },
                      [_vm._v("批量导出")]
                    )
                  : _vm._e(),
                _c(
                  "span",
                  {
                    staticStyle: {
                      margin: "0 10px",
                      display: "inline-block",
                      "font-size": "12px",
                      color: "#393D60"
                    }
                  },
                  [
                    _vm._v(" 已选 "),
                    _c("span", { staticStyle: { color: "#607FFF" } }, [
                      _vm._v(_vm._s(_vm.getSelectionDataLength))
                    ]),
                    _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: { click: _vm.resetSelectData }
                  },
                  [_vm._v("清空")]
                )
              ],
              1
            )
          ]
        ),
        _c("MTable", {
          ref: "mTable",
          staticClass: "table-left",
          attrs: {
            columns: _vm.workingHoursColumn,
            height: _vm.height,
            "set-data-method": _vm.getTableData,
            "columns-key": _vm.columnsKey
          },
          on: {
            "selection-change": function(data) {
              return (_vm.selectData = data)
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "businessUnit",
                fn: function(ref) {
                  var row = ref.row
                  return row.isEdit
                    ? _c(
                        "el-select",
                        {
                          attrs: { filterable: "" },
                          model: {
                            value: row.businessUnit,
                            callback: function($$v) {
                              _vm.$set(row, "businessUnit", $$v)
                            },
                            expression: "row.businessUnit"
                          }
                        },
                        _vm._l(_vm.hoursVestInOptions, function(item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.name, value: item.key }
                          })
                        }),
                        1
                      )
                    : _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.hoursVestInOptionsMaps[row.businessUnit] || "-"
                          )
                        )
                      ])
                }
              },
              {
                key: "type",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      " " + _vm._s(_vm.workHoursTypeMaps[row.type] || "-") + " "
                    )
                  ])
                }
              },
              {
                key: "sourceType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.sourceTypeMaps[row.sourceType] || "-") +
                        " "
                    )
                  ])
                }
              },
              {
                key: "userType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      " " + _vm._s(["正式", "外包"][row.userType] || "-") + " "
                    )
                  ])
                }
              },
              {
                key: "userName",
                fn: function(ref) {
                  var row = ref.row
                  return row.isEdit
                    ? _c(
                        "el-select",
                        {
                          attrs: { filterable: "" },
                          model: {
                            value: row.userId,
                            callback: function($$v) {
                              _vm.$set(row, "userId", $$v)
                            },
                            expression: "row.userId"
                          }
                        },
                        _vm._l(
                          _vm.workingHoursFormList[0].children[0].options,
                          function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }
                        ),
                        1
                      )
                    : _c("div", [_vm._v(_vm._s(row.userName))])
                }
              },
              {
                key: "planStartTime",
                fn: function(ref) {
                  var row = ref.row
                  return row.isEdit
                    ? _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "default-time": "00:00:00",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: row.planStartTime,
                          callback: function($$v) {
                            _vm.$set(row, "planStartTime", $$v)
                          },
                          expression: "row.planStartTime"
                        }
                      })
                    : _c("div", [_vm._v(" " + _vm._s(row.planStartTime))])
                }
              },
              {
                key: "planEndTime",
                fn: function(ref) {
                  var row = ref.row
                  return row.isEdit
                    ? _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "default-time": "00:00:00",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: row.planEndTime,
                          callback: function($$v) {
                            _vm.$set(row, "planEndTime", $$v)
                          },
                          expression: "row.planEndTime"
                        }
                      })
                    : _c("div", [_vm._v(" " + _vm._s(row.planEndTime))])
                }
              },
              {
                key: "remark",
                fn: function(ref) {
                  var row = ref.row
                  return row.isEdit
                    ? _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: row.remark,
                          callback: function($$v) {
                            _vm.$set(row, "remark", $$v)
                          },
                          expression: "row.remark"
                        }
                      })
                    : _c("div", [_vm._v(" " + _vm._s(row.remark))])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.permission("Edit") && row.sourceType !== 3
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle(
                                      row.isEdit ? "save" : "edit",
                                      row
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.isEdit ? "保存" : "编辑"))]
                            )
                          ]
                        : _vm._e(),
                      row.isEdit
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  row.isEdit = false
                                }
                              }
                            },
                            [_vm._v("取消")]
                          )
                        : _vm._e(),
                      _vm.permission("Del") && row.sourceType !== 3
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle("del", row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }
              }
            ],
            null,
            true
          )
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }