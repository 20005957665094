var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedule-right" },
    [
      _c("div", { staticClass: "lines" }),
      _c("div", { staticClass: "right-top" }, [
        _c("div", { staticClass: "line-left" }, [
          _vm._v(_vm._s(_vm.areaName)),
          _vm.count !== 0
            ? _c("span", { staticClass: "li-span" }, [
                _vm._v(_vm._s(_vm.count))
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "line-right" },
          [
            _c(
              "el-popover",
              {
                ref: "sortPopover",
                staticClass: "lines-pover",
                attrs: { width: 200, offset: 20 },
                on: {
                  hide: function($event) {
                    _vm.showSort = true
                  }
                },
                model: {
                  value: _vm.showSortPopover,
                  callback: function($$v) {
                    _vm.showSortPopover = $$v
                  },
                  expression: "showSortPopover"
                }
              },
              _vm._l(_vm.sortList, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "line-li",
                    style: {
                      color: _vm.currentIdx === item.id ? "#607FFF" : "#41456B"
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectSorts(item)
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(item.name) + " "),
                    _vm.currentIdx === item.id
                      ? _c("img", {
                          staticClass: "imgs",
                          attrs: { src: require("@/assets/plans/duigou.png") }
                        })
                      : _vm._e()
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "popover",
                    rawName: "v-popover:sortPopover",
                    arg: "sortPopover"
                  }
                ],
                staticClass: "showMore",
                style: { background: _vm.showSort ? "" : "#F8F8F8" },
                on: {
                  click: function($event) {
                    return _vm.changeMore(1)
                  }
                }
              },
              [
                _c("span", [_vm._v("排序")]),
                _vm.showSort
                  ? _c("i", { staticClass: "el-icon-caret-bottom icons" })
                  : _c("i", { staticClass: "el-icon-caret-top" })
              ]
            ),
            _c(
              "el-popover",
              {
                ref: "selectPopovers",
                attrs: { width: 290, offset: 20, trigger: "manual" },
                on: {
                  hide: function($event) {
                    _vm.showSelect = true
                  },
                  show: _vm.setProcessId
                },
                model: {
                  value: _vm.showSelectPopover,
                  callback: function($$v) {
                    _vm.showSelectPopover = $$v
                  },
                  expression: "showSelectPopover"
                }
              },
              [
                _c(
                  "SearchForm",
                  {
                    ref: "serachForms",
                    staticClass: "searchs",
                    attrs: {
                      "button-span": 24,
                      "form-list-extend": _vm.searchFormList,
                      "form-data": _vm.searchFormDatas
                    },
                    on: { search: _vm.searchTable }
                  },
                  [
                    _c("SelectMultipleMaterialsNew", {
                      ref: "multipleMaterial",
                      attrs: { slot: "mPrepend" },
                      slot: "mPrepend"
                    }),
                    _c(
                      "el-select",
                      {
                        staticStyle: { position: "relative", left: "-20px" },
                        attrs: {
                          slot: "prepend",
                          filterable: "",
                          placeholder: "请选择工艺路线"
                        },
                        on: { change: _vm.processFlowChange },
                        slot: "prepend",
                        model: {
                          value: _vm.processId,
                          callback: function($$v) {
                            _vm.processId = $$v
                          },
                          expression: "processId"
                        }
                      },
                      _vm._l(_vm.formOptions.processFlowList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    ),
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-more" },
                      on: {
                        click: function($event) {
                          _vm.showProcessFlow = true
                        }
                      },
                      slot: "append"
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "produceOrderId",
                        staticStyle: { width: "100%" },
                        attrs: { slot: "produceWorkOrderId" },
                        slot: "produceWorkOrderId"
                      },
                      [
                        _c("m-remote-select", {
                          staticClass: "input-prepend",
                          attrs: {
                            placeholder: "生产工单",
                            "interface-fun": _vm.api.getPlanpage,
                            options: _vm.orderLists,
                            "collapse-tags": "",
                            filterable: "",
                            multiple: "",
                            clearable: "",
                            "label-name": "produceWorkOrderNumber",
                            "search-name": "produceWorkOrderNumber"
                          },
                          on: {
                            "update:options": function($event) {
                              _vm.orderLists = $event
                            },
                            "on-change": function($event) {
                              return _vm.searchData("produceWorkOrderIds")
                            }
                          },
                          model: {
                            value: _vm.searchFormDatas.produceWorkOrderIds,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchFormDatas,
                                "produceWorkOrderIds",
                                $$v
                              )
                            },
                            expression: "searchFormDatas.produceWorkOrderIds"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { slot: "produceOrderId" },
                        slot: "produceOrderId"
                      },
                      [
                        _c("m-remote-select", {
                          staticClass: "input-prepend",
                          attrs: {
                            placeholder: "生产订单号",
                            "interface-fun": _vm.api.getOrderpages,
                            options: _vm.orderLists,
                            filterable: "",
                            clearable: "",
                            "label-name": "produceOrderNumber",
                            "search-name": "produceOrderNumber"
                          },
                          on: {
                            "update:options": function($event) {
                              _vm.orderLists = $event
                            },
                            "on-change": function($event) {
                              return _vm.searchData("produceOrderId")
                            }
                          },
                          model: {
                            value: _vm.searchFormDatas.produceOrderId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchFormDatas,
                                "produceOrderId",
                                $$v
                              )
                            },
                            expression: "searchFormDatas.produceOrderId"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { slot: "producePlanNumber" },
                        slot: "producePlanNumber"
                      },
                      [
                        _c("m-remote-select", {
                          staticClass: "input-prepend",
                          attrs: {
                            placeholder: "生产计划号",
                            "interface-fun": _vm.apis.getPlanpage,
                            options: _vm.orderLists,
                            filterable: "",
                            clearable: "",
                            "label-name": "producePlanNumber",
                            "search-name": "producePlanNumber"
                          },
                          on: {
                            "update:options": function($event) {
                              _vm.orderLists = $event
                            },
                            "on-change": function($event) {
                              return _vm.searchData("producePlanNumber")
                            }
                          },
                          model: {
                            value: _vm.searchFormDatas.producePlanNumber,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchFormDatas,
                                "producePlanNumber",
                                $$v
                              )
                            },
                            expression: "searchFormDatas.producePlanNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { slot: "batchNumber" },
                        slot: "batchNumber"
                      },
                      [
                        _c("m-remote-select", {
                          staticClass: "input-prepend",
                          attrs: {
                            placeholder: "生产批次码",
                            "interface-fun": _vm.apis.getPlanpage,
                            options: _vm.orderLists,
                            filterable: "",
                            clearable: "",
                            "label-name": "batchNumber",
                            "search-name": "batchNumber"
                          },
                          on: {
                            "update:options": function($event) {
                              _vm.orderLists = $event
                            },
                            "on-change": function($event) {
                              return _vm.searchData("batchNumber")
                            }
                          },
                          model: {
                            value: _vm.searchFormDatas.producePlanNumber,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchFormDatas,
                                "producePlanNumber",
                                $$v
                              )
                            },
                            expression: "searchFormDatas.producePlanNumber"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "popover",
                    rawName: "v-popover:selectPopovers",
                    arg: "selectPopovers"
                  }
                ],
                staticClass: "showMore",
                style: { background: _vm.showSelect ? "" : "#F8F8F8" },
                on: {
                  click: function($event) {
                    return _vm.changeMore(2)
                  }
                }
              },
              [
                _c("span", [_vm._v("筛选")]),
                _vm.showSelect
                  ? _c("i", { staticClass: "el-icon-caret-bottom icons" })
                  : _c("i", { staticClass: "el-icon-caret-top" })
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "right-bot" },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "m-radio-group right-radio",
              on: { change: _vm.radioChange },
              model: {
                value: _vm.params,
                callback: function($$v) {
                  _vm.params = $$v
                },
                expression: "params"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "NoSchedule" } }, [
                _vm._v("未排程"),
                _c("span", { staticClass: "li-span" }, [
                  _vm._v(_vm._s(_vm.noNum))
                ])
              ]),
              _c("el-radio-button", { attrs: { label: "haveSchedule" } }, [
                _vm._v("已排程"),
                _c("span", { staticClass: "li-span" }, [
                  _vm._v(_vm._s(_vm.haveNum))
                ])
              ])
            ],
            1
          ),
          _c("div", { staticClass: "list-all" }, [
            _vm.scheduleList.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "list",
                    style: { height: _vm.heights + "px" },
                    attrs: { id: "lists" }
                  },
                  [
                    _c(
                      "draggable",
                      _vm._b(
                        {
                          staticClass: "plan-drag-container",
                          attrs: { disabled: _vm.disabled },
                          on: { start: _vm.dragStart, end: _vm.dragEnd },
                          model: {
                            value: _vm.scheduleList,
                            callback: function($$v) {
                              _vm.scheduleList = $$v
                            },
                            expression: "scheduleList"
                          }
                        },
                        "draggable",
                        _vm.dragOptions,
                        false
                      ),
                      [
                        _c(
                          "transition-group",
                          _vm._l(_vm.scheduleList, function(item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "list-li",
                                style: {
                                  cursor:
                                    _vm.params === "NoSchedule"
                                      ? "move"
                                      : "pointer"
                                },
                                on: {
                                  dragstart: function($event) {
                                    return _vm.ondragend(item)
                                  },
                                  click: function($event) {
                                    return _vm.modifys(item)
                                  }
                                }
                              },
                              [
                                _c("div", {
                                  staticClass: "li-left",
                                  style: {
                                    background:
                                      _vm.priorityStatus[item.priority].color
                                  }
                                }),
                                _c("div", { staticClass: "li-right" }, [
                                  _c("div", { staticClass: "li-right-top" }, [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(item.materialsName))
                                    ]),
                                    _c("div", { staticClass: "tips" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tip",
                                          style: {
                                            color:
                                              item.produceStatus === 0
                                                ? "#E02020"
                                                : item.produceStatus === 1
                                                ? "#00AB29"
                                                : "#607fff",
                                            background:
                                              item.produceStatus === 0
                                                ? "rgba(224, 32, 32, 0.1)"
                                                : item.produceStatus === 1
                                                ? "rgba(0, 171, 41, 0.1)"
                                                : "rgba(96, 127, 255, 0.1)"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.productionStatus[
                                                  item.produceStatus
                                                ].name
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      item.isRepair === 1
                                        ? _c("img", {
                                            staticClass: "tip-img",
                                            attrs: {
                                              src: require("@/assets/plans/fanxiu.png")
                                            }
                                          })
                                        : _vm._e()
                                    ])
                                  ]),
                                  _c("div", { staticClass: "des" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.reportWorkQuantity
                                            ? item.reportWorkQuantity
                                            : 0
                                        ) +
                                          "/" +
                                          _vm._s(item.quantity)
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "des-title" }, [
                                    _vm._v(
                                      "当前工序：" +
                                        _vm._s(
                                          item.produceWorkOrder &&
                                            item.produceWorkOrder
                                              .produceWorkOrderTaskList
                                            ? item.produceWorkOrder
                                                .produceWorkOrderTaskList[0]
                                                .procedureName
                                            : ""
                                        )
                                    )
                                  ]),
                                  _c("div", { staticClass: "des-tit" }, [
                                    _vm._v(
                                      "生产订单号：" +
                                        _vm._s(item.produceOrderNumber)
                                    )
                                  ]),
                                  _c("div", { staticClass: "des-tit" }, [
                                    _vm._v(
                                      "物料规格：" +
                                        _vm._s(item.materialsSpecifications)
                                    )
                                  ]),
                                  _c("div", { staticClass: "des-tit" }, [
                                    _vm._v(
                                      "物料编码：" + _vm._s(item.materialsCode)
                                    )
                                  ]),
                                  _c("div", { staticClass: "des-tit" }, [
                                    _vm._v("交期：" + _vm._s(item.deliveryTime))
                                  ]),
                                  _c("div", { staticClass: "dec" }, [
                                    _vm._v("计划优先级： "),
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          color:
                                            _vm.priorityStatus[item.priority]
                                              .color
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.priorityStatus[item.priority]
                                              .name
                                          ) + " "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    ),
                    _c("div", { staticStyle: { height: "40px" } })
                  ],
                  1
                )
              : _c("div", [
                  _c("img", {
                    staticClass: "no-img",
                    attrs: { src: "https://oss.mubyte.cn/static/noData.png" }
                  })
                ])
          ])
        ],
        1
      ),
      _c("select-process-flow-by-table", {
        attrs: { single: true, visible: _vm.showProcessFlow },
        on: {
          "update:visible": function($event) {
            _vm.showProcessFlow = $event
          },
          submitForm: _vm.submitProcessFlow
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }