<template>
  <div class="orderAdd">
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-form
          ref="ruleForm"
          label-position="right"
          :model="ruleForm"
          :rules="rules"
          label-width="140px"
          class="demo-form-inline"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="模具名称：" prop="name">
                <el-input
                  v-if="['edit','add'].includes(handleType)"
                  v-model="ruleForm.name"
                  :disabled="handleType==='edit'"
                  placeholder="请输入模具名称"
                />
                <span v-else>{{ ruleForm.name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="模具分类：" prop="categoryId">
                <CURDSelect
                  v-if="['edit','add'].includes(handleType)"
                  style="width: 100%;"
                  :default-value="ruleForm.categoryId"
                  :default-value-index="0"
                  api-name="MouldType"
                  @change="onSelect"
                />
                <span v-else>{{ ruleForm.categoryName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="规格：" prop="spec">
                <el-input
                  v-if="['edit','add'].includes(handleType)"
                  v-model="ruleForm.spec"
                  :disabled="handleType==='edit'"
                  placeholder="请输入规格描述"
                />
                <span v-else>{{ ruleForm.spec }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="损耗计算方式：" prop="calculateType">
                <el-select
                  v-if="['edit','add'].includes(handleType)"
                  v-model="ruleForm.calculateType"
                  placeholder=""
                >
                  <el-option
                    v-for="item in calculateTypes"
                    :key="item.key"
                    :label="item.label"
                    :value="item.key"
                  />
                </el-select>
                <span v-else>计件</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="lossWarningScale">
                <span slot="label">损耗预警比例：<el-tooltip
                  class="item"
                  effect="dark"
                  content="当损耗进度等于其数值是进行报警"
                  placement="top"
                >
                  <i class="el-icon-question" />
                </el-tooltip>
                </span>

                <el-input-number
                  v-if="['edit','add'].includes(handleType)"
                  v-model="ruleForm.lossWarningScale"
                  controls-position="right"
                  :min="0"
                  @blur="ruleForm.lossWarningScale=sliceVal(ruleForm.lossWarningScale,2)"
                />
                <span v-else>{{ ruleForm.lossWarningScale }}</span>
                %
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="备注：">
                <el-input v-if="['edit','add'].includes(handleType)" v-model="ruleForm.remarks" />
                <span v-else>{{ ruleForm.remarks }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="lines" />

    <div v-if="$route.query.id" class="detail-card">
      <div class="card-tit">寿命信息</div>
      <div class="card-bot">
        <MTable
          ref="mUnitTable"
          :height="400"
          :show-page="false"
          :data="procedureLifespans"
          :columns="columnsLifeInformation"
          :columns-setting="false"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="unit" slot-scope="{ row }">{{ row.unit||'PCS' }}</div>
          <div slot="procedureSerialsCode" slot-scope="{ row }">{{ row.procedureDupId.procedureSerialsCode }}</div>
          <div slot="lifespan" slot-scope="{ row }">
            <el-input-number
              v-if="['edit','add'].includes(handleType)"
              v-model="row.lifespan"
              :min="0"
              controls-position="right"
              :step="1"
              @blur="row.lifespan=sliceVal(row.lifespan,5)"
            />
            <span v-else>{{ row.lifespan }}</span>
          </div>
        </MTable>
      </div>
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="$router.go(-1)">{{ this.handleType!=='view'?'取消':'返回' }}</el-button>
        <el-button v-if="this.handleType!=='view'" type="primary" @click="save">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { sliceVal } from '@/utils'
import CURDSelect from '@/components/CURDSelect/CURDSelect'
import api from '@/api/mould/index'
import { columnsLifeInformation } from './data'
export default {
  name: 'MouldHandle',
  components: { CURDSelect },
  data() {
    return {
      handleType: null,
      columnsLifeInformation,
      procedureLifespans: [],
      calculateTypes: [
        {
          label: '计件',
          key: 1
        }
      ],
      ruleForm: {
        name: '',
        categoryId: '',
        spec: '',
        lossWarningScale: 100,
        remarks: '',
        calculateType: 1

      },
      rules: {
        name: [
          { required: true, message: '请输入模具名称', trigger: 'blur' }
        ],
        lossWarningScale: [
          { required: true, message: '损耗预警比例', trigger: 'blur' }
        ],
        calculateType: [
          { required: true, message: '请选择损耗计算方式', trigger: 'blur' }
        ],
        categoryId: [
          { required: true, message: '请选择模具分类', trigger: 'blur' }
        ]
      }
    }
  },
  async created() {
    this.handleType = this.$route.query.type
    switch (this.handleType) {
      case 'edit':
      case 'view':
        await this.getDetail()
        break

      case 'add':

        break

      default:
        break
    }
  },
  methods: {
    sliceVal,
    onSelect(item) {
      this.ruleForm.categoryId = item.id
      this.$refs.ruleForm.validateField('categoryId')
    },

    // 获取详情
    async getDetail() {
      const res = await api.viewMould({ id: this.$route.query.id })
      if (res) {
        this.ruleForm = { ...res, calculateType: 1 }
        this.procedureLifespans = this.ruleForm.procedureLifespans.map(item => ({ lifespan: item.lifespan, id: item.id, ...item.procedure }))
      }
    },

    async save() {
      const flag = await this.$refs.ruleForm.validate()
      if (!flag) return
      const apis = {
        add: api.createMould,
        edit: api.editMould
      }
      const { categoryId, calculateType, lossWarningScale, name, remarks, spec } = this.ruleForm
      let params = { categoryId, calculateType, lossWarningScale, name, remarks, spec }
      this.handleType === 'edit' && (params = {
        ...params, id: this.$route.query.id, editMoldClazzLifespanReqs: this.procedureLifespans?.map(item => ({ lifespan: item.lifespan, id: item.id }))
      })
      const res = await apis[this.handleType](params)
      if (res) {
        this.$message.success('保存成功')
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-upload-list__item.is-success.focusing .el-icon-close-tip {
  display: none !important;
}
::v-deep {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .el-form-item {
    margin-bottom: 20px;
    .el-form-item__label {
      color: #606266;
    }
  }
}
.orderAdd {
  width: 100%;
  height: 100%;
  .input-new-tag {
    width: 130px;
  }
  .button-new-tag {
    height: 32px;
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }
  ::v-deep .el-form-item__content {
    display: flex;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  .e-btn {
    margin-bottom: 10px;
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
