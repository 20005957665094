// 列表
export const columnsAll = [
    {
      type: 'selection',
      width: 50,
      fixed: 'left',
      reserveSelection: true
    },
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      minWidth: 80
    },
    {
      prop: 'status',
      label: '订单状态',
      hiddenSearch: true,
      sortable: false,
      slotName: 'status',
      minWidth: 120
    },
    // {
    //   prop: 'drawingStatus',
    //   label: '图纸状态',
    //   hiddenSearch: true,
    //   sortable: false,
    //   slotName: 'drawingStatus',
    //   minWidth: 100
    // },
    // {
    //   prop: 'bomStatus',
    //   label: 'BOM状态',
    //   hiddenSearch: true,
    //   sortable: false,
    //   slotName: 'bomStatus',
    //   minWidth: 100
    // },
    {
      prop: 'quantity',
      label: '生产订单数量',
      hiddenSearch: true,
      sortable: 'custom',
      slotName: 'quantity',
      minWidth: 180
    },
    {
      prop: 'orderProcess',
      label: '生产进度',
      hiddenSearch: true,
      sortable: false,
      slotName: 'orderProcess',
      minWidth: 180
    },
    {
      prop: 'planQuantity',
      label: '已计划数量',
      hiddenSearch: true,
      sortable: false,
      minWidth: 120
    },
    {
      prop: 'reportWorkQuantity',
      label: '报工数量',
      minWidth: 120,
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'produceOrderNumber',
      label: '生产订单号',
      hiddenSearch: true,
      sortable: 'custom',
      slotName: 'produceOrderNumber',
      minWidth: 180
    },
    {
      prop: 'source',
      label: '订单来源',
      hiddenSearch: true,
      sortable: false,
      slotName: 'source'
    },
    {
      prop: 'salesOrderCodes',
      label: '销售订单编号',
      hiddenSearch: true,
      sortable: 'custom',
      minWidth: 180
    },
    // {
    //   prop: 'materialsClassName',
    //   label: '所属订单',
    //   hiddenSearch: true,
    //   sortable: false,
    // },
    // {
    //   prop: 'materialsName',
    //   label: '项目名称',
    //   hiddenSearch: true,
    //   sortable: false,
    // },
    {
      prop: 'priority',
      label: '订单优先级',
      hiddenSearch: true,
      sortable: 'custom',
      slotName: 'priority',
      minWidth: 120
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      slotName: 'materialsCode',
      minWidth: 180
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false,
      minWidth: 180
    },
    {
      prop: 'materialsSpecifications',
      label: '规格',
      hiddenSearch: true,
      sortable: false,
      minWidth: 400
    },
    {
      prop: 'materialsUnit',
      label: '单位',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'deliveryTime',
      label: '交期',
      hiddenSearch: true,
      sortable: 'custom',
      minWidth: 120
    },
    {
      prop: 'customerName',
      label: '客户',
      hiddenSearch: true,
      sortable: false,
      minWidth: 180
    },
    {
      prop: 'remark',
      label: '备注',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'creator',
      label: '创建人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: 'custom',
      minWidth: 180
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      hiddenSearch: true,
      sortable: false,
      width: 144
    }
  ]
  // 订单产品列表
  export const columnsOrder = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
      prop: 'code',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      width: 150
    },
    {
      prop: 'name',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'specifications',
      label: '规格',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'mainUnitName',
      label: '单位',
      hiddenSearch: true,
      sortable: false,
      width: 100
    },
    {
      prop: 'quantity',
      label: '生产订单数量',
      hiddenSearch: true,
      sortable: false,
      slotName: 'quantity'
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      hiddenSearch: true,
      sortable: false,
      width: 100
    }
  ]
  // 关联工单
  export const columnsWorkOrder = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
      prop: 'produceWorkOrderNumber',
      label: '生产工单号',
      hiddenSearch: false,
      sortable: false,
      width: 200,
      slotName: 'produceWorkOrderNumber'
    },
    {
      prop: 'isRepair',
      label: '工单属性',
      hiddenSearch: true,
      sortable: false,
      width: 100,
      slotName: 'isRepair'
    },
    {
      prop: 'produceOrderNumber',
      label: '所属生产订单',
      hiddenSearch: false,
      sortable: false,
      slotName: 'produceOrderNumber'
    },
    {
      prop: 'status',
      label: '生产状态',
      hiddenSearch: true,
      sortable: false,
      slotName: 'status',
      width: 120
    },
    {
      prop: 'quantity',
      label: '工单数量',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'reportWorkQuantity',
      label: '已完工数量',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'currentProcedure',
      label: '工单当前所处工序',
      hiddenSearch: true,
      sortable: false,
      slotName: 'currentProcedure'
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      slotName: 'materialsCode'
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialsSpecifications',
      label: '规格',
      hiddenSearch: true,
      sortable: false,
      width: 300
    },
    {
      prop: 'materialsUnit',
      label: '单位',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
      prop: 'processFlowName',
      label: '工艺流程',
      hiddenSearch: true,
      sortable: false,
      slotName: 'processFlowName'
    },
    {
      prop: 'planStartTime',
      label: '计划开始时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'planEndTime',
      label: '计划结束时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'actualStartTime',
      label: '实际开始时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'actualEndTime',
      label: '实际结束时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      hiddenSearch: true,
      sortable: false,
      width: 100
    }
  ]
  export const columnsProduct = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      width: 150,
      slotName: 'materialsCode'
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false
    },
   
    {
      prop: 'specifications',
      label: '物料规格',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'quantity',
      label: '需求数量',
      hiddenSearch: true,
      sortable: false
    }
  ]
  //计划用料
  export const columnsMaterialPlan = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      width: 150,
      slotName: 'materialsCode'
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialsSpecifications',
      label: '物料规格',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'quantity',
      label: '需求数量',
      hiddenSearch: true,
      sortable: false
    }
  ]
  // 关联计划
  export const columnsPlanOrder = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
      prop: 'producePlanNumber',
      label: '生产计划单号',
      hiddenSearch: false,
      sortable: false,
      width: 200
    },
    {
      prop: 'status',
      label: '计划状态',
      hiddenSearch: true,
      sortable: false,
      width: 120,
      slotName: 'status'
    },
    {
      prop: 'produceOrderNumber',
      label: '所属生产订单',
      hiddenSearch: false,
      sortable: false,
      slotName: 'produceOrderNumber'
    },
    {
      prop: 'produceStatus',
      label: '生产状态',
      hiddenSearch: true,
      sortable: false,
      slotName: 'produceStatus',
      width: 120
    },
    {
      prop: 'quantity',
      label: '计划数量',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'reportWorkQuantity',
      label: '报工数量',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      slotName: 'materialsCode'
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialsSpecifications',
      label: '规格',
      hiddenSearch: true,
      sortable: false,
      width: 300
    },
    {
      prop: 'materialsUnit',
      label: '单位',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
      prop: 'processFlowName',
      label: '工艺流程',
      hiddenSearch: true,
      sortable: false,
      slotName: 'processFlowName'
    },
    {
      prop: 'planStartTime',
      label: '计划开始时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'planEndTime',
      label: '计划结束时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'actualStartTime',
      label: '实际开始时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'actualEndTime',
      label: '实际结束时间',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'deliveryTime',
      label: '交期',
      hiddenSearch: true,
      sortable: false
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      hiddenSearch: true,
      sortable: false,
      width: 100
    }
  ]
   // 关联生产订单
   export const columnsProductOrderList = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
      prop: 'produceOrderNumber',
      label: '生产订单号',
      hiddenSearch: true,
      sortable: false,
      slotName:'produceOrderNumber',
      width: 200
    },
    {
      prop: 'status',
      label: '订单状态',
      hiddenSearch: true,
      sortable: false,
      width: 220,
      slotName: 'status'
    },
    {
      prop: 'quantity',
      label: '生产订单数量',
      hiddenSearch: true,
      sortable: false,
      width: 120,
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      width: 220,
      slotName: 'materialsCode'
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false,
      width: 220
    },
    {
      prop: 'materialsSpecifications',
      label: '规格',
      hiddenSearch: true,
      sortable: false,
      width: 220
    },
    {
      prop: 'materialsUnit',
      label: '单位',
      hiddenSearch: true,
      sortable: false,
      width: 120,
    },
    {
      prop: 'deliveryTime',
      label: '交期',
      hiddenSearch: true,
      sortable: false,
    },
  ]