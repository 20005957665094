
import uApi from '@/api/sets/user/user'
import api from '@/api/mould/index'

// 预警状态
export const warnOptions = [
  { label: '正常', value: 'Normal' },
  { label: '预警', value: 'Warning' },
  { label: '损坏', value: 'Died' }
]
export const warnMap = warnOptions.reduce((pre, cur) => (pre[cur.value] = cur.label) && pre, {})

// 报废状态
export const scrapOptions = [
  { label: '正常', value: false },
  { label: '报废', value: true }
]
export const scrapMap = scrapOptions.reduce((pre, cur) => (pre[cur.value] = cur.label) && pre, {})

export const apiMaps = {
  categoryIds: {
    api: api.getMouldTypeList,
    placeholder: '请选择模具分类',
    optionVal: 'id',
    optionLabel: 'name',
    sizeName: 'size'
  },
  createBy: {
    placeholder: '请选择创建人',
    api: uApi.getUserListPage,
    optionVal: 'id',
    optionLabel: 'name'
  },
  updateBy: {
    placeholder: '请选择更新人',
    api: uApi.getUserListPage,
    optionVal: 'id',
    optionLabel: 'name'
  }
}

export const searchFormSelList = [
  {
    colSpan: 6,
    key: 'categoryIds',
    searchName: 'name',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['categoryIds']
      }
    ]
  },
  {
    colSpan: 6,
    key: 'createBy',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['createBy']
      }
    ]
  },
  {
    colSpan: 6,
    key: 'updateBy',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['updateBy']
      }
    ]
  }
]

export const searchFormList = [
  ...searchFormSelList,
  {
    colSpan: 6,
    key: 'spec',
    tagName: 'el-input',
    props: {
      placeholder: '请输入规格'
    }
  },
  {
    colSpan: 6,
    key: 'createTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建开始时间',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 6,
    key: 'updateTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '更新开始时间',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  }
]
// 列表
export const columnsAll = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'name',
    label: '模具名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'spec',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'categoryName',
    label: '模具分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    prop: 'remarks',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    prop: 'createByName',
    label: '创建人',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    prop: 'createAt',
    label: '创建时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 100
  },
  {
    prop: 'updateByName',
    label: '更新人',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
    prop: 'updateAt',
    label: '更新时间',
    hiddenSearch: true,
    sortable: 'custom'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 160
  }
]
export const columnsLifeInformation = [
  {
    prop: 'processFlowName',
    label: '工艺路线名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'processFlowCode',
    label: '工艺路线编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialName',
    label: '成品物料',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'processFlowVersion',
    label: '版本号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureSerialsCode',
    slotName: 'procedureSerialsCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'lifespan',
    label: '最大使用寿命',
    slotName: 'lifespan',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'unit',
    label: '单位',
    slotName: 'unit',
    sortable: false,
    hiddenSearch: true
  }
]
export const stockApiMaps = {
  categoryIds: {
    api: api.getMouldTypeList,
    placeholder: '请选择模具分类',
    optionVal: 'id',
    optionLabel: 'name',
    sizeName: 'size'
  },
  createBy: {
    placeholder: '请选择执行人',
    api: uApi.getUserListPage,
    optionVal: 'id',
    optionLabel: 'name'
  }
}

export const searchFormStockSelList = [
  {
    colSpan: 6,
    key: 'categoryIds',
    searchName: 'name',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['categoryIds']
      }
    ]
  },
  {
    colSpan: 6,
    key: 'createBy',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['createBy']
      }
    ]
  }
]

export const stockSearchFormList = [
  {
    colSpan: 6,
    key: 'moldClazzName',
    tagName: 'el-input',
    defaultValue: '',
    attrs: {
      placeholder: '请输入模具名称'
    }
  },
  ...searchFormStockSelList,
  {
    colSpan: 6,
    key: 'moldClazzSpec',
    tagName: 'el-input',
    defaultValue: '',
    attrs: {
      placeholder: '请输入规格'
    }
  },
  {
    colSpan: 6,
    key: 'inStockTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '入库开始时间',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 6,
    key: 'lossScale',
    tagName: 'div',
    defaultValue: '',
    domProps: { className: 'customer-content' },
    children: [
      {
        slots: ['lossScale']
      }
    ]
  },
  {
    colSpan: 6,
    key: 'lossScaleStatus',
    tagName: 'el-select',
    props: {
      placeholder: '预警状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'label',
          value: 'value'
        },
        options: warnOptions
      }
    ]
  },
  {
    colSpan: 6,
    key: 'isScraped',
    tagName: 'el-select',
    props: {
      placeholder: '报废状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'label',
          value: 'value'
        },
        options: scrapOptions
      }
    ]
  }
]
// 列表
export const stockColumnsAll = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'id',
    label: '唯一编码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'moldClazzName',
    label: '模具名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'moldCategoryName',
    label: '模具分类',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'moldClazzSpec',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'lossScale',
    label: '损耗进度',
    slotName: 'lossScale',
    hiddenSearch: true,
    sortable: false,
    minWidth: 150
  },
  {
    prop: 'lossScaleStatus',
    label: '预警状态',
    slotName: 'lossScaleStatus',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'isScrap',
    label: '报废状态',
    slotName: 'isScrap',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createAt',
    label: '入库时间',
    hiddenSearch: true,
    sortable: 'custom'
  },
  {
    prop: 'createByName',
    label: '执行人',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 160
  }
]

// 入库表格列表
export const columnsInStock = [
  {
    prop: 'code',
    label: '唯一编码',
    slotName: 'code',
    headerSlotName: 'codeHeader',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '模具名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'categoryName',
    label: '模具分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'spec',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remarks',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'lossScale',
    label: '损耗进度（%）',
    slotName: 'lossScale',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isScraped',
    label: '报废状态',
    slotName: 'isScraped',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    sortable: false,
    hiddenSearch: true
  }
]
export const columnsRecord = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '工单编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'procedureName',
    slotName: 'procedureName',
    label: '工序',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'reportWorkQuantity',
    label: '报工数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'stationName',
    label: '报工工位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'equipmentName',
    label: '设备名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'moldInstances',
    slotName: 'moldInstances',
    label: '所用模具',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operator',
    label: '操作人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'frequencyName',
    label: '班次',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operateTime',
    label: '操作时间',
    hiddenSearch: true,
    sortable: false
  }
]

