<template>
  <div class="schedule-right">
    <div class="lines" />
    <div class="right-top">
      <div class="line-left">{{ areaName }}<span v-if="count !== 0" class="li-span">{{ count }}</span></div>
      <div class="line-right">
        <el-popover
          ref="sortPopover"
          v-model="showSortPopover"
          :width="200"
          :offset="20"
          class="lines-pover"
          @hide="showSort = true"
        >
          <div
            v-for="item in sortList"
            :key="item.id"
            class="line-li"
            :style="{color: currentIdx === item.id ? '#607FFF' : '#41456B'}"
            @click="selectSorts(item)"
          >
            {{ item.name }}
            <img v-if="currentIdx === item.id" src="@/assets/plans/duigou.png" class="imgs">
          </div>
        </el-popover>
        <div v-popover:sortPopover class="showMore" :style="{background: showSort ? '' : '#F8F8F8'}" @click="changeMore(1)">
          <span>排序</span>
          <i v-if="showSort" class="el-icon-caret-bottom icons" />
          <i v-else class="el-icon-caret-top" />
        </div>
        <el-popover
          ref="selectPopovers"
          v-model="showSelectPopover"
          :width="290"
          :offset="20"
          trigger="manual"
          @hide="showSelect = true"
          @show="setProcessId"
        >
          <SearchForm ref="serachForms" :button-span="24" :form-list-extend="searchFormList" :form-data="searchFormDatas" class="searchs" @search="searchTable">
            <SelectMultipleMaterialsNew ref="multipleMaterial" slot="mPrepend" />
            <el-select slot="prepend" v-model="processId" filterable placeholder="请选择工艺路线" style="position: relative;left: -20px" @change="processFlowChange">
              <el-option
                v-for="item in formOptions.processFlowList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <el-button slot="append" icon="el-icon-more" @click="showProcessFlow = true" />
            <div slot="produceWorkOrderId" style="width: 100%" class="produceOrderId">
              <m-remote-select
                v-model="searchFormDatas.produceWorkOrderIds"
                class="input-prepend"
                :placeholder="'生产工单'"
                :interface-fun="api.getPlanpage"
                :options.sync="orderLists"
                collapse-tags
                filterable
                multiple
                clearable
                :label-name="'produceWorkOrderNumber'"
                :search-name="'produceWorkOrderNumber'"
                @on-change="searchData('produceWorkOrderIds')"
              />
            </div>
            <div slot="produceOrderId" style="width: 100%">
              <m-remote-select
                v-model="searchFormDatas.produceOrderId"
                class="input-prepend"
                :placeholder="'生产订单号'"
                :interface-fun="api.getOrderpages"
                :options.sync="orderLists"
                filterable
                clearable
                :label-name="'produceOrderNumber'"
                :search-name="'produceOrderNumber'"
                @on-change="searchData('produceOrderId')"
              />
            </div>
            <div slot="producePlanNumber" style="width: 100%">
              <m-remote-select
                v-model="searchFormDatas.producePlanNumber"
                class="input-prepend"
                :placeholder="'生产计划号'"
                :interface-fun="apis.getPlanpage"
                :options.sync="orderLists"
                filterable
                clearable
                :label-name="'producePlanNumber'"
                :search-name="'producePlanNumber'"
                @on-change="searchData('producePlanNumber')"
              />
            </div>
            <div slot="batchNumber" style="width: 100%">
              <m-remote-select
                v-model="searchFormDatas.producePlanNumber"
                class="input-prepend"
                :placeholder="'生产批次码'"
                :interface-fun="apis.getPlanpage"
                :options.sync="orderLists"
                filterable
                clearable
                :label-name="'batchNumber'"
                :search-name="'batchNumber'"
                @on-change="searchData('batchNumber')"
              />
            </div>
          </SearchForm>
        </el-popover>
        <div v-popover:selectPopovers class="showMore" :style="{background: showSelect ? '' : '#F8F8F8'}" @click="changeMore(2)">
          <span>筛选</span>
          <i v-if="showSelect" class="el-icon-caret-bottom icons" />
          <i v-else class="el-icon-caret-top" />
        </div>
      </div>
    </div>
    <div class="right-bot">
      <el-radio-group v-model="params" class="m-radio-group right-radio" @change="radioChange">
        <el-radio-button label="NoSchedule">未排程<span class="li-span">{{ noNum }}</span></el-radio-button>
        <el-radio-button label="haveSchedule">已排程<span class="li-span">{{ haveNum }}</span></el-radio-button>
      </el-radio-group>
      <div class="list-all">
        <div v-if="scheduleList.length > 0" id="lists" class="list" :style="{ height: heights + 'px'}">
          <draggable
            v-model="scheduleList"
            v-bind="dragOptions"
            class="plan-drag-container"
            :disabled="disabled"
            @start="dragStart"
            @end="dragEnd"
          >
            <transition-group>
              <div v-for="item in scheduleList" :key="item.id" class="list-li" :style="{ cursor: params === 'NoSchedule' ? 'move' : 'pointer'}" @dragstart="ondragend(item)" @click="modifys(item)">
                <div class="li-left" :style="{ background: priorityStatus[item.priority].color }" />
                <div class="li-right">
                  <div class="li-right-top">
                    <div class="title">{{ item.materialsName }}</div>
                    <div class="tips">
                      <div
                        class="tip"
                        :style="{
                          color: item.produceStatus === 0 ? '#E02020' : item.produceStatus === 1 ? '#00AB29' : '#607fff',
                          background: item.produceStatus === 0 ? 'rgba(224, 32, 32, 0.1)' : item.produceStatus === 1 ? 'rgba(0, 171, 41, 0.1)' : 'rgba(96, 127, 255, 0.1)'}"
                      >
                        {{ productionStatus[item.produceStatus].name }}
                      </div>
                      <img v-if="item.isRepair === 1" src="@/assets/plans/fanxiu.png" class="tip-img">
                    </div>
                  </div>
                  <div class="des">
                    <span>{{ item.reportWorkQuantity ? item.reportWorkQuantity : 0 }}/{{ item.quantity }}</span>
                  </div>
                  <div class="des-title">当前工序：{{ item.produceWorkOrder && item.produceWorkOrder.produceWorkOrderTaskList ? item.produceWorkOrder.produceWorkOrderTaskList[0].procedureName : '' }}</div>
                  <div class="des-tit">生产订单号：{{ item.produceOrderNumber }}</div>
                  <div class="des-tit">物料规格：{{ item.materialsSpecifications }}</div>
                  <div class="des-tit">物料编码：{{ item.materialsCode }}</div>
                  <div class="des-tit">交期：{{ item.deliveryTime }}</div>
                  <div class="dec">计划优先级：
                    <span :style="{color: priorityStatus[item.priority].color}">{{ priorityStatus[item.priority].name }} </span>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
          <div style="height:40px" />
        </div>
        <div v-else>
          <img src="https://oss.mubyte.cn/static/noData.png" class="no-img">
        </div>
      </div>
    </div>
    <select-process-flow-by-table :single="true" :visible.sync="showProcessFlow" @submitForm="submitProcessFlow" />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import SearchForm from '@/components/SearchForm/SearchForm'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import { searchFormList } from './form-list'
import apis from '@/api/production/plans'
import api from '@/api/production/work-order'
import { getUUid } from '@/utils/index'
import { Encrypt } from '@/utils/sercet'
import { productionStatus } from '@/config/options.config'
import SelectProcessFlowByTable from '@/components/SelectProcessFlow/SelectProcessFlowByTable'
export default {
  name: 'ScheduleRight',
  components: { SelectProcessFlowByTable, SearchForm, SelectMultipleMaterialsNew, draggable },
  props: {
    areaId: {
      type: Number,
      default: null
    },
    areaName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      api,
      apis,
      orderLists: [],
      processId: '',
      showProcessFlow: false,
      unPlanProducePlanList: [],
      planProducePlanList: [],
      secList: {},
      disabled: false,
      isDragging: false,
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghostClass',
        sort: false
      },
      noNum: 0,
      haveNum: 0,
      showTip: true,
      searchFormList,
      searchFormDatas: {
        produceWorkOrderIds: '',
        produceOrderId: '',
        producePlanNumber: '',
        batchNumber: ''
      },
      productionStatus,
      params: 'NoSchedule',
      scheduleList: [],
      showSelect: true,
      showSort: true,
      showSortPopover: false,
      showSelectPopover: false,
      heights: '',
      sortList: [{
        id: 1,
        name: '按优先级最高',
        value: 'priority',
        sort: '+priority'
      },
      {
        id: 2,
        name: '按交期最早',
        value: 'deliveryTime',
        sort: '+deliveryTime'
      },
      {
        id: 3,
        name: '按交期最晚',
        value: 'deliveryTime',
        sort: '-deliveryTime'
      },
      {
        id: 4,
        name: '按计划开始时间最早',
        value: 'planStartTime',
        sort: '+planStartTime'
      },
      {
        id: 5,
        name: '按计划开始时间最晚',
        value: 'planStartTime',
        sort: '-planStartTime'
      },
      {
        id: 6,
        name: '按计划结束时间最早',
        value: 'planEndTime',
        sort: '+planEndTime'
      },
      {
        id: 7,
        name: '按计划结束时间最晚',
        value: 'planEndTime',
        sort: '-planEndTime'
      }],
      currentIdx: -1,
      currentSort: null,
      keyword: '',
      selectKeyword: '0',
      searchDatas: [],
      count: 0,
      formOptions: {
        processFlowList: []
      },
      produceWorkOrderId: undefined,
      searchFenData: []
    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  watch: {
    areaId(val) {
      if (val) {
        this.params = 'NoSchedule'
        this.currentIdx = -1
        this.$refs.serachForms.reset()
        // this.getNoTotal()
        // this.getHaveTotal()
      }
    },
    scheduleList() {
      this.heights = this.$store.state.app.contentHeight - 320 + 37
    },
    params(val) {
      this.disabled = val !== 'NoSchedule'
    }
  },
  mounted() {
    this.searchFormList[6].children[0].options = this.priorityStatus.map((item, index) => {
      return {
        ...item,
        id: index
      }
    })
    // this.getOrderPages()
    // this.getOrderList()
    // this.getList()
    this.processFlowList()
    if (this.areaId) {
      this.getScheduleList()
    }
  },
  methods: {
    setProcessId() {
      this.processId = this.$refs.serachForms.form.processFlowId
    },
    ondragend(row) {
      this.secList = row
    },
    dragStart() {
      this.isDragging = true
    },
    async dragEnd(e) {
      console.log(e, document.getElementById('lists').offsetLeft)
      const l = document.getElementById('lists').offsetLeft
      const h = document.getElementById('lists').clientWidth
      console.log(l + h)
      if (e.originalEvent.clientX <= l + h) {
        this.$emit('modify', { id: this.secList.id, type: 1, isHave: 1 })
      }
      // this.isDragging = false
    },
    modifys(row) {
      // if (row.produceWorkOrder) {
      //   this.$message.error('该计划已下发，不能修改')
      //   return false
      // }
      this.$emit('modify', { id: row.id, type: 1 })
    },
    // 订单
    // async getOrderPages() {
    //   const res = await api.getOrderpage()
    //   if (res) {
    //     this.searchFormList[2].children[0].options = res
    //   }
    // },
    // 工单列表
    // async getOrderList() {
    //   const res = await api.getPlanpageno()
    //   if (res) {
    //     this.searchFormList[0].children[0].options = res
    //   }
    // },
    // 计划号和批次码列表
    // async getList() {
    //   const res = await apis.getNoPlanPage()
    //   if (res) {
    //     this.searchFormList[1].children[0].options = res
    //     this.searchFormList[3].children[0].options = res ? res.filter(item => item.batchNumber) : []
    //   }
    // },
    // 工艺列表
    async processFlowList() {
      const res = await apis.getFlowers()
      if (res) {
        this.formOptions.processFlowList = res
        this.searchFormList[5].children[0].options = res
      }
    },
    processFlowChange(val) {
      this.$refs.serachForms.setForm({ processFlowId: val })
    },
    searchData(item) {
      this.searchFenData = []
      const { form } = this.$refs.serachForms
      if (item == 'produceWorkOrderIds' && this.searchFormDatas.produceWorkOrderIds) {
        this.produceWorkOrderId = this.searchFormDatas.produceWorkOrderIds.join('^')
      }
      if (item == 'produceOrderId' && this.searchFormDatas.produceOrderId) {
        this.searchFenData.push({
          id: getUUid(),
          fieldName: 'produceOrderId',
          fieldType: 'number',
          fieldValue: this.searchFormDatas.produceOrderId,
          operator: 'eq'
        })
      }
      if (item == 'producePlanNumber' && this.searchFormDatas.producePlanNumber) {
        this.searchFenData.push({
          id: getUUid(),
          fieldName: 'producePlanNumber',
          fieldType: 'string',
          fieldValue: this.searchFormDatas.producePlanNumber,
          operator: 'like'
        })
      }
      if (item == 'batchNumber' && this.searchFormDatas.batchNumber) {
        this.searchFenData.push({
          id: getUUid(),
          fieldName: 'batchNumber',
          fieldType: 'string',
          fieldValue: this.searchFormDatas.batchNumber,
          operator: 'like'
        })
      }
      if (this.areaId) {
        this.getScheduleList()
      }
    },
    // 查询
    async searchTable(data, val) {
      console.log('查询', data)
      if (val === 1) {
        this.searchDatas = []
        this.keyword = ''
        this.$refs.multipleMaterial.keyword = ''
      }
      if (val !== 1) {
        this.searchDatas = data.filter(item => item.fieldName !== 'keyword' &&
        item.fieldName !== 'produceWorkOrderId' &&
        item.fieldName !== 'produceOrderId' &&
        item.fieldName !== 'producePlanNumber' &&
        item.fieldName !== 'batchNumber')
        this.keyword = this.$refs.multipleMaterial.keyword.code
      }
      await this.getScheduleList()
      this.showSelectPopover = false
      this.showSelect = true
    },
    async selectSorts(val) {
      this.currentIdx = val.id
      this.currentSort = val.sort
      await this.getScheduleList()
      this.showSortPopover = false
      this.showSort = true
    },
    radioChange() {
      if (this.params === 'haveSchedule') {
        this.scheduleList = this.planProducePlanList
      } else {
        this.scheduleList = this.unPlanProducePlanList
      }
    },
    changeMore(val) {
      if (val === 1) {
        this.showSort = !this.showSort
      }
      if (val === 2) {
        this.showSelect = !this.showSelect
        this.showSelectPopover = !this.showSelectPopover
      }
    },
    // 获取已排程和未排程
    async getScheduleList() {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'status',
        fieldType: 'number',
        fieldValue: '0^1^2^3',
        operator: 'in'
      })
      search.push({
        id: getUUid(),
        fieldName: 'areaId',
        fieldType: 'number',
        fieldValue: this.areaId,
        operator: 'eq'
      })
      console.log('search=>', search, this.searchDatas, this.searchFenData)
      const res = await apis.getScheduleNums({
        materialsSearchType: this.selectKeyword,
        materialsSearchValue: this.keyword,
        sort: this.currentSort,
        produceWorkOrderIds: this.produceWorkOrderId,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchDatas, ...this.searchFenData]))
      })
      if (res) {
        this.count = res.count
        this.haveNum = res.planProducePlanList ? res.planProducePlanList.length : 0
        this.noNum = res.unPlanProducePlanList ? res.unPlanProducePlanList.length : 0
        this.unPlanProducePlanList = res.unPlanProducePlanList
        this.planProducePlanList = res.planProducePlanList
        this.radioChange()
      }
    },
    submitProcessFlow(val) {
      this.processId = val[0].id
      this.processFlowChange(val[0].id)
      this.showProcessFlow = false
    }
  }
}
</script>

<style scoped lang="scss">
.input-prepend {
  width: 264px;
}
::v-deep {
  .el-popover, .el-popper {
    width: 200px!important;
    padding: 0!important;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-input__icon, .el-form-item__content, .el-range-editor.el-input__inner, .el-input--suffix .el-input__inner, .el-input-group--prepend .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-date-editor .el-range-separator, .el-date-editor .el-range__icon{
    line-height: 25px;
  }
  .el-button--medium {
    padding: 8px 20px;
  }
  .el-col-24 {
    text-align: center;
  }
  .el-col-24:nth-child(5){
    text-align: left !important;
 }
  .el-input--suffix .el-input__inner {
    height: 32px!important;
  }
}
.searchs {
  padding: 0;
}
.line-li {
    height: 42px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456B;
    line-height: 42px;
    cursor: pointer;
    position: relative;
    &:hover {
      color: #607FFF;
      // background: #F9FAFC;
    }
    .imgs {
      width: 14px;
      height: 10px;
      display: block;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -5px;
    }
}
.schedule-right {
  .lines {
    width: 100%;
    height: 20px;
    background: #fff;
  }
  .right-top {
    padding: 0 15px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DDE3EB;
    margin-bottom: 10px;
    .line-left {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      width: 190px;
    }
    .line-right {
      display: flex;
      justify-content: flex-end;
      .lines-pover {
        padding: 10px 0 0;
      }
    }
  }
  .right-radio {
    width: 335px;
    margin-left: 15px;
  }
 .li-span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9597AE;
    margin-left: 6px;
  }
  .right-bot {
    height: 100%;
    .no-img {
      width: 298px;
      height: 189px;
      margin: 20px auto;
      display: block;
    }
    .m-radio-group .el-radio-button {
      width: 100%;
      margin-left: 0;
    }
    .list-all {
      overflow-y: scroll;
      height: 100%;
      margin-top: 10px;
    }
    .list {
      width: 100%;
      // height: 100%;
      background: #F0F2F5;
      padding-bottom: 20px;
      .list-li {
        width: 338px;
        // height: 175px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        margin: 0 auto 10px auto;
        cursor: pointer;
        position: relative;
        &:last-child {
          margin-bottom: 10px;
        }
        .li-left {
          width: 6px;
          height: 100%;
          background: #A4B3C6;
          border-radius: 4px 0px 0px 4px;
          position: absolute;
          left: 0;
          top: 0;
        }
        .li-right {
          width: calc(100% - 6px);
          padding: 10px 5px 0 15px;
          .li-right-top {
            display: flex;
            justify-content: space-between;
            .title {
              width: 205px;
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #393D60;
              line-height: 22px;
            }
            .tips {
              position: relative;
            }
            .tip-img {
              position: absolute;
              top: 32px;
              width: 58px;
              height: 22px;
              display: block;
            }
            .tip {
              padding: 0 10px;
              height: 22px;
              line-height: 22px;
              border-radius: 4px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FA6400;
            }
          }
          .des {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393D60;
            line-height: 20px;
            margin: 5px 0;
            height: 20px;
          }
          .des-title {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393D60;
            line-height: 17px;
          }
          .des-tit {
            // height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9597AE;
            line-height: 17px;
            margin: 5px 0;
          }
          .dec {
            // height: 35px;
            border-top: 1px solid #F0F2F5;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9597AE;
            line-height: 24px;
          }
        }
      }
    }
  }
  .showMore {
    padding: 0 10px;
    height: 30px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    .icons {
      color: #DDE3EB;
    }
    &:hover {
      background:#F8F8F8;
      i {
        color: #607FFF;
      }
    }
  }
}
::v-deep input[classname=select-materials] {
  display: none;
}

::v-deep .el-input-group--prepend {
  width: 100%;
  height: 32px;

}

::v-deep .el-input-group__prepend {
  background: #FFFFFF;
  width: calc(100% - 56px);

  > div {
    width: calc(100% + 40px);
  }
  .input-prepend {
    width: 100%;
    color: #495060;
  }
}

::v-deep .el-input-group__append {
  width: 56px;
}
::v-deep .customer-content2 {
  div:first-child{
    display: block !important;
  }
}
</style>
