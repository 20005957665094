
import { procedureClass } from '@/config/options.config'
import { transformColumnsToForm } from '@/utils'
import { columnsAll, HavecolumnsAll } from './columns'

export const searchFormList = [
  {
    colSpan: 24,
    key: 'produceWorkOrderId',
    tagName: 'div',
    defaultValue: '',
    props: {
      style: {
        width: '100%'
      }
    },
    children: [
      {
        slots: ['produceWorkOrderId']
      }
    ]
  },
  {
    colSpan: 24,
    key: 'producePlanNumber',
    tagName: 'div',
    defaultValue: '',
    props: {
      style: {
        width: '100%'
      }
    },
    children: [
      {
        slots: ['producePlanNumber']
      }
    ]
  },
  {
    colSpan: 24,
    key: 'produceOrderId',
    tagName: 'div',
    defaultValue: '',
    props: {
      style: {
        width: '100%'
      }
    },
    children: [
      {
        slots: ['produceOrderId']
      }
    ]
  },
  {
    colSpan: 24,
    key: 'batchNumber',
    tagName: 'div',
    defaultValue: '',
    props: {
      style: {
        width: '100%'
      }
    },
    children: [
      {
        slots: ['batchNumber']
      }
    ]
  },
  // {
  //   key: 'producePlanNumber',
  //   tagName: 'el-select',
  //   colSpan: 24,
  //   props: {
  //     placeholder: '生产计划号',
  //     filterable: true
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'producePlanNumber',
  //         value: 'producePlanNumber'
  //       },
  //       options: []
  //     }
  //   ]
  // },
  // {
  //   key: 'produceOrderId',
  //   tagName: 'el-select',
  //   colSpan: 24,
  //   props: {
  //     placeholder: '生产订单号',
  //     filterable: true
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'produceOrderNumber',
  //         value: 'id'
  //       },
  //       options: []
  //     }
  //   ]
  // },
  // {
  //   key: 'batchNumber',
  //   tagName: 'el-select',
  //   colSpan: 24,
  //   props: {
  //     placeholder: '生产批次码',
  //     filterable: true
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'batchNumber',
  //         value: 'batchNumber'
  //       },
  //       options: []
  //     }
  //   ]
  // },
  {
    colSpan: 24,
    key: 'keyword',
    tagName: 'div',
    defaultValue: '',
    domProps: { className: 'customer-content2' },
    children: [
      {
        slots: ['mPrepend']
      }
    ]
  },
  {
    key: 'processFlowId',
    tagName: 'el-input',
    colSpan: 24,
    attrs: {
      className: 'select-materials'
    },
    children: [
      {
        slots: ['prepend', 'append']
      }
    ]
  },
  {
    key: 'priority',
    tagName: 'el-select',
    colSpan: 24,
    props: {
      placeholder: '计划优先级'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'isRepair',
    tagName: 'el-select',
    colSpan: 24,
    props: {
      placeholder: '计划属性'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: procedureClass
      }
    ]
  },
  {
    key: 'produceStatus',
    tagName: 'el-select',
    colSpan: 24,
    props: {
      placeholder: '生产状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [{
          id: 0,
          name: '未开始'
        },
        {
          id: 1,
          name: '进行中'
        }]
      }
    ]
  },
  {
    colSpan: 24,
    key: 'planStartTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '计划开始时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  },
  {
    colSpan: 24,
    key: 'planEndTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '计划结束时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  },
  {
    colSpan: 24,
    key: 'actualStartTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '实际开始时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  },
  {
    colSpan: 24,
    key: 'actualEndTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '实际结束时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  }
]
export const dialogFormList = transformColumnsToForm(columnsAll)
export const haveDialogFormList = transformColumnsToForm(HavecolumnsAll)
