import { transformColumnsToForm } from '@/utils'


// 上下工记录表单字段
export const upDownWorkRecordFormList = [
  {
    colSpan: 4,
    key: 'direction',
    tagName: 'el-select',
    props: {
      placeholder: '状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [
          { name: '已上工', id: 0 },
          { name: '已下工', id: 1 }
        ]
      }
    ]
  },
  {
    colSpan: 4,
    key: 'userIds',
    tagName: 'el-select',
    props: {
      placeholder: '人员',
      multiple: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'fullName',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 8,
    key: 'planStartTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '上工开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 8,
    key: 'planEndTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '下工开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 4,
    key: 'procedureIds',
    tagName: 'div',
    domProps: { className: 'custom-sel' },
    children: [
      {
        slots: ['procedureIds']
      }
    ]
  },
  {
    colSpan: 4,
    key: 'workHoursType',
    tagName: 'el-select',
    props: {
      placeholder: '工时类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [
          { name: '正常', id: 2 },
          { name: '停工', id: 0 },
          { name: '返工', id: 1 },
        ]
      }
    ]
  },
  {
    colSpan: 4,
    key: 'workHoursReasonId',
    tagName: 'el-select',
    props: {
      placeholder: '工时原因',
      multiple: false
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
]
