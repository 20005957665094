var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "procedure-detail-wrapper" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "workList" })
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("工单详情")])
          ]
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.permission("GoRepair") &&
            _vm.baseFormData.status === 1 &&
            _vm.isProcess &&
            _vm.baseFormData.isPartialOutbound === 0
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: "primary",
                      loading: _vm.buttonLoadings
                    },
                    on: { click: _vm.startRepair }
                  },
                  [_vm._v(" 发起返修 ")]
                )
              : _vm._e(),
            _vm.permission("outSource") && _vm.baseFormData.status !== 2
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: "primary",
                      loading: _vm.buttonLoading
                    },
                    on: { click: _vm.startOutSource }
                  },
                  [_vm._v(" 外协 ")]
                )
              : _vm._e(),
            _vm.permission("reductionRecord") && _vm.baseFormData.status === 1
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: "primary",
                      loading: _vm.buttonLoading
                    },
                    on: { click: _vm.startReduction }
                  },
                  [_vm._v(" 减产 ")]
                )
              : _vm._e(),
            _vm.permission("divisionRecord") && _vm.baseFormData.status === 1
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: "primary",
                      loading: _vm.buttonLoading
                    },
                    on: { click: _vm.startDivision }
                  },
                  [_vm._v(" 拆单 ")]
                )
              : _vm._e(),
            _vm.permission("PrintWorkOrder")
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: "primary",
                      loading: _vm.buttonLoading
                    },
                    on: { click: _vm.printOrder }
                  },
                  [_vm._v(" 打印 ")]
                )
              : _vm._e(),
            _vm.permission("DelWorkOrder") && _vm.baseFormData.status === 0
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: "primary",
                      loading: _vm.buttonLoading
                    },
                    on: { click: _vm.delTask }
                  },
                  [_vm._v(" 删除 ")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "procedure-detail-body" },
        [
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _c("BaseData", {
                  ref: "BaseData",
                  attrs: { "detail-data": _vm.baseFormData }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("生产物料")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _c("MTable", {
                  ref: "mTable1",
                  attrs: {
                    "show-page": false,
                    height: 120,
                    columns: _vm.columnsBase,
                    data: _vm.materialsLists,
                    "columns-setting": false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "materialsPicture",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            row.materialsPicture &&
                            row.materialsPicture !== "[]"
                              ? _c("el-image", {
                                  staticClass: "drawing-img",
                                  attrs: {
                                    src: row.materialsPicture,
                                    "preview-src-list": _vm.fixImg(
                                      row.materialsPicture
                                    )
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _c(
            "el-tabs",
            {
              staticClass: "detail-tabs",
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "工艺路线", name: "flows" } },
                [
                  _c(
                    "div",
                    { staticStyle: { background: "#fff", padding: "15px" } },
                    [
                      _c("single-process-flow", {
                        ref: "processFlow",
                        attrs: {
                          "is-partial-outbound":
                            _vm.baseFormData.isPartialOutbound,
                          "current-procedure-names":
                            _vm.baseFormData.currentProcedureName,
                          "read-only": true,
                          "all-work-order-task-list": _vm.baseFormData
                            .allWorkOrderTaskList
                            ? _vm.baseFormData.allWorkOrderTaskList
                            : []
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "工艺文件", name: "files" } },
                [
                  _c("MTable", {
                    ref: "mTable2",
                    attrs: {
                      "show-page": false,
                      height: 150,
                      columns: _vm.fileAndDrawings,
                      data: _vm.fileList,
                      "columns-setting": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "remark",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: row.remark,
                                    placement: "top"
                                  }
                                },
                                [_c("div", [_vm._v(_vm._s(row.remark))])]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "fileName",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c(
                              "a",
                              {
                                staticStyle: { color: "#607FFF" },
                                attrs: {
                                  href: row.url,
                                  download: row.name,
                                  target: "_blank"
                                }
                              },
                              [_vm._v(_vm._s(row.name))]
                            ),
                            _c("img", {
                              staticStyle: {
                                width: "11px",
                                "margin-left": "5px"
                              },
                              attrs: {
                                src: require("@/assets/information/procedure/附件@2x.png")
                              }
                            })
                          ])
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm.permission("firstList")
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "批次信息", name: "first" } },
                    [
                      _c(
                        "FunctionHeader",
                        {
                          staticStyle: { "padding-top": "0" },
                          attrs: {
                            tags: _vm.functionHeader.tags,
                            "hidden-search": true
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { slot: "all" },
                              slot: "all"
                            },
                            [
                              _vm.permission(_vm.activeName + "BatchOperate")
                                ? _c(
                                    "el-dropdown",
                                    {
                                      attrs: {
                                        size: "small",
                                        "split-button": ""
                                      },
                                      on: {
                                        command: function(command) {
                                          _vm.allMore(command)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "el-dropdown-link" },
                                        [_vm._v(" 批量操作 ")]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown"
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: { command: "batchPrint" }
                                            },
                                            [_vm._v("打印")]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            { attrs: { command: "allDel" } },
                                            [_vm._v("删除")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _c("MTable", {
                        ref: "mTable4",
                        attrs: {
                          height: 300,
                          data: _vm.batchNumberList,
                          columns: _vm.batchNumbers,
                          "show-page": false,
                          "columns-setting": false
                        },
                        on: { "selection-change": _vm.selectionChange },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  { staticClass: "all-btns" },
                                  [
                                    _vm.permission(_vm.activeName + "PrintCode")
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.printCode(row)
                                              }
                                            }
                                          },
                                          [_vm._v("打印批次码")]
                                        )
                                      : _vm._e(),
                                    _vm.permission(_vm.activeName + "PrintCode")
                                      ? _c("el-divider", {
                                          attrs: { direction: "vertical" }
                                        })
                                      : _vm._e(),
                                    _vm.permission(_vm.activeName + "Del")
                                      ? [
                                          row.isUse !== 1
                                            ? _c("DelPopover", {
                                                staticClass: "li-btn",
                                                on: {
                                                  onOk: function(callback) {
                                                    return _vm.del(
                                                      row,
                                                      callback
                                                    )
                                                  }
                                                }
                                              })
                                            : _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    disabled: ""
                                                  }
                                                },
                                                [_vm._v("删除")]
                                              )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          537270481
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.permission("plansModifyMaterial")
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "计划用料", name: "plans" } },
                    [
                      _vm.permission("plansModifyMaterial")
                        ? _c("PlansMatertials", {
                            attrs: {
                              "base-form-data": _vm.baseFormData,
                              "active-name": _vm.activeName
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.permission("secondList")
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "投产记录", name: "second" } },
                    [
                      _c("FunctionHeader", {
                        staticStyle: { "padding-top": "0" },
                        attrs: {
                          "export-name": "exportReceiveMaterials",
                          "hidden-search": true,
                          "export-params": _vm.searchData
                        }
                      }),
                      _c("MTable", {
                        ref: "mTable5",
                        attrs: {
                          data: _vm.receiveMaterialsList,
                          height: 300,
                          columns: _vm.receiveMaterials,
                          "columns-setting": false,
                          "show-page": false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "name",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(_vm._s(row.materials.name))
                                ])
                              }
                            },
                            {
                              key: "code",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(_vm._s(row.materials.code))
                                ])
                              }
                            },
                            {
                              key: "warehouseType",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.warehouseType === 1
                                          ? row.sitWarehouseName
                                          : row.warehouseName +
                                              "-" +
                                              row.warehouseLocationName
                                      ) +
                                      " "
                                  )
                                ])
                              }
                            },
                            {
                              key: "specifications",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(_vm._s(row.materials.specifications))
                                ])
                              }
                            }
                          ],
                          null,
                          false,
                          129238457
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.permission("thirdList")
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "报工记录", name: "third" } },
                    [
                      _c("FunctionHeader", {
                        staticStyle: { "padding-top": "0" },
                        attrs: {
                          "export-name": "exportReportWork",
                          "hidden-search": true,
                          "export-params": _vm.searchData
                        }
                      }),
                      _c("MTable", {
                        ref: "reportTable",
                        attrs: {
                          "set-data-method": _vm.getTaskList,
                          height: 300,
                          columns: _vm.reportWork,
                          "columns-setting": false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "departureTime",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(row.departureTime))
                                    ]),
                                    _vm.baseFormData.isPartialOutbound === 0 &&
                                    row.cancelCheckOutFlag === 1
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "mar-right",
                                            staticStyle: { width: "40px" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.cancelDeparture(row)
                                              }
                                            }
                                          },
                                          [_vm._v("撤回")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "arrivalTime",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(row.arrivalTime))
                                    ]),
                                    _vm.baseFormData.isPartialOutbound === 0 &&
                                    row.cancelCheckInFlag === 1
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "mar-right",
                                            staticStyle: { width: "40px" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.cancelArrival(row)
                                              }
                                            }
                                          },
                                          [_vm._v("撤回")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "procedureType",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "flex-start",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.procedureType[row.procedureType]
                                            ? _vm.procedureType[
                                                row.procedureType
                                              ].name
                                            : ""
                                        )
                                      )
                                    ]),
                                    row.procedureType === 0 && row.type === 1
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "40px",
                                            height: "22px",
                                            "margin-left": "10px"
                                          },
                                          attrs: {
                                            src: require("@/assets/plans/wai.png")
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var row = ref.row
                                return row.procedureType === 0
                                  ? _c(
                                      "div",
                                      {},
                                      [
                                        _vm.baseFormData.isPartialOutbound === 1
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.scanArrivalRecord(
                                                      row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("进出站记录")]
                                            )
                                          : _vm._e(),
                                        _vm.baseFormData.isPartialOutbound === 1
                                          ? _c("el-divider", {
                                              attrs: { direction: "vertical" }
                                            })
                                          : _vm._e(),
                                        _vm.permission(
                                          _vm.activeName + "SubmitRecord"
                                        )
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.scanWorkRecord(
                                                      row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("报工记录")]
                                            )
                                          : _vm._e(),
                                        _vm.permission(
                                          _vm.activeName + "SubmitRecord"
                                        )
                                          ? _c("el-divider", {
                                              attrs: { direction: "vertical" }
                                            })
                                          : _vm._e(),
                                        _vm.permission(
                                          _vm.activeName + "ScrapHistory"
                                        )
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.scanWasteRecord(
                                                      row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("报废历史")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-tab-pane",
                { attrs: { label: "工步记录", name: "step" } },
                [
                  _c("MTable", {
                    ref: "stepTable",
                    attrs: {
                      data: _vm.workStepList,
                      height: 300,
                      columns: _vm.workStepColumns,
                      "columns-setting": false,
                      "show-page": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "name",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            { staticClass: "step-item" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: row.name,
                                    placement: "top"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(row.name))]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "stepName",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            _vm._l(row.procedureSteps, function(item, idx) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "step-item",
                                  style: {
                                    height: _vm.getHeight(item.params),
                                    borderBottom:
                                      idx === row.procedureSteps.length - 1
                                        ? "none"
                                        : "1px solid #ebeef5"
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: "", alt: "", srcset: "" }
                                  }),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: item.name,
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(item.name))]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        }
                      },
                      {
                        key: "description",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            _vm._l(row.procedureSteps, function(item, idx) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "step-item",
                                  style: {
                                    height: _vm.getHeight(item.params),
                                    borderBottom:
                                      idx === row.procedureSteps.length - 1
                                        ? "none"
                                        : "1px solid #ebeef5"
                                  }
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: item.description,
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.description || "-") +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        }
                      },
                      {
                        key: "remark",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            _vm._l(row.procedureSteps, function(item, idx) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "step-item",
                                  style: {
                                    height: _vm.getHeight(item.params),
                                    borderBottom:
                                      idx === row.procedureSteps.length - 1
                                        ? "none"
                                        : "1px solid #ebeef5"
                                  }
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: item.remark,
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.remark || "-"))]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        }
                      },
                      {
                        key: "params",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            _vm._l(row.procedureSteps, function(item, idx) {
                              return _c(
                                "div",
                                { key: item.id },
                                _vm._l(item.params, function(i, idx2) {
                                  return _c(
                                    "div",
                                    {
                                      key: i.id,
                                      staticClass: "step-item",
                                      style: {
                                        height: "35px",
                                        borderBottom:
                                          idx ===
                                            row.procedureSteps.length - 1 &&
                                          idx2 === item.params.length - 1
                                            ? "none"
                                            : "1px solid #ebeef5"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content: i.name,
                                            placement: "top"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                overflow: "hidden",
                                                "text-overflow": "ellipsis"
                                              }
                                            },
                                            [_vm._v(" " + _vm._s(i.name))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            }),
                            0
                          )
                        }
                      },
                      {
                        key: "paramsRecord",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            _vm._l(row.procedureSteps, function(item, idx) {
                              return _c(
                                "div",
                                { key: item.id },
                                _vm._l(item.params, function(i, idx2) {
                                  return _c(
                                    "div",
                                    {
                                      key: i.id,
                                      staticClass: "step-item",
                                      style: {
                                        height: "35px",
                                        borderBottom:
                                          idx ===
                                            row.procedureSteps.length - 1 &&
                                          idx2 === item.params.length - 1
                                            ? "none"
                                            : "1px solid #ebeef5"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content: i.value,
                                            placement: "top"
                                          }
                                        },
                                        [
                                          i.type === 5 || i.type === 7
                                            ? _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content"
                                                },
                                                _vm._l(
                                                  _vm.getImgs(i.value),
                                                  function(url) {
                                                    return _c("el-image", {
                                                      key: url,
                                                      staticStyle: {
                                                        width: "100px",
                                                        height: "100px"
                                                      },
                                                      attrs: {
                                                        src: url,
                                                        "preview-src-list": _vm.getImgs(
                                                          i.value
                                                        )
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                overflow: "hidden",
                                                "text-overflow": "ellipsis",
                                                "text-wrap": "nowrap"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(i.value || "-")
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            }),
                            0
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm.permission("fourList")
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "返修记录", name: "four" } },
                    [
                      _c("FunctionHeader", {
                        staticStyle: { "padding-top": "0" },
                        attrs: {
                          "export-name": "exportRepairRecord",
                          hiddenSearch: true,
                          "export-params": _vm.searchData
                        }
                      }),
                      _c("MTable", {
                        ref: "repairTable",
                        attrs: {
                          data: _vm.allLists,
                          height: 300,
                          columns: _vm.repairRecord,
                          "columns-setting": false,
                          "show-page": false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "status",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        row.status === 0
                                          ? "待处理"
                                          : row.status === 1
                                          ? "部分处理"
                                          : "已处理"
                                      )
                                    )
                                  ])
                                ])
                              }
                            },
                            {
                              key: "batchNumber",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  row.repairType === "报工返修"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.repairBatchNumber) +
                                            " "
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          " " + _vm._s(row.batchNumber) + " "
                                        )
                                      ])
                                ])
                              }
                            },
                            {
                              key: "planList",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  row.repairType === "报工返修"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.repairPlanNumber) +
                                            " "
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.fixRepair(row.planList)
                                            ) +
                                            " "
                                        )
                                      ])
                                ])
                              }
                            },
                            {
                              key: "sourceWorkOrderNumber",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  row.repairType === "报工返修"
                                    ? _c(
                                        "div",
                                        [
                                          _c("NewPageOpen", {
                                            attrs: {
                                              path: {
                                                name: "workDetail",
                                                query: {
                                                  id: row.repairWorkOrderId
                                                }
                                              },
                                              text: row.repairWorkOrderNumber
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        _vm._l(
                                          row.planList && row.planList,
                                          function(item) {
                                            return _c(
                                              "div",
                                              { key: item.id },
                                              [
                                                item.produceWorkOrder
                                                  ? _c("NewPageOpen", {
                                                      attrs: {
                                                        path: {
                                                          name: "workDetail",
                                                          query: {
                                                            id:
                                                              item
                                                                .produceWorkOrder
                                                                .id
                                                          }
                                                        },
                                                        text:
                                                          item.produceWorkOrder
                                                            .produceWorkOrderNumber
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                ])
                              }
                            }
                          ],
                          null,
                          false,
                          285701768
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.permission("fiveList")
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "质检记录", name: "five" } },
                    [
                      _c("FunctionHeader", {
                        staticStyle: { "padding-top": "0" },
                        attrs: { "hidden-search": true }
                      }),
                      _c("MTable", {
                        ref: "qualityTable",
                        attrs: {
                          data: _vm.getQualityList,
                          height: 300,
                          columns: _vm.inspectionOrder,
                          "columns-setting": false,
                          "show-page": false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "number",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "li-btn",
                                      on: {
                                        click: function($event) {
                                          return _vm.viewQuality(row)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(row.number))]
                                  )
                                ])
                              }
                            },
                            {
                              key: "inspectionType",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.inspectionType === 0
                                          ? "首检"
                                          : "过程检"
                                      )
                                  )
                                ])
                              }
                            },
                            {
                              key: "status",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "faultStatus",
                                      style: {
                                        background:
                                          _vm.qualityStatus[row.status]
                                            .background,
                                        color:
                                          _vm.qualityStatus[row.status].color
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.qualityStatus[row.status].name
                                          )
                                      )
                                    ]
                                  )
                                ])
                              }
                            },
                            {
                              key: "passRate",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  row.inspectionType === 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(row.passRate) + "%")
                                      ])
                                    : _vm._e(),
                                  row.inspectionType === 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(row.result === 1 ? 100 : 0) +
                                            "%"
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              }
                            },
                            {
                              key: "allCount",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  row.inspectionType === 0
                                    ? _c("span", [
                                        _vm._v(_vm._s(row.result === 1 ? 1 : 0))
                                      ])
                                    : _vm._e(),
                                  row.inspectionType === 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(row.okCount) +
                                            "/" +
                                            _vm._s(row.ngCount)
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              }
                            },
                            {
                              key: "reports",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "li-btn",
                                      on: {
                                        click: function($event) {
                                          return _vm.views(row)
                                        }
                                      }
                                    },
                                    [_vm._v("查看")]
                                  )
                                ])
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "li-btn",
                                      on: {
                                        click: function($event) {
                                          return _vm.printQuality(row)
                                        }
                                      }
                                    },
                                    [_vm._v("打印检验报告")]
                                  )
                                ])
                              }
                            }
                          ],
                          null,
                          false,
                          3242609014
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.permission("sixList")
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "减产记录", name: "six" } },
                    [
                      _c("FunctionHeader", {
                        staticStyle: { "padding-top": "0" },
                        attrs: { "hidden-search": true }
                      }),
                      _c("MTable", {
                        ref: "reductionTable",
                        attrs: {
                          data: _vm.reductionRecordList,
                          height: 300,
                          columns: _vm.reductionRecord,
                          "columns-setting": false,
                          "show-page": false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            }
                          ],
                          null,
                          false,
                          442309118
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.permission("sevenList")
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "拆单记录", name: "seven" } },
                    [
                      _c("FunctionHeader", {
                        staticStyle: { "padding-top": "0" },
                        attrs: { "hidden-search": true }
                      }),
                      _c("MTable", {
                        ref: "divisionTable",
                        attrs: {
                          data: _vm.divisionRecordList,
                          height: 300,
                          columns: _vm.divisionRecord,
                          "columns-setting": false,
                          "show-page": false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "newProduceWorkOrderNumber",
                              fn: function(ref) {
                                var row = ref.row
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c("NewPageOpen", {
                                      attrs: {
                                        path: {
                                          name: "workDetail",
                                          query: {
                                            id: row.newProduceWorkOrderId
                                          }
                                        },
                                        text: row.newProduceWorkOrderNumber
                                      }
                                    })
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "action",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  +row.status === 1
                                    ? _c(
                                        "a",
                                        { staticClass: "li-btn color-btns" },
                                        [_vm._v("创建计划")]
                                      )
                                    : _c(
                                        "a",
                                        {
                                          staticClass: "li-btn",
                                          on: {
                                            click: function($event) {
                                              return _vm.getRepairs(row)
                                            }
                                          }
                                        },
                                        [_vm._v("创建计划")]
                                      )
                                ])
                              }
                            }
                          ],
                          null,
                          false,
                          672326826
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticStyle: { height: "50px" } })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "外协申请" },
          on: { onOk: _vm.saveOutSource },
          model: {
            value: _vm.outSourceVisible,
            callback: function($$v) {
              _vm.outSourceVisible = $$v
            },
            expression: "outSourceVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "dialogFormBuilds",
            attrs: {
              "form-data": _vm.dialogFormDatas,
              "form-list": _vm.dialogFormList,
              "custom-rules": _vm.rules
            }
          })
        ],
        1
      ),
      _c("repair-model", {
        attrs: {
          visible: _vm.repairVisible,
          "repair-list": _vm.repairList,
          "produce-work-order-id": parseInt(_vm.params.id),
          "old-process-flow-id": _vm.baseFormData.processFlowId,
          "base-form-data": _vm.baseFormData
        },
        on: {
          "update:visible": function($event) {
            _vm.repairVisible = $event
          },
          loadWord: _vm.loadWords
        }
      }),
      _c("reduction-model", {
        attrs: {
          visible: _vm.reductionVisible,
          "task-list": _vm.taskList,
          "base-form-data": _vm.baseFormData
        },
        on: {
          "update:visible": function($event) {
            _vm.reductionVisible = $event
          },
          loadWord: _vm.loadWord
        }
      }),
      _c("division-model", {
        attrs: {
          visible: _vm.divisionVisible,
          "task-list": _vm.taskList,
          "base-form-data": _vm.baseFormData
        },
        on: {
          "update:visible": function($event) {
            _vm.divisionVisible = $event
          },
          loadWord: _vm.loadWords
        }
      }),
      _c("division-repair-model", {
        attrs: {
          visible: _vm.repairVisibles,
          "repair-record-id": _vm.repairRecordId,
          "old-process-flow-id": _vm.baseFormData.processFlowId,
          "produce-work-order-id": _vm.baseFormData.id,
          "base-form-data": _vm.infos
        },
        on: {
          "update:visible": function($event) {
            _vm.repairVisibles = $event
          },
          loadWord: _vm.loadWords
        }
      }),
      _c("TableDialog", {
        attrs: {
          title: "查看工位数量",
          show: _vm.stationVisible,
          columns: _vm.stationColums,
          "table-data": _vm.stationList
        },
        on: {
          "update:show": function($event) {
            _vm.stationVisible = $event
          }
        }
      }),
      _c("batch-number", {
        attrs: {
          "add-visible": _vm.addVisible,
          "base-form-data": _vm.baseFormData
        },
        on: {
          "update:addVisible": function($event) {
            _vm.addVisible = $event
          },
          "update:add-visible": function($event) {
            _vm.addVisible = $event
          },
          sumbitBatch: _vm.sumbitBatch
        }
      }),
      _c("TableDialog", {
        attrs: {
          title: "查看进出站记录",
          show: _vm.arrivalVisible,
          columns: _vm.arrivalColumns,
          "table-data": _vm.allArrOutList
        },
        on: {
          "update:show": function($event) {
            _vm.arrivalVisible = $event
          },
          cancelArrivals: _vm.cancelCheckInPartial
        }
      }),
      _c("TableDialog", {
        attrs: {
          title: "查看报工记录",
          show: _vm.workVisible,
          columns: _vm.workColumns,
          "table-data": _vm.workList
        },
        on: {
          "update:show": function($event) {
            _vm.workVisible = $event
          },
          scanDetail: _vm.scanDetail,
          BaoDetail: _vm.BaoDetail
        }
      }),
      _c("TableDialog", {
        attrs: {
          title: "查看报废历史",
          show: _vm.wasteVisible,
          columns: _vm.wasteColumns,
          "table-data": _vm.wasteList
        },
        on: {
          "update:show": function($event) {
            _vm.wasteVisible = $event
          }
        }
      }),
      _c(
        "MDialog",
        {
          attrs: {
            value: _vm.show,
            title: "查看上传工序参数",
            "show-footer": false
          },
          on: {
            close: function($event) {
              _vm.show = false
            }
          }
        },
        _vm._l(_vm.groupList, function(row) {
          return _c("div", { key: row.name, staticClass: "params-card" }, [
            _c("div", { staticClass: "header flex-sbc" }, [
              _c("div", [_vm._v("参数组标题：" + _vm._s(row.name))])
            ]),
            _c(
              "div",
              { staticClass: "body" },
              _vm._l(row.fromData, function(item) {
                return _c("div", { key: item.id }, [
                  item.type !== 4
                    ? _c("span", { staticClass: "bot-ti" }, [
                        _vm._v(
                          _vm._s(item.key) +
                            "：" +
                            _vm._s(_vm.fixData(item.value))
                        )
                      ])
                    : _c(
                        "span",
                        { staticClass: "bot-ti" },
                        [
                          _vm._v(_vm._s(item.key) + "： "),
                          _vm._l(_vm.fixArr(item.value), function(items) {
                            return _c(
                              "a",
                              {
                                key: items,
                                staticClass: "imgs",
                                attrs: { href: items, target: "_blank" }
                              },
                              [_c("img", { attrs: { src: items } })]
                            )
                          })
                        ],
                        2
                      )
                ])
              }),
              0
            )
          ])
        }),
        0
      ),
      _c("view-result", {
        ref: "viewResult",
        attrs: { visible: _vm.visibles },
        on: {
          "update:visible": function($event) {
            _vm.visibles = $event
          }
        }
      }),
      _c("PrintResult", { ref: "printQualitys" }),
      _c("PrintOrder", {
        ref: "printCodes",
        attrs: { "common-ids": _vm.commonIds }
      }),
      _vm.memberCode === "reagold"
        ? _c("PrintBatchNumber", {
            ref: "printCode",
            attrs: { "common-id": _vm.commonId }
          })
        : _vm.memberCode === "kiki"
        ? _c("PrintCodes", {
            ref: "printCode",
            attrs: { "common-id": _vm.commonId }
          })
        : (_vm.memberCode === "bbelc" || _vm.memberCode === "bbwz") &&
          this.baseFormData.processFlowCode === "TXGY008"
        ? _c("PrintCodeBbelc", {
            ref: "printCode",
            attrs: { "common-id": _vm.commonId }
          })
        : (_vm.memberCode === "bbelc" || _vm.memberCode === "bbwz") &&
          this.baseFormData.processFlowCode === "ZZGY001"
        ? _c("PrintPackage", {
            ref: "printCode",
            attrs: { "common-id": _vm.commonId }
          })
        : _c("PrintCode", {
            ref: "printCode",
            attrs: { commonId: _vm.commonId }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }