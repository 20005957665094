var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForm", {
            ref: "searchForm",
            staticStyle: { padding: "0" },
            attrs: {
              "form-list-extend": _vm.searchFormList,
              "form-data": _vm.searchFormData
            },
            on: { search: _vm.searchTable }
          })
        ],
        1
      ),
      _c(
        "FunctionHeader",
        {
          ref: "functionHeader",
          staticClass: "func-header",
          staticStyle: { padding: "10px 0 10px 0", "margin-left": "-10px" },
          attrs: {
            "search-title": "请输入工单号/订单号",
            tags: !_vm.selectData.length ? _vm.functionHeader.tags : [],
            "export-name": _vm.functionHeader.exportName,
            "export-params": _vm.functionHeader.exportParams,
            "show-export": !_vm.selectData.length ? true : false
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectData.length,
                  expression: "selectData.length"
                }
              ],
              attrs: { slot: "all" },
              slot: "all"
            },
            [
              _vm.permission("Export")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.batchExport()
                        }
                      }
                    },
                    [_vm._v("批量导出")]
                  )
                : _vm._e(),
              _vm.permission("BatchCheck")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.allCheck()
                        }
                      }
                    },
                    [_vm._v("批量通过")]
                  )
                : _vm._e(),
              _vm.permission("BatchCancel")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.allCancel()
                        }
                      }
                    },
                    [_vm._v("批量驳回")]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "0 10px",
                    display: "inline-block",
                    "font-size": "12px",
                    color: "#393D60"
                  }
                },
                [
                  _vm._v(" 已选 "),
                  _c("span", { staticStyle: { color: "#607FFF" } }, [
                    _vm._v(_vm._s(_vm.getSelectionDataLength))
                  ]),
                  _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "small" },
                  on: { click: _vm.resetSelectData }
                },
                [_vm._v("清空")]
              )
            ],
            1
          )
        ]
      ),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        on: { "selection-change": _vm.selectionChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "produceWorkOrderNumber",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.isShowChild
                    ? _c("NewPageOpen", {
                        attrs: {
                          path: {
                            name: "workDetail",
                            query: { id: row.produceWorkOrderId }
                          },
                          text: row.produceWorkOrderNumber
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          },
          {
            key: "produceOrderNumber",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.isShowChild
                    ? _c("NewPageOpen", {
                        attrs: {
                          path: {
                            name: "orderDetail",
                            query: { id: row.produceWorkOrderId }
                          },
                          text: row.produceOrderNumber
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "faultStatuss",
                    style: {
                      color: _vm.outSourceStatus[row.status]
                        ? _vm.outSourceStatus[row.status].color
                        : "",
                      background: _vm.outSourceStatus[row.status]
                        ? _vm.outSourceStatus[row.status].background
                        : ""
                    }
                  },
                  [_vm._v(_vm._s(_vm.outSourceStatus[row.status].name))]
                )
              ])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.permission("BatchCheck") && row.status === 0
                    ? _c(
                        "a",
                        {
                          staticClass: "li-btn",
                          on: {
                            click: function($event) {
                              return _vm.allCheck(1, row)
                            }
                          }
                        },
                        [_vm._v("通过")]
                      )
                    : _c("a", { staticClass: "li-btn color-btns" }, [
                        _vm._v("通过")
                      ]),
                  _vm.permission("BatchCheck")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("BatchCancel") && row.status === 0
                    ? _c(
                        "a",
                        {
                          staticClass: "li-btn",
                          on: {
                            click: function($event) {
                              return _vm.allCancel(1, row)
                            }
                          }
                        },
                        [_vm._v("驳回")]
                      )
                    : _c("a", { staticClass: "li-btn color-btns" }, [
                        _vm._v("驳回")
                      ]),
                  _vm.permission("BatchCancel")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Cancel") && row.status === 1
                    ? _c(
                        "a",
                        {
                          staticClass: "li-btn",
                          on: {
                            click: function($event) {
                              return _vm.cancels(row)
                            }
                          }
                        },
                        [_vm._v("撤销")]
                      )
                    : _c("a", { staticClass: "li-btn color-btns" }, [
                        _vm._v("撤销")
                      ])
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }