var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "MDialog",
        {
          attrs: { title: "减产", width: "80%" },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c("div", { staticClass: "batchImport" }, [
            _vm.stepActive === 0
              ? _c("div", [
                  _c("div", { staticClass: "repair-detail" }, [
                    _c(
                      "div",
                      { staticClass: "repair-num" },
                      [
                        _c("div", { staticClass: "num-label" }, [
                          _vm._v("当前工序：")
                        ]),
                        _vm.baseFormData.isPartialOutbound === 1
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "allow-create": "",
                                  "default-first-option": "",
                                  placeholder: "请选择或输入"
                                },
                                model: {
                                  value: _vm.formData.produceWorkOrderTaskId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "produceWorkOrderTaskId",
                                      $$v
                                    )
                                  },
                                  expression: "formData.produceWorkOrderTaskId"
                                }
                              },
                              _vm._l(_vm.taskList, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.procedureName,
                                    value: item.produceWorkOrderTaskId
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.selectProduceCode(item)
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          : _c("div", { staticClass: "num-dec" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.baseFormData.produceWorkOrderTaskList
                                    ? _vm.fixProduceData(
                                        _vm.baseFormData
                                          .produceWorkOrderTaskList
                                      )
                                    : ""
                                )
                              )
                            ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "repair-num" },
                          [
                            _c("div", { staticClass: "num-label" }, [
                              _vm._v("减产原因：")
                            ]),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "allow-create": "",
                                  "default-first-option": "",
                                  placeholder: "请选择或输入"
                                },
                                model: {
                                  value: _vm.formData.reason,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "reason", $$v)
                                  },
                                  expression: "formData.reason"
                                }
                              },
                              _vm._l(_vm.reasonList, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "repair-num" },
                          [
                            _c("div", { staticClass: "num-label" }, [
                              _vm._v("选择减产批次：")
                            ]),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  filterable: ""
                                },
                                model: {
                                  value: _vm.formData.batchNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "batchNumber", $$v)
                                  },
                                  expression: "formData.batchNumber"
                                }
                              },
                              _vm._l(_vm.list, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.batchNumber,
                                    value: item.batchNumber
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.selectBatch(item)
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c("MTable", {
                          ref: "mTable",
                          attrs: {
                            data: _vm.batchNumberList,
                            height: 150,
                            columns: _vm.stationColums,
                            "columns-setting": false,
                            "show-page": false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "index",
                                fn: function(ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return _c("div", {}, [
                                    _vm._v(_vm._s($index + 1))
                                  ])
                                }
                              },
                              {
                                key: "quantity",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          min: 0,
                                          max: Number(row.reportWorkQuantity),
                                          "controls-position": "right",
                                          step: 1
                                        },
                                        model: {
                                          value: row.quantity,
                                          callback: function($$v) {
                                            _vm.$set(row, "quantity", $$v)
                                          },
                                          expression: "row.quantity"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            3908071969
                          )
                        })
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm.stepActive === 1
              ? _c("div", [
                  _c("div", { staticClass: "repair-detail" }, [
                    _c("img", {
                      staticClass: "img-sucs",
                      attrs: {
                        src: "https://oss.mubyte.cn/static/data-success.png"
                      }
                    }),
                    _c("div", { staticClass: "suc-tits" }, [_vm._v("减产完成")])
                  ])
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "mubai-dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.stepActive === 0
                ? _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v(_vm._s(_vm.cancelText[_vm.stepActive]))
                  ])
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.submitLoading,
                    disabled: _vm.uploading
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(_vm._s(_vm.confirmTextFun()))]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }