<template>
  <div>
    <MDialog v-model="modalShow" title="发起返修" width="80%">
      <div class="batchImport">
        <el-steps :active="stepActive" finish-status="success" process-status="finish">
          <el-step title="选择返修批次" description="请选择返修批次并输入数量" />
          <el-step title="确定返修工艺路线" description="请确定返修需经历的工艺路线" />
          <el-step title="确认返修" description="请确认并生成相应返修计划、及返修批次码" />
        </el-steps>
        <!-- 选择返修批次 -->
        <div v-if="stepActive === 0">
          <div class="repair-detail">
            <div class="repair-num">
              <div class="num-label">请选择或扫描批次码：</div>
              <el-select v-model="formData.batchNumber" placeholder="请选择或扫描批次码" filterable class="num-select">
                <el-option
                  v-for="item in repairList"
                  :key="item.batchNumber"
                  :label="item.batchNumber"
                  :value="item.batchNumber"
                  @click.native="selectBatch(item)"
                />
              </el-select>
              <el-button class="num-btn" size="small" type="primary" @click="save">确认</el-button>
            </div>
            <div v-if="isShow">
              <div class="repair-num">
                <div class="num-label">请选择发生返修的工序：</div>
                <el-select v-model="formData.procedureName" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, index) in procedureList"
                    :key="index"
                    :label="item.procedureName"
                    :value="item.procedureName"
                    @click.native="selectProcedure(item)"
                  />
                </el-select>
              </div>
              <MTable ref="mTable" :data="formData.repairStationList" :height="150" :columns="stationColums" :columns-setting="false" :show-page="false">
                <div slot="quantity" slot-scope="{ row }">
                  <el-input-number v-model="row.initQuantity" :min="1" :max="row.baoQuantity" controls-position="right" :step="1" />
                </div>
              </MTable>
              <div class="repair-nums">
                返修数量：{{ nums }}
              </div>
              <div v-if="formData.repairParallelProcedureList.length > 0" class="mul-num">
                <div class="tit">请勾选已报工该返修批次的并行工序：</div>
                <div class="mul-check">
                  <div v-for="item in formData.repairParallelProcedureList" :key="item.id" class="check-li">
                    <!--  -->
                    <el-checkbox v-model="item.checked">{{ item.procedureName }}</el-checkbox>
                    <div class="li-right">
                      <div class="ri-tit">报工数量：</div>
                      <el-input-number v-model="item.initQuantity" :min="1" :max="item.baoQuantity" controls-position="right" :step="1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 确定返修工艺路线 -->
        <div v-if="stepActive === 1">
          <div v-if="showProcess" class="repair-detail">
            <div class="repair-num">
              <div class="num-label">请选择返修工艺路线：</div>
              <el-input v-model="formData.processFlowName" class="num-select">
                <template slot="append">
                  <el-button icon="el-icon-more" @click="selectProcessFlows" />
                </template>
              </el-input>
            </div>
            <div class="repair-num">
              <div class="num-label" />
              <img src="@/assets/plans/add.png" class="num-img" @click="addProcess">
              <div class="num-tits" @click="addProcess">新增返修工艺路线</div>
            </div>
          </div>
          <div v-if="!showProcess" class="repair-detail">
            <div class="repair-tit">确认返修工艺路线图</div>
            <process-flow ref="processFlow" :area-id-list="areaIdList" :read-only="saveButton" :process-flow-details="processFlowVersion" :produce-work-order-id="produceWorkOrderId" />
          </div>
        </div>
        <!-- 确认返修 -->
        <div v-if="stepActive === 2">
          <div class="repair-detail">
            <div class="repair-num">
              <div class="num-label num-width">需返修批次码：</div>
              <div class="num-dec">{{ formData.batchNumber }}</div>
            </div>
            <div class="repair-num">
              <div class="num-label num-width">返修数量：</div>
              <div class="num-dec colorNum">{{ nums }}</div>
            </div>
            <div class="repair-num">
              <div class="num-label num-width">返修结算工序：</div>
              <div class="num-dec">
                <span>{{ formData.procedureName }}</span>
                <span class="spas">原工单工序任务从返修结算工序的后道工序任务开始扣除返修数量</span>
              </div>
            </div>
            <div class="repair-num">
              <div class="num-label num-width"><span class="icon-color">*</span>产线：</div>
              <el-select v-model="formData.areaId" placeholder="请选择" class="num-select">
                <el-option
                  v-for="item in areaIds"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="repair-num">
              <div class="num-label num-width"><span class="icon-color">*</span>交期：</div>
              <el-date-picker
                v-model="formData.deliveryTime"
                type="date"
                placeholder="请选择"
                style="width: 250px;"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              />
            </div>
            <div class="repair-num">
              <div class="num-label num-width">计划开始时间：</div>
              <el-date-picker
                v-model="formData.planStartTime"
                style="width: 250px"
                type="datetime"
                placeholder="请选择开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm"
              />
            </div>
            <div class="repair-num">
              <div class="num-label num-width">计划结束时间：</div>
              <el-date-picker
                v-model="formData.planEndTime"
                style="width: 250px"
                type="datetime"
                placeholder="请选择结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm"
              />
            </div>
          </div>
        </div>
        <div v-if="stepActive === 3">
          <div class="repair-detail">
            <img src="https://oss.mubyte.cn/static/data-success.png" class="img-sucs">
            <div class="suc-tits">新增返修计划成功</div>
            <div class="li-all">
              <div class="li-le">新返修批次码：{{ batchNumber }}</div>
              <div class="li-ri" @click="printCode">打印</div>
            </div>
            <div class="li-all">
              <div class="li-le">返修计划号：{{ producePlanNumber }}</div>
              <div class="li-ri" @click="goPlan">去生产计划列表</div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="mubai-dialog-footer">
        <el-button v-if="stepActive !== 3" @click="cancel">{{ cancelText[stepActive] }}</el-button>
        <el-button type="primary" :loading="submitLoading" :disabled="uploading" @click="submitForm">{{ confirmTextFun() }}</el-button>
      </div>
    </MDialog>
    <SelectProcessFlow
      ref="processFlow"
      :visible.sync="processFlowVisible"
      :select-process-and-version-ids="processIdAndVersionId"
      :proce-type="proceType"
      @submit="selectFlow"
    />
    <!--  打印批次码：瑞鼎定制-->
    <PrintBatchNumber v-if="memberCode === 'reagold'" ref="printCode" :common-id="commonId" />
    <!--  打印批次码：奇奇定制-->
    <PrintBatchNumberKiKi v-else-if="memberCode === 'kiki'" ref="printCode" :common-id="commonId" />
    <PrintCode v-else ref="printCode" :common-id="commonId" />
  </div>
</template>
<script>
import { stationColums } from './columns'
import SelectProcessFlow from '@/components/SelectProcessFlow/SelectProcessFlow'
import api from '@/api/production/work-order'
import PrintCode from '@/components/PrintCode/printCode'
import PrintBatchNumber from '@/project-custom/reagold/print-code/printBatchNumber'
import PrintBatchNumberKiKi from '@/project-custom/kiki/print-code/printBatchNumber'
import ProcessFlow from '@/components/OldProcessFlow/ProcessFlow'
import processApi from '@/api/information/production/process'
import orderApi from '@/api/production/orders'

export default {
  name: 'RepairModel',
  components: { PrintBatchNumberKiKi, SelectProcessFlow, PrintCode, PrintBatchNumber, ProcessFlow },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    repairList: {
      type: Array,
      default: []
    },
    produceWorkOrderId: {
      type: Number,
      default: null
    },
    oldProcessFlowId: {
      type: Number,
      default: null
    },
    baseFormData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      commonId: 'print',
      proceType: 1,
      stationColums,
      areaIdList: [],
      formatReturnData: {},
      saveButton: false,
      showProcess: true,
      batchNumber: '',
      producePlanNumber: '',
      stationlist: [],
      isShow: false,
      checked: false,
      processFlowVisible: false,
      batchNumbers: [],
      processIdAndVersionId: [],
      areaIds: [],
      formData: {
        batchNumber: '',
        procedureName: '',
        processFlowId: '',
        processFlowName: '',
        processFlowVersionId: '',
        areaId: undefined,
        produceWorkOrderTaskId: undefined,
        planStartTime: '',
        planEndTime: '',
        repairParallelProcedureList: [],
        repairStationList: [],
        deliveryTime: ''
      },
      processFlowVersion: {},
      currentVersionId: undefined,
      procedureList: [],
      modalShow: false,
      stepActive: 0,
      total: 0,
      loading: false,
      submitLoading: false,
      uploading: false,
      excelContent: [],
      cancelText: {
        0: '取 消',
        1: '上一步',
        2: '上一步',
        3: '关 闭'
      },
      confirmText: {
        0: '下一步',
        1: '下一步',
        2: '确认',
        3: '完成'
      },
      total: 0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 48 - 12 - 220
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    nums() {
      if (this.formData.repairStationList.length > 0) {
        const lists = this.formData.repairStationList.map(item => item.initQuantity)
        const list = lists.filter(item => item)
        const sum = (...arr) => [].concat(...arr).reduce((acc, val) => Number(acc) + Number(val), 0)
        const total = list.length > 0 ? sum(list) : 0
        this.total = total
        return total
      }
      return 0
    }
  },
  watch: {
    total(val) {
      if (this.formData.repairParallelProcedureList.length > 0) {
        this.formData.repairParallelProcedureList.forEach(item => {
          item.max = Math.min(item.baoQuantity, val)
          item.min = val - (item.maxQuantity - item.baoQuantity)
          item.quantity = item.max
        })
        console.log('123', this.formData.repairParallelProcedureList)
        console.log('456', this.formData.repairStationList)
      }
    },
    visible(val) {
      this.modalShow = val
      if (val) {
        this.stepActive = 0
        Object.assign(this.formData,
          {
            batchNumber: '',
            procedureName: '',
            processFlowId: '',
            processFlowName: '',
            processFlowVersionId: '',
            areaId: undefined,
            produceWorkOrderTaskId: undefined,
            planStartTime: '',
            planEndTime: '',
            repairParallelProcedureList: [],
            repairStationList: [],
            deliveryTime: ''
          }
        )
        this.isShow = false
        this.showProcess = true
        this.loading = false
        this.batchNumber = ''
        this.producePlanNumber = ''
      }
    },
    modalShow(val) {
      if (!val) {
        this.$emit('update:visible', false)
      }
    }
  },
  mounted() {
    this.modalShow = this.visible
  },
  methods: {
    fixMinNums(val) {
      return this.nums - (val.maxQuantity - val.quantity)
    },
    // 保存工艺
    async addProcessBtn() {
      this.formatReturnData = await this.$refs.processFlow.formatReturnData()
      const datas = this.$refs.processFlow.processFlow
      console.log(this.formatReturnData.procedureList, this.$refs.processFlow.haveAreaIdList, this.$refs.processFlow.processFlow)
      const data = {
        procedureList: this.formatReturnData.procedureList,
        areaIdList: this.$refs.processFlow.haveAreaIdList,
        processFlow: {
          ...datas,
          processFlowVersion: {
            version: '版本1',
            procedureDetails: JSON.stringify(this.formatReturnData.formatFlow),
            details: JSON.stringify(this.formatReturnData.originFlow),
            isMain: 1
          }
        }
      }
      const res = await processApi.addProcess(data)
      if (res) {
        this.formData.processFlowId = res
        this.getProcessDetailById(res)
      }
      return false
    },
    // id获取工艺详情
    async getProcessDetailById(id) {
      const res = await processApi.getProcessDetail({ id })
      if (res) {
        this.processFlowVersion = res.processFlowVersion
        this.currentVersionId = res.processFlowVersion.id
        this.areaIdList = res.areaIdList
        this.areaIds = res.areaList
        this.formData.areaId = this.areaIds[0].id
        console.log('产线id', this.areaIds)
      }
    },
    async addProcess() {
      await this.getProcessDetailById(this.oldProcessFlowId)
      this.showProcess = false
    },
    goPlan() {
      this.$router.push({ name: 'planList' })
    },
    // 打印
    async printCode() {
      this.commonId = `${this.batchNumber}print`
      // 瑞鼎定制
      if (this.memberCode === 'reagold') {
        let res = { remark: '' }
        if (this.baseFormData.produceOrderId) {
          res = await orderApi.getOrderDetail(this.baseFormData.produceOrderId)
        }
        this.$refs.printCode.print({
          ...this.baseFormData,
          batchNumber: this.batchNumber,
          remark: res.remark
        })
        return false
      }
      // 奇奇定制
      if (this.memberCode === 'kiki') {
        this.$refs.printCode.print({
          ...this.baseFormData,
          batchNumber: this.batchNumber
        })
        return false
      }
      // 产品
      const newData = {
        batchNumber: this.batchNumber,
        materialsCode: this.baseFormData.materialsCode,
        materialsName: this.baseFormData.materialsName,
        materialsSpecifications: this.baseFormData.materialsSpecifications
      }
      this.$refs.printCode.print(newData)
    },
    // 工艺弹窗
    selectProcessFlows() {
      if (this.processFlowId) {
        this.processIdAndVersionId = [this.processFlowId, this.processFlowVersionId]
        this.$refs.processFlow.selectProcessChange(this.processFlowId)
      }
      this.processFlowVisible = true
    },
    selectFlow(data) {
      this.areaIds = data.processData.areaList
      this.formData.processFlowName = data.processData.name
      this.formData.processFlowId = data.processFlowVersion.processFlowId
      this.formData.processFlowVersionId = data.processFlowVersion.id
      this.processFlowVisible = false
    },
    selectBatch(item) {
      this.procedureList = item.repairProcedureList
      this.isShow = false
      this.formData.procedureName = ''
      this.formData.repairStationList = []
    },
    save() {
      if (!this.formData.batchNumber) {
        this.$message.error('请选择或扫描批次码')
        return false
      }
      this.isShow = true
    },
    selectProcedure(item) {
      this.formData.repairStationList = item.repairStationList ? item.repairStationList.map(item => {
        return {
          ...item,
          baoQuantity: Number(item.quantity),
          initQuantity: undefined
        }
      }) : []
      this.formData.produceWorkOrderTaskId = item.produceWorkOrderTaskId
      this.formData.repairParallelProcedureList = item.repairParallelProcedureList
        ? item.repairParallelProcedureList.map(item => {
          return {
            ...item,
            checked: true,
            max: Math.min(item.quantity, this.nums),
            min: this.fixMinNums(item),
            baoQuantity: item.quantity,
            initQuantity: undefined
          }
        }) : []
    },
    async submitForm() {
      if (!this.formData.batchNumber) {
        this.$message.error('请选择或扫描批次码')
        return false
      }
      if (this.formData.procedureName === '') {
        this.$message.error('请选择发生返修的工序')
        return false
      }
      const list = this.formData.repairStationList.length > 0 ? this.formData.repairStationList.every(item => item.initQuantity > 0) : false
      if (!list) {
        this.$message.error('请填写返修数量')
        return false
      }
      if (this.formData.repairParallelProcedureList.length > 0) {
        const checks = this.formData.repairParallelProcedureList.some(item => item.checked)
        const checksList = this.formData.repairParallelProcedureList.filter(item => item.checked)
        const repairlist = checksList.length > 0 ? checksList.every(item => item.initQuantity > 0) : false
        if (checks && !repairlist) {
          this.$message.error('请填写报工数量')
          return false
        }
      }
      if (this.stepActive === 0) {
        this.stepActive = 1
        this.showProcess = true
        return true
      }
      if (this.stepActive === 1) {
        if (this.showProcess && !this.formData.processFlowId) {
          this.$message.error('请选择或新建工艺路线')
          return false
        }
        if (!this.showProcess) {
          await this.addProcessBtn()
          if (!this.formData.processFlowId) {
            return false
          }
          this.stepActive = 2
          return true
        }
        this.stepActive = 2
        this.formData.areaId = this.areaIds[0].id
        return true
      }
      if (this.stepActive === 2) {
        this.submitLoading = true
        await this.getRepair()
        return true
      }
      if (this.stepActive === 3) {
        this.modalShow = false
        this.$emit('loadWord')
      }
    },
    // 确认返修批次
    async getRepair() {
      if ((this.formData.planStartTime && this.formData.planEndTime) && (this.compareDate(this.formData.planStartTime, this.formData.planEndTime))) {
        this.$message.error('开始时间不能大于或等于结束时间')
        return false
      }
      if (this.formData.repairStationList.length > 0) {
        this.formData.repairStationList.forEach(item => {
          item.quantity = item.initQuantity
        })
      }
      if (this.formData.repairParallelProcedureList.length > 0) {
        const repairlist = this.formData.repairParallelProcedureList.filter(item => item.checked)
        repairlist.forEach(item => {
          item.quantity = item.initQuantity
        })
        this.formData.repairParallelProcedureList = repairlist
      }
      const data = Object.assign(this.formData, {
        produceWorkOrderId: this.produceWorkOrderId
      })
      const res = await api.saveRepairDetail(data)
      if (res) {
        this.batchNumber = res.batchNumber
        this.producePlanNumber = res.producePlanNumber
        this.stepActive = 3
      }
      this.submitLoading = false
    },
    compareDate(d1, d2) {
      return ((new Date(d1.replace(/\-/g, '\/'))) >= (new Date(d2.replace(/\-/g, '\/'))))
    },
    cancel() {
      if (this.stepActive === 0) {
        this.modalShow = false
        return true
      }
      if (this.stepActive === 1) {
        this.stepActive = 0
        this.loading = false
        return true
      }
      if (this.stepActive === 2) {
        this.stepActive = 1
        this.loading = false
        return true
      }
    },
    confirmTextFun() {
      return this.confirmText[this.stepActive]
    }
  }
}
</script>

<style lang="scss" scoped>
.repair-detail {
  background: #F6F7F9;
  border-radius: 4px;
  padding: 20px;
  margin-top: 25px;
  .repair-tit {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #4B4F77;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .img-sucs {
    width: 279px;
    height: 279px;
    margin: 25px auto 7px auto;
    display: block;
  }
  .suc-tits {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393D60;
    line-height: 20px;
    margin-top: -120px;
    text-align: center;
    margin-bottom: 98px;
  }
  .li-all {
    display: flex;
    justify-content: center;
    height: 32px;
    align-items: center;
    .li-le {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
      line-height: 20px;
      margin-right: 20px;
    }
    .li-ri {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .repair-num {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    .num-label {
      width: 160px;
      height: 32px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
      line-height: 32px;
      text-align: right;
    }
    .icon-color {
      color: #f00;
      margin-right: 5px;
    }
    .num-dec {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
      line-height: 32px;
      text-align: left;
      .spas {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9597AE;
        line-height: 17px;
        display: block;
      }
    }
    .colorNum {
      color: #f00;
    }
    .num-width {
      width: 100px;
    }
    .num-select {
      width: 250px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 4px;
    }
    .num-btn {
      width: 88px;
      height: 32px;
      margin-left: 20px;
    }
    .num-img {
      width: 18px;
      height: 18px;
      display: inline-block;
      cursor: pointer
    }
    .num-tits {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #607FFF;
      line-height: 20px;
      margin-left: 10px;
      cursor: pointer
    }
  }
  .repair-nums {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456B;
    line-height: 20px;
    margin: 20px 0 27px 0;
  }
  .mul-num {
    padding: 20px;
    border-radius: 4px;
    border: 1px dashed #9597AE;
    .tit {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #41456B;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .mul-check {
      .check-li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        ::v-deep {
          .el-checkbox__label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #41456B;
            line-height: 20px;
          }
        }
        .li-right {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .ri-tit {
            height: 32px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #41456B;
            line-height: 32px;
            width: 150px;
            text-align: right;
          }
        }
      }
    }
  }
}
.batchImport {
  padding: 10px;

  .down-template {
    background: #F6F7F9;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0 20px 0;

    .down-le {
      width: 6px;
      background: #607FFF;
      border-radius: 4px 0px 0px 4px;
      margin-right: 20px;
      padding: 20px 0;
    }

    .down-ri {
      padding: 20px 0;

      .down-tit {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 20px;
        margin-bottom: 2px;
      }

      .down-des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9FA1B7;
        line-height: 20px;
      }

      .down-btn {
        margin-top: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 21px;
          height: 15px;
          color: #607FFF;;
        }

        .download {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #607FFF;
          line-height: 20px;
          margin-left: 8px;
        }

        .icons {
          color: #607FFF;
          display: inline-flex;
          align-items: center;

          ::v-deep .el-icon-upload {
            font-size: 22px;
            color: #607FFF;
          }
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0091FF;
          line-height: 20px;
          margin-left: 8px;
        }
      }
    }
  }

  .check-success {
    margin-top: 30px;

    .suc-top {
      background: rgba(0, 171, 41, 0.02);
      border-radius: 4px;
      border: 1px solid #00AB29;
      padding: 20px 0 20px 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A9ABBF;
        line-height: 22px;
      }
    }

    .imgs {
      width: 264px;
      height: 166px;
      margin: 43px auto 7px auto;
      display: block;
    }

    .check-tip {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A9ABBF;
      line-height: 20px;
      text-align: center;
    }
  }

  .check-error {
    margin-top: 30px;

    .err-top {
      background: rgba(224, 32, 32, 0.02);
      border-radius: 4px;
      border: 1px solid #E02020;
      padding: 20px 0 20px 25px;
      margin-bottom: 25px;

      .tit {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #545982;
        line-height: 22px;
      }

      .des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A9ABBF;
        line-height: 22px;
      }
    }

    .font-color {
      color: #f00;
    }

    .font-wight {
      font-weight: 700;
    }

  }

  .suc-tit {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393D60;
    line-height: 20px;
    text-align: center;
    margin-top: -50px;
  }

  .check-tips {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A9ABBF;
    line-height: 20px;
    text-align: center;
  }

  .img-suc {
    width: 279px;
    height: 279px;
    margin: 125px auto 7px auto;
    display: block;
  }
}

.el-dialog__footer {
  padding: 0 !important;
  height: 57px;
}

.mubai-dialog-footer {
  position: absolute;
  height: 57px;
  width: 100%;
  border-top: 1px solid #DDE3EB;
  padding: 10px 20px;
  bottom: 0;
  background: #FFFFFF;

  button {
    padding: 10px 40px !important;

    &:first-child {
      background: #F8F8FB;
      color: #8A8CA5;
      transition: all .5s;

      &:hover {
        border-color: #dadaf6;
        background: #f0f0f8;
      }
    }

    &:last-child {
      background: $primary-color;
      color: #ffffff;
      transition: all .5s;

      &:hover {
        background: #7691fc;
      }
    }
  }
}

.center-content {
  text-align: center;
}
::v-deep {
  .has-error {
   background: rgba(224, 32, 32, 0.04);
  }
  .el-input-number{
    height:32px;
    line-height:32px;
    width: 100%;
  }

  .el-input__inner{
    height:32px;
    line-height:32px;
  }
  .el-input-number.is-controls-right .el-input-number__increase, .el-input-number.is-controls-right .el-input-number__decrease{
    line-height:15px;
  }
  .el-input__icon {
    line-height:32px;
  }
}
</style>
