var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "certainNumberList" })
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("新增定额")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-form-inline",
                attrs: {
                  "label-position": "right",
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "98px"
                }
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "定额单号：", prop: "number" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.number,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "number", $$v)
                                },
                                expression: "ruleForm.number"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注：" } },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", rows: 1 },
                              model: {
                                value: _vm.ruleForm.remark,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "remark", $$v)
                                },
                                expression: "ruleForm.remark"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("订单产品")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-button",
              {
                staticClass: "e-btn",
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.selectadd }
              },
              [_vm._v("选择生产订单")]
            ),
            _c("MTable", {
              ref: "mUnitTable",
              attrs: {
                height: _vm.height,
                "show-page": false,
                data: _vm.orderList,
                columns: _vm.columnsOrder,
                "columns-setting": false
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "status",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(
                        _vm._s(
                          row.status === 0
                            ? "未计划"
                            : row.status === 1
                            ? "全部计划未完工"
                            : row.status === 2
                            ? "已完工"
                            : row.status === 3
                            ? "全部计划未完工"
                            : "--"
                        )
                      )
                    ])
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "order-footer" }, [
        _c("div", { staticClass: "footer-le" }),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v("取消")
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.submitForms } },
              [_vm._v("确认")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c("select-orders", {
        ref: "selectMaterials",
        attrs: {
          selectMaterials: _vm.selectMaterials,
          visible: _vm.showMaterials,
          single: false,
          "is-producible": true,
          "is-add": true
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials,
          addMaterial: _vm.addMaterial
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }