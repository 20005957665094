
export const noplans = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureName',
    label: '投入工序',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsQuantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  }
  // {
  //   prop: 'isMaterial',
  //   label: '关键物料',
  //   sortable: false,
  //   hiddenSearch: true
  // }
]
export const plans = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureName',
    label: '投入工序',
    sortable: false,
    hiddenSearch: true,
    slotName: 'procedureName',
    width: 200
  },
  {
    prop: 'materialsQuantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'materialsQuantity'
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  }
  // {
  //   prop: 'isMaterial',
  //   label: '关键物料',
  //   sortable: false,
  //   hiddenSearch: true
  // }
]
