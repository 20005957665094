var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { background: "#F0F2F5" } },
    [
      _c(
        "el-button",
        {
          staticStyle: { margin: "0 0 10px 0" },
          attrs: { icon: "el-icon-plus", type: "primary", size: "medium" },
          on: {
            click: function($event) {
              _vm.visible = true
            }
          }
        },
        [_vm._v("选择入库模具")]
      ),
      _c(
        "div",
        { staticClass: "orderAdd" },
        [
          _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("已选模具")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                _c(
                  "MTable",
                  {
                    ref: "mUnitTable",
                    attrs: {
                      height: _vm.$store.state.app.contentHeight - 300,
                      "show-page": false,
                      data: _vm.tableList,
                      columns: _vm.columnsInStock,
                      "columns-setting": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "code",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("el-input", {
                            attrs: { maxlength: 50, placeholder: "请输入编码" },
                            model: {
                              value: row.code,
                              callback: function($$v) {
                                _vm.$set(row, "code", $$v)
                              },
                              expression: "row.code"
                            }
                          })
                        }
                      },
                      {
                        key: "lossScale",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              attrs: {
                                min: 0,
                                max: 100,
                                "controls-position": "right",
                                placeholder: "请输入损耗进度"
                              },
                              on: {
                                blur: function($event) {
                                  row.lossScale = _vm.sliceVal(row.lossScale, 2)
                                }
                              },
                              model: {
                                value: row.lossScale,
                                callback: function($$v) {
                                  _vm.$set(row, "lossScale", $$v)
                                },
                                expression: "row.lossScale"
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "isScraped",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择报废状态" },
                              model: {
                                value: row.isScraped,
                                callback: function($$v) {
                                  _vm.$set(row, "isScraped", $$v)
                                },
                                expression: "row.isScraped"
                              }
                            },
                            _vm._l(_vm.scrapOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        }
                      },
                      {
                        key: "action",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onCopy(row, $index)
                                      }
                                    }
                                  },
                                  [_vm._v("复制")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.tableList.splice($index, 1)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "fit-content" },
                        attrs: { slot: "codeHeader", type: "text" },
                        on: { click: _vm.setCode },
                        slot: "codeHeader"
                      },
                      [_vm._v("批量生成 ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "order-footer" }, [
            _c("div"),
            _c(
              "div",
              { staticClass: "footer-ri" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.go(-1)
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.save } },
                  [_vm._v("确定")]
                )
              ],
              1
            )
          ]),
          _c("SelMould", {
            attrs: { visible: _vm.visible, "select-moulds": _vm.tableList },
            on: {
              "update:visible": function($event) {
                _vm.visible = $event
              },
              submitForm: _vm.getSelData
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }