<template>
  <div class="searchs">
    <!-- 顶部折叠搜索 -->
    <el-row>
      <el-col :md="24" :gutter="10">
        <el-row :gutter="10" type="flex" justify="start" style="flex-flow:row wrap">
          <el-col :md="6">
            <el-date-picker
              v-model="searchFormData.operateTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="报工开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              clearable
              @change="searchData"
            />
          </el-col>
          <el-col :md="4">
            <el-select
              v-model="searchFormData.procedureId"
              placeholder="工序"
              collapse-tags
              filterable
              multiple
              clearable
              @change="searchData"
            >
              <el-option
                v-for="item in currentProcedures"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="4">
            <el-select
              v-model="searchFormData.areaId"
              placeholder="产线"
              collapse-tags
              filterable
              multiple
              clearable
              @change="searchData"
            >
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="4">
            <el-select
              v-model="searchFormData.stationId"
              placeholder="报工工位"
              collapse-tags
              filterable
              multiple
              clearable
              @change="searchData"
            >
              <el-option
                v-for="item in stationList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6" class="all-btns">
            <el-button type="primary" size="small" @click="searchData">查询</el-button>
            <el-button size="small" @click="resetData">重置</el-button>
            <div class="showMore" :style="{background: show ? '' : '#F8F8F8'}" @click="changeMore">
              <span>高级筛选</span>
              <i v-if="show" class="el-icon-caret-bottom icons" />
              <i v-else class="el-icon-caret-top" />
            </div>
          </el-col>
        </el-row>
        <el-row v-if="!show" type="flex" justify="start" style="flex-flow:row wrap" :gutter="10">
          <el-col :md="6">
            <SelectMultipleMaterialsNew ref="multipleMaterial" @typeChange="searchData" />
          </el-col>
          <el-col :md="4">
            <el-select
              v-model="searchFormData.operatorId"
              placeholder="操作人"
              collapse-tags
              filterable
              multiple
              clearable
              @change="searchData"
            >
              <el-option
                v-for="item in operatorList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="4">
            <el-select
              v-model="searchFormData.frequencyId"
              placeholder="班次"
              filterable
              clearable
              @change="searchData"
            >
              <el-option
                v-for="item in flightList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :md="6">
            <SelectRemoteSearch
              ref="mouldOption"
              class="select"
              clearable
              placeholder="唯一编码/模具名称/规格"
              :multiple="true"
              option-val="id"
              option-label="id,moldClazzName,moldClazzSpec"
              :api="workApi.getMouldList"
              size-name="size"
              collapse-tags
              @change="val => {
                handleSelect( val)
              }
              "
            />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { orderSource, orderStatus, pageStatus, isDelay } from '@/config/options.config'
import { getUUid } from '@/utils'
import dayjs from 'dayjs'
import materialsApi from '@/api/information/materials'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import orderApi from '@/api/production/orders'
import workApi from '@/api/production/work-order'

export default {
  components: { SelectMultipleMaterialsNew },
  props: {
    formOption: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      workApi,
      orderApi,
      orderLists: [],
      orderNumbers: [],
      currentProcedures: [],
      areaList: [],
      flightList: [],
      operatorList: [],
      workOrderList: [],
      stationList: [],
      isDelay,
      orderSource,
      orderStatus,
      pageStatus,
      show: true,
      searchFormData: {
        operateTime: '',
        procedureId: '',
        areaId: '',
        stationId: '',
        operatorId: '',
        frequencyId: ''
      },
      dates: [],
      selectKeyword: '0',
      dates: [],
      searchDatas: [],
      remoteSelectCondition: [{
        id: getUUid(),
        fieldName: 'isEnabled',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      }],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = dayjs().format('YYYY-MM-DD 23:59:59')
            const start = dayjs().format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '昨天',
          onClick(picker) {
            const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')
            const start = dayjs().subtract(1, 'day').format('YYYY-MM-DD 00:00:00')
            // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  mounted() {
    this.orderNumbers = this.formOption.orderOptions
    this.currentProcedures = this.formOption.proceduresOptions
    this.areaList = this.formOption.areaOptions
    this.flightList = this.formOption.flightOptions
    this.operatorList = this.formOption.operatorOptions
    this.workOrderList = this.formOption.workOrderOptions
    this.stationList = this.formOption.stationOptions
  },
  methods: {

    handleSelect(val) {
      this.moldInstanceIds = val
      this.searchData()
    },
    // typeChange(val) {
    //   this.selectKeyword = val
    // },
    changeMore() {
      this.show = !this.show
    },
    // 顶部查询
    searchData() {
      const obj = {}
      const searchDatas = []
      Object.keys(this.searchFormData).forEach(item => {
        if (Array.isArray(this.searchFormData[item])) {
          if (this.searchFormData[item].length > 0) {
            if (item === 'procedureId' ||
              item === 'areaId' ||
              item === 'stationId' ||
              item === 'operatorId') {
              searchDatas.push({
                id: getUUid(),
                fieldName: item,
                fieldType: 'number',
                fieldValue: this.searchFormData[item].join('^'),
                operator: 'in'
              })
            } else {
              searchDatas.push({
                id: getUUid(),
                fieldName: item,
                fieldType: 'date',
                maxValue: `${dayjs(this.searchFormData[item][1]).format('YYYY-MM-DD')} 23:59:59`,
                minValue: `${dayjs(this.searchFormData[item][0]).format('YYYY-MM-DD')} 00:00:00`,
                operator: 'range'
              })
            }
          }
        } else if (this.searchFormData[item] || this.searchFormData[item] === 0) {
          searchDatas.push({
            id: getUUid(),
            fieldName: item,
            fieldType: typeof this.searchFormData[item],
            fieldValue: this.searchFormData[item],
            operator: typeof this.searchFormData[item] === 'number' ? 'eq' : 'like'
          })
        }
      })
      let keyword
      if (this.$refs.multipleMaterial !== undefined) {
        keyword = this.$refs.multipleMaterial.keyword.code
      } else {
        keyword = ''
      }

      // console.log('搜索=》', this.moldInstanceIds)
      this.$emit('searchData', searchDatas, this.selectKeyword, keyword, this.moldInstanceIds)
    },
    // 顶部重置
    resetData() {
      this.moldInstanceIds = ''
      if (this.$refs.mouldOption !== undefined) {
      this.$refs.mouldOption.value = []
      }
      this.searchFormData = {
        operateTime: '',
        procedureId: '',
        areaId: '',
        stationId: '',
        operatorId: '',
        frequencyId: ''
      }
      if (this.$refs.multipleMaterial !== undefined) {
        this.$refs.multipleMaterial.keyword = ''
      }
      this.$emit('resetData')
    }
  }
}
</script>

<style scoped lang="scss">
.searchs {
  padding-top: 10px;
  ::v-deep {
    .el-button-group .el-button {
      height: 32px;
    }
  }
  ::v-deep {
    .el-col-md-5,
    .el-col-md-6,
    .el-col-md-8 {
      margin-bottom: 10px;
    }
    .el-date-editor--datetimerange.el-input,
    .el-date-editor--datetimerange.el-input__inner {
      width: 100%;
      height: 32px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
      height: 32px;
    }
    .el-select {
      width: 100%;
    }
    .el-input--suffix .el-input__inner {
      height: 32px;
    }
    .el-button-group .el-button {
      height: 32px;
    }
    .el-date-editor .el-range-separator,
    .el-date-editor .el-range__icon {
      line-height: 27px;
    }
  }
  .showMore {
    width: 88px;
    height: 40px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #607fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    .icons {
      color: #dde3eb;
    }
    &:hover {
      background: #f8f8f8;
      i {
        color: #607fff;
      }
    }
  }
  .all-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    ::v-deep {
      .el-button {
        height: 32px;
        line-height: 4px;
      }
    }
  }
}
</style>
